import React, { useState, useEffect } from "react";
import amplitude from "amplitude-js";
import { NavLink, useHistory } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import Title from "../../common/Title";
import Button from "../../common/Button";
import Loader from "../../common/Loader";
import IconSprite from "../../common/IconSprite";
import CategoryTag from "../../common/CategoryTag";
import ExerciseCard from "./components/ExerciseCard";

import "./workoutPlan.sass";

const WorkoutPlan = ({
  title,
  description,
  workouts,
  workoutPlanId,
  handleToggleExercise,
  isLifestyle,
  singleVideo,
  singleVideoData,
  handleSendWorkoutPlanToResults,
  author,
  category,
  level,
  handleSendGAProgress,
  handleSendGAPause,
  ...props
}) => {
  const history = useHistory();

  const [singleVideoIsReady, setSingleVideoIsReady] = useState(false);

  const workoutsIsFound = workouts.some((item) => {
    return item.workout_exercises.length;
  });

  useEffect(() => {
    if (history.location.state?.from !== "timer") {
      const data = {
        title,
        category,
        level,
      };
      if (author) {
        data.author = `${author.firstName} ${author.lastName}`;
      }
      amplitude.getInstance().logEvent("workout", data);
    } else {
      history.replace();
    }
  }, [title, category, author, level, history]);

  return (
    <div className="workout-plan-page content-page">
      <div className="container">
        <div className="content-page-inner workout-plan">
          <div className="workout-plan__left content-page-left">
            <Title className="workout-plan__title">{title}</Title>
            <div className="title-add">{description}</div>
            <div className="workout-plan__tags">
              <CategoryTag category={category} color={level} />
              {author && (
                <NavLink
                  to={`/author/${author.id}`}
                  className="workout-author-name"
                >
                  Created by {`${author.firstName} ${author.lastName}`}
                </NavLink>
              )}
            </div>
            {!isLifestyle && workoutsIsFound && (
              <NavLink to="/workout-play" className="workout-plan__play-link">
                <Button
                  className="workout-plan__play"
                  icon={
                    <IconSprite className="icon-play-small" name="play-small" />
                  }
                >
                  Start
                </Button>
              </NavLink>
            )}
          </div>
          <div className="workout-plan__right content-page-right">
            {workoutsIsFound ? (
              <>
                {singleVideo ? (
                  <div className="exercise-single">
                    <Title size="medium">{singleVideoData.title}</Title>
                    <div className="exercise-single-video">
                      {singleVideoIsReady || (
                        <div className="exercise-single-video-loader">
                          <Loader size={32} />
                        </div>
                      )}
                      <Vimeo
                        video={singleVideoData.video}
                        color="000000"
                        loop={true}
                        onReady={() => {
                          setSingleVideoIsReady(true);
                        }}
                        onTimeUpdate={(event) => {
                          if (event.seconds > 10) {
                            handleSendWorkoutPlanToResults();
                          }
                          handleSendGAProgress(event.percent);
                        }}
                        onPause={(event) => {
                          handleSendGAPause();
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {workouts.map((workout) => {
                      const setId = workout.id;
                      return (
                        <div className="exercise-list-wrap" key={workout.id}>
                          <Title size="medium">{workout.name}</Title>
                          <div className="title-add">
                            Repeat {workout.repeatCount} time(s)
                          </div>
                          <div className="exercise-list">
                            {workout.workout_exercises.map((exercise) => {
                              return (
                                <ExerciseCard
                                  setId={setId}
                                  exerciseId={exercise.id}
                                  handleToggleExercise={handleToggleExercise}
                                  key={exercise.id}
                                  repsDuration={exercise.repsDuration}
                                  workoutPlanId={workoutPlanId}
                                  additionalInfo={exercise.additionalInfo}
                                  isOpen={exercise.isOpen}
                                  {...exercise.exercise}
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <div className="workout-plan-empty">
                <div className="workout-plan-empty__inner">
                  <IconSprite className="icon-workouts" name="workouts" />
                  <div className="workout-plan-empty__desc">
                    Exercises not found
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkoutPlan;
