import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import './filterTabs.sass'

const FilterTabs = ({
  size,
  className,
  filterActive,
  filterTabs,
  handleClick
}) => {
  const [activeFilter, setActiveFilter] = useState(filterActive || null);

  useEffect(() => {
    setActiveFilter(filterActive);
  }, [setActiveFilter, filterActive]);

  const onClickFilter = (name, value) => {
    if (name === activeFilter) return false;

    setActiveFilter(name);

    if (handleClick) {
      handleClick(value || name, name);
    }
  };

  return (
    <div className={clsx("filters", {
      [className]: className,
      ['filters--' + size]: size
    })}>
      {
        filterTabs.map(item => {
          let classes = '';

          if (item.name === activeFilter) {
            classes = 'active';
          } else if (!activeFilter && item.name === 'all') {
            classes = 'active';
          }
          return (
            <div
              key={item.name}
              className={`filter-item ${classes}`}
              onClick={() => onClickFilter(item.name, item.value)}
            >
              <span>
                {item.title}
              </span>
              {
                !item.children || item.children
              }
            </div>
          )
        })
      }
    </div>
  )
};

export default FilterTabs;
