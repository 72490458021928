import React, { useEffect, useRef, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import clsx from 'clsx';

import IconSprite from '../../../../common/IconSprite';
import Timer from '../Timer';

import './WorkoutPlaySlide.sass';

const WorkoutPlaySlide = ({
  isActive,
  video1,
  handleSlideNext,
  toggleWorkoutPause,
  isWorkoutPause,
  preview,
  phrase,
  audioUpdatePlay,
  isAudioMute,
  audioToggleMute,
  audioSignalPlay,
  duration,
  workoutPlanName,
  handleFullScreen
}) => {
  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const timer = useRef(null);
  const preLoadTimer = useRef(null);
  const [isPlay, setIsPlay] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isPlayAudio, setIsPlayAudio] = useState(false);
  const [isPreTimerStart, setIsPreTimerStart] = useState(false);

  useEffect(() => {
    if (isActive && !isReady && !isPlayAudio) {
      audioUpdatePlay(phrase);
      setIsPlayAudio(true);
    }

    if (!isActive && !isFinish) {
      setIsPlayAudio(false);
    }
  }, [isActive, phrase, audioUpdatePlay, isPlayAudio, setIsPlayAudio, isReady, isFinish]);

  useEffect(() => {
    if (isActive && !isReady) {
      preLoadTimer.current.onStart();
    }

    if (!isFinish && !isActive) {
      setIsReady(false);
      setIsPreTimerStart(false);
    }

    if (isActive && !isReady && isWorkoutPause) {
      preLoadTimer.current.onPause();
    }

  }, [isActive, isFinish, isReady, isWorkoutPause]);

  useEffect(() => {
    if (isPlay && !isFinish && isReady) {
      timer.current.onStart();
    }

    if (!isActive && isPlay && !isFinish) {
      setIsPlay(false);
      timer.current.onReset();
      timer.current.onStop();
    }
  }, [isPlay, isActive, setIsPlay, isFinish, isReady]);

  useEffect(() => {
    if (isPlay && isWorkoutPause) {
      timer.current.onPause();
    } else if (isReady && isPlay && !isWorkoutPause) {
      timer.current.onResume();
    }
  }, [isWorkoutPause, isPlay, isReady]);

  return (
    <div className={clsx("workout-play-slide", {
      "is-not-ready": !isReady
    })}>
      {(!isReady && isActive) && (
        <div className="workout-play-slide__pre-play">
          <div className="workout-play-slide__pre-timer">
            <Timer
              onTimerEnd={() => {
                setIsReady(true);
              }}
              onTimerBeforeEnd={() => {
                audioSignalPlay();
              }}
              onTimerBeforeStart={() => {
                setIsPreTimerStart(true);
              }}
              onSkipTimer={audioSignalPlay}
              startImmediately={false}
              time={6}
              ref={preLoadTimer}
              finalWord="GO!"
              startWord=" "
            />
            <div className={clsx("workout-play-slide__pre-phrase", {
              "starting": isPreTimerStart
            })}>Get Ready</div>
          </div>
        </div>
      )}
      <Timer
        className="workout-play-main-timer"
        onTimerEnd={() => {
          setIsFinish(true);
          handleSlideNext();
        }}
        ref={timer}
        startImmediately={false}
        time={video1 ? duration : 0}
      />
      <div className="workout-play-slide-nav">
        <div className="workout-play-main-name">{workoutPlanName}</div>
        <div className="workout-play-slide-muting" onClick={audioToggleMute}>
          {isAudioMute ? (
            <IconSprite className="mute-icon" name="mute" />
          ) : (
              <IconSprite className="unmute-icon" name="unmute" />
            )}
        </div>
        <div className="workout-play-slide-full" onClick={handleFullScreen}>
          <IconSprite className="full-screen-icon" name="full-screen" />
        </div>
      </div>
      <div className="workout-play-pause-block" onClick={toggleWorkoutPause} />
      <div className="workout-play-video">
        {(video1 && isActive) && (
          <>
            <div className={"workout-play-video__preview"}>
              <img src={preview} alt="" />
            </div>
            <Vimeo
              className="workout-play-video__item"
              video={video1}
              color="000000"
              loop={true}
              muted={true}
              paused={isWorkoutPause}
              autoplay={true}
              controls={false}
              onPlay={() => {
                setIsPlay(true);
              }}
              onProgress={() => {
                if (isActive && isIos && !isPlay) {
                  setIsPlay(true);
                }
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default WorkoutPlaySlide;
