import React from 'react';
import Loader from '../Loader';

const PageLoader = props => {
  return (
    <div className="page-loader">
      <Loader size={34} color="#7DF3B3" />
    </div>
  )
};

export default PageLoader;
