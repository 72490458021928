import React from 'react';
import Helmet from 'react-helmet'

import "./Docs.sass";

const Privacy = () => {
  return (
    <>
      <Helmet>
        <body className="docs-page" />
        <title>General Conditions</title>
      </Helmet>
      <h1>Privacy Policy</h1>
      <p>We strive for transparency and trust when it comes to protecting your privacy and we aim to clearly explain how we collect and process your information. It’s important to us that you should enjoy using our products, services and website(s) without compromising your privacy in any way. This policy outlines how we collect and use different types of personal and behavioural information, and the reasons for doing so. You have the right to access, change or delete your personal information at any time and you can find out more about this and your rights by calling our customer service line. Alternatively, you can get in touch directly with our Data Privacy Team; their details are in our “Contact Us” section.<br />
            You should read this policy in conjunction with our Cookie Policy and General Conditions to ensure that you understand how we collect and use your information.<br />
              Our policies will be updated from Time-to-time. Please refer back regularly to keep yourself updated.</p>
      <p>This policy applies to “users” and "customers" (or "you"); that is anyone ordering, registering or interacting with any product or service. This includes event attendees, subscribers, registrants, website users, app users etc.</p>
      <h3>Collection and use of your information</h3>
      <p>The information you provide us, and that which we gather based on your activity, helps us to deliver more relevant content as well as create a more seamless experience across the products and channels through which you may access that content.<br />
                We collect and store some information about you in order to deliver products and services to you (such as your subscription). We share some of that information with trusted suppliers (including payment providers and postal services) to ensure the delivery of those products and services, or other third parties who provide or give personalised recommendations on things we/they think will be of interest to you. We also collect information when you use our products and services and further details are provided below.</p>
      <h3>Information we collect<br />
                  Information you provide us which we need in order to provide you with our services and products</h3>
      <p>There is certain personal information that we need in order to provide the products and services that you have chosen. Examples of details we collect in order to fulfil a subscription include but not limited to:</p>
      <ul>
        <li>Name</li>
        <li>Postal address (including postcode)</li>
        <li>Email address</li>
        <li>Payment details</li>
      </ul>
      <p>In addition to the above, examples of the details we collect for our business could include:</p>
      <ul>
        <li>Job title</li>
        <li>Work email</li>
        <li>Company</li>
        <li>Country</li>
        <li>Industry</li>
      </ul>
      <p>We may also ask or collect for different information such as phone numbers, gender or date of birth, as indicated when you interact with those products or services.</p>
      <h3>Information we collect through your use of our products</h3>
      <p>When you use any of our online platforms or apps we collect information that helps us to deliver the service you have chosen and to improve your experience. This is done through Cookies and other technologies. Examples of the type of information we may collect are:</p>
      <ul>
        <li>Browser</li>
        <li>Email provider</li>
        <li>The pages you read on our websites and how you got to them</li>
        <li>Device</li>
        <li>IP address</li>
        <li>Internet connection</li>
        <li>Location (in some cases)</li>
      </ul>
      <p>You can also volunteer to disclose additional information which may not be essential for us to deliver a service to you. By providing it you help us to ensure we communicate with you in a way that’s most relevant, useful and engaging for you. We may collect and ask for additional information when you order, purchase, register, subscribe or make use of our products or services in any way, for example in person, via our marketing campaigns, via phone or mail order, or our websites.</p>
      <h3>Information we receive from third parties and/or provide to third parties</h3>
      <p>We occasionally work with third parties who provide additional information that you have shared with them, such as your telephone number and postal address (for example if you’ve moved house or updated your phone number and we need to contact you with important service information). Moreover, we also provide to third parties the information which you have shared with us, such as your telephone number and postal address (for example if you’ve moved house or updated your phone number and we need to contact you with important service information).  Some information may be shared about your interaction on our sites to help them personalise services to you.</p>
      <h3>Information we do not track</h3>
      <p>We do not track or collect any sensitive information about you. This includes race, religion, ethnicity, and political opinion.</p>
      <h3>How we use your information</h3>
      <p>We primarily use your information for the purpose of delivering the products and services that you have chosen and to personalise our interactions (including advertising) with you. Also, we share this information with third parties such as Insideketo.com and ketoeverything.com amongst others, which will communicate relevant information and products to you. For further details about this, and other ways we may use your information, please read the sections below.</p>
      <h3>To provide products and services;</h3>
      <ul>
        <li>To fulfil your orders and contracts with us this includes our third party specialised payment providers.</li>
        <li>To manage your access to our online content and apps, and to send you content via push notifications, email, newsletters and EIU subscriptions if you have requested us to do so.</li>
        <li>To send you service notifications related to your product or service such as subscription renewal notifications, password resets and order confirmations.</li>
        <li>To manage customer service queries and complaints.</li>
        <li>To manage your privacy preferences and to ensure you only receive communications that you have requested, which may include using your details to suppress you from communications.</li>
        <li>To send you administrative emails about your account, reminders for upcoming events, service changes, or new policies. These updates, changes and notifications are essential for the services that you have selected.</li>
        <li>To provide general location-based services (e.g. the region or country you are in), advertising or search results for our content.</li>
        <li>To detect and reduce fraud including fraudulent orders.</li>
        <li>To prevent users from posting illegal, offensive or objectionable comments on our site.</li>
      </ul>
      <h3>To deliver marketing and advertising</h3>
      <p>We need your consent to use your information for some specific purposes such as marketing, brand response communications and personalised advertising. Ways in which we will use your information if you consent are as follows:</p>
      <ul>
        <li>We may send marketing communications via a range of channels including email and push notifications and you can opt-out of these at any time. If you give us marketing permission, we may contact you to tell you about special offers and related or similar products or services within that relate to your interests and that are provided through third parties.</li>
        <li>We may pass your information to specially selected third parties, such as but not limited to Insideketo.com and ketoeverything.com, who would like to contact you with information regarding their own products and services such as other subscriptions, events or content services.  Those parties are responsible for their use of your data and you should read their privacy policies carefully. For more information on how we work with third parties please see the section below.</li>
        <li>We will ask you if you wish to opt-in of such marketing when you first sign up to receive our products or services. You can also update your preferences for your subscription at any time by contacting customer services. You can also opt-out of email marketing by clicking the unsubscribe link at the bottom of our emails. This does not apply to important service notifications such as payment confirmations (as covered in the section above) or where we have some other legal basis for contacting you.</li>
        <li>In order to deliver advertising and marketing messages that are relevant to you, we may use the information we hold about you, including details that we collate from your use of our services or third parties, such as more precise information on your location or your company's profile (ie. company name, company size), to ensure that the advertising you see is of interest to you. This information may also be passed to specially selected third parties, such as but not limited to Insideketo.com and ketoeverything.com.</li>
      </ul>

      <h3>Other uses of your information</h3>

      <p>Other than where we have asked for your consent, we mainly rely on two other separate bases to lawfully use your information. First, we need to use your information in certain ways to provide our products or services to you, in accordance with our contracts with you. In this case, it is necessary for us to use your information so that we can deliver the products or services you have chosen. Second, as described in more detail below, in certain cases, we may use your information where necessary to further our legitimate interests, where those legitimate interests are not overridden by your rights or interests, including:</p>

      <ul>
        <li>To measure customer and user response and engagement with our products and services such as online content, email newsletters and subscription offers. This may include sharing your information with third parties who help us to analyse and measure these things.</li>
        <li>To ensure our products (including websites and apps) are compatible with the browsers and operating systems used by most of our visitors.</li>
        <li>To help us improve our customer and user experience and to support new product development. We may send customer satisfaction surveys and market research questionnaires (for which we may share your information with third party suppliers employed by us).</li>
        <li>To create audience profiles for personalised advertising, marketing or research and development on and off our websites.</li>
        <li>To detect and reduce fraudulent activity and for other security related purposes such as to help us protect against harassment, IP infringement, crime or other security issues.</li>
      </ul>

      <p>You have the right to object to any of the above uses of your information, please contact us if you wish to do so. We will consider all objections reasonably, but there may be legal reasons where we deem that the use of your information is still appropriate. We will explain our decision to you in a timely manner.</p>

      <h3>Social media</h3>

      <p>We publish content on social media platforms e.g. Twitter, Facebook, LinkedIn through both "organic" and "paid" methods to reach current and potential users:</p>

      <ul>
        <li>“Organic” methods describe where content and/or offers are published onto a social platform so that they may appear in your social platform’s content, without being promoted or forced to appear more prominently.</li>
        <li>“Paid” methods describe where content and/or offers are published onto a social platform so that they will appear more prominently, or be shown to users that do not currently follow group's social pages e.g. a promoted tweet on your Twitter timeline.</li>
      </ul>

      <p>We may place one or more social media platform “tags” on our website in order to better understand how we may be of best value to you by providing you with the most relevant content available according to what you have chosen to read on our own websites. These tags only record information around events to help us understand if you are registered or subscribed with us, so that we may use your reading preferences to provide more relevant content and/or offers to you, on social media platforms, e.g. removing subscription offers from your feed if you are a current subscriber.<br />We do not have direct access to your personal data on your social media platforms</p>

      <h3>How we use your details for audience profiling</h3>

      <p>To enable us to personalise the content and advertising you see (including on social media), we may use your interaction and browsing behaviour or preferences (such as how and what you read on our websites and our apps) to create audience profiles. This is to enable content and message personalisation, and in some cases, advertising to be delivered to you or a group of users (an audience) with similar interests to you. This can be done both on our site and on those of third parties. Please see our  for more information.<br />
            We may analyse your individual information to create a profile of your interests and preferences as a part of an audience.  These insights are used to help us make marketing decisions so that we can ensure our messages are relevant to you. There are times we may use additional information available from external sources to help us do this effectively</p>

      <p>You can choose to opt out of our audience profiling activity if you wish. This does not mean that you will no longer see advertisements, it just means that the advertisements you will see are no longer personalised to your interests. Please contact us to do so.</p>

      <h3>How we work with third parties</h3>

      <p>We disclose personal information to third parties when it is necessary to deliver a service or product, when third parties have relevant products for you, for example, when we pass your information to specially selected third parties, such as but not limited to Insideketo.com and ketoeverything.co,  in addition to helping us improve your experience with us, or when we are required to do so by contract or law. “Third parties” include agents, subcontractors, sponsors and other associated organisations. We have in place contracts to ensure the information remains secure and limited in use, and if we do not have a legitimate business reason to pass on your information, we will ask you to give consent first. Some examples of when we share your information are below:</p>

      <ul>
        <li>When you make a payment on any of our sites your payment will be processed by a specialist payment processor to ensure a secure transaction. All payment processors used are compliant with required security standards. If you have any questions regarding secure transactions, please contact the Data Privacy team.</li>
        <li>When you log on to your subscription account, a third party provider who specialises in online account management may manage your access, including re-setting your password.</li>
        <li>When we send you an email or a push notification, these are delivered by marketing platforms. As part of this service, certain information such as message opens, clicks and formatting are recorded to help deliver the best email experience. Your information may be passed on to relevant third parties who will contact you with emails and newsletters which may be relevant to you.</li>
        <li>When we test and launch new products, services or offers, we may work with trusted third parties to support us.</li>
        <li>We also employ third parties to carry out statistical analyses and conduct surveys on our behalf, to support our advertising and content production efforts respectively.</li>
        <li>To provide information for auditing purposes by official regulators.</li>
        <li>To enhance your profile with non-personal information.</li>
        <li>To enable third parties such as advertisers or sponsors to contact you with information about their own products and services that may be of interest – when you give us explicit permission for us to do so. By using our products and services you acknowledge and agree to your personal information being transferred in this way.</li>
      </ul>

      <h3>
        Retaining and storing your information<br />
                Retention policy
</h3>

      <p>
        We securely store your information, and hold it for as long as we need to in order provide our services and products to you in accordance with (i) applicable law, or (ii) as long as is set out in any relevant contract you have with us.
</p>

      <p>
        We review our retention periods for personal information regularly. If you have not interacted with us in any way, we will generally no longer hold your information after 5 years (although this may be shorter for individual businesses (you can confirm by contacting us)). Sometimes we may need to keep it for longer periods such as for tax and other financial regulatory reasons, this would typically be 7 years. We would only keep it for longer than this if we are required to by law.
</p>

      <p>
        If you request for us to no longer contact you, for example with marketing communications, we will retain the minimum amount of information about you so that we can ensure we remove you from any future communications. Please note that if you ask us to completely remove all information about you, and you subsequently use our products and services at a later date, we will no longer be able to recognise your previous request not to be contacted, which is why we would keep it and suppress it in line with industry standards.
</p>

      <h3>Storing and transferring information internationally</h3>

      <p>
        As the internet is a global environment and we work with third parties across the globe, collecting and using your personal information may involve the transfer of this information internationally, including outside of the European Union. By using our products and services you acknowledge and agree to your personal information being transferred in this way, including to jurisdictions outside the EEA.<br />
            We maintain strict policies to ensure all information that is transferred is done so safely and securely.
</p>

      <h3>Keeping your information safe</h3>

      <p>We take information security seriously and have policies and procedures in place to ensure the information we hold on you remains safe. We limit who has access to your information and ensure that those who do are bound by contracts to keep your information availability restricted and safe.</p>

      <h3>Individuals under 16</h3>

      <p>We do not intentionally, or knowingly, process personal information from individuals under the age of 16. When necessary, users under the age of 16 will be told not to submit any personal details. We will make every effort to delete any details of such users where a parent or guardian has informed us that these details have been collected.</p>

      <h3>External links</h3>

      <p>This Privacy Policy only applies to our use of data. Our websites, services or products may, from Time-to-time, contain links to, use or be on external sites. We are not responsible for the Privacy Policies or the content of such sites and we recommend that you read the Privacy Policies on any external sites you use. Similarly, if you are directed to our website from a third party we are not responsible for the Privacy Policy or practices of the third party. We strongly recommend you read their policy.</p>

      <h3>Cookies</h3>

      <p>Cookies are small files that are created in your web browser when you visit any of our websites.<br />
          You can find more information about the types of Cookies that we, and certain third parties use, why we use them, and how to manage them in our cookie policy.</p>

      <h3>Who we are</h3>

      <p>
        This Site is controlled and operated by Company<br />
            Contact Information: Company, 1156 Autumn Ridge Dr, Lexington, KY 40509 USA: +1-866-856-4807 / UK: +44-80-0041-8628 / AU: +61-18-0095-8363 (toll-free) / 1-502-251-1332 (International Callers)
</p>

      <h3>Contact Us</h3>

      <p>If you have questions about this policy or about your personal information, please complete contact us here: Contact Information: Company, 1156 Autumn Ridge Dr, Lexington, KY 40509 USA: +1-866-856-4807 / UK: +44-80-0041-8628 / AU: +61-18-0095-8363 (toll-free) / 1-502-251-1332 (International Callers)</p>

      <h3>Your rights</h3>

      <p>You also have the right to ask for us to update, delete or stop processing information we hold about you. However, please note that there are circumstances in which complete erasure of your information or ceasing to process your information will not be possible for operational, legal and business reasons. This may include if you remain a customer for whom we need to provide services, or if you wish us to no longer contact you for marketing purposes. In this case we may need to retain some of your details securely in order to facilitate this request by, for example, keeping you on a “do not contact” or suppression list. This will be the only purpose for which your data will be used if this is the case.</p>

      <h3>Changes to this policy</h3>

      <p>From Time-to-time, we may make changes to the Privacy Policy. This may be in relation to changes in the law, best practice or changes in our services. These changes will be reflected in this statement, so you should check here regularly.</p>

      <p>Last update: 1 december 2019</p>
    </>
  );
};

export default Privacy;
