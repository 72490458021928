import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import favicons from "./configs/favicons";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./configs/configureStore";
import { getAuth } from "./actions/auth";

import "normalize.css";
import "./assets/sass/styles.sass";

store.dispatch(getAuth());

ReactDOM.render(
  <Provider store={store}>
    {favicons}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
