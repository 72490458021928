import React from "react";
import clsx from "clsx";
import themeConfig from "../../../configs/theme";

import Title from "../../common/Title";
import Button from "../../common/Button";
import IconSprite from "../../common/IconSprite";
import Input from "../../common/Input";
import UserAvatar from "../../common/UserAvatar";
import RadioList from "../../common/RadioList";
import FixedLoader from "../../common/FixedLoader";

import ConfirmModal from "../../modals/ConfirmModal";

import CardResult from "./components/CardResult";
import CancelSubscription from "./components/CancelSubscription";

import "./Profile.sass";
import { NavLink } from "react-router-dom";

const Profile = ({
  userName,
  height,
  currentWeight,
  goalWeight,
  measurement,
  language,
  email,
  timezone,
  handleGoalWeightChange,
  handleGoalWeightBlur,
  isEdit,
  handleSubmit,
  isFetching,
  handleEdit,
  avatar,
  fieldIsFocus,
  handleGoalWeightFocus,
  goalWeightError,
  cancelSubscribeRadiosConfig,
  handleCloseCancelModal,
  handleOpenCancelModal,
  cancelModalIsOpen,
  mealPlanTitle,
  handleChangeSubscriptionCancelRadio,
  handleSubmitCancelSubscription,
  isCancelLoading,
  isCancelSend,
  subscribeCancelSent,
  isModalCancelDisabled,
  age,
  activity,
  gender,
  updateCancelResult,
  sendOffer,
  hasInvited,
  openReferralModal,
}) => {
  return (
    <>
      <div className="content-page">
        <div className="container">
          <div className="profile">
            <div className="profile-info">
              <UserAvatar
                avatarPath={avatar}
                firstLetter={userName ? userName[0] : ""}
                handleEdit={handleEdit}
                withEdit
              />
              <div className="profile-info__body">
                <Title className="user-name">{userName}</Title>
                <div className="profile-info__params">
                  <div className="user-parameter">
                    <div className="user-parameter__icon">
                      <IconSprite className="height-icon" name="height" />
                    </div>
                    <div className="user-parameter__body">
                      <div className="user-parameter__title">Height</div>
                      <div className="user-parameter__value">
                        {height ? (
                          <>
                            {height} {measurement === "imperial" ? "" : "cm"}
                          </>
                        ) : (
                          "Not provided"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="user-parameter">
                    <div className="user-parameter__icon">
                      <IconSprite className="weight-icon" name="weight" />
                    </div>
                    <div className="user-parameter__body">
                      <div className="user-parameter__title">
                        <span>Current</span> Weight
                      </div>
                      <div className="user-parameter__value">
                        <span>
                          {currentWeight ? (
                            <>
                              {currentWeight}{" "}
                              {measurement === "imperial" ? "lb" : "kg"}
                            </>
                          ) : (
                            "Not provided"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="user-parameter">
                    <div className="user-parameter__icon">
                      <IconSprite className="eat-icon" name="eat" />
                    </div>
                    <div className="user-parameter__body">
                      <div className="user-parameter__title">Meal Plan</div>
                      <div className="user-parameter__value">
                        {mealPlanTitle}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="weight-goal-wrap">
                <div
                  className={clsx("weight-goal", {
                    "is-focus": fieldIsFocus,
                    error: goalWeightError,
                  })}
                >
                  <div className="weight-goal__title">Goal Weight</div>
                  <input
                    className="weight-goal__input"
                    value={goalWeight || ""}
                    onChange={handleGoalWeightChange}
                    onBlur={handleGoalWeightBlur}
                    onFocus={handleGoalWeightFocus}
                    type="tel"
                    pattern="[0-9]*"
                    autoComplete="off"
                  />
                  <div className="circle-edit-btn weight-goal__btn">
                    <IconSprite className="icon-checkbox" name="checkbox" />
                  </div>
                  {goalWeightError && (
                    <div className="weight-goal__help-error">
                      {goalWeightError}
                    </div>
                  )}
                </div>
                <NavLink to="/log-weight" className="weight-log-link">
                  <IconSprite className="history-icon" name="history" />
                  <span>Your weight HIstory</span>
                </NavLink>
                {hasInvited === "no" && (
                  <Button
                    className="weight-log-link refer-friend"
                    onClick={openReferralModal}
                  >
                    Refer a friend
                  </Button>
                )}
              </div>
            </div>
            <div className="profile-results">
              <CardResult
                height={height}
                weight={currentWeight}
                measurement={measurement}
                age={age}
                activity={activity}
                gender={gender}
                target={goalWeight}
              />
            </div>
            <div className="profile-settings">
              <Title className="profile-settings__title" size="medium">
                Settings
              </Title>
              <div className="profile-settings__fields">
                <Input label="Measurement" value={measurement || ""} />
                <Input label="Language" value={language || ""} />
                <Input label="Email" value={email || ""} />
                <Input label="Time Zone" value={timezone || ""} />
              </div>
              <Button
                className="profile-settings__btn"
                spin={isFetching}
                onClick={handleSubmit}
                disabled={isEdit || goalWeightError}
              >
                Save
              </Button>
            </div>
            {themeConfig.type !== "fitculator" && (
              <>
                {cancelSubscribeRadiosConfig && (
                  <div className="profile-footer">
                    {!isCancelSend && !subscribeCancelSent ? (
                      <button
                        className="cancel-sub-btn"
                        onClick={handleOpenCancelModal}
                      >
                        Сancel your subscrition
                      </button>
                    ) : (
                      <div className="cancel-sub-btn send">
                        You will receive an email confirming your cancellation.
                        Please allow 48 hours for this email to be delivered.
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {themeConfig.type === "fitculator" && cancelSubscribeRadiosConfig && (
              <div className="profile-footer">
                <CancelSubscription
                  subscribes={cancelSubscribeRadiosConfig}
                  sendOffer={sendOffer}
                  updateCancelResult={updateCancelResult}
                  isCancelSend={isCancelSend}
                  subscribeCancelSent={subscribeCancelSent}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isCancelLoading && <FixedLoader />}
      {cancelModalIsOpen && (
        <ConfirmModal
          title="Cancel subscrition"
          description="What do you want to cancel:"
          buttonMainName="Yes, Cancel"
          buttonSecondName="No, Don't cancel"
          handleClose={handleCloseCancelModal}
          handleMainClick={handleSubmitCancelSubscription}
          handleSecondClick={handleCloseCancelModal}
          mainButtonDisabled={isModalCancelDisabled}
          className="cancel-sub-modal"
        >
          <RadioList
            className="cancel-sub-radios"
            config={cancelSubscribeRadiosConfig}
            onChange={handleChangeSubscriptionCancelRadio}
          />
        </ConfirmModal>
      )}
    </>
  );
};

export default Profile;
