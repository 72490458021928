import React from "react";

import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import MealCard from "../../../../common/MealCard";
import Title from "../../../../common/Title";
import IconSprite from "../../../../common/IconSprite";
import ViewAllLink from "../../../../common/ViewAllLink";

import "swiper/components/pagination/pagination.scss";
import "./MealsList.sass";

SwiperCore.use([Pagination, Navigation]);

const MealsList = ({ mealPlan, carouselId, status }) => {
  const mealsTypes = [
    { id: 1, type: "breakfast", name: "Breakfast" },
    { id: 2, type: "morning_snack", name: "Morning snack" },
    { id: 3, type: "lunch", name: "Lunch" },
    { id: 4, type: "afternoon_snack", name: "Afternoon snack" },
    { id: 5, type: "dinner", name: "Dinner" },
  ];

  const mealCards = mealsTypes
    .map((type) => {
      const meal = mealPlan[type.type];
      if (!meal) return null;

      return (
        <SwiperSlide key={meal.id}>
          <MealCard
            image={meal.image && meal.image.length ? meal.image[0].url : null}
            tagName={type.name}
            title={meal.title}
            level={meal.level}
            cooking_time={meal.cooking_time}
            serving={meal.meal_serving}
            typeId={type.id}
            type={type.type}
            id={meal.id}
            linkTo={status ? `/meals/${meal.id}` : `/meal/${meal.id}`}
          />
        </SwiperSlide>
      );
    })
    .filter((item) => item);
  return (
    <div className="meals-list-section">
      <div className="container">
        <div className="meals-list-section-head">
          <Title size="medium">Your meals for Today</Title>
          <ViewAllLink to={`/meals`} />
        </div>
      </div>
      <div className="meal-plan-preview-list">
        <div className="container">
          <Swiper
            spaceBetween={10}
            slidesPerView="auto"
            breakpoints={{
              0: {
                spaceBetween: 10,
              },
              503: {
                spaceBetween: 10,
              },
              756: {
                spaceBetween: 15,
              },
              1021: {
                spaceBetween: 20,
              },
              1318: {
                spaceBetween: 30,
              },
              1348: {
                spaceBetween: 37,
                allowTouchMove: false,
              },
            }}
            pagination={{
              el: ".meal-plan-preview-pagination",
              clickable: true,
            }}
            navigation={{
              nextEl: ".carousel-next-" + carouselId,
              prevEl: ".carousel-prev-" + carouselId,
            }}
          >
            {mealCards}
          </Swiper>
          <div
            className={
              "swiper-button-nav swiper-button-prev carousel-prev-" + carouselId
            }
          >
            <IconSprite className="arrow-left-icon" name="arrow-left" />
          </div>
          <div
            className={
              "swiper-button-nav swiper-button-next carousel-next-" + carouselId
            }
          >
            <IconSprite className="arrow-right-icon" name="arrow-right" />
          </div>
        </div>
        <div className="meal-plan-preview-pagination swiper-pagination"></div>
      </div>
    </div>
  );
};

export default MealsList;
