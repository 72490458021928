import React, { useEffect } from "react";
import amplitude from "amplitude-js";

import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { authAPI } from "./api";
import themeConfig from "./configs/theme";

import {
  checkSubscribeStatuses,
  amplitudeTransformData,
} from "./common/helpers";
import { setIsAppLoaded, setBaseUserData, setAvatar } from "./actions/profile";

import {
  setStatuses,
  clearAuth,
  setAppFields,
  setLastVisit,
} from "./actions/auth";
import Auth from "./layouts/Auth";
import { Terms, Privacy } from "./layouts/Docs";
import MainLayout from "./layouts/MainLayout";
import PageLoader from "./components/common/PageLoader";

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY);

const App = ({
  isAuth,
  getUser,
  isAppLoaded,
  setIsAppLoaded,
  logout,
  statuses,
  company,
  isSignUp,
  passedLongTime,
  isPollPassed,
}) => {
  const history = useHistory();
  const urlSourceParam = new URLSearchParams(window.location.search).get(
    "source"
  );
  const urlCodeParam = new URLSearchParams(window.location.search).get("code");

  useEffect(() => {
    if (isAuth && !isAppLoaded) {
      getUser(urlSourceParam);
    } else {
      setIsAppLoaded();
    }
  }, [getUser, isAuth, isAppLoaded, setIsAppLoaded, urlSourceParam]);

  useEffect(() => {
    if (isAuth && urlCodeParam && history.location.pathname === "/signup") {
      logout();
      history.push("/signup?code=" + urlCodeParam);
    }
  }, [urlCodeParam, isAuth, logout, history]);

  if (!isAppLoaded) {
    return <PageLoader />;
  }

  const mainRedirectPath = isSignUp || passedLongTime ? "/meals" : "/";

  return (
    <Switch>
      <Route exact path="/docs/terms" component={Terms} />
      <Route exact path="/docs/privacy-policy" component={Privacy} />
      <Route
        exact
        path={[
          "/signin",
          "/signup",
          "/signup-temp",
          "/signin/forgot",
          "/signin/reset",
        ]}
        component={() =>
          !isAuth ? <Auth /> : <Redirect to={mainRedirectPath} />
        }
      />
      <Route
        path="/"
        component={() =>
          isAuth ? (
            <MainLayout
              statuses={statuses}
              logout={logout}
              company={company}
              passedLongTime={passedLongTime}
              isPollPassed={isPollPassed}
              isAuth={isAuth}
            />
          ) : (
            <Redirect to={"/signin" + window.location.search} />
          )
        }
      />
    </Switch>
  );
};

export default connect(
  (state) => ({
    isAuth: state.auth.isAuth,
    isAppLoaded: state.profile.isAppLoaded,
    confirmed: state.profile.confirmed,
    token: state.auth.token,
    statuses: state.auth.statuses,
    company: state.auth.company,
    isSignUp: state.auth.isSignUp,
    passedLongTime: state.auth.passedLongTime,
    isPollPassed: state.auth.isPollPassed,
  }),
  (dispatch) => ({
    setIsAppLoaded: () => dispatch(setIsAppLoaded()),
    getUser: (urlSourceParam) => {
      return authAPI
        .getUser()
        .then((user) => {
          let userIsActive = checkSubscribeStatuses(user.data.subscribes);
          if (!userIsActive) {
            if (!userIsActive) {
              throw Object.assign(new Error(), {
                innerError: true,
                message: "Account is not active",
              });
            }
          }
          dispatch(setStatuses(user.data.subscribes));
          dispatch(
            setAvatar(
              user.data.avatar
                ? `${process.env.REACT_APP_API_DOMAIN}${user.data.avatar.url}`
                : user.data.avatar
            )
          );

          dispatch(
            setBaseUserData(
              user.data.id,
              user.data.first_name,
              user.data.confirmed,
              user.data.created_at,
              user.data.current_weight,
              user.data.units,
              user.data.email,
              user.data.actual_weight || user.data.current_weight,
              user.data.goal_weight,
              user.data.height,
              user.data.age,
              user.data.country_code
            )
          );
          dispatch(setLastVisit(user.data.last_visited));

          amplitude.getInstance().setUserId(user.data.email);
          amplitude.getInstance().setGroup("Client Name", themeConfig.title);
          amplitude
            .getInstance()
            .logEvent(
              "user_properties",
              amplitudeTransformData(user.data, user.data.subscribes)
            );

          if (urlSourceParam) {
            dispatch(setAppFields(urlSourceParam));
          }
          dispatch(setIsAppLoaded());
        })
        .catch((error) => {
          if (error && error?.response?.status === 401) {
            clearAuth(dispatch);
          } else if (error.innerError) {
            clearAuth(dispatch);
          }
          dispatch(setIsAppLoaded());
        });
    },
    logout: () => {
      clearAuth(dispatch);
    },
  })
)(App);
