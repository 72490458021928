import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import themeConfig from "../configs/theme";
import { connect } from "react-redux";
import { contentAPI } from "../api";
import { setIsLoaded, setCategoriesList } from "../actions/categories";

import { clearAuth } from "../actions/auth";

import { getFeelingSoreCategories } from "../common/helpers";

import PageLoader from "../components/common/PageLoader";

import Categories from "../components/pages/Categories";

const CategoriesPage = ({ isLoaded, categoriesList, getCategoriesList }) => {
  useEffect(() => {
    getCategoriesList();
  }, [getCategoriesList]);

  if (!isLoaded) {
    return <PageLoader />;
  }

  return (
    <>
      <Helmet>
        <title>Categories to Explore - {themeConfig.title}</title>
      </Helmet>
      <Categories categories={categoriesList} />
    </>
  );
};

export default connect(
  (state) => ({
    isLoaded: state.categories.isLoaded,
    categoriesList: state.categories.categoriesList,
  }),
  (dispatch) => ({
    getCategoriesList: () => {
      return contentAPI
        .getActualWorkouts()
        .then((categories) => {
          const categoriesList = getFeelingSoreCategories(
            categories.data.categories
          ).map((category) => {
            return {
              title: category.title,
              name: category.name,
            };
          });
          dispatch(setCategoriesList(categoriesList));
          dispatch(setIsLoaded());
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
  })
)(CategoriesPage);
