import React from 'react';
import clsx from 'clsx';
import IconSprite from '../IconSprite';

import "./CategoryTag.sass";

const CategoryTag = ({
  category,
  color
}) => {

  const categoryTitle = category.split("_").join(" ")

  return (
    <div className={clsx("category-tag", {
      [color]: color
    })}>
      <IconSprite className={`category-tag__icon icon-${category}`} name={`category-${category}`} />
      <div className="category-tag__title">{categoryTitle}</div>
    </div>
  );
};

export default CategoryTag;
