import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { sendTestResult } from "../actions/auth";

import Survey from "../components/modals/Survey";

const SurveyPage = ({ defaultUnits, isPollPassed, sendTestResult }) => {
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  const handleSubmitSurvey = (data) => {
    setSubmitting(true);
    return sendTestResult(data).then((res) => {
      setSubmitting(false);
      history.push("/meals");
      return res;
    });
  };

  return (
    <>
      <Survey
        defaultUnits={defaultUnits}
        loading={submitting}
        isOpen={!isPollPassed}
        onSubmit={handleSubmitSurvey}
      />
    </>
  );
};

const mapStateToProps = ({ auth, profile }) => ({
  defaultUnits: profile.defaultUnits,
  isPollPassed: auth.isPollPassed,
});

const mapStateToDispatch = {
  sendTestResult,
};

export default connect(mapStateToProps, mapStateToDispatch)(SurveyPage);
