import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Modal } from "../../../common/components";

import classes from "./ReferralModal.module.sass";

const ValidatorSchema = Yup.object().shape({
  email: Yup.string().email("Email must be a valid email"),
});

const Input = ({ field, form, ...props }) => {
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  return (
    <div className={classes.inputWrap}>
      <input className={classes.input} {...field} {...props} />
      {error && touched && <div className={classes.errorHelper}>{error}</div>}
    </div>
  );
};

const SuccessScreen = ({ onClose }) => (
  <div className={classes.wrap}>
    <div className={classes.title}>Refer a Friend</div>
    <div className={classes.body}>
      <div className={classes.desc}>Thank you for a referring a friend</div>
    </div>
    <div className={classes.footer}>
      <button className={classes.button} onClick={onClose}>
        Close
      </button>
    </div>
  </div>
);

const ErrorScreen = ({ onClose }) => (
  <div className={classes.wrap}>
    <div className={classes.title}>Refer a Friend</div>
    <div className={classes.body}>
      <div className={classes.desc}>
        Oops, something went wrong. We got the notification about and working to
        fix this. Please try again later.
      </div>
    </div>
    <div className={classes.footer}>
      <button className={classes.button} onClick={onClose}>
        Close
      </button>
    </div>
  </div>
);

const ReferralModal = ({ open, loading, sended, error, onClose, onSubmit }) => {
  return (
    <Modal open={open} onClose={onClose} isLoading={loading} mobileSmall>
      {!sended && !error && (
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={ValidatorSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          validateOnChange
          validateOnMount
        >
          {({ dirty, isValid }) => (
            <Form className={classes.wrap}>
              <div className={classes.title}>Refer a Friend</div>
              <div className={classes.body}>
                <div className={`${classes.desc} ${classes.descCenter}`}>
                  Extend your 14-day trial to a 30-day
                  <br />
                  trial by simply referring a friend
                </div>
                <Field
                  name="email"
                  placeholder="Enter email"
                  component={Input}
                />
              </div>
              <div className={classes.footer}>
                <button
                  type="submit"
                  className={classes.button}
                  disabled={!(dirty && isValid)}
                >
                  Send
                </button>
                <button
                  className={`${classes.button} ${classes.transparent}`}
                  onClick={onClose}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {sended && <SuccessScreen onClose={onClose} />}
      {error && <ErrorScreen onClose={onClose} />}
    </Modal>
  );
};

export default ReferralModal;
