import React from "react";

import Button from "../../common/Button";
import themeConfig from "../../../configs/theme";
import { Field, reduxForm } from "redux-form";
import {
  required,
  minLength,
  checkLatinSymbols,
  checkHasLetters,
  checkCorrectEmail,
} from "../../../common/helpers/validators";
import InputAuth from "../../common/InputAuth";
import IconSprite from "../../common/IconSprite";

const Form = ({ handleSubmit, isFetching }) => {
  return (
    <form onSubmit={handleSubmit} className="form-wrap">
      <Field
        size="large"
        type="text"
        placeholder="Your email"
        name={"email"}
        icon={<IconSprite className="mail-symbol" name="mail-symbol" />}
        validate={[required, checkCorrectEmail]}
        component={InputAuth}
      />
      <Field
        size="large"
        type="password"
        icon={<IconSprite className="lock" name="lock" />}
        placeholder="Password"
        name={"password"}
        validate={[required, checkLatinSymbols, minLength, checkHasLetters]}
        component={InputAuth}
      />
      <Button type="primary" spin={isFetching} className="auth-btn">
        Access {themeConfig.title}
      </Button>
    </form>
  );
};

export default reduxForm({
  form: "signUp",
})(Form);
