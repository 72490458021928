import produce from "immer";
import {
  SET_IS_LOADED,
  SET_IS_FETCHING,
  SET_LIST,
  CLEAR_DATA,
  SET_LIST_INFO,
  UPDATE_LIST,
  ADD_FILTERS_PARAMS,
  ADD_FAVORITE,
} from "../constants/categoryDetailTypes";

const initialState = {
  isLoaded: false,
  isFetching: false,
  list: [],
  listCount: null,
  listStart: 0,
  filterParams: {
    level: "all",
    duration: "all",
  },
  filtersString: "",
  categoryName: null,
};

const categoryDetail = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FAVORITE: {
      const newList = produce(state.list, (draftState) => {
        const idx = draftState.findIndex((item) => item.id === action.payload);
        draftState[idx].isFavourite = draftState[idx].isFavourite
          ? false
          : true;
      });
      return {
        ...state,
        list: newList,
      };
    }
    case SET_IS_LOADED: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case ADD_FILTERS_PARAMS: {
      return {
        ...state,
        filterParams: { ...action.filterParams },
        filtersString: action.filtersString,
      };
    }
    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case SET_LIST_INFO: {
      return {
        ...state,
        listCount: action.listCount,
        listStart: action.listStart,
        categoryName: action.categoryName || state.categoryName,
      };
    }
    case SET_LIST: {
      return {
        ...state,
        list: [...action.list],
      };
    }
    case UPDATE_LIST: {
      return {
        ...state,
        list: [...state.list, ...action.list],
      };
    }
    case CLEAR_DATA: {
      return {
        ...state,
        ...initialState,
      };
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default categoryDetail;
