export const ADD_ACTUAL_WEIGHT = "PROFILE:ADD_ACTUAL_WEIGHT";
export const ADD_RESULTS_DATA = "PROFILE:ADD_RESULTS_DATA";
export const SET_IS_APP_LOADED = "PROFILE:SET_IS_APP_LOADED";
export const SET_IS_FETCHING = "PROFILE:SET_IS_FETCHING";
export const SET_TEMP_AVATAR = "PROFILE:SET_TEMP_AVATAR";
export const SET_AVATAR = "PROFILE:SET_AVATAR";
export const SET_BASE_USER_DATA = "PROFILE:SET_BASE_USER_DATA";
export const SET_IS_LOADED = "PROFILE:SET_IS_LOADED";
export const SET_ALL_USER_DATA = "PROFILE:SET_ALL_USER_DATA";
export const SET_GOAL_WEIGHT = "PROFILE:SET_TEMP_GOAL_WEIGHT";
export const CLEAR_USER_DATA = "PROFILE:CLEAR_USER_DATA";
export const ADD_CANCEL_RESULT = "PROFILE:ADD_CANCEL_RESULT";
export const ADD_MC_LOADED = "PROFILE:ADD_MC_LOADED";
export const UPDATE_USER_DATA = "PROFILE:UPDATE_USER_DATA";
export const SET_HAS_INVITED_STATUS = "PROFILE:SET_HAS_INVITED_STATUS";
