export const required = (value, objValue, state, fieldName) => {
  const name = fieldName.split('_');
  const resultName = name.map(word => {
    return word[0].toUpperCase() + word.slice(1);
  }).join(' ');

  if (value && value.trim()) return undefined;
  return resultName + ' field is required';
};

export const matchPasswords = (password, allValues) => {
  return password !== allValues.password ? "Passwords don't match" : undefined;
};

export const minLength = (password) => {
  return password.length < 8 ? "Your password must have 8 or more characters" : undefined;
};

export const checkLatinSymbols = (password) => {
  let regexp = /^[0-9a-zA-Z !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~ ]+$/;
  return !regexp.test(password) ? "Please use only english letters, digits and characters" : undefined;
};

export const checkHasMixedCase = (password) => {
  let regexp = /([a-z].*[A-Z])|([A-Z].*[a-z])/;
  return !regexp.test(password) ? "Your password must have upper & lowercase letters" : undefined;
};

export const checkHasLetters = (password) => {
  let regexp = /([a-z])|([A-Z])/;
  return !regexp.test(password) ? "Your password must have letters" : undefined;
};

export const checkCorrectEmail = (email) => {
  let regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !regexp.test(email) ? "Please enter email in format yourname@example.com" : undefined;
};
