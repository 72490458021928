import React from 'react';

import svgSprite from '../../../assets/images/general/sprite.svg';

import './sectionDesc.sass';

const SectionDesc = ({
  children,
  className
}) => {

  const mainClass = 'section-desc';
  const classes = className ? `${mainClass} ${className}` : mainClass;

  return (
    <div className={classes}>
      <div className="section-desc__icon">
        <svg className="icon-desc-info">
          <use xlinkHref={`${svgSprite}#desc-info`}></use>
        </svg>
      </div>
      <span>{children}</span>
    </div>
  );
};

export default SectionDesc;
