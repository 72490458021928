import React from "react";

import Title from "../../../../common/Title";
import SearchFail from "../../../../common/SearchFail";
import IconSprite from "../../../../common/IconSprite";
import PageLoader from "../../../../common/PageLoader";
import FilterSelectList from "../../../../common/FilterSelectList";
import MealCard from "../../../../common/MealCard";
import LoaderWrap from "../../../../common/LoaderWrap";
import Button from "../../../../common/Button";
import "./MealsList.sass";

const MealsList = ({
  type,
  isFetchingList,
  handleSearchMealNameChange,
  mealName,
  handleFilterSelectChange,
  filtersList,
  mealsList,
  mealsListCount,
  handleLoadMore,
  isFetching,
  handleOpenPreview,
  handleReplaceFromList,
  statuses,
  withChange,
}) => {
  return (
    <div className="meals-list-replace fixed">
      {isFetchingList ? (
        <PageLoader />
      ) : (
        <div className="meal-list">
          <div className="meal-list__head">
            <Title size="medium">Select Recipe</Title>
            <div className="meal-list__filters">
              <div className="meal-search">
                <button className="meal-search__btn">
                  <IconSprite className="icon-search" name="search" />
                </button>
                <input
                  type="text"
                  className="meal-search__input"
                  placeholder="Search recipe... "
                  onChange={(event) =>
                    handleSearchMealNameChange(event.target.value)
                  }
                  value={mealName}
                />
              </div>
              <FilterSelectList
                className="meal-list__selects"
                list={filtersList}
                handleChange={handleFilterSelectChange}
                color="white"
              />
            </div>
          </div>
          <div className="meals-list-wrap">
            {mealsList.length ? (
              <>
                <div className="meals-list">
                  {mealsList.map((meal) => {
                    const {
                      id,
                      image,
                      meal_types,
                      title,
                      level,
                      cooking_time,
                      meal_serving,
                    } = meal;
                    return (
                      <MealCard
                        key={id}
                        handleOpenPreview={() => handleOpenPreview(id)}
                        image={image.length ? image[0].url : null}
                        tagName={meal_types.length ? meal_types[0].name : null}
                        title={title}
                        level={level}
                        cooking_time={cooking_time}
                        serving={meal_serving ? meal_serving.type : null}
                        id={id}
                        handleReplace={() => handleReplaceFromList(id)}
                        statuses={statuses}
                        withChange={withChange}
                      />
                    );
                  })}
                </div>
                {mealsList.length < mealsListCount ? (
                  <div className="meals-list-footer">
                    <LoaderWrap spin={isFetching} colorLoader="#ffffff">
                      <Button
                        className="meals-list-footer__btn"
                        onClick={handleLoadMore}
                        disabled={isFetching}
                      >
                        View more
                      </Button>
                    </LoaderWrap>
                  </div>
                ) : null}
              </>
            ) : (
              <SearchFail
                message="No recipes with such parameters were found"
                className="meals-list-replace-not-found"
                type="white"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MealsList;
