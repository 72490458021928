import React from "react";

import Button from "../../../../common/Button";
import LoaderWrap from "../../../../common/LoaderWrap";
import MealCard from "../../../../../common/containers/MealCardContainer";
import FavoritesIcon from "./icons/favorites-icon.svg";
import "./RecipesFavoritesList.sass";

const RecipesFavoritesList = ({
  list,
  handleDeleteLike,
  favoritesCurrentStart,
  favoritesCount,
  handleFavoritesLoadMore,
  recipesIsFetching,
}) => {
  return (
    <div className="recipes-body">
      {favoritesCount > 0 ? (
        <>
          <div className="meals-list meals-list--small">
            {list.map(({ recipe, id }) => {
              const {
                id: mealId,
                image,
                meal_types = [],
                title,
                level,
                cooking_time,
                meal_serving,
              } = recipe;
              return (
                <MealCard
                  key={mealId}
                  linkTo={`/meals/${mealId}`}
                  size="small"
                  image={image && image.length ? image[0].url : null}
                  tagName={meal_types.length ? meal_types[0].name : null}
                  title={title}
                  level={level}
                  cooking_time={cooking_time}
                  serving={meal_serving ? meal_serving : null}
                  id={id}
                  handleLike={() => handleDeleteLike(id)}
                  isFavourite={true}
                />
              );
            })}
          </div>
          {favoritesCurrentStart < favoritesCount ? (
            <div className="recipes-footer">
              <LoaderWrap spin={recipesIsFetching}>
                <Button
                  onClick={handleFavoritesLoadMore}
                  disabled={recipesIsFetching}
                >
                  View more
                </Button>
              </LoaderWrap>
            </div>
          ) : null}
        </>
      ) : (
        <div className="recipes-empty">
          <img className="recipes-empty__icon" src={FavoritesIcon} alt="" />
          <div className="recipes-empty__text">
            <div className="recipes-empty__text-title">No recipes</div>
            <div className="recipes-empty__text-desc">
              You have no recipes in your favorites
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecipesFavoritesList;
