import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormControl from "../FormControl";
import Button from "../Button";

import { createValidator } from "./utils";
import classes from "./Measurements.module.sass";

const validator = createValidator(Yup);

const ValidatorSchema = Yup.object().shape({
  age: validator(18, 99),
  height: validator(135, 256),
  weight: validator(40, 180),
  target: validator(40, 180),
});

const Metric = ({ answers, onSubmit, onPrev }) => {
  return (
    <Formik
      initialValues={{
        age: "",
        height: "",
        weight: "",
        target: "",
      }}
      validationSchema={ValidatorSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validateOnChange
    >
      <Form className={classes.form}>
        <div className={classes.fields}>
          <FormControl
            name="age"
            placeholder="Age"
            icon="age"
            type="number"
            autoComplete="off"
          />
          <FormControl
            name="height"
            placeholder="Height (cm)"
            icon="height"
            type="number"
            autoComplete="off"
          />
          <FormControl
            name="weight"
            placeholder="Weight (kg)"
            icon="weight"
            type="number"
            autoComplete="off"
          />
          <FormControl
            name="target"
            placeholder="Target weight (kg)"
            icon="target"
            type="number"
            autoComplete="off"
          />
        </div>
        <div className={classes.formFooter}>
          <Button
            className={`${classes.backBtnForm} ${classes.formBtn}`}
            transparent
            type="button"
            onClick={onPrev}
          >
            Back
          </Button>
          <Button className={classes.formBtn} type="submit">
            Continue
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default Metric;
