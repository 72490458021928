import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

import IconSprite from "../IconSprite";
import TagList from "../TagList";
import Tag from "../Tag";
import ParamList from "../ParamList";
import Param from "../Param";
import Guard from "../Guard";
import ImageCreator from "../ImageCreator";

import "./mealCard.sass";

const convertToHours = (minutes) => {
  minutes = parseInt(minutes);

  if (minutes === 0) return ":)";
  if (minutes <= 60) {
    return `${minutes} min`;
  }
  const diff = minutes % 60;
  const resultMinutes = diff > 0 ? `${diff}m` : "";
  return `${(minutes - diff) / 60}h ${resultMinutes}`;
};

const InnerCardWrapper = ({ linkTo, handleClick, id, children }) => {
  if (linkTo) {
    return (
      <NavLink to={linkTo} className="meal-card__inner">
        {children}
      </NavLink>
    );
  } else if (!linkTo && handleClick) {
    return (
      <div className="meal-card__inner" onClick={() => handleClick(id)}>
        {children}
      </div>
    );
  }
  return <div className="meal-card__inner">{children}</div>;
};

const MealCard = ({
  linkTo,
  image,
  tagName,
  title,
  typeId,
  cooking_time,
  serving,
  handleReplace,
  handleLike,
  handleOpenPreview,
  type,
  id,
  size,
  isFavourite,
  statuses,
  withChange,
  isBlocked,
  handleBuySubscribe,
}) => {
  const [isAddFavorite, setIsAddFavorite] = useState(isFavourite);

  const mealCardInner = (
    <>
      {!tagName || (
        <TagList>
          <Tag>{tagName}</Tag>
        </TagList>
      )}

      <div className="meal-card__body">
        <div className="meal-card__name">{title}</div>
        <ParamList>
          <Param
            icon={<IconSprite className="icon-time-small" name="time-small" />}
          >
            {convertToHours(cooking_time)}
          </Param>
          {!serving || (
            <Param icon={<IconSprite className="icon-serve" name="serve" />}>
              {serving} {serving > 1 ? "servings" : "serving"}
            </Param>
          )}
        </ParamList>
      </div>
    </>
  );

  return (
    <div
      className={clsx("meal-card", {
        ["meal-card--" + size]: size,
      })}
    >
      {isBlocked && (
        <div className="meal-card__close" onClick={handleBuySubscribe}>
          <IconSprite className="icon-lock-outlined" name="lock-outlined" />
        </div>
      )}
      <ImageCreator
        path={image ? `${image}` : null}
        className="meal-card__pic"
        alt={title}
        icon="recipes"
      />
      {handleReplace && withChange ? (
        <Guard condition={statuses.recipes}>
          <button
            className="meal-card__replace"
            onClick={() => handleReplace(typeId, id, type)}
          >
            <IconSprite className="replace" name="replace" />
          </button>
        </Guard>
      ) : null}
      {handleLike && !isBlocked ? (
        <button
          className="meal-card__like"
          onClick={() => {
            if (!isAddFavorite && !isFavourite) {
              setIsAddFavorite(true);
              handleLike();
            }
            if (isAddFavorite && isFavourite) {
              setIsAddFavorite(false);
              handleLike();
            }
          }}
        >
          <div
            className={clsx("meal-card__like-inner", {
              "is-favorite": isFavourite,
            })}
          >
            <IconSprite className="icon-like" name="like" />
            <IconSprite className="icon-like-full" name="like-full" />
          </div>
        </button>
      ) : null}
      {isBlocked ? (
        <InnerCardWrapper id={id} handleClick={handleBuySubscribe}>
          {mealCardInner}
        </InnerCardWrapper>
      ) : (
        <InnerCardWrapper
          linkTo={linkTo}
          id={id}
          handleClick={handleOpenPreview}
        >
          {mealCardInner}
        </InnerCardWrapper>
      )}
    </div>
  );
};

export default MealCard;
