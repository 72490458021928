import React from "react";
import { connect } from "react-redux";
import {
  setIsOpen,
  setIsLoading,
  setIsDone,
  clearModalData,
  setError,
} from "../actions/subscriptions";
import { setStatuses } from "../actions/auth";
import { contentAPI } from "../api";

import Subscriptions from "../components/modals/Subscriptions";

const SubscriptionsModal = ({
  onPurchaseSubscriptions,
  subscribes,
  ...props
}) => {
  const purchaseSubscriptionsHandle = () => {
    onPurchaseSubscriptions(subscribes);
  };

  return (
    <Subscriptions
      {...props}
      onPurchaseSubscriptions={purchaseSubscriptionsHandle}
    />
  );
};

export default connect(
  ({ subscriptions, auth }) => ({
    isOpen: subscriptions.isOpen,
    isLoading: subscriptions.isLoading,
    isDone: subscriptions.isDone,
    isError: subscriptions.isError,
    subscribes: auth.subscribes,
  }),
  (dispatch) => ({
    handleCloseModal: () => dispatch(setIsOpen(false)),
    onPurchaseSubscriptions: (subscribes) => {
      dispatch(setIsLoading(true));
      return contentAPI
        .updateSubscriptions()
        .then((res) => {
          dispatch(setStatuses(res.data));
          dispatch(setIsDone());
          dispatch(setIsLoading(false));
        })
        .catch((e) => {
          dispatch(setError());
        });
    },
    onClearData: () => dispatch(clearModalData()),
  })
)(SubscriptionsModal);
