import React from "react";
import { NavLink } from "react-router-dom";

import UserAvatar from "../../common/UserAvatar";
import Title from "../../common/Title";
import ViewAllLink from "../../common/ViewAllLink";
import LogCounter from "../../common/LogCounter";
import Guard from "../../common/Guard";
import CircleProgress from "./components/CircleProgress";
import WorkoutsList from "./components/WorkoutsList";
import MealsList from "./components/MealsList";
import CategoriesTabs from "./components/CategoriesTabs";

import "./Home.sass";

const Home = ({
  userName,
  avatar,
  historyCount,
  mealPlan,
  workouts,
  categoryWorkouts,
  categories,
  categoryActive,
  handleChangeCategory,
  measurement,
  statuses,
  recommendedWorkouts,
  quote,
  actualWeight,
  isSending,
  isLogged,
  handleSendLogWeight,
  currentWeekIsLogged,
}) => {
  return (
    <div className="content-page home-page">
      <div className="container">
        <div className="home-head">
          <div className="home-user">
            <UserAvatar
              avatarPath={avatar}
              firstLetter={!userName || userName[0]}
              className="home-user__avatar"
            />
            {!currentWeekIsLogged ? (
              <div className="home-user__body">
                <div className="home-user__greeting-small">
                  Hi {userName}, Time to log weight
                </div>
                <LogCounter
                  className="home-user__counter"
                  value={actualWeight || "0.0"}
                  measurement={measurement}
                  isSending={isSending}
                  isLogged={isLogged}
                  onSubmit={handleSendLogWeight}
                />
              </div>
            ) : (
              <div className="home-user__body">
                <div className="home-user__greeting">Hi {userName}</div>
                <div className="home-user__motivation">{quote}</div>
              </div>
            )}
          </div>
          <Guard condition={statuses.workouts}>
            <NavLink to="/history" className="workouts-finished">
              <div className="workouts-finished__icon">
                <CircleProgress finished={historyCount} goal={20} />
              </div>
              <div className="workouts-finished__body">
                <div className="workouts-finished__desc">Month statistics</div>
                <div className="workouts-finished__title">
                  Workouts finished
                </div>
                <div className="workouts-finished__subtitle">In this month</div>
              </div>
            </NavLink>
          </Guard>
        </div>
      </div>
      <Guard condition={statuses.mealPlan}>
        {Boolean(Object.keys(mealPlan).length) && (
          <MealsList
            mealPlan={mealPlan}
            carouselId="meals"
            status={statuses.recipes}
          />
        )}
      </Guard>
      {Boolean(recommendedWorkouts.length) && (
        <Guard condition={statuses.workouts}>
          <div className="recommended-workouts-section">
            <div className="container">
              <div className="workouts-page-section">
                <Title size="medium">Recommended for you</Title>
              </div>
            </div>
            <WorkoutsList
              list={recommendedWorkouts}
              carouselId="recommended"
              isCategoryTag={true}
            />
          </div>
        </Guard>
      )}
      <Guard condition={statuses.workouts}>
        <div className="last-workouts-section">
          <div className="container">
            <div className="workouts-page-section">
              <Title size="medium">Workouts</Title>
              <ViewAllLink to={`/workouts`} />
            </div>
          </div>
          <WorkoutsList
            list={workouts}
            carouselId="last"
            paginationStyle="white"
          />
        </div>
      </Guard>
      <Guard condition={statuses.workouts}>
        <div className="category-workouts-section">
          <div className="container">
            <div className="workouts-page-section">
              <Title size="medium">Categories</Title>
              <ViewAllLink to={`/categories`} />
            </div>
            <CategoriesTabs
              categories={categories}
              categoryActive={categoryActive}
              onClick={handleChangeCategory}
            />
          </div>
          <WorkoutsList
            list={categoryWorkouts}
            size="small"
            carouselId="categories"
            paginationStyle="white"
          />
        </div>
      </Guard>
    </div>
  );
};

export default Home;
