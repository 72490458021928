export const ADD_IS_LOADED = "WORKOUTS:ADD_IS_LOADED";
export const ADD_ERROR = "WORKOUTS:ADD_ERROR";
export const ADD_WORKOUT_CATEGORIES = "WORKOUTS:ADD_WORKOUT_CATEGORIES";
export const ADD_WORKOUT_FAVORITES = "WORKOUTS:ADD_WORKOUT_FAVORITES";
export const ADD_WORKOUT_FAVORITES_IS_FETCHING =
  "WORKOUTS:ADD_WORKOUT_FAVORITES_IS_FETCHING";
export const ADD_FAVORITE = "WORKOUTS:ADD_FAVORITE";
export const ADD_FAVORITES_COUNT = "WORKOUTS:ADD_FAVORITES_COUNT";
export const ADD_FAVORITES_COUNT_REMOVE = "WORKOUTS:ADD_FAVORITES_COUNT_REMOVE";
export const ADD_FAVORITE_ITEM = "WORKOUTS:ADD_FAVORITE_ITEM";
