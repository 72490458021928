import {
  SET_TOGGLE_FETCHING,
  SET_WORKOUT_PLAN_DATA,
  TOGGLE_EXERCISE,
  SET_IS_LOADED,
  CLEAR_WORKOUT_PLAN,
  ADD_ERROR,
  ADD_SIMILAR_PLANS,
  ADD_SIMILAR_PLANS_LOADING,
} from "../constants/workoutPlanTypes";

const initialState = {
  workoutPlanData: null,
  idFetching: false,
  isLoaded: false,
  error: null,
  similarWorkouts: [],
  similarWorkoutsIsLoading: false,
};

const workoutPlan = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOGGLE_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case ADD_SIMILAR_PLANS: {
      return {
        ...state,
        similarWorkouts: [...action.workouts],
      };
    }
    case ADD_SIMILAR_PLANS_LOADING: {
      return {
        ...state,
        similarWorkoutsIsLoading: true,
      };
    }
    case SET_IS_LOADED: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case ADD_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case CLEAR_WORKOUT_PLAN: {
      return {
        ...state,
        similarWorkoutsIsLoading: false,
        isLoaded: false,
        error: null,
      };
    }
    case SET_WORKOUT_PLAN_DATA: {
      return {
        ...state,
        workoutPlanData: action.data,
      };
    }
    case TOGGLE_EXERCISE: {
      const setId = action.setId;
      const exerciseId = action.exerciseId;
      const { workouts, ...workoutPlanData } = state.workoutPlanData;
      const newWorkoutsArray = [...workouts];

      const resultWorkoutsArray = newWorkoutsArray.map((workout) => {
        const { workout_exercises, ...workoutInfo } = workout;
        let tempExercises = [];
        tempExercises = workout_exercises.map((item) => {
          if (workoutInfo.id === setId && item.id === exerciseId) {
            if (item.isOpen) {
              item.isOpen = false;
            } else {
              item.isOpen = true;
            }
            return item;
          } else {
            item.isOpen = false;
            return item;
          }
        });

        return {
          ...workoutInfo,
          workout_exercises: [...tempExercises],
        };
      });
      const resultWorkoutPlanData = { ...workoutPlanData };
      resultWorkoutPlanData.workouts = [...resultWorkoutsArray];

      return {
        ...state,
        workoutPlanData: { ...resultWorkoutPlanData },
      };
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default workoutPlan;
