import React from "react";
import Helmet from "react-helmet";
import themeConfig from "./theme";

const faviconsFolderName = themeConfig.name;

const favicons = (
  <Helmet>
    <link
      rel="icon"
      href={`/favicons/${faviconsFolderName}/favicon.ico`}
      type="image/x-icon"
    />
    <link
      rel="apple-touch-icon"
      href={`/favicons/${faviconsFolderName}/apple/apple-touch-icon.png`}
    />
    <link
      rel="apple-touch-icon-precomposed"
      href={`/favicons/${faviconsFolderName}/apple/apple-touch-icon.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href={`/favicons/${faviconsFolderName}/apple/apple-touch-icon-120x120.png`}
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="120x120"
      href={`/favicons/${faviconsFolderName}/apple/apple-touch-icon-120x120.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href={`/favicons/${faviconsFolderName}/favicon-32x32.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={`/favicons/${faviconsFolderName}/favicon-16x16.png`}
    />
    <link
      rel="mask-icon"
      href={`/favicons/${faviconsFolderName}/safari-pinned-tab.svg`}
    />
    <link
      rel="manifest"
      href={`/favicons/${faviconsFolderName}/site.webmanifest`}
    />
    <link rel="icon" href={`/favicons/${faviconsFolderName}/favicon.ico`} />
  </Helmet>
);

export default favicons;
