import React from 'react';

import Form from './Form';

import './LoginReset.sass';

const LoginReset = ({
  handleSubmitForm,
  isFetching
}) => {
  return (
    <div className="form-wrap login-form">
      <div className="login-form__title sign-up-title">Set your new password</div>
      <Form
        onSubmit={handleSubmitForm}
        isFetching={isFetching}
      />
    </div>
  );
};

export default LoginReset;
