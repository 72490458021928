import produce from "immer";

import {
  SET_IS_LOADED,
  SET_HISTORY_WORKOUTS,
  SET_IS_FETCHING,
  SET_HISTORY_INFO,
  UPDATE_HISTORY_WORKOUTS,
  SET_HISTORY_PREVIEW_LIST,
  UPDATE_HISTORY_PREVIEW_LIST,
  SET_FILTER_DATA,
  ADD_CURRENT_MONTH_COUNT,
  TOGGLE_ELEMENT_TO_DELETE,
  CLEAR_ELEMENT_TO_DELETE,
  ADD_IS_DELETING,
  DELETE_WORKOUTS,
} from "../constants/historyTypes";

const initialState = {
  isLoaded: false,
  isFetching: false,
  workouts: [],
  count: null,
  start: null,
  calories: null,
  duration: null,
  previewWorkoutsCount: 0,
  previewWorkoutsCalories: null,
  previewWorkoutsList: [],
  filterData: {
    value: "all",
  },
  workoutsToDelete: [],
  isDeleting: false,
};

const history = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_WORKOUTS: {
      const nextList = produce(state.workouts, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          if (draft[i].isDelete) {
            delete draft[i];
          }
        }
      }).filter((item) => item);

      return {
        ...state,
        count: action.payload.count,
        start: action.payload.start,
        workouts: nextList,
        workoutsToDelete: [],
      };
    }
    case ADD_IS_DELETING: {
      return {
        ...state,
        isDeleting: action.payload,
      };
    }
    case CLEAR_ELEMENT_TO_DELETE: {
      const nextList = produce(state.workouts, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          draft[i].isDelete = false;
        }
      });
      return {
        ...state,
        workouts: nextList,
        workoutsToDelete: [],
      };
    }
    case TOGGLE_ELEMENT_TO_DELETE: {
      const nextList = produce(state, (draft) => {
        const idx = draft.workouts.findIndex((item) => action.id === item.id);
        if (draft.workouts[idx].isDelete) {
          draft.workouts[idx].isDelete = false;

          const idxToDelete = draft.workoutsToDelete.findIndex(
            (item) => item === action.id
          );
          draft.workoutsToDelete.splice(idxToDelete, 1);
        } else {
          draft.workouts[idx].isDelete = true;
          draft.workoutsToDelete.push(action.id);
        }
      });

      return {
        ...state,
        workouts: nextList.workouts,
        workoutsToDelete: nextList.workoutsToDelete,
      };
    }
    case SET_IS_LOADED: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case SET_HISTORY_WORKOUTS: {
      return {
        ...state,
        workouts: [...action.workouts],
      };
    }
    case UPDATE_HISTORY_WORKOUTS: {
      return {
        ...state,
        workouts: [...state.workouts, ...action.workouts],
      };
    }
    case SET_FILTER_DATA: {
      return {
        ...state,
        filterData: { ...action.filterData },
      };
    }
    case SET_HISTORY_INFO: {
      return {
        ...state,
        count: action.count,
        start: action.start,
        calories: action.calories,
        duration: action.duration,
      };
    }
    case SET_HISTORY_PREVIEW_LIST: {
      return {
        ...state,
        previewWorkoutsList: [...action.list],
        previewWorkoutsCalories: action.calories,
      };
    }
    case ADD_CURRENT_MONTH_COUNT: {
      return {
        ...state,
        previewWorkoutsCount: action.count,
      };
    }
    case UPDATE_HISTORY_PREVIEW_LIST: {
      const newPlan = action.workoutPlan;
      const idx = state.previewWorkoutsList.findIndex(
        (item) => item.id === newPlan.id
      );
      let resultArr = [];
      if (idx > -1) {
        const tempArr = [
          ...state.previewWorkoutsList.slice(0, idx),
          ...state.previewWorkoutsList.slice(idx + 1),
        ];
        resultArr = [newPlan, ...tempArr];
      } else {
        resultArr = [newPlan, ...state.previewWorkoutsList].slice(0, 3);
      }

      return {
        ...state,
        previewWorkoutsCount: ++state.previewWorkoutsCount,
        previewWorkoutsList: [...resultArr],
        previewWorkoutsCalories:
          state.previewWorkoutsCalories + +action.workoutPlan.calories,
        filterData: {
          value: "all",
        },
        isLoaded: false,
        count: null,
      };
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default history;
