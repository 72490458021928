import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import amplitude from "amplitude-js";
import Axios from "axios";
import themeConfig from "../configs/theme";
import { contentAPI } from "../api";

import dayjs from "dayjs";

import {
  setIsLoaded,
  setWorkouts,
  setCategoryWorkouts,
  setActiveCategory,
  setRecommendedWorkouts,
  setQuote,
} from "../actions/home";
import { setCurrentHistoryCount } from "../actions/history";
import {
  setCurrentMealPlan,
  setMealsIdFromMealPlan,
} from "../actions/mealPlan";
import {
  setIsSending,
  updateLogWeightList,
  setCurrentWeekIsLogged,
} from "../actions/logWeight";
import { setActualWeight } from "../actions/profile";
import { clearAuth } from "../actions/auth";

import PageLoader from "../components/common/PageLoader";
import Home from "../components/pages/Home";

let isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

let isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

let weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

const HomePage = ({
  userName,
  avatar,
  isLoaded,
  getPageData,
  historyCount,
  mealPlan,
  workouts,
  categoryWorkouts,
  categories,
  categoryActive,
  setActiveCategory,
  filterCategories,
  measurement,
  statuses,
  recommendedWorkouts,
  quote,
  actualWeight,
  isSending,
  sendWeightLogs,
  currentWeekIsLogged,
  setCurrentWeekIsLogged,
  profileCreatedDate,
}) => {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      getPageData(profileCreatedDate);
    }
  }, [getPageData, isLoaded, profileCreatedDate]);

  useEffect(() => {
    return () => {
      if (isLogged) {
        setCurrentWeekIsLogged();
      }
    };
  }, [setCurrentWeekIsLogged, isLogged]);

  const handleSendLogWeight = (value) => {
    sendWeightLogs(value).then(() => {
      setIsLogged(true);
    });
  };

  const handleChangeCategory = (value, name) => {
    setActiveCategory(name);
    filterCategories(value);
  };

  if (!isLoaded) {
    return <PageLoader />;
  }

  return (
    <>
      <Helmet>
        <title>Home - {themeConfig.title}</title>
      </Helmet>
      <Home
        userName={userName}
        avatar={avatar}
        historyCount={historyCount}
        mealPlan={mealPlan}
        workouts={workouts}
        categoryWorkouts={categoryWorkouts}
        categories={categories}
        categoryActive={categoryActive}
        handleChangeCategory={handleChangeCategory}
        measurement={measurement}
        statuses={statuses}
        recommendedWorkouts={recommendedWorkouts}
        quote={quote}
        actualWeight={actualWeight}
        isSending={isSending}
        isLogged={isLogged}
        handleSendLogWeight={handleSendLogWeight}
        currentWeekIsLogged={currentWeekIsLogged}
      />
    </>
  );
};

const checkQuote = () => {
  const localQuote = JSON.parse(localStorage.getItem("quote"));
  if (localQuote && localQuote.date === dayjs().endOf("day").format()) {
    return localQuote.quote;
  }
  return contentAPI.getRandomQuote();
};

export default connect(
  ({ profile, auth, home, history, mealPlan, logWeight }) => ({
    userName: profile.userName,
    avatar: profile.avatar,
    measurement: profile.measurement,
    actualWeight: profile.actualWeight,
    profileCreatedDate: profile.profileCreatedDate,
    isLoaded: home.isLoaded,
    historyCount: history.previewWorkoutsCount,
    mealPlan: mealPlan.currentMealPlan,
    workouts: home.workouts,
    categoryWorkouts: home.categoryWorkouts,
    categories: home.categories,
    categoryActive: home.categoryActive,
    statuses: auth.statuses,
    recommendedWorkouts: home.recommendedWorkouts,
    quote: home.quote,
    isSending: logWeight.isSending,
    currentWeekIsLogged: logWeight.currentWeekIsLogged,
  }),
  (dispatch) => ({
    setActiveCategory: (category) => dispatch(setActiveCategory(category)),
    filterCategories: (filter) => {
      return contentAPI
        .getWorkoutPlans(0, 10, filter)
        .then((res) => {
          dispatch(setCategoryWorkouts(res.data.data));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    getPageData: (profileCreatedDate) => {
      const dataFilterFrom = dayjs().startOf("month").format();
      const dataFilterTo = dayjs().endOf("month").format();
      return Axios.all([
        contentAPI
          .getFinishedWorkoutPlan(0, 0, dataFilterFrom, dataFilterTo)
          .then((res) => res)
          .catch((err) => null),
        contentAPI
          .getMealPlan()
          .then((res) => {
            return res;
          })
          .catch((err) => null),
        contentAPI.getWorkoutPlans(0, 10, "&category=weight_loss"),
        contentAPI.getWorkoutPlans(0, 10, "&category_ncontains=weight_loss"),
        contentAPI.getRecommendedWorkoutPlans(),
        checkQuote(),
        contentAPI.getWeightLogs(true),
      ])
        .then(
          Axios.spread(
            (
              history,
              mealPlan,
              workouts,
              categories,
              recommended,
              quote,
              weightLog
            ) => {
              if (history) {
                dispatch(setCurrentHistoryCount(history.data.count));
              }

              if (mealPlan) {
                const mealPlanDateActivated =
                  mealPlan.activated_at || profileCreatedDate;
                const startDate = dayjs(mealPlanDateActivated)
                  .startOf("week")
                  .format();
                const startWeek = dayjs(startDate)
                  .weekday(1)
                  .format("YYYY-MM-DD");
                const currentDate = dayjs().startOf("day").format("YYYY-MM-DD");
                const diffDay = dayjs(currentDate).diff(startWeek, "day") + 1;

                const remains = diffDay % mealPlan.data.data.daily_meals.length;

                let mealPlanResult = [];
                if (remains === 0) {
                  mealPlanResult =
                    mealPlan.data.data.daily_meals[
                      mealPlan.data.data.daily_meals.length - 1
                    ];
                } else {
                  mealPlanResult = mealPlan.data.data.daily_meals[remains - 1];
                }
                setMealsIdFromMealPlan(mealPlan.data.data.daily_meals);
                dispatch(setCurrentMealPlan(mealPlanResult));
              }
              if (recommended) {
                dispatch(setRecommendedWorkouts(recommended.data));
              }
              if (quote) {
                let resultQuote =
                  typeof quote === "string" ? quote : quote.data[0].text;
                dispatch(setQuote(resultQuote, dayjs().endOf("day").format()));
              }
              if (weightLog && weightLog.data.length) {
                const logItem = weightLog.data[0];
                const lastLogItemDate = dayjs(logItem.created_at).format();
                const currentStartDay = dayjs()
                  .isoWeekday(1)
                  .startOf("day")
                  .format();
                const currentEndDay = dayjs(currentStartDay)
                  .subtract(-6, "day")
                  .endOf("day")
                  .format("");
                const isBetween = dayjs(lastLogItemDate).isBetween(
                  currentStartDay,
                  currentEndDay,
                  "day",
                  "[]"
                );

                if (isBetween) {
                  dispatch(setCurrentWeekIsLogged());
                }
              }

              dispatch(setWorkouts(workouts.data.data));
              dispatch(setCategoryWorkouts(categories.data.data));
              dispatch(setIsLoaded());
            }
          )
        )
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    sendWeightLogs: (value) => {
      dispatch(setIsSending(true));
      return contentAPI
        .sendWeightLogs(value)
        .then((res) => {
          dispatch(setActualWeight(res.data.weightLog.weight));
          dispatch(updateLogWeightList(res.data.weightLog));
          dispatch(setIsSending(false));
          amplitude
            .getInstance()
            .logEvent("user_properties", { current_weight: value });

          return res;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    setCurrentWeekIsLogged: () => dispatch(setCurrentWeekIsLogged()),
  })
)(HomePage);
