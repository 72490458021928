import React from "react";

import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import clsx from "clsx";

import WorkoutCard from "../../../../../common/containers/WorkoutCardContainer";
import IconSprite from "../../../../common/IconSprite";

import "swiper/components/pagination/pagination.scss";
import "./WorkoutsList.sass";

SwiperCore.use([Pagination, Navigation]);

const WorkoutsList = ({
  list,
  size = null,
  carouselId,
  paginationStyle,
  isCategoryTag = false,
}) => {
  const breakpointsOptions =
    size === "small"
      ? {
          0: {
            spaceBetween: 10,
          },
          640: {
            spaceBetween: 15,
          },
          890: {
            spaceBetween: 25,
          },
          1024: {
            spaceBetween: 35,
          },
          1200: {
            spaceBetween: 47,
          },
        }
      : {
          0: {
            spaceBetween: 10,
          },
          640: {
            spaceBetween: 20,
          },
          820: {
            spaceBetween: 30,
          },
          1024: {
            spaceBetween: 40,
          },
          1200: {
            spaceBetween: 50,
          },
          1350: {
            spaceBetween: 64,
          },
        };
  return (
    <div
      className={clsx("workouts-list-carousel", {
        [size]: size,
      })}
    >
      <div className="container">
        <Swiper
          watchSlidesVisibility={true}
          slidesPerView="auto"
          breakpoints={breakpointsOptions}
          navigation={{
            nextEl: ".carousel-next-" + carouselId,
            prevEl: ".carousel-prev-" + carouselId,
          }}
          pagination={{
            el: ".workouts-pagination-" + carouselId,
            clickable: true,
          }}
        >
          {list.map(
            ({ id, title, level, calories, duration, category, preview }) => {
              return (
                <SwiperSlide key={id}>
                  <WorkoutCard
                    key={id}
                    linkTo={`/workouts/${id}`}
                    title={title}
                    level={level}
                    calories={calories}
                    duration={duration}
                    category={category}
                    preview={preview}
                    size={size}
                    isCategoryTag={isCategoryTag}
                  />
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
        <div
          className={
            "swiper-button-nav swiper-button-prev carousel-prev-" + carouselId
          }
        >
          <IconSprite className="arrow-left-icon" name="arrow-left" />
        </div>
        <div
          className={
            "swiper-button-nav swiper-button-next carousel-next-" + carouselId
          }
        >
          <IconSprite className="arrow-right-icon" name="arrow-right" />
        </div>
      </div>
      <div className="container">
        <div
          className={clsx(
            "swiper-pagination workouts-pagination-" + carouselId,
            {
              ["swiper-pagination--" + paginationStyle]: paginationStyle,
            }
          )}
        ></div>
      </div>
    </div>
  );
};

export default WorkoutsList;
