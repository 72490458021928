import React from "react";

import "./Footer.sass";

const Footer = ({
  onPurchaseSubscriptions,
  isDone,
  handleCloseModal,
  isError,
}) => {
  return (
    <div className="subs-modal-footer">
      {(isDone || isError) && (
        <button className="subs-modal-footer__btn" onClick={handleCloseModal}>
          Close
        </button>
      )}
      {!isDone && !isError && (
        <>
          <button
            className="subs-modal-footer__btn purchase"
            onClick={onPurchaseSubscriptions}
          >
            GET MY FREE ACCESS
          </button>
          <div className="subs-modal-footer__add-info">
            7-day free trial, cancel anytime
          </div>
          <div className="subs-modal-footer__desc">
            *Automatic renewal after 7 days at $23.99 every 30 days until
            cancellation
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
