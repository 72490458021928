import { contentAPI } from "../api";
import {
  ADD_IS_LOADED,
  ADD_WORKOUT_CATEGORIES,
  ADD_ERROR,
  ADD_WORKOUT_FAVORITES,
  ADD_WORKOUT_FAVORITES_IS_FETCHING,
  ADD_FAVORITE,
  ADD_FAVORITE_ITEM,
  ADD_FAVORITES_COUNT,
  ADD_FAVORITES_COUNT_REMOVE,
} from "../constants/workoutsTypes";
import { toggleFavorite as toggleFavoriteCategory } from "./categoryDetail";

export const toggleFavorite = (id, category) => {
  return (dispatch, getState) => {
    const state = getState().workouts;
    const stateCategory = getState().categoryDetail;
    let item = null;
    let addFavorite;
    if (state.isLoaded) {
      let groupToChange, activeWorkoutIdx;
      let activeList = state.workouts.find(({ name }) => name === category);
      if (activeList) {
        groupToChange = "workouts";
        activeWorkoutIdx = activeList.list.findIndex((item) => item.id === id);
        addFavorite = !Boolean(activeList.list[activeWorkoutIdx].isFavourite);
        item = {
          ...activeList.list[activeWorkoutIdx],
          isFavourite: addFavorite,
        };
      } else {
        activeList = state.lifestyle.find(({ name }) => name === category);
        groupToChange = "lifestyle";
        activeWorkoutIdx = activeList.list.findIndex((item) => item.id === id);
        addFavorite = !Boolean(activeList.list[activeWorkoutIdx].isFavourite);
        item = {
          ...activeList.list[activeWorkoutIdx],
          isFavourite: addFavorite,
        };
      }
      dispatch(setFavorite(groupToChange, id, category, activeWorkoutIdx));
    }
    if (stateCategory.isLoaded && stateCategory.categoryName === category) {
      const idx = stateCategory.list.findIndex((item) => item.id === id);
      dispatch(toggleFavoriteCategory(id));
      addFavorite = !Boolean(stateCategory.list[idx].isFavourite);
      if (!item) {
        item = { ...stateCategory.list[idx], isFavourite: addFavorite };
      }
    }
    dispatch(addFavoriteItem(id, item, addFavorite));
    if (addFavorite) {
      return contentAPI.sendFavoriteWorkout(id);
    } else {
      dispatch(setCountToRemove(state.countToRemoveFavorites + 1));
      return contentAPI.deleteFavoriteWorkout(id).then((res) => {
        dispatch(checkToUpdateFavorites());
      });
    }
  };
};

export const setCountToRemove = (payload) => ({
  type: ADD_FAVORITES_COUNT_REMOVE,
  payload,
});

export const addFavoriteItem = (id, item, addFavorite) => ({
  type: ADD_FAVORITE_ITEM,
  payload: { id, item, addFavorite },
});

export const checkToUpdateFavorites = () => {
  return (dispatch, getState) => {
    const {
      favorites,
      favoritesCount,
      favoritesIsLoaded,
      countToRemoveFavorites,
    } = getState().workouts;

    dispatch(setCountToRemove(countToRemoveFavorites - 1));

    if (
      favoritesIsLoaded &&
      favoritesCount > favorites.length &&
      favorites.length <= 4 &&
      countToRemoveFavorites - 1 === 0
    ) {
      dispatch(updateFavorites());
    }
  };
};

export const updateFavorites = () => {
  return (dispatch, getState) => {
    const { favorites } = getState().workouts;
    let countToLoaded = 8 - (favorites.length % 8);

    if (countToLoaded < 4) {
      countToLoaded += 8;
    }

    dispatch(setFavoritesFetching(true));
    return contentAPI
      .getWorkoutsFavorites(favorites.length, countToLoaded)
      .then((res) => {
        dispatch(setFavorites(res.data.count, res.data.data));
        dispatch(setFavoritesFetching(false));
      });
  };
};

export const getFavorites = () => {
  return (dispatch, getState) => {
    return contentAPI.getWorkoutsFavorites(0, 8).then((res) => {
      dispatch(setFavorites(res.data.count, res.data.data));
    });
  };
};

export const setFavoritesCount = (count) => ({
  type: ADD_FAVORITES_COUNT,
  payload: count,
});

export const setFavorite = (groupToChange, id, category, activeWorkoutIdx) => ({
  type: ADD_FAVORITE,
  payload: { groupToChange, id, category, activeWorkoutIdx },
});

export const setFavoritesFetching = (fetching) => ({
  type: ADD_WORKOUT_FAVORITES_IS_FETCHING,
  payload: fetching,
});

export const setFavorites = (count, list) => {
  return {
    type: ADD_WORKOUT_FAVORITES,
    payload: {
      count,
      list,
    },
  };
};

export const setIsLoaded = () => {
  return {
    type: ADD_IS_LOADED,
  };
};

export const setError = (error) => {
  return {
    type: ADD_ERROR,
    error,
  };
};

export const setWorkouts = (workouts) => {
  const resultWorkouts = workouts.map((category) => {
    const title = category.name
      .split("_")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");

    return {
      name: category.name,
      title,
      list: [...category.workoutPlans.data],
    };
  });

  return {
    type: ADD_WORKOUT_CATEGORIES,
    workouts: resultWorkouts.slice(6),
    lifestyle: resultWorkouts.slice(0, 6),
  };
};
