import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';

import { Swiper, SwiperSlide } from 'swiper/react';

import IconSprite from '../../../../common/IconSprite';

import './CategoriesTabs.sass';

const calculateBreakpoint = (pageWidth) => {
  if (pageWidth < 1024) {
    return 'mobile';
  }
  return 'desktop';
};

const CategoriesTabs = ({
  categories,
  categoryActive,
  onClick
}) => {

  const [breakpointName, setBreakpointName] = useState(calculateBreakpoint(window.innerWidth));

  const calculateBreakpointWrapper = useCallback((event) => {
    setBreakpointName(calculateBreakpoint(event.target.innerWidth));
  }, [setBreakpointName]);


  useEffect(() => {
    window.addEventListener('resize', calculateBreakpointWrapper);
    return () => window.removeEventListener('resize', calculateBreakpointWrapper);
  }, [calculateBreakpointWrapper]);

  return (
    <div className="category-tabs-wrap">
      {breakpointName === "mobile" ? (
        <div className="category-tabs">
          {categories.map(({ value, title, name }) => {
            return (
              <div className={clsx("category-tab", {
                "active": categoryActive === name
              })}
                key={name}
                onClick={() => onClick(value, name)}
              >
                <IconSprite className={`${name}-icon`} name={`category-${name === 'all' ? 'stretching' : name}`} />
                <span>{title}</span>
              </div>
            )
          })}
        </div>
      ) : (
          <Swiper
            watchSlidesVisibility={true}
            slidesPerView="auto"
            className="category-tabs"
          >
            {categories.map(({ value, title, name }) => {
              return (
                <SwiperSlide key={name} className={clsx("category-tab", {
                  "active": categoryActive === name
                })}
                  onClick={() => onClick(value, name)}>
                  <IconSprite className={`${name}-icon`} name={`category-${name === 'all' ? 'stretching' : name}`} />
                  <span>{title}</span>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
    </div>
  );
};

export default CategoriesTabs;
