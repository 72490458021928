import React from "react";
import clsx from "clsx";

import Title from "../Title";

import classes from "./Step.module.sass";

const Step = ({ title, fullHeight, children }) => {
  return (
    <div
      className={clsx(classes.wrap, {
        [classes.full]: fullHeight,
      })}
    >
      {title && (
        <div className={classes.head}>
          <Title>{title}</Title>
        </div>
      )}
      <div className={classes.body}>
        <div className={classes.inner}>{children}</div>
      </div>
    </div>
  );
};

export default Step;
