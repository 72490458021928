import React from "react";

import classes from "./Input.module.sass";

const Input = ({ placeholder, ...props }) => {
  return (
    <div className={classes.wrap}>
      <input className={classes.input} placeholder={placeholder} {...props} />
    </div>
  );
};

export default Input;
