import React, { useState } from 'react';

import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';

import IconSprite from '../../../../common/IconSprite';

import 'swiper/swiper.scss';
import './MealCardSwipeable.sass';

const MealCardSwipeable = ({
  children,
  done,
  onDone,
  onUndo
}) => {
  const [swiper, setSwiper] = useState(null);
  const [isDone, setIsDone] = useState(done);

  const handleDone = () => {
    swiper.slideTo(0, 400);

    if (isDone) {
      setIsDone(false);
      onUndo();
    } else {
      setIsDone(true);
      onDone();
    }
  };

  return (
    <div className={clsx("meal-card-swipeable with-transition", {
      'is-done': isDone,
      'is-undo': !isDone,
    })}>
      <Swiper
        onSwiper={(swiper) => setSwiper(swiper)}
        speed={400}
        spaceBetween={0}
        slidesPerView="auto"
      >
        <SwiperSlide>
          <div className="meal-card-swipeable-element">
            {children}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="meal-card-done-btn"
            onClick={handleDone}
          >
            <div className="meal-card-done-btn__inner">
              <IconSprite className="done-icon" name="done" />
              <span>Log Meal</span>
            </div>
            <div className="meal-card-done-btn__inner">
              <IconSprite className="undo-arrow-icon" name="undo-arrow" />
              <span>Undo</span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MealCardSwipeable;
