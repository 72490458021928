import React from "react";
import clsx from "clsx";

import IconSprite from "../../../../common/IconSprite";

import classes from "./Radio.module.sass";

const Radio = ({ title, big, field, form, value, active }) => {
  return (
    <label
      className={clsx(classes.wrap, {
        [classes.big]: big,
      })}
    >
      <input type="radio" className={classes.input} {...field} value={value} />
      <div className={classes.content}>
        <div className={classes.inner}>{title}</div>
      </div>
      <div className={classes.flag}>
        <IconSprite className={classes.flagIcon} name="check-outlined" />
      </div>
    </label>
  );
};

export default Radio;
