import dayjs from "dayjs";
import axios from "../configs/axios";
import { contentAPI } from "../api";
import { setHasInvited } from "./profile";
// import configTheme from "../configs/theme";

import {
  SET_FETCHING_STATUS,
  SET_AUTH_DATA,
  CLEAR_AUTH_DATA,
  ADD_SUBSCRIBE_STATUS,
  ADD_APP_FIELDS,
  ADD_LAST_VISIT,
  SET_REFERRAL_MODAL,
} from "../constants/authTypes";

export const sendReferral = (data) => {
  return (dispatch, getState) => {
    const subscribes = getState().auth.subscribes;
    const subscribeId = subscribes.find(
      (subscribe) => subscribe?.product?.type === "workouts_recipes"
    ).id;

    return contentAPI.sendReferral(data, subscribeId).then((res) => {
      dispatch(setHasInvited("yes"));
      return res;
    });
  };
};

export const sendTestResult = (data) => {
  return (dispatch) => {
    return contentAPI
      .sendSurvey({
        shop_id: 5051,
        status: "active",
        ...data,
      })
      .then((res) => {
        dispatch(setReferralModal(true));
        return res;
      });
  };
};

export const setReferralModal = (payload) => {
  return {
    type: SET_REFERRAL_MODAL,
    payload,
  };
};

export const setLastVisit = (lastVisit) => {
  const daysSince = dayjs().diff(lastVisit, "day");
  let passedLongTime = false;
  if (daysSince > 2) {
    passedLongTime = true;
  }
  return {
    type: ADD_LAST_VISIT,
    lastVisit,
    passedLongTime,
    daysSince,
  };
};

export const setAppFields = (source) => {
  return {
    type: ADD_APP_FIELDS,
    source,
  };
};

export const setFetchingLoader = (isFetching) => {
  return {
    type: SET_FETCHING_STATUS,
    isFetching,
  };
};

export const setAuthData = (
  token,
  isAuth,
  userId,
  userName,
  isSignUp = false
) => {
  return {
    type: SET_AUTH_DATA,
    token,
    isAuth,
    userId,
    userName,
    isSignUp,
    isFetching: false,
  };
};

export const setStatuses = (subscribes) => {
  const statuses = {
    workouts: false,
    recipes: false,
    mealPlan: false,
  };
  let company = "";
  let isPollPassed = true;

  subscribes.forEach((subscribe) => {
    if (subscribe?.product?.type === "workouts") {
      statuses.workouts = subscribe.status === "active";
    }
    if (subscribe?.product?.type === "recipes") {
      statuses.recipes = subscribe.status === "active";
    }
    if (subscribe?.product?.type === "workouts_recipes") {
      statuses.recipes = subscribe.status === "active";
      statuses.workouts = subscribe.status === "active";
    }
    if (
      subscribe?.product?.type === "meal_plan" &&
      subscribe.status === "active"
    ) {
      statuses.mealPlan = true;
      company = subscribe.company;
      if (
        subscribe.isPollPassed === false
        // && configTheme.name === "funappetit"
      ) {
        isPollPassed = false;
      }
    }
  });

  return {
    type: ADD_SUBSCRIBE_STATUS,
    statuses,
    company,
    subscribes,
    isPollPassed,
  };
};

export const setDefaultStore = () => {
  return {
    type: "DEFAULT_STATE",
  };
};

export const clearAuthData = () => {
  return {
    type: CLEAR_AUTH_DATA,
  };
};

export const clearAuth = (dispatch) => {
  localStorage.removeItem("token");
  delete axios.defaults.headers.common["Authorization"];
  document.body.style.overflow = "";
  dispatch(clearAuthData());
  dispatch(setDefaultStore());
};

export const getAuth = () => {
  return (dispatch) => {
    if (localStorage.getItem("token")) {
      dispatch(
        setAuthData(
          localStorage.getItem("token"),
          true,
          +localStorage.getItem("userId"),
          localStorage.getItem("userName")
        )
      );
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
    }
  };
};
