import React from 'react';
import Vimeo from '@u-wave/react-vimeo';

import './video.sass';

const Video = ({
  video1,
  video2,
  isSecondVideo
}) => {

  return (
    <>
      {
        (!isSecondVideo && video1) ?
          (
            <div className="video">
              <Vimeo
                video={video1}
                autoplay={true}
                color="000000"
                controls={false}
                loop={true}
                muted={true}
              />
            </div>
          ) : null
      }
      {
        (isSecondVideo || !video1) ?
          (
            <div className="video-second">
              <Vimeo
                video={video2}
                autoplay={true}
                color="000000"
                loop={true}
              />
            </div>
          ) : null
      }
    </>
  );
};

export default Video;
