import React from "react";
import clsx from "clsx";

import IconSprite from "../../../../common/IconSprite";

import classes from "./DietCard.module.sass";

const DietCard = ({ image, title, desc, active, onClick }) => {
  return (
    <div
      className={clsx(classes.card, {
        [classes.active]: active,
      })}
      onClick={onClick}
    >
      <div
        className={classes.inner}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className={classes.content}>
          <div className={classes.name}>{title}</div>
          <div className={classes.desc}>{desc}</div>
        </div>
      </div>
      <div className={classes.flag}>
        <IconSprite className={classes.flagIcon} name="check-outlined" />
      </div>
    </div>
  );
};

export default DietCard;
