import dayjs from 'dayjs';

import {
  ADD_IS_LOADED,
  ADD_LOG_WEIGHT_LIST,
  ADD_IS_SENDING,
  UPDATE_LOG_WEIGHT_LIST,
  ADD_CURRENT_WEEK_IS_LOGGED
} from '../constants/logWeightTypes';

export const setIsLoaded = () => {
  return {
    type: ADD_IS_LOADED,
  };
};

export const setIsSending = (isSending) => {
  return {
    type: ADD_IS_SENDING,
    isSending
  };
};

export const setCurrentWeekIsLogged = (logItem) => {
  return {
    type: ADD_CURRENT_WEEK_IS_LOGGED
  };
};

export const setLogWeightList = (list) => {
  const resultList = list.map(item => {
    return createLogItem(item);
  });

  return {
    type: ADD_LOG_WEIGHT_LIST,
    list: resultList
  };
};

export const updateLogWeightList = (item) => {
  return {
    type: UPDATE_LOG_WEIGHT_LIST,
    logItem: createLogItem(item)
  };
};

const createLogItem = (item) => {
  return {
    id: item.id,
    dateNative: item.updated_at,
    weightValue: item.weight,
    dateDetail: dayjs(item.updated_at).format("MMM DD, YYYY hh:mm A"),
    dateMin: dayjs(item.updated_at).format("MMM DD"),
  };
};
