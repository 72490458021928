import {
  SET_IS_LOADED,
  SET_DAILY_MEALS,
  SET_PLAN_INFO,
  CLEAR_MEAL_PLAN,
  SET_TOGGLE_FETCHING,
  SET_SELECTED_MEALS_DAY,
  SET_IS_REPLACING,
  SET_MEAL,
  SET_MEALS_LIST,
  SET_IS_FETCHING_LIST,
  SET_SELECTED_TYPE,
  RELOAD_MEALS_LIST,
  CLEAR_MEALS_LIST,
  SET_MEAL_PREVIEW,
  CLEAR_MEAL_PREVIEW,
  SET_NEW_MEAL_TYPE,
  SET_NEW_MEAL_ID,
  ADD_ERROR,
  ADD_CURRENT_MEAL_PLAN,
  ADD_LIST_PLANS,
  UPDATE_ACTIVE_PLAN,
  ADD_IS_PURCHASING,
  UPDATE_LIST_PLANS,
  ADD_IS_REPLACING_MEAL_PLAN,
  ADD_REPLACE_MEAL_PLAN,
  CLEAR_PURCHASE_MODAL,
  ADD_PURCHASE_ERROR,
  ADD_MEALS_FROM_MEAL_PLAN,
} from "../constants/mealPlanTypes";

const initialState = {
  isLoaded: false,
  dailyMeals: [],
  daysCount: null,
  mealPlanDateCreated: null,

  isFetching: false,

  selectedMealsDay: null,

  meal: {},
  mealId: null,
  mealsList: [],
  mealsListStart: null,
  mealsListCount: null,
  isFetchingList: false,
  selectedType: null,
  mealPreview: null,
  isLoadedMealPreview: false,
  isReplacing: false,
  typeName: null,
  newMealId: null,
  activeDay: null,
  error: null,
  currentMealPlan: {},
  withChange: true,
  defaultMealPlanId: null,

  listPlans: [],
  listPlansReadyToDefault: [],
  activePlan: null,
  notPurchased: [],
  isPurchasing: false,
  isPurchasedDone: false,
  isPurchasedError: false,
  isReplacingMealPlan: false,

  mealsFromMealPlan: [],
};

const mealPlan = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MEALS_FROM_MEAL_PLAN: {
      return {
        ...state,
        mealsFromMealPlan: [...action.mealsFromMealPlan],
      };
    }
    case ADD_PURCHASE_ERROR: {
      return {
        ...state,
        isPurchasedError: true,
      };
    }
    case CLEAR_PURCHASE_MODAL: {
      return {
        ...state,
        isPurchasing: false,
        isPurchasedDone: false,
        isPurchasedError: false,
      };
    }
    case ADD_REPLACE_MEAL_PLAN: {
      let newActivePlan = { ...state.activePlan };
      const newListPlans = state.listPlans.map((diet) => {
        if (diet.isPurchased) {
          let result = {};
          if (diet.type === action.mealPlanType) {
            result = { ...diet, status: "Default", isDefault: true };
            newActivePlan = { ...result };
          } else {
            result = { ...diet, status: "Not default", isDefault: false };
          }
          return result;
        }
        return { ...diet };
      });
      return {
        ...state,
        listPlans: [...newListPlans],
        activePlan: { ...newActivePlan },
        withChange: true,
        defaultMealPlanId: newActivePlan.id,
        currentMealPlan: { ...newActivePlan.days[action.activeMeal] },
      };
    }
    case ADD_IS_REPLACING_MEAL_PLAN: {
      return {
        ...state,
        isReplacingMealPlan: action.isReplacingMealPlan,
      };
    }
    case UPDATE_LIST_PLANS: {
      const newNotPurchasedArr = [];
      const newListPlans = state.listPlans.map((diet) => {
        const idx = action.diets.findIndex((item) => item.id === diet.id);
        if (idx !== -1) {
          return {
            ...diet,
            ...action.diets[idx],
          };
        } else {
          if (!diet.isPurchased) {
            newNotPurchasedArr.push(diet.type);
          }
          return {
            ...diet,
          };
        }
      });
      const idx = newListPlans.findIndex(
        ({ id }) => id === state.activePlan.id
      );
      let newActivePlan = { ...state.activePlan };

      if (idx !== -1) {
        newActivePlan = { ...newListPlans[idx] };
        newActivePlan.days = [...newListPlans[idx].days];
      }
      const newListPlansReadyToDefault = action.diets.map((diet) => {
        const idx = newListPlans.findIndex((item) => item.id === diet.id);
        return newListPlans[idx].type;
      });

      return {
        ...state,
        isPurchasedDone: true,
        listPlans: [...newListPlans],
        notPurchased: [...newNotPurchasedArr],
        activePlan: { ...newActivePlan },
        dailyMeals: [...newActivePlan.days],
        mealPlanDateCreated: newActivePlan.datePurchased,
        daysCount: newActivePlan.daysCount,
        listPlansReadyToDefault: [...newListPlansReadyToDefault],
      };
    }
    case ADD_IS_PURCHASING: {
      return {
        ...state,
        isPurchasing: action.isPurchasing,
      };
    }
    case ADD_LIST_PLANS: {
      return {
        ...state,
        listPlans: [...action.list],
        activePlan: { ...action.plan },
        defaultMealPlanId: action.plan.id,
        notPurchased: [...action.notPurchased],
      };
    }
    case UPDATE_ACTIVE_PLAN: {
      const idx = state.listPlans.findIndex(({ id }) => id === action.id);
      const newActivePlan = { ...state.listPlans[idx] };
      let withChange = true;
      if (state.defaultMealPlanId !== newActivePlan.id) {
        withChange = false;
      }
      return {
        ...state,
        activePlan: { ...newActivePlan },
        dailyMeals: [...newActivePlan.days],
        mealPlanDateCreated: newActivePlan.datePurchased,
        daysCount: newActivePlan.daysCount,
        withChange: withChange,
      };
    }
    case SET_IS_LOADED: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case SET_DAILY_MEALS: {
      return {
        ...state,
        dailyMeals: [...action.dailyMeals],
      };
    }
    case SET_PLAN_INFO: {
      return {
        ...state,
        daysCount: action.count,
        mealPlanDateCreated: action.date,
      };
    }
    case CLEAR_MEAL_PLAN: {
      return {
        ...state,
        ...initialState,
        currentMealPlan: { ...state.currentMealPlan },
      };
    }

    case SET_MEAL: {
      return {
        ...state,
        meal: action.meal,
      };
    }
    case SET_MEALS_LIST: {
      return {
        ...state,
        mealsList: [...state.mealsList, ...action.mealsList],
        mealsListStart: action.mealsListStart,
        mealsListCount: action.mealsListCount,
      };
    }
    case RELOAD_MEALS_LIST: {
      return {
        ...state,
        mealsList: [...action.mealsList],
        mealsListStart: action.mealsListStart,
        mealsListCount: action.mealsListCount,
      };
    }
    case SET_IS_FETCHING_LIST: {
      return {
        ...state,
        isFetchingList: action.isFetchingList,
      };
    }
    case SET_SELECTED_TYPE: {
      return {
        ...state,
        selectedType: action.selectedType,
        typeName: action.typeName,
        mealId: action.mealId,
        activeDay: action.activeDay,
      };
    }
    case CLEAR_MEALS_LIST: {
      return {
        ...state,
        meal: {},
        mealsList: [],
        mealsListStart: null,
        mealsListCount: null,
        isFetchingList: false,
        selectedType: null,
        mealPreview: null,
        isLoadedMealPreview: false,
      };
    }
    case SET_MEAL_PREVIEW: {
      return {
        ...state,
        mealPreview: { ...action.mealPreview },
        isLoadedMealPreview: true,
      };
    }
    case ADD_CURRENT_MEAL_PLAN: {
      return {
        ...state,
        currentMealPlan: { ...action.mealPlan },
      };
    }
    case CLEAR_MEAL_PREVIEW: {
      return {
        ...state,
        mealPreview: null,
        isLoadedMealPreview: false,
      };
    }
    case SET_IS_REPLACING: {
      return {
        ...state,
        isReplacing: action.isReplacing,
      };
    }
    case SET_NEW_MEAL_ID: {
      return {
        ...state,
        newMealId: action.newMealId,
      };
    }

    case SET_NEW_MEAL_TYPE: {
      return {
        ...state,
        selectedMealId: action.id,
        selectedMealName: action.name,
      };
    }
    case SET_TOGGLE_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case SET_SELECTED_MEALS_DAY: {
      return {
        ...state,
        selectedMealsDay: action.day,
      };
    }
    case ADD_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default mealPlan;
