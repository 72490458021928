import React from 'react';

import './param.sass'

const Param = ({
  className,
  icon,
  children
}) => {

  const mainClass = 'param';
  const classes = className ? `${mainClass} ${className}` : mainClass;

  return (
    <div className={classes}>
      {icon}
      <span>{children}</span>
    </div>
  );
};

export default Param;
