import React from 'react';

import './tagList.sass';

const TagList = ({
  className,
  children
}) => {

  const mainClass = 'tag-list';
  const classes = className ? `${mainClass} ${className}` : mainClass;

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export default TagList;