import React, { useEffect } from "react";

import WorkoutCard from "../../../../../common/containers/WorkoutCardContainer";
import LoaderWrap from "../../../../common/LoaderWrap";
import Button from "../../../../common/Button";
import Loader from "../../../../common/Loader";
import FavoritesIcon from "../../../Recipes/components/RecipesFavoritesList/icons/favorites-icon.svg";
import classes from "./Favorites.module.sass";

const Favorites = ({
  list = [],
  listCount = 0,
  isFetching,
  favoritesIsLoaded,
  getFavorites,
  onUpdate,
}) => {
  useEffect(() => {
    if (!favoritesIsLoaded) {
      getFavorites();
    }
  }, [favoritesIsLoaded, getFavorites]);

  return (
    <div className="favorites-page">
      {favoritesIsLoaded ? (
        <div className="container">
          {list.length ? (
            <>
              <div className="workout-plans-list">
                {list
                  .map(
                    ({
                      id,
                      title,
                      level,
                      calories,
                      duration,
                      category,
                      preview,
                      isFavourite,
                    }) => {
                      return (
                        <WorkoutCard
                          key={id}
                          id={id}
                          linkTo={`/workouts/${id}`}
                          title={title}
                          level={level}
                          calories={calories}
                          duration={duration}
                          category={category}
                          preview={preview}
                          size="small"
                          withLike
                          isFavourite={isFavourite}
                        />
                      );
                    }
                  )
                  .filter((item) => item)}
              </div>
              {listCount > list.length ? (
                <div className="workout-plans-more">
                  <LoaderWrap spin={isFetching}>
                    <Button disabled={isFetching} onClick={onUpdate}>
                      View more
                    </Button>
                  </LoaderWrap>
                </div>
              ) : null}
            </>
          ) : (
            <div className="recipes-empty">
              <img className="recipes-empty__icon" src={FavoritesIcon} alt="" />
              <div className="recipes-empty__text">
                <div className="recipes-empty__text-title">No workouts</div>
                <div className="recipes-empty__text-desc">
                  You have no workouts in your Favorites
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.loader}>
          <Loader size={32} />
        </div>
      )}
    </div>
  );
};

export default Favorites;
