import React from 'react';
import clsx from 'clsx';

import './Radio.sass';

const Radio = ({
  className,
  name,
  label,
  value,
  onChange,
  valueIsChecked,
  checked,
  addData
}) => {
  return (
    <label className={clsx("radio-wrap", {
      [className]: className
    })}>
      <input
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
      />
      <div className="radio-icon"></div>
      <div className="radio-label">{label}</div>
    </label>
  );
};

export default Radio
