import React from "react";
import clsx from "clsx";

import classes from "./Button.module.sass";

const Button = ({ children, className, type, ...props }) => {
  return (
    <button
      className={clsx(classes.button, {
        [className]: className,
        [classes[type]]: type,
      })}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
