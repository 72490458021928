import React, { useState } from "react";

import HeadIcon from "../HeadIcon";
import Input from "../Input";
import Button from "../Button";
import Checkbox from "../Checkbox";

import classes from "./Steps.module.sass";

const transformToNumber = (value) => {
  let result = value;
  const regex = /\D/i;
  result = value.replace(regex, "").trim();

  if (String(result).length > 1 && String(result[0]) === "0") {
    result = result.slice(1);
  }

  return result;
};

const validate = (max, min) => {
  return (value) => {
    let error = null;
    if (
      !value ||
      typeof parseInt(value) !== "number" ||
      parseInt(value) > max ||
      parseInt(value) < min
    ) {
      error = `Please try from ${min} to ${max}`;
    }
    return error;
  };
};

const getImperialHeight = (type, height) => {
  if (!height) return "";
  const arr = height.split(" ");
  if (type === "ft") {
    return arr[0];
  }
  return arr[2];
};

const toImperialHeight = (type, height, data) => {
  if (!height) height = " ft  inch";
  const arr = height.split(" ");
  if (type === "ft") {
    arr[0] = data;
  } else {
    arr[2] = data;
  }
  return arr.join(" ");
};

export const StepAge = ({ age, onSubmit }) => {
  const [value, setValue] = useState(age || "");
  const [error, setError] = useState(null);

  const rules = {
    max: 99,
    min: 18,
    helper: "age",
  };

  const handleChange = ({ target }) => {
    const validation = validate(rules.max, rules.min);
    const result = transformToNumber(target.value);
    setError(validation(result));
    setValue(result);
  };

  return (
    <div className={classes.wrap}>
      <HeadIcon type="age">
        {age ? (
          <>
            Did we get your <strong>age</strong> right?
          </>
        ) : (
          <>
            What's your <strong>age</strong>?
          </>
        )}
      </HeadIcon>
      <Input
        className={classes.input}
        value={value}
        type="text"
        helper={rules.helper}
        onChange={handleChange}
        error={error}
      />
      <Button
        disabled={Boolean(error) || !Boolean(value)}
        className={classes.btn}
        onClick={() =>
          onSubmit(value, "age", {
            name: "age_greeting_modal",
            data: {
              current: age,
              confirmed: value,
            },
          })
        }
      >
        confirm
      </Button>
    </div>
  );
};

export const StepHeight = ({ height, measurement, onSubmit }) => {
  const [value, setValue] = useState(height || "");
  const [error, setError] = useState(null);

  const rules = {
    imperial: {
      ft: {
        max: 8,
        min: 4,
        helper: "ft",
      },
      inch: {
        max: 11,
        min: 0,
        helper: "inch",
      },
    },
    metric: {
      max: 256,
      min: 135,
      helper: "cm",
    },
  };

  const handleChangeMetric = ({ target }) => {
    const validation = validate(rules.metric.max, rules.metric.min);
    const result = transformToNumber(target.value);
    setError(validation(result));
    setValue(result);
  };

  const handleChangeImperial = ({ target }) => {
    const validation = validate(
      rules.imperial[target.name].max,
      rules.imperial[target.name].min
    );
    const result = transformToNumber(target.value);
    setError((prev) => {
      return { ...prev, [target.name]: validation(result) };
    });
    setError((prev) => {
      if (!prev?.ft && !prev?.inch) {
        return null;
      }
      return { ...prev };
    });
    setValue(toImperialHeight(target.name, value, result));
  };

  const validValue =
    measurement === "imperial"
      ? value.split(" ").filter((item) => item).length < 4
      : !value;

  return (
    <div className={classes.wrap}>
      <HeadIcon type="height">
        {height ? (
          <>
            Did we get your <strong>height</strong> right?
          </>
        ) : (
          <>
            What's your <strong>height</strong>?
          </>
        )}
      </HeadIcon>
      {measurement === "metric" ? (
        <Input
          className={classes.input}
          value={value}
          type="text"
          helper={rules.metric.helper}
          onChange={handleChangeMetric}
          error={error}
        />
      ) : (
        <div className={classes.inputWrap}>
          <Input
            className={classes.input}
            value={getImperialHeight("ft", value)}
            type="text"
            name="ft"
            helper={rules.imperial.ft.helper}
            onChange={handleChangeImperial}
            error={error?.ft}
          />
          <Input
            className={classes.input}
            value={getImperialHeight("inch", value)}
            type="text"
            name="inch"
            helper={rules.imperial.inch.helper}
            onChange={handleChangeImperial}
            error={error?.inch}
          />
        </div>
      )}
      <Button
        disabled={Boolean(error) || !Boolean(value) || validValue}
        className={classes.btn}
        onClick={() =>
          onSubmit(value, "height", {
            name: "height_greeting_modal",
            data: {
              current: height,
              confirmed: value,
              units: measurement,
            },
          })
        }
      >
        confirm
      </Button>
    </div>
  );
};

export const StepWeight = ({
  weight,
  measurement,
  onSubmit,
  title,
  name,
  eventName,
}) => {
  const [value, setValue] = useState(weight || "");
  const [error, setError] = useState(null);

  const rules = {
    imperial: {
      max: 400,
      min: 90,
      helper: "lbs",
    },
    metric: {
      max: 180,
      min: 40,
      helper: "kg",
    },
  };

  const handleChange = ({ target }) => {
    const validation = validate(rules[measurement].max, rules[measurement].min);
    const result = transformToNumber(target.value);
    setError(validation(result));
    setValue(result);
  };

  return (
    <div className={classes.wrap}>
      <HeadIcon type="weight">{title}</HeadIcon>
      <Input
        className={classes.input}
        value={value}
        type="text"
        helper={rules[measurement].helper}
        onChange={handleChange}
        error={error}
      />
      <Button
        disabled={Boolean(error) || !Boolean(value)}
        className={classes.btn}
        onClick={() =>
          onSubmit(value, name, {
            name: eventName,
            data: {
              current: weight,
              confirmed: value,
              units: measurement,
            },
          })
        }
      >
        confirm
      </Button>
    </div>
  );
};

const idToNames = (ids, names) => {
  const result = [];
  ids.forEach((id) => {
    const element = names.find((item) => item.id === id);
    if (element && element !== -1) {
      result.push(element.title);
    }
  });
  return result.sort().join(", ");
};

export const StepCheckboxes = ({
  config,
  onSubmit,
  title,
  name,
  eventName,
}) => {
  const [goals, setGoals] = useState([]);

  const handleChange = (id) => {
    const idx = goals.findIndex((item) => item === id);
    if (idx === -1) {
      setGoals([...goals, id]);
    } else {
      const newArr = [...goals];
      newArr.splice(idx, 1);
      setGoals([...newArr]);
    }
  };

  return (
    <div className={classes.wrap}>
      <HeadIcon type={name}>{title}</HeadIcon>
      <div className={classes.checkboxes}>
        {config.map((item) => {
          return (
            <Checkbox
              name={name}
              key={item.id}
              onChange={() => handleChange(item.id)}
              title={item.title}
            />
          );
        })}
      </div>
      <Button
        disabled={!Boolean(goals.length)}
        className={classes.btn}
        onClick={() =>
          onSubmit(goals, name, {
            name: eventName,
            data: {
              confirmed: idToNames(goals, config),
            },
          })
        }
      >
        confirm
      </Button>
    </div>
  );
};
