import { getBankSiteName, getConfig } from "./utils";

/**
 * devClient - variable for testing different themes.
 * For regular domains you need to take one element of the array domains.
 * For banksites, you can add any name and prefix "site."
 */
const devClient = false;

const domains = ["mealplan60fit", "delifitplan", "funappetit"];

const config = {
  hadew: {
    type: "hadew",
    title: "Hadew",
    name: "hadew",
    copyright: "Hadew.com",
  },
  fitculator: {
    type: "fitculator",
    title: "Fitculator",
    name: "fitculator",
    copyright: "Fitculator.com",
  },
  mealplan60fit: {
    type: "fitculator",
    title: "Mealplan60fit",
    name: "mealplan60fit",
    copyright: "Mealplan60fit.com",
  },
  delifitplan: {
    type: "fitculator",
    title: "DeliFitPlan",
    name: "delifitplan",
    copyright: "DeliFitPlan.com",
  },
  funappetit: {
    type: "fitculator",
    title: "FunAppetit",
    name: "funappetit",
    copyright: "FunAppetit.fit",
  },
  banksites: {
    type: "hadew",
    title: getBankSiteName(devClient),
    name: getBankSiteName(devClient).toLowerCase(),
    copyright: getBankSiteName(devClient) + ".com",
    isBankSite: true,
  },
};

export default getConfig("hadew", config, domains, devClient);
