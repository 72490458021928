import React from "react";
import clsx from "clsx";

import classes from "./Input.module.sass";

const Input = ({ helper, error, className, ...props }) => {
  return (
    <div
      className={clsx(classes.wrap, {
        [className]: className,
      })}
    >
      <input
        className={clsx(classes.input, {
          [classes["error"]]: error,
        })}
        {...props}
      />
      {helper && <span className={classes.helper}>{helper}</span>}
      {error && <span className={classes.errorHelper}>{error}</span>}
    </div>
  );
};

export default Input;
