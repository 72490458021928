import React from 'react';
import clsx from 'clsx';

import Radio from '../Radio';

import './RadioList.sass';

const RadioList = ({
  className,
  config,
  onChange
}) => {
  return (
    <div className={clsx("radio-list", {
      [className]: className
    })}>
      {config.options.map(option => {
        return (
          <Radio
            key={option.value}
            value={option.value}
            name={config.name}
            label={option.label}
            valueIsChecked={config.defaultOption}
            onChange={() => {
              onChange(option.value, option.id);
            }}
            checked={option.checked}
          />
        )
      })}
    </div>
  );
};

export default RadioList;
