import React from 'react';

import './loader.sass';
import LoaderIcon from '../Icons/LoaderIcon';

const Loader = ({
  type,
  size,
  color
}) => {

  return (
    <LoaderIcon size={size} color={color} />
  );
};

export default Loader;
