import React, { useState, useEffect } from "react";
import amplitude from "amplitude-js";
import dayjs from "dayjs";
import clsx from "clsx";

import { Swiper, SwiperSlide } from "swiper/react";

import IconSprite from "../IconSprite";

import "./WeekPicker.sass";
import "swiper/swiper.scss";

let weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

const createDayObj = (date, idx, mealIndex, isActive = false) => {
  const resultObj = {
    mealIndex,
    dayNumber: dayjs(date).subtract(idx, "day").format("DD"),
    dayName: dayjs(date).subtract(idx, "day").format("ddd"),
    date:
      dayjs(date).subtract(idx, "day").format("YYYY") +
      "-" +
      dayjs(date).subtract(idx, "day").format("MM") +
      "-" +
      dayjs(date).subtract(idx, "day").format("DD"),
  };
  if (isActive) {
    resultObj.isActive = true;
  }

  return resultObj;
};

const calculateFirstWeekDay = (dateStart) => {
  if (dayjs(dateStart).weekday() === 0) {
    return dayjs(dateStart).weekday(-6).format("YYYY-MM-DD");
  }
  return dayjs(dateStart).weekday(1).format("YYYY-MM-DD");
};

const calculateDaysCountToStartDate = (startDate, currentDate) => {
  return dayjs(currentDate).diff(startDate, "day");
};

const calculateLastMealIndex = (daysCountToStart, planDaysCount) => {
  return (daysCountToStart + 1) % planDaysCount;
};

const divideGroups = (list) => {
  const array = [...list];
  const groupsCount = array.length / 5;
  const result = [];
  let start = 0;
  let limit = 5;
  for (let i = 0; i < groupsCount; i++) {
    const arr = array.slice(start, limit);
    result.push([...arr]);
    start += 5;
    limit += 5;
  }

  return result;
};

const calculateDaysList = (
  firstWeekDaysCountToStart,
  planDaysCount,
  currentDate,
  daysCountToStart
) => {
  let lastMealIndex = calculateLastMealIndex(
    firstWeekDaysCountToStart,
    planDaysCount,
    currentDate
  );
  const resultArrayDays = [];
  let mealIndex = lastMealIndex;
  let countFromStart = 2;
  if (daysCountToStart < 2) {
    countFromStart = daysCountToStart;
  }
  mealIndex -= countFromStart;
  if (mealIndex <= 0) {
    mealIndex += planDaysCount;
  }
  for (let i = countFromStart; i >= -(59 - countFromStart); i--) {
    if (i === 0) {
      resultArrayDays.push(createDayObj(currentDate, i, mealIndex, true));
    } else {
      resultArrayDays.push(createDayObj(currentDate, i, mealIndex));
    }
    mealIndex++;
    if (mealIndex > planDaysCount) {
      mealIndex = 1;
    }
  }
  return divideGroups(resultArrayDays);
};

const calculateNewActiveDay = (listDay, date) => {
  let firstDay = false;
  let lastDay = false;
  let isCurrent = false;
  const arr = listDay.map((row, rowIndex) => {
    return row.map((day, dayIndex) => {
      if (day.isActive) {
        const newDay = { ...day };
        newDay.isActive = false;
        return newDay;
      }
      if (day.date === date) {
        const newDay = { ...day };
        if (rowIndex === 0 && dayIndex === 2) {
          isCurrent = true;
        }
        newDay.isActive = true;
        return newDay;
      }
      return day;
    });
  });

  return {
    firstDay: firstDay,
    lastDay: lastDay,
    resultArr: [...arr],
    isCurrent: isCurrent,
  };
};

const WeekPicker = ({
  dateStartInput = "2021-02-15T16:44:23+03:00",
  recipesCount = 8,
  setActiveMeal,
  activeMeal,
  setIsCurrentDay,
  activeId,
}) => {
  const [swiper, setSwiper] = useState(null);
  const [dateStart, setDateStart] = useState(
    dayjs(dateStartInput).format("YYYY-MM-DD")
  );

  const [dateCurrent] = useState(dayjs().format("YYYY-MM-DD"));
  const [firstWeekFirstDate, setFirstWeekFirstDate] = useState(
    calculateFirstWeekDay(dateStart, dateCurrent)
  );
  const [firstWeekDaysCountToStart, setFirstWeekDaysCountToStart] = useState(
    calculateDaysCountToStartDate(firstWeekFirstDate, dateCurrent)
  );
  const [daysCountToStart, setDaysCountToStart] = useState(
    calculateDaysCountToStartDate(dateStart, dateCurrent)
  );
  const [daysArray, setDaysArray] = useState(
    calculateDaysList(
      firstWeekDaysCountToStart,
      recipesCount,
      dateCurrent,
      daysCountToStart
    )
  );

  const handleNextSlide = () => {
    swiper.slideNext();
  };

  const handlePrevSlide = () => {
    swiper.slidePrev();
  };

  const handleDayClick = (date, mealIndex, isActive) => {
    if (isActive) return false;

    const result = calculateNewActiveDay(daysArray, date);
    setDaysArray(result.resultArr);
    setActiveMeal(mealIndex);
    setIsCurrentDay(result.isCurrent);
    amplitude.getInstance().logEvent("meal_plan_open_day");
  };

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0);

      const newDateStart = dayjs(dateStartInput).format("YYYY-MM-DD");
      const newFirstWeekFirstDate = calculateFirstWeekDay(
        newDateStart,
        dateCurrent
      );
      const newFirstWeekDaysCountToStart = calculateDaysCountToStartDate(
        newFirstWeekFirstDate,
        dateCurrent
      );
      const newDaysCountToStart = calculateDaysCountToStartDate(
        newDateStart,
        dateCurrent
      );
      const newDaysArray = calculateDaysList(
        newFirstWeekDaysCountToStart,
        recipesCount,
        dateCurrent,
        newDaysCountToStart
      );
      setDateStart(newDateStart);
      setFirstWeekFirstDate(newFirstWeekFirstDate);
      setDaysCountToStart(newDaysCountToStart);
      setFirstWeekDaysCountToStart(newFirstWeekDaysCountToStart);
      setDaysArray([...newDaysArray]);
      const activeDayIndex = newDaysArray[0].findIndex((day) => day.isActive);
      setActiveMeal(newDaysArray[0][activeDayIndex].mealIndex);
      amplitude.getInstance().logEvent("meal_plan_open_day");
    }
  }, [
    dateStartInput,
    swiper,
    setDateStart,
    dateStart,
    dateCurrent,
    setFirstWeekFirstDate,
    setDaysCountToStart,
    setFirstWeekDaysCountToStart,
    firstWeekFirstDate,
    setDaysArray,
    daysCountToStart,
    recipesCount,
    setActiveMeal,
    activeId,
  ]);

  return (
    <div className="week-calendar-wrap">
      <div
        className="calendar-arrow-nav calendar-arrow-nav--left"
        onClick={handlePrevSlide}
      >
        <IconSprite className="icon-arrow" name="arrow-left" />
      </div>
      <div
        className="calendar-arrow-nav calendar-arrow-nav--right"
        onClick={handleNextSlide}
      >
        <IconSprite className="icon-arrow" name="arrow-right" />
      </div>
      <Swiper
        onSwiper={(swiper) => setSwiper(swiper)}
        spaceBetween={4}
        slidesPerView={1}
      >
        {daysArray.map((row, idx) => {
          return (
            <SwiperSlide key={idx}>
              <div className="week-calendar-row">
                {row.map(
                  ({ dayName, dayNumber, date, isActive, mealIndex }) => {
                    return (
                      <div
                        className={clsx("week-calendar-col", {
                          active: isActive,
                        })}
                        key={date}
                        onClick={() =>
                          handleDayClick(date, mealIndex, isActive)
                        }
                      >
                        <div className="week-calendar-day-name">
                          {date !== dateCurrent ? dayName : "Today"}
                        </div>
                        <div className="week-calendar-day-num">{dayNumber}</div>
                      </div>
                    );
                  }
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default WeekPicker;
