import React, { useState, useEffect } from "react";

import HeadIcon from "../HeadIcon";
import Title from "../Title";
import Button from "../Button";
import Radio from "../Radio";
import Input from "../Input";

import classes from "./ModalCancelReason.module.sass";

const config = [
  { id: 1, value: "I'm using another service" },
  { id: 2, value: "I couldn’t find workouts/recipes that I liked" },
  { id: 3, value: "I don’t use it enough" },
  { id: 4, value: "I am having technical problems " },
  { id: 5, value: "Other reason", showInput: true },
];

const ModalCancelReason = ({ onSend }) => {
  const [showInput, setShowInput] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [selectedReason, setSelectedReason] = useState(null);

  const handleChangeRadio = (id, value, showInput) => {
    if (showInput) {
      setShowInput(true);
      setSelectedReason("");
    } else {
      setShowInput(false);
      setSelectedReason(value);
    }
  };

  useEffect(() => {
    if (selectedReason) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [selectedReason]);

  const handleChangeInput = ({ target }) => {
    setSelectedReason(target.value);
  };

  const handleSend = () => {
    onSend(selectedReason);
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.body}>
        <HeadIcon name="reason" />
        <Title>Why are you leaving us?</Title>
        <div className={classes.form}>
          <div className={classes.radioList}>
            {config.map((item) => {
              return (
                <Radio
                  name="cancel_reason"
                  key={item.id}
                  onChange={() =>
                    handleChangeRadio(item.id, item.value, item.showInput)
                  }
                >
                  {item.value}
                </Radio>
              );
            })}
          </div>
          {showInput && (
            <Input
              placeholder="Your reason"
              value={selectedReason}
              onChange={handleChangeInput}
            />
          )}
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          disabled={disabledButton}
          className={classes.btn}
          onClick={handleSend}
        >
          Continue with cancellation
        </Button>
      </div>
    </div>
  );
};

export default ModalCancelReason;
