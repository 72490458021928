import {
  ADD_IS_LOADED,
  ADD_AUTHOR,
  ADD_ERROR,
  CLEAR_AUTHOR_DATA
} from '../constants/authorTypes';

export const setIsLoaded = () => {
  return {
    type: ADD_IS_LOADED
  };
};

export const setAuthorData = (data) => {
  const firstName = data.firstName ? data.firstName : '';
  const lastName = data.lastName ? data.lastName : '';

  return {
    type: ADD_AUTHOR,
    name: `${firstName} ${lastName}`,
    avatar: data.avatar?.url ? `${process.env.REACT_APP_API_DOMAIN}${data.avatar.url}` : null,
    description: data.description,
    socials: [...data.links]
  };
};

export const clearAuthor = () => {
  return {
    type: CLEAR_AUTHOR_DATA
  };
};

export const addError = (error) => {
  return {
    type: ADD_ERROR,
    error
  };
};