import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import clsx from 'clsx';
import ReactTimer from 'react-compound-timer';

import './Timer.sass';

const Timer = ({
  time = 12,
  className,
  finalWord = '0',
  startWord,
  onTimerEnd,
  onTimerBeforeEnd,
  startImmediately,
  audioPlay,
  onSkipTimer,
  onTimerBeforeStart
}, ref) => {
  const timerRef = useRef(null);
  const timerInnerRef = useRef(null);

  const [localTime, setLocalTime] = useState(time);
  const [isFinish, setIsFinish] = useState(false);

  const RADIUS = 56;
  const CIRCUMFERENCE = (2 * Math.PI * RADIUS);

  const handleAddTime = () => {
    if (timerRef.current.getTime() > 0) {
      const actualTime = Math.ceil(timerRef.current.getTime() / 1000) - 1;
      const resultTime = (localTime + 10) - (localTime - actualTime);
      setLocalTime(localTime + 10);
      setIsFinish(false);
      timerRef.current.setTime(resultTime * 1000);
      if (!isFinish) {
        timerInnerRef.current.classList.add('is-active');
      }
      timerInnerRef.current.classList.remove('final-backlight');
      setTimeout(() => {
        timerInnerRef.current.classList.remove('is-active');
      }, 300);
    }
  };

  useImperativeHandle(ref, () => ({
    onStart: () => {
      timerRef.current.start();
    },
    onReset: () => {
      timerRef.current.reset();
      setIsFinish(false);
    },
    onStop: () => {
      timerRef.current.stop();
    },
    onPause: () => {
      timerRef.current.pause();
    },
    onResume: () => {
      timerRef.current.resume();
    },
    onSetTime: (time) => {
      timerRef.current.setTime((time + 1) * 1000);
    },
    onUpdateTime: () => {
      handleAddTime();
    }
  }));

  return (
    <ReactTimer
      initialTime={(time + 1) * 1000}
      direction="backward"
      ref={timerRef}
      startImmediately={startImmediately}
      checkpoints={[{
        time: 0, callback: () => {
          if (onTimerEnd) {
            onTimerEnd(true);
          }
        },
      }, {
        time: 1000, callback: () => {
          if (onTimerBeforeEnd) {
            onTimerBeforeEnd();
          }
        },
      }, {
        time: 4000, callback: () => {
          setIsFinish(true);
        }
      }, {
        time: 8000, callback: () => {
          timerInnerRef.current.classList.add('final-backlight');
          if (audioPlay) {
            audioPlay();
          }
        }
      }, {
        time: (time * 1000), callback: () => {
          if (onTimerBeforeStart) {
            onTimerBeforeStart();
          }
        }
      }]}

    >
      {({ getTime, setTime }) => {
        let realTimeStep = Math.abs(Math.ceil(getTime() / 1000));
        let timeStep = realTimeStep - 1;
        let progress = ((localTime - timeStep) * 100) / (localTime - 1);
        let offset = CIRCUMFERENCE * (1 - ((progress >= 100 ? 100 : progress) / 100));

        return (
          <div className={clsx("timer", {
            [className]: className
          })}
            ref={timerInnerRef}
          >
            <svg className="timer__icon" viewBox="0 0 120 120">
              <circle className="timer__meter" cx="60" cy="60" r={RADIUS} strokeWidth="8" />
              <circle
                className="timer__value"
                cx="60"
                cy="60"
                r={RADIUS}
                strokeWidth="8"
                strokeDasharray={CIRCUMFERENCE}
                strokeDashoffset={offset}
              />
            </svg>
            <div className={clsx("timer__nums", {
              "is-finish": isFinish,
              "is-start": timeStep === time
            })}
              onClick={() => {
                setTime(1000);
                timerInnerRef.current.classList.add('final-backlight');
                if (onSkipTimer) {
                  onSkipTimer();
                }
              }}
            >
              {timeStep < 1 ?
                finalWord : timeStep === time && startWord ?
                  startWord : timeStep}
            </div>
          </div>
        )
      }}
    </ReactTimer>
  )
};

export default forwardRef(Timer);
