import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import amplitude from "amplitude-js";
import IconSprite from "../IconSprite";
import Loader from "../Loader";

import "./LogCounter.sass";

const sendGA = (event) => {
  if (window.gtag) {
    window.gtag("event", event);
  }
};

const LogCounter = ({
  className,
  value,
  measurement,
  isSending,
  onSubmit,
  isLogged,
}) => {
  const [isFloatWeight, setIsFloatWeight] = useState(true);
  const [isMaxValue, setIsMaxValue] = useState(false);
  const [isMinValue, setIsMinValue] = useState(false);
  const [logValue, setLogValue] = useState(
    value ? parseFloat(value).toFixed(1) : "0.0"
  );

  const points = {
    from: 40,
    to: 180,
  };

  if (measurement === "imperial") {
    points.from = 90;
    points.to = 400;
  }

  const handleTypeInputChange = (isFloat) => {
    setIsFloatWeight(isFloat);
  };

  const handlePlus = () => {
    if (isFloatWeight) {
      setLogValue((parseFloat(logValue) + 0.1).toFixed(1));
    } else {
      if (parseFloat(logValue) + 1 <= points.to)
        setLogValue((parseFloat(logValue) + 1).toFixed(1));
    }
  };

  const handleMinus = () => {
    if (isFloatWeight) {
      setLogValue((parseFloat(logValue) - 0.1).toFixed(1));
    } else {
      if (parseFloat(logValue) - 1 >= points.from)
        setLogValue((parseFloat(logValue) - 1).toFixed(1));
    }
  };

  useEffect(() => {
    if (logValue >= points.to) {
      setIsMaxValue(true);
    } else {
      setIsMaxValue(false);
    }

    if (logValue <= points.from) {
      setIsMinValue(true);
    } else {
      setIsMinValue(false);
    }
  }, [logValue, points]);

  if (isLogged) {
    return (
      <div className="weight-logged">
        <div className="weight-logged__inner">
          <div className="weight-logged__title">
            <IconSprite name="check-circle" className="check-circle-icon" />
            <span>Weight logged</span>
          </div>
          <NavLink to="/log-weight" className="weight-logged__link">
            View weight history
          </NavLink>
        </div>
      </div>
    );
  }

  const logResult = logValue.split(".");
  return (
    <div
      className={clsx("log-counter", {
        [className]: className,
      })}
    >
      {isSending && (
        <div className="log-counter__loader">
          <Loader />
        </div>
      )}
      <div className="log-counter__input">
        <button
          className="log-counter__input-btn"
          onClick={handleMinus}
          disabled={isMinValue}
        />
        <div className="log-counter__field">
          <div
            className={clsx("log-counter__field-number", {
              active: !isFloatWeight,
            })}
            onClick={() => handleTypeInputChange(false)}
          >
            {logResult[0]}
          </div>
          <div className="log-counter__field-dot">.</div>
          <div
            className={clsx("log-counter__field-number", {
              active: isFloatWeight,
            })}
            onClick={() => handleTypeInputChange(true)}
          >
            {logResult[1]}
          </div>
        </div>
        <button
          className="log-counter__input-btn plus"
          onClick={handlePlus}
          disabled={isMaxValue}
        />
      </div>
      <button
        className="log-counter__btn"
        onClick={() => {
          onSubmit(parseFloat(logValue));
          sendGA("log weight");
          amplitude.getInstance().logEvent("log_weight");
        }}
      >
        Log
      </button>
    </div>
  );
};

export default LogCounter;
