import React from 'react';

import './MainNavLink.sass';
import { NavLink } from 'react-router-dom';
import IconSprite from '../IconSprite';

const MainNavLink = ({
  linkTo,
  icon,
  label,
  handleClick,
  onClose
}) => {

  if (handleClick && !linkTo) {
    return (
      <div onClick={() => {
        if (onClose) {
          onClose();
        }
        handleClick();
      }} className="main-nav-link">
        <IconSprite className={`icon-${icon}`} name={icon} />
        <span>{label}</span>
      </div>
    );
  }

  return (
    <NavLink onClick={onClose} to={linkTo} className="main-nav-link" exact>
      <IconSprite className={`icon-${icon}`} name={icon} />
      <span>{label}</span>
    </NavLink>
  );
};

export default MainNavLink;