import React, { useState, useEffect, useCallback } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import dayjs from "dayjs";
import Title from "../../../../common/Title";
import Helper from "../../../../common/Helper";
import IconSprite from "../../../../common/IconSprite";
import MealCard from "../../../../common/MealCard";
import MealCardSwipeable from "../MealCardSwipeable";

import "swiper/swiper.scss";

import "./dailyList.sass";

const DailyList = ({
  meals,
  activeMeal,
  handleModalRecipeOpen,
  handleOpenModalList,
  handleMealDone,
  userEmail,
  handleHelperClose,
  isCurrentDay,
  statuses,
  handleMealUnDo,
  withChange,
  isPurchased,
  isDefault,
}) => {
  const checkIsMobile = useCallback((windowWidth) => {
    if (windowWidth < 576) {
      return true;
    } else {
      return false;
    }
  }, []);

  const mealPlanDoneModel = JSON.parse(localStorage.getItem("mealPlan"));
  let mealsDone = null;
  let withHelper = true;

  if (
    mealPlanDoneModel &&
    mealPlanDoneModel[userEmail] &&
    mealPlanDoneModel[userEmail].date ===
      dayjs().startOf("day").format("YYYY-MM-DD")
  ) {
    mealsDone = mealPlanDoneModel[userEmail].list;
  }

  if (mealPlanDoneModel && mealPlanDoneModel[userEmail]) {
    withHelper = mealPlanDoneModel[userEmail].withHelper;
  }

  const [isMobile, setIsMobile] = useState(checkIsMobile(window.innerWidth));
  const [isVisibleHelper, setIsVisibleHelper] = useState(withHelper);

  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setIsMobile(checkIsMobile(event.target.innerWidth));
    });
  }, [setIsMobile, checkIsMobile]);

  if (!meals) {
    return null;
  }

  const mealsTypes = [
    { id: 1, type: "breakfast", name: "Breakfast" },
    { id: 2, type: "morning_snack", name: "Morning snack" },
    { id: 3, type: "lunch", name: "Lunch" },
    { id: 4, type: "afternoon_snack", name: "Afternoon snack" },
    { id: 5, type: "dinner", name: "Dinner" },
    { id: 6, type: "evening_snack", name: "Evening snack" },
  ];

  const mealCards = mealsTypes
    .map((type) => {
      const meal = meals[type.type];
      if (meal && Object.keys(meal).length) {
        return (
          <MealCard
            key={meal.id + "-" + type.name.toLowerCase()}
            handleOpenPreview={() =>
              handleModalRecipeOpen(
                meal,
                type.id,
                type.type,
                meal.id,
                activeMeal
              )
            }
            image={meal.image.length ? meal.image[0].url : null}
            tagName={type.name}
            title={meal.title}
            level={meal.level}
            cooking_time={meal.cooking_time}
            serving={meal.meal_serving}
            handleReplace={() =>
              handleOpenModalList(type.id, activeMeal, type.type, meal.id)
            }
            typeId={type.id}
            type={type.type}
            id={meal.id}
            statuses={statuses}
            withChange={withChange}
          />
        );
      }
      return null;
    })
    .filter((el) => !!el === true);

  const mealCardsSwipeable = mealsTypes
    .map((type) => {
      const meal = meals[type.type];
      let isDone = false;
      if (mealsDone && mealsDone[type.type] === type.type) {
        isDone = true;
      }

      if (!withChange) {
        return (
          <MealCard
            key={meal.id + "-" + type.name.toLowerCase()}
            handleOpenPreview={() =>
              handleModalRecipeOpen(
                meal,
                type.id,
                type.type,
                meal.id,
                activeMeal
              )
            }
            image={meal.image.length ? meal.image[0].url : null}
            tagName={type.name}
            title={meal.title}
            level={meal.level}
            cooking_time={meal.cooking_time}
            serving={meal.meal_serving}
            handleReplace={() =>
              handleOpenModalList(type.id, activeMeal, type.type, meal.id)
            }
            typeId={type.id}
            type={type.type}
            id={meal.id}
            statuses={statuses}
            withChange={withChange}
          />
        );
      }

      if (meal && Object.keys(meal).length) {
        return (
          <MealCardSwipeable
            key={meal.id + "-" + type.name.toLowerCase()}
            onDone={() => handleMealDone(type.type)}
            onUndo={() => handleMealUnDo(type.type)}
            done={isDone}
          >
            <MealCard
              handleOpenPreview={() =>
                handleModalRecipeOpen(
                  meal,
                  type.id,
                  type.type,
                  meal.id,
                  activeMeal
                )
              }
              image={meal.image.length ? meal.image[0].url : null}
              tagName={type.name}
              title={meal.title}
              level={meal.level}
              cooking_time={meal.cooking_time}
              serving={meal.meal_serving}
              handleReplace={() =>
                handleOpenModalList(type.id, activeMeal, type.type, meal.id)
              }
              typeId={type.id}
              type={type.type}
              id={meal.id}
              statuses={statuses}
              withChange={withChange}
            />
          </MealCardSwipeable>
        );
      }
      return null;
    })
    .filter((el) => !!el === true);

  return (
    <div className="daily-list-section">
      <div className="daily-list-head">
        <Title size="medium">{isPurchased ? "Daily meals" : "Preview"}</Title>
        {isMobile && isDefault && isVisibleHelper && isCurrentDay && (
          <Helper
            className="daily-list-helper"
            message="Swipe left to log a meal"
            onClose={() => {
              setIsVisibleHelper(false);
              handleHelperClose();
            }}
          />
        )}
        <div className="daily-list-nav">
          <div className="daily-list-arrow daily-list-arrow--left">
            <IconSprite className="arrow-left" name="arrow-left" />
          </div>
          <div className="daily-list-arrow daily-list-arrow--right">
            <IconSprite className="arrow-right" name="arrow-right" />
          </div>
        </div>
      </div>
      {isMobile ? (
        <>
          {isCurrentDay ? (
            <div className="daily-list">{mealCardsSwipeable}</div>
          ) : (
            <div className="daily-list">{mealCards}</div>
          )}
        </>
      ) : (
        <div className="daily-list-carousel">
          <Swiper
            spaceBetween={50}
            slidesPerView={3}
            breakpoints={{
              320: {
                spaceBetween: 20,
                slidesPerView: 1,
              },
              576: {
                spaceBetween: 20,
                slidesPerView: 2,
              },
              890: {
                slidesPerView: 3,
              },
              1200: {
                spaceBetween: 30,
              },
              1300: {
                spaceBetween: 60,
              },
            }}
            navigation={{
              nextEl: ".daily-list-arrow--right",
              prevEl: ".daily-list-arrow--left",
            }}
          >
            {mealCards.map((item, index) => (
              <SwiperSlide className="daily-list-item" key={index}>
                {item}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default DailyList;
