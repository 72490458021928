import produce from "immer";
import {
  ADD_IS_LOADED,
  ADD_WORKOUT_CATEGORIES,
  ADD_ERROR,
  ADD_WORKOUT_FAVORITES,
  ADD_WORKOUT_FAVORITES_IS_FETCHING,
  ADD_FAVORITE,
  ADD_FAVORITE_ITEM,
  ADD_FAVORITES_COUNT,
  ADD_FAVORITES_COUNT_REMOVE,
} from "../constants/workoutsTypes";

const initialState = {
  isLoaded: false,
  error: null,
  workouts: [],
  lifestyle: [],
  favorites: [],
  favoritesCount: 0,
  favoritesIsLoaded: false,
  favoritesIsFetching: false,
  countToRemoveFavorites: 0,
};

const workouts = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FAVORITES_COUNT_REMOVE: {
      return {
        ...state,
        countToRemoveFavorites: action.payload,
      };
    }
    case ADD_FAVORITES_COUNT: {
      return {
        ...state,
        favoritesCount: action.payload,
      };
    }
    case ADD_FAVORITE_ITEM: {
      let newFavoritesCount = state.favoritesCount;
      const newFavoritesList = produce(state.favorites, (draftState) => {
        if (action.payload.addFavorite) {
          draftState.unshift(action.payload.item);
          newFavoritesCount++;
        } else {
          const idx = draftState.findIndex(
            (item) => item.id === action.payload.id
          );
          draftState.splice(idx, 1);
          newFavoritesCount--;
        }
      });
      return {
        ...state,
        favorites: newFavoritesList,
        favoritesCount: newFavoritesCount,
      };
    }
    case ADD_FAVORITE: {
      const newWorkoutsList = produce(
        state[action.payload.groupToChange],
        (draftState) => {
          const idx = draftState.findIndex(
            (item) => item.name === action.payload.category
          );
          draftState[idx].list[action.payload.activeWorkoutIdx].isFavourite =
            draftState[idx].list[action.payload.activeWorkoutIdx].isFavourite
              ? false
              : true;
        }
      );
      return {
        ...state,
        [action.payload.groupToChange]: newWorkoutsList,
      };
    }
    case ADD_WORKOUT_FAVORITES_IS_FETCHING: {
      return {
        ...state,
        favoritesIsFetching: action.payload,
      };
    }
    case ADD_WORKOUT_FAVORITES: {
      const newWorkoutsList = action.payload.list.map(
        ({ workout, ...props }) => {
          return { ...workout, isFavourite: true };
        }
      );

      return {
        ...state,
        favoritesIsLoaded: true,
        favorites: [...state.favorites, ...newWorkoutsList],
        favoritesCount: action.payload.count,
      };
    }
    case ADD_IS_LOADED: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case ADD_WORKOUT_CATEGORIES: {
      return {
        ...state,
        workouts: action.workouts,
        lifestyle: action.lifestyle,
      };
    }
    case ADD_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default workouts;
