import React from "react";

import Title from "../Title";

import classes from "./Heads.module.sass";

export const Head = ({ children }) => {
  return (
    <div className={classes.wrap}>
      <Title>{children}</Title>
    </div>
  );
};
