import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import themeConfig from "../configs/theme";
import { authAPI } from "../api";

import { setFetchingLoader } from "../actions/auth";

import LoginForgot from "../components/pages/LoginForgot";

const LoginForgotPage = ({ isFetching, sendPassword }) => {
  const [isSend, setIsSend] = useState(false);

  const handleSubmitForm = (values) => {
    return sendPassword(values.email).then((res) => {
      if (res) {
        if (res.response && res.response.status !== 200) {
          if (res.response.status === 404) {
            throw new SubmissionError({
              email: "User not found",
            });
          } else if (res.response.data && res.response.data.message) {
            throw new SubmissionError({
              email: res.response.data.message,
            });
          } else {
            throw new SubmissionError({
              email: "Ops... Unknown error",
            });
          }
        } else {
          setIsSend(true);
        }
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password - {themeConfig.title}</title>
      </Helmet>
      <LoginForgot
        handleSubmitForm={handleSubmitForm}
        isFetching={isFetching}
        isSend={isSend}
      />
    </>
  );
};

export default connect(
  (state) => ({
    isFetching: state.auth.isFetching,
  }),
  (dispatch) => ({
    sendPassword: (email) => {
      dispatch(setFetchingLoader(true));
      return authAPI
        .forgotPassword(email)
        .then((res) => {
          dispatch(setFetchingLoader(false));
          return res;
        })
        .catch((err) => {
          dispatch(setFetchingLoader(false));
          return err;
        });
    },
  })
)(LoginForgotPage);
