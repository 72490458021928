import React from 'react';

const CaloriesResult = ({
  calories,
  min,
  max
}) => {
  return (
    <div className="finish-card-calories">
      <div className="finish-card-calories__body">
        <div className="finish-card-value">
          <span>{calories}</span> Cal
        </div>
      </div>
    </div>
  );
};

export default CaloriesResult;