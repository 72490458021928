import React from "react";

import Button from "../../common/Button";
import themeConfig from "../../../configs/theme";
import { Field, reduxForm } from "redux-form";
import {
  required,
  matchPasswords,
  minLength,
  checkLatinSymbols,
  checkHasLetters,
} from "../../../common/helpers/validators";
import InputAuth from "../../common/InputAuth";
import IconSprite from "../../common/IconSprite";

const Form = ({ handleSubmit, isFetching }) => {
  return (
    <form onSubmit={handleSubmit} className="form-wrap">
      <Field
        size="large"
        type="password"
        icon={<IconSprite className="lock" name="lock" />}
        placeholder="Password"
        name={"password"}
        validate={[required, checkLatinSymbols, minLength, checkHasLetters]}
        component={InputAuth}
        normalize={(value) => value.replace(/\s/g, "")}
      />
      <Field
        size="large"
        type="password"
        icon={<IconSprite className="lock" name="lock" />}
        placeholder="Repeat Password"
        name={"repeat_password"}
        validate={[required, matchPasswords]}
        component={InputAuth}
        normalize={(value) => value.replace(/\s/g, "")}
      />
      <Button type="primary" spin={isFetching} className="auth-btn">
        Access {themeConfig.title}
      </Button>
    </form>
  );
};

export default reduxForm({
  form: "signUp",
})(Form);
