export default [
  {
    id: 0,
    name: "protein",
    question: "Do you eat meat?",
    answers: [
      { id: 0, value: "meat", title: "Yes, I do" },
      { id: 1, value: "vegetarian", title: "No, I don't" },
    ],
    hideWithDiet: 508,
  },
  {
    id: 1,
    name: "fish",
    question: "What about fish?",
    answers: [
      { id: 0, value: "yes", title: "Yes, I do" },
      { id: 1, value: "no", title: "No, I don't" },
    ],
  },
  {
    id: 2,
    name: "dairy",
    question: "How do you feel about dairy?",
    answers: [
      { id: 0, value: "yes", title: "Yes, I am good with dairy" },
      { id: 1, value: "no", title: "No, I don't do dairy" },
    ],
  },
];
