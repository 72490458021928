export const SET_IS_LOADED = "HISTORY:SET_IS_LOADED";
export const SET_IS_FETCHING = "HISTORY:SET_IS_FETCHING";
export const SET_HISTORY_WORKOUTS = "HISTORY:SET_HISTORY_WORKOUTS";
export const UPDATE_HISTORY_WORKOUTS = "HISTORY:UPDATE_HISTORY_WORKOUTS";
export const SET_HISTORY_INFO = "HISTORY:SET_HISTORY_INFO";
export const SET_HISTORY_PREVIEW_LIST = "HISTORY:SET_HISTORY_PREVIEW_LIST";
export const UPDATE_HISTORY_PREVIEW_LIST =
  "HISTORY:UPDATE_HISTORY_PREVIEW_LIST";
export const SET_FILTER_DATA = "HISTORY:SET_FILTER_DATA";
export const ADD_CURRENT_MONTH_COUNT = "HISTORY:ADD_CURRENT_MONTH_COUNT";
export const TOGGLE_ELEMENT_TO_DELETE = "HISTORY:TOGGLE_ELEMENT_TO_DELETE";
export const CLEAR_ELEMENT_TO_DELETE = "HISTORY:CLEAR_ELEMENT_TO_DELETE";
export const ADD_IS_DELETING = "HISTORY:ADD_IS_DELETING";
export const DELETE_WORKOUTS = "HISTORY:DELETE_WORKOUTS";
