import React, { useState } from "react";
import { connect } from "react-redux";
import { setReferralModal, sendReferral } from "../actions/auth";

import ReferralModal from "../components/modals/ReferralModal";

const ReferralContainer = ({ open, setReferralModal, sendReferral }) => {
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setReferralModal(false);
  };

  const handleSubmit = (email) => {
    setLoading(true);
    sendReferral(email)
      .then((res) => {
        setSended(true);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <ReferralModal
      open={open}
      loading={loading}
      sended={sended}
      error={error}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

const mapStateToProps = ({ auth }) => ({
  open: auth.referralModalOpen,
});

const mapStateToDispatch = {
  setReferralModal,
  sendReferral,
};

export default connect(mapStateToProps, mapStateToDispatch)(ReferralContainer);
