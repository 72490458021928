import React from 'react';

import Error from '../components/pages/Error';

const ErrorPage = ({
  error
}) => {
  return (
    <Error error={error} />
  );
};

export default ErrorPage;