import React, { useState } from 'react';
import clsx from 'clsx';

import ImageCreator from '../../../../common/ImageCreator';

import Video from '../Video';

import './exerciseCard.sass';

const ExerciseCard = ({
  title,
  video1Preview,
  additionalInfo,
  repsDuration,
  video1,
  video2,
  isOpen,
  exerciseId,
  setId,
  handleToggleExercise
}) => {
  const [isSecondVideo, setIsSecondVideo] = useState(!video1);

  return (
    <div className="exercise-card">
      <div className="exercise-card__inner" onClick={() => { handleToggleExercise(setId, exerciseId) }}>
        <ImageCreator
          path={video1Preview}
          className="exercise-card__pic"
          alt={title}
          icon="workouts"
        />
        <div className="exercise-card__body">
          <div className="exercise-card__info">
            <div className="exercise-card__name">{repsDuration} {title}</div>
            {!additionalInfo || <div className="exercise-card__desc">{additionalInfo}</div>}
          </div>
        </div>
      </div>
      {!isOpen || (
        <div className="exercise-card__dropdown exercise-video">
          <div className="exercise-video__head">
            {!video1 ||
              <button
                className={clsx("exercise-video__btn", {
                  active: !isSecondVideo
                })}
                onClick={() => { setIsSecondVideo(false) }}
              >
                Video
              </button>
            }
            {!video2 ||
              <button
                className={clsx("exercise-video__btn", {
                  active: isSecondVideo
                })}
                onClick={() => { setIsSecondVideo(true) }}
              >
                Instruction
              </button>
            }
            <button
              className="exercise-video__btn exercise-video__btn--close"
              onClick={() => { handleToggleExercise(setId, exerciseId) }}
            >
              CLOSE
            </button>
          </div>
          <div className="exercise-video__body">
            <Video video1={video1} video2={video2} isSecondVideo={isSecondVideo} />
          </div>
        </div>
      )}

    </div>
  );
};

export default ExerciseCard;