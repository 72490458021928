import React from 'react';

import Title from '../../common/Title';
import IconSprite from '../../common/IconSprite';
import BlockGroup from '../../common/BlockGroup';
import ImageCreator from '../../common/ImageCreator';

import './Author.sass';

const Author = ({
  name,
  avatar,
  description,
  socials
}) => {
  return (
    <div className="content-page">
      <div className="container">
        <div className="content-page-inner author">
          <BlockGroup className="author-left" breakpoint={720}>
            <ImageCreator
              path={avatar}
              className="author-pic"
              alt={name}
              icon="workouts"
            />
          </BlockGroup>
          <BlockGroup className="author-right" breakpoint={720}>
            <Title className="author-name">{name}</Title>
            <div className="title-add">
              {description}
            </div>
            <div className="socials-group">
              {socials.map(({ type, url, id }) => (
                <a href={url} target="_blank" className="social-btn" key={id} rel="noopener noreferrer">
                  <IconSprite className="social-icon" name={type.toLowerCase()} />
                </a>
              ))}
            </div>
          </BlockGroup>
        </div>
      </div>
    </div>
  );
};


export default Author;
