export default (subscribes) => {
  if (!subscribes) return false;

  const statuses = {
    workouts: "none",
    recipes: "none",
  };

  subscribes.forEach((subscribe) => {
    if (subscribe?.product?.type === "workouts") {
      statuses.workouts = subscribe.status;
    }
    if (subscribe?.product?.type === "recipes") {
      statuses.recipes = subscribe.status;
    }
    if (subscribe?.product?.type === "workouts_recipes") {
      statuses.workouts = subscribe.status;
      statuses.recipes = subscribe.status;
    }
  });
  return statuses;
};
