import React from "react";

import IconSprite from "../IconSprite";

import "./Select.sass";

const Select = ({ options, onChange }) => {
  return (
    <div className="select-input-wrap">
      <select className="select-input" onChange={onChange}>
        {options.map(({ value, title }) => {
          return (
            <option key={value} value={value}>
              {title}
            </option>
          );
        })}
      </select>
      <IconSprite className="select-input-icon" name="angle-bottom" />
    </div>
  );
};

export default Select;
