import React from "react";
import { connect } from "react-redux";
import { setIsOpen } from "../../actions/subscriptions";
import { toggleFavorite } from "../../actions/workouts";
import WorkoutCard from "../../components/common/WorkoutCard";

const WorkoutCardContainer = ({
  statuses,
  openSubscribeModal,
  toggleFavorite,
  ...props
}) => {
  const isBlocked = statuses.workouts && statuses.workouts === "empty";

  const handleBuySubscribe = () => {
    if (!isBlocked) {
      return false;
    }
    openSubscribeModal();
  };

  const handleSetFavorite = (id, category) => {
    toggleFavorite(id, category);
  };

  return (
    <>
      <WorkoutCard
        isBlocked={isBlocked}
        handleBuySubscribe={handleBuySubscribe}
        handleSetFavorite={handleSetFavorite}
        {...props}
      />
    </>
  );
};

export default connect(
  ({ auth }) => ({
    statuses: auth.statuses,
  }),
  (dispatch) => ({
    toggleFavorite: (id, category) => dispatch(toggleFavorite(id, category)),
    openSubscribeModal: () => dispatch(setIsOpen(true)),
  })
)(WorkoutCardContainer);
