import React from "react";
import themeConfig from "../../../configs/theme";
import { NavLink } from "react-router-dom";
import Form from "./Form";

import "./LoginForgot.sass";

const LoginForgot = ({ isFetching, handleSubmitForm, isSend }) => {
  return (
    <div className="form-wrap login-form">
      {isSend ? (
        <>
          <div className="login-form__title">Password Reset</div>
          <div className="login-form__text">
            <p>
              You will receive an email with instructions about how to reset
              your password in a few minutes.
            </p>
            <NavLink className="login-form__link forgot" to="/signin">
              Back to login
            </NavLink>
          </div>
        </>
      ) : (
        <>
          <div className="login-form__title">Forgot Your Password?</div>
          <Form onSubmit={handleSubmitForm} isFetching={isFetching} />
          <a
            href={`mailto:orders@${themeConfig.name}.com`}
            className="login-form__link"
          >
            I don't remember my email address
          </a>
        </>
      )}
    </div>
  );
};

export default LoginForgot;
