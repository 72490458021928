import React from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import TagList from "../TagList";
import Tag from "../Tag";
import ParamList from "../ParamList";
import Param from "../Param";
import IconSprite from "../IconSprite";
import ImageCreator from "../ImageCreator";
import CategoryTag from "../CategoryTag";

import "./workoutCard.sass";

const WorkoutCard = ({
  linkTo,
  title,
  level,
  duration,
  calories,
  category,
  size,
  preview,
  isCategoryTag = false,
  isBlocked,
  handleBuySubscribe,
  handleSetFavorite,
  withLike,
  isFavourite,
  id,
}) => {
  const innerCard = (
    <>
      <ImageCreator
        path={preview}
        className="workout-card__pic"
        alt={title}
        icon="workouts"
      />
      <div className="workout-card__inner">
        <TagList className="workout-card__tags">
          <Tag className={`workout-card__tag ${level}`}>{level}</Tag>
        </TagList>
        <div className="workout-card__body">
          <div className="workout-card__info">
            <div className="workout-card__title">{title}</div>
            <ParamList className="workout-card__params">
              <Param
                icon={
                  <IconSprite className="icon-time-small" name="time-small" />
                }
              >
                {duration} min
              </Param>
              {size !== "small" ? (
                <Param
                  icon={
                    <IconSprite className="icon-kcal-small" name="kcal-small" />
                  }
                >
                  {calories} kcal
                </Param>
              ) : null}
            </ParamList>
          </div>
          <button className="workout-card__btn btn-circle">
            <IconSprite className="icon-btn-arrow" name="btn-arrow" />
          </button>
        </div>
      </div>
    </>
  );

  if (isBlocked) {
    return (
      <div
        className={clsx("workout-card", {
          "workout-card--small": size === "small",
        })}
      >
        <div className="workout-card__close" onClick={handleBuySubscribe}>
          <IconSprite className="icon-lock-outlined" name="lock-outlined" />
        </div>
        {innerCard}
      </div>
    );
  }

  return (
    <div
      className={clsx("workout-card", {
        "workout-card--small": size === "small",
      })}
    >
      <div
        className={clsx("workout-card__nav", {
          "b-category": !isCategoryTag && size !== "small",
        })}
      >
        {isCategoryTag ? (
          <CategoryTag category={category} color={level} />
        ) : (
          <>
            {size !== "small" ? (
              <div className="workout-card__category">
                <div className={`workout-card__category-icon ${level}`}>
                  <IconSprite
                    className={`icon-${category}`}
                    name={`category-${category}`}
                  />
                </div>
              </div>
            ) : null}
          </>
        )}
        {withLike && (
          <button
            className="workout-card-like"
            onClick={() => handleSetFavorite(id, category)}
          >
            <div
              className={clsx("workout-card-like__inner", {
                "is-favorite": isFavourite,
              })}
            >
              <IconSprite className="icon-like" name="like" />
              <IconSprite className="icon-like-full" name="like-full" />
            </div>
          </button>
        )}
      </div>
      <NavLink to={linkTo} className="workout-card-link">
        {innerCard}
      </NavLink>
    </div>
  );
};

export default WorkoutCard;
