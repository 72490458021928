import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import Loader from '../../../components/common/Loader';

import './LoaderOver.sass';

const LoaderOver = ({
  isLoading
}) => {

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    }
  }, [isLoading]);

  return (
    <CSSTransition
      in={isLoading}
      timeout={550}
      classNames="loader-over-transition"
      unmountOnExit
    >
      <div className="loader-over">
        <Loader size={36} />
      </div>
    </CSSTransition>
  );
};

export default LoaderOver;