import React, { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import IconSprite from '../../../../common/IconSprite';
import Button from '../../../../common/Button';

import './PauseVideo.sass';

const PauseVideo = ({
  video,
  preview
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenVideo = () => {
    document.body.classList.add('video-pause-body');
    setIsOpen(true);
  };

  const handleCloseVideo = () => {
    document.body.classList.remove('video-pause-body');
    setIsOpen(false);
  };

  return (
    <>
      <button className="pause-video-btn" onClick={handleOpenVideo}>
        <div className="pause-video-btn__pic">
          {preview ? (
            <img src={preview} alt={"instructions from the coach"} />
          ) : (
              <div className="pause-video-btn__pic-default">
                <IconSprite className="workouts-icon" name="workouts" />
              </div>
            )}
        </div>
        <div className="pause-video-btn__text">
          see instructions from the coach
        </div>
      </button>
      {isOpen && (
        <div className="pause-video-wrapper">
          <div className="pause-video-bg" onClick={handleCloseVideo}></div>
          <div className="pause-video">
            <Button className="pause-video-close" onClick={handleCloseVideo}>Close</Button>
            <div className="pause-video-inner">
              <Vimeo
                video={video}
                autoplay={true}
                color="000000"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PauseVideo;