import React from 'react';
import svgSprite from '../../../assets/images/general/sprite.svg';

const IconSprite = ({
  className,
  name
}) => {
  const classes = className || '';

  return (
    <svg className={classes}>
      <use xlinkHref={`${svgSprite}#${name}`}></use>
    </svg>
  );
};

export default IconSprite;
