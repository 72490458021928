import React from "react";

import BMIResult from "./BMIResult";
import MetabolicResult from "./MetabolicResult";
import CaloriesResult from "./CaloriesResult";

import "./CardResult.sass";

const calculateHeight = (type, height) => {
  if (!height) return height;
  if (type === "imperial") {
    height = height.split(" ");
    const ft = parseInt(height[0]);
    const inch = parseInt(height[2]);
    return (ft * 12 + inch) * 2.54;
  }
  return height;
};

const calculateWeight = (type, weight) => {
  if (type === "imperial") {
    return Math.ceil(weight * 0.45);
  }
  return weight;
};

const calculateBMI = (weight, height) => {
  height = height / 100;

  const value = parseFloat((weight / Math.pow(height, 2)).toFixed(0));
  let status = null;

  if (value > 0 && value <= 18.5) {
    status = "Underweight";
  } else if (value > 18.5 && value <= 25) {
    status = "Normal";
  } else if (value > 25 && value <= 30) {
    status = "Overweight";
  } else if (value > 30) {
    status = "Obesity";
  }
  return { value, status };
};

const calculateMetabolic = (activity, age, bmi) => {
  const bmiCoefficient = bmi / 23;
  const activityCoefficient = activity / 1.4;
  return Math.ceil(age * bmiCoefficient * activityCoefficient);
};

const calculateCalories = (activity, gender, age, height, target) => {
  gender = gender && gender.toLowerCase();
  const result = {};
  if (gender === "female") {
    result.calories = Math.floor(
      (10 * target + 6.25 * height - 5 * age - 161) * activity
    );
  } else {
    result.calories = Math.floor(
      (10 * target + 6.25 * height - 5 * age + 5) * activity
    );
  }

  result.min = Math.trunc(result.calories / 10) * 10 - 50;
  result.max = Math.floor(result.min + 100);

  return result;
};

const CardResult = ({
  weight,
  height,
  measurement,
  age,
  gender,
  activity,
  target,
}) => {
  const currentWeight = calculateWeight(measurement, weight);
  const targetWeight = calculateWeight(measurement, target);
  const currentHeight = calculateHeight(measurement, height);

  const bmi = calculateBMI(currentWeight, currentHeight);

  const metabolicAge = calculateMetabolic(activity, age, bmi.value);
  const calories = calculateCalories(
    activity,
    gender,
    age,
    currentHeight,
    targetWeight
  );

  return (
    <div className="result-cards">
      {activity && age && (
        <div className="card-result">
          <div className="card-result__title">Metabolic Age</div>
          <MetabolicResult age={metabolicAge} />
        </div>
      )}
      {Boolean(bmi.value && bmi.value !== Infinity) && (
        <div className="card-result">
          <div className="card-result__title">BMI</div>
          <BMIResult status={bmi.status} value={bmi.value} />
        </div>
      )}
      {activity && age && gender && (
        <div className="card-result">
          <div className="card-result__title">Calories</div>
          <CaloriesResult
            min={calories.min}
            max={calories.max}
            calories={calories.calories}
          />
        </div>
      )}
    </div>
  );
};

export default CardResult;
