import {
  SET_IS_LOADED,
  SET_CATEGORIES_LIST,
} from '../constants/categoriesTypes';

const initialState = {
  isLoaded: false,
  categoriesList: []
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADED: {
      return {
        ...state,
        isLoaded: true
      }
    }
    case SET_CATEGORIES_LIST: {
      return {
        ...state,
        categoriesList: [...action.list]
      }
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState
      }
    }
    default:
      return state;
  }
};


export default categories;
