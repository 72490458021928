import React, { useRef, useEffect } from "react";
import amplitude from "amplitude-js";

import Button from "../../../../common/Button";

import Timer from "../Timer";

import "./StartScreen.sass";

const sendGA = (event) => {
  if (window.gtag) {
    window.gtag("event", event);
  }
};

const sendAmplitude = (event, data) => {
  const params = [event];
  if (data) {
    params.push(data);
  }

  amplitude.getInstance().logEvent(...params);
};

const StartScreen = ({
  handleChangeModuleStatus,
  audioLoad,
  audioPlay,
  audioUpdate,
  audioCountSkip,
  workoutPlanName,
  workoutPlanAuthor,
  workoutPlanCategory,
  workoutPlanLevel,
}) => {
  const timer = useRef(null);

  const handleUpdateTimer = () => {
    timer.current.onUpdateTime();
    audioUpdate();
    sendGA("add +10 seconds");
    sendAmplitude("add +10 seconds");
  };

  audioLoad();

  useEffect(() => {
    const analyticsEventName = "start workout timer";
    const analyticsData = {
      title: workoutPlanName,
      category: workoutPlanCategory,
      level: workoutPlanLevel,
    };
    if (workoutPlanAuthor) {
      analyticsData.author = `${workoutPlanAuthor.firstName} ${workoutPlanAuthor.lastName}`;
    }

    sendGA(analyticsEventName);
    sendAmplitude(analyticsEventName, analyticsData);
  }, [
    workoutPlanName,
    workoutPlanAuthor,
    workoutPlanCategory,
    workoutPlanLevel,
  ]);

  return (
    <div className="start-screen">
      <div className="start-screen__inner">
        <div className="start-screen__head">
          <div className="start-screen__title">Get ready</div>
          <div className="start-screen__desc">or tap to start immediately</div>
        </div>
        <Timer
          ref={timer}
          time={12}
          onTimerEnd={() => handleChangeModuleStatus(true)}
          startImmediately={true}
          finalWord="GO!"
          audioPlay={audioPlay}
          onSkipTimer={audioCountSkip}
        />
        <Button className="start-screen__btn" onClick={handleUpdateTimer}>
          +10 Second
        </Button>
      </div>
    </div>
  );
};

export default StartScreen;
