import React from 'react';

import Loader from '../Loader';
import './FixedLoader.sass';

const FixedLoader = (props) => {
  return (
    <div className="fixed-loader">
      <Loader size={36} />
    </div>
  );
};

export default FixedLoader;
