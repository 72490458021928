import React from 'react';

const MetabolicResult = ({
  age
}) => {
  return (
    <div className="finish-card-metabolic">
      <div className="finish-card-metabolic__body">
        <div className="finish-card-value">
          <span>{age}</span> years
        </div>
      </div>
    </div>
  );
};

export default MetabolicResult;