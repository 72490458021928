import React from "react";

import icons from "./icons";

import classes from "./HeadIcon.module.sass";

const HeadIcon = ({ name }) => {
  return (
    <div className={classes.wrap}>
      <img className={classes.icon} src={icons[name]} alt={name} />
    </div>
  );
};

export default HeadIcon;
