import React from "react";

import Button from "../Button";

import classes from "./ButtonsGroup.module.sass";

const ButtonsGroup = ({ onPrev, disabled, submitButtonTitle }) => {
  return (
    <div className={classes.wrap}>
      <Button
        className={classes.btn}
        transparent
        type="button"
        onClick={onPrev}
      >
        Back
      </Button>
      <Button className={classes.btn} disabled={disabled} type="submit">
        {submitButtonTitle ? submitButtonTitle : "Next"}
      </Button>
    </div>
  );
};

export default ButtonsGroup;
