import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

import Title from "../../common/Title";
import IconSprite from "../../common/IconSprite";
import FilterSelectList from "../../common/FilterSelectList";
import ParamList from "../../common/ParamList";
import Param from "../../common/Param";
import LoaderWrap from "../../common/LoaderWrap";
import Button from "../../common/Button";
import SearchFail from "../../common/SearchFail";

import "./History.sass";

const calculateDuration = (duration) => {
  if (!duration) return "0M";
  const minutes = duration % 60;
  const hours = (duration - minutes) / 60;
  let resultDuration = "";

  if (hours > 0) {
    resultDuration += hours + "H ";
  }

  if (minutes > 0) {
    resultDuration += minutes + "M";
  }

  return resultDuration;
};

const History = ({
  filtersList,
  handleFilterSelectChange,
  workouts,
  count,
  handleUpdateHistory,
  isFetching,
  periodName,
  calories,
  duration,
  toggleDeleteElement,
  clearElementsToDelete,
  deleteWorkouts,
  isDeleting,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!isDeleting) {
      setIsEdit(false);
    }
  }, [isDeleting]);

  useEffect(() => {
    return () => {
      clearElementsToDelete();
    };
  }, [clearElementsToDelete]);

  const handleStartEdit = () => {
    setIsEdit(true);
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    clearElementsToDelete();
  };

  const handleClickOnLink = (e) => {
    if (isEdit) {
      e.preventDefault();
    }
  };

  return (
    <div className="content-page">
      <div className="container">
        <div className="history content-page-inner">
          <div className="content-page-left history__head">
            <Title className="history__title">History</Title>
            <div className="title-add history__title-add">
              Select workout plan and start your daily workout goals{" "}
            </div>
            <div className="history-counters">
              <div className="history-counter">
                <div className="history-counter__title">{periodName} time</div>
                <div className="history-counter__value">
                  {calculateDuration(duration)}
                </div>
              </div>
              <div className="history-counter">
                <div className="history-counter__title">
                  {periodName} workouts
                </div>
                <div className="history-counter__value">{count}</div>
              </div>
              <div className="history-counter">
                <div className="history-counter__title">{periodName} kcal</div>
                <div className="history-counter__value">{calories || 0}</div>
              </div>
            </div>
          </div>
          <div className="content-page-right">
            <div className="history-head">
              <Title
                size="medium"
                className={clsx("history-head__title", {
                  auto: count === 0,
                })}
              >
                Completed
              </Title>
              {count !== 0 && (
                <div className="history-nav">
                  {!isEdit ? (
                    <button
                      className="history-nav-edit"
                      onClick={handleStartEdit}
                    >
                      <IconSprite className="icon-pencil" name="pencil" />
                      <span>EDIT</span>
                    </button>
                  ) : (
                    <>
                      <button
                        className="history-nav-edit"
                        onClick={deleteWorkouts}
                      >
                        <IconSprite className="icon-pencil" name="pencil" />
                        <span>SAVE</span>
                      </button>
                      <button
                        className="history-nav-discard"
                        onClick={handleCancelEdit}
                      >
                        Discard
                      </button>
                    </>
                  )}
                </div>
              )}
              <FilterSelectList
                list={filtersList}
                handleChange={handleFilterSelectChange}
                className={clsx("history-head__filter", {
                  "edit-hide": isEdit,
                })}
              />
            </div>
            {count > 0 ? (
              <>
                <div className="history-list">
                  {workouts.map(({ workout_plan, isDelete, ...workout }) => {
                    return (
                      <div
                        className={clsx("history-item-wrap", {
                          "history-item-wrap-edit": isEdit,
                          "history-item-wrap-delete": isDelete,
                        })}
                        key={workout.id}
                      >
                        <button
                          className="history-item-delete"
                          onClick={() => toggleDeleteElement(workout.id)}
                        >
                          <IconSprite
                            className="history-item-delete-icon"
                            name="cross-bold"
                          />
                        </button>
                        <NavLink
                          to={`/workouts/${workout_plan.id}`}
                          className={`history-item history-item--${workout_plan.level}`}
                          onClick={handleClickOnLink}
                        >
                          <div className="history-item__date">
                            {dayjs(workout.created_at).format(
                              "MMM DD, YYYY hh:mm A"
                            )}
                          </div>
                          <div className="history-item__name">
                            <div className="history-item__name-inner">
                              <span>{workout_plan.title}</span>
                              <div className="history-item__icon">
                                <IconSprite
                                  className={`icon-${workout_plan.category}`}
                                  name={`category-${workout_plan.category}`}
                                />
                              </div>
                            </div>
                          </div>
                          <ParamList className="history-item__params">
                            <Param
                              icon={
                                <IconSprite
                                  className="icon-time-small"
                                  name="time-small"
                                />
                              }
                            >
                              {workout_plan.duration} min
                            </Param>
                            <Param
                              icon={
                                <IconSprite
                                  className="icon-kcal-small"
                                  name="kcal-small"
                                />
                              }
                            >
                              {workout_plan.calories} kcal
                            </Param>
                          </ParamList>
                          <div className="history-item__level">
                            <span>{workout_plan.level}</span>
                          </div>
                        </NavLink>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={clsx("history-footer", {
                    "edit-hide": isEdit,
                  })}
                >
                  {count > workouts.length ? (
                    <LoaderWrap spin={isFetching}>
                      <Button
                        disabled={isFetching}
                        onClick={handleUpdateHistory}
                      >
                        View more
                      </Button>
                    </LoaderWrap>
                  ) : null}
                </div>
              </>
            ) : (
              <SearchFail
                message="No workouts with such parameters were found"
                className="history-not-found"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
