import React from "react";

import IconSprite from "../../../../common/IconSprite";

import "./PlanCartHead.sass";

const PlanCartHead = ({ isShow }) => {
  if (!isShow) {
    return null;
  }

  return (
    <div className="plan-cart-head">
      <IconSprite className="plan-cart-icon" name="cart" />
    </div>
  );
};

export default PlanCartHead;
