import getSubscribeStatuses from "./getSubscribeStatuses";

const getSerialNum = (subscribes) => {
  let serial = 0;

  subscribes.forEach((sub) => {
    if (
      (sub?.product?.type === "workouts" && sub.status === "active") ||
      (sub?.product?.type === "recipes" && sub.status === "active") ||
      (sub?.product?.type === "recipes_workouts" && sub.status === "active")
    ) {
      if (
        sub.serial_num &&
        parseInt(sub.serial_num) > 0 &&
        parseInt(sub.serial_num) > serial
      ) {
        serial = parseInt(sub.serial_num);
      }
    }
  });

  return serial;
};

export default (user, subscriptions) => {
  const subscribes = getSubscribeStatuses(subscriptions);
  const subscribesKeys = Object.keys(subscribes);
  const serial = getSerialNum(subscriptions);

  return {
    email: user.email,
    gender: user.gender,
    age: user.age,
    subscriptions: subscribesKeys.join(", "),
    subscriptions_status: subscribesKeys
      .map((key) => subscribes[key])
      .join(", "),
    level: user.activity_level,
    logged_at: user.logged_at,
    created_at: user.created_at,
    activated_at: user.activated_at,
    actual_weight: user.actual_weight || user.current_weight,
    goal_weight: user.goal_weight,
    serial,
    units: user.units,
  };
};
