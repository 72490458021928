import React, { useEffect } from "react";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { contentAPI } from "../api";
import {
  setList,
  setIsLoaded,
  setIsFetching,
  srtListInfo,
  updateList,
  setFilters,
} from "../actions/categoryDetail";
import themeConfig from "../configs/theme";
import { clearAuth } from "../actions/auth";

import CategoryDetail from "../components/pages/CategoryDetail";
import PageLoader from "../components/common/PageLoader";

const CategoryDetailPage = ({
  isLoaded,
  getFirstList,
  list,
  listCount,
  listStart,
  isFetching,
  match,
  updateList,
  reloadList,
  setFilters,
  filterParams,
  filtersString,
  categoryName,
}) => {
  const category = match.params.categoryName;
  const categoryTitle = category.split("_").join(" ");

  const handleUpdateListWorkouts = () => {
    let filter = filtersString;
    if (!filtersString) {
      filter = `&category=${category}`;
    }
    updateList(listStart, 8, filter);
  };

  const handleClearFilter = () => {
    const resultObject = {
      level: "all",
      duration: "all",
    };
    let result = `&category=${category}`;
    setFilters({ ...resultObject }, result);
    reloadList(result);
  };

  const handleFilterSelectChange = (value, name) => {
    const resultObject = { ...filterParams };
    let result = "";

    if (name === "duration") {
      const timeArray = value.split("-");
      resultObject[name + "_gte"] = timeArray[0];
      resultObject[name + "_lte"] = timeArray[1];
    } else {
      resultObject[name] = value;
    }

    const keys = Object.keys(resultObject);
    if (keys.length) {
      keys.forEach((key) => {
        if (resultObject[key] !== "all" && resultObject[key]) {
          result += `&${key}=${resultObject[key]}`;
        }
      });
    }
    result += `&category=${category}`;
    setFilters({ ...resultObject }, result);
    reloadList(result);
  };

  useEffect(() => {
    if (category !== categoryName) {
      getFirstList(category);
    }
    amplitude.getInstance().logEvent(`category:${category.toLowerCase()}`);
  }, [getFirstList, category, categoryName]);

  if (!isLoaded || category !== categoryName) {
    return <PageLoader />;
  }

  const filtersList = [
    {
      name: "duration",
      label: "Time",
      options: [
        { title: "All", value: "all" },
        { title: "0-10 min", value: "0-10" },
        { title: "10-30 min", value: "10-30" },
        { title: "30-45 min", value: "30-45" },
        { title: "45-60 min", value: "45-60" },
        { title: "60+ min", value: "60" },
      ],
      defaultOption: filterParams.duration,
    },
    {
      name: "level",
      label: "Level",
      options: [
        { title: "All", value: "all" },
        { title: "Beginner", value: "beginner" },
        { title: "Intermediate", value: "intermediate" },
        { title: "Advanced", value: "advanced" },
      ],
      defaultOption: filterParams.level,
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Explore {categoryTitle.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}{" "}
          by {themeConfig.title}
        </title>
      </Helmet>
      <CategoryDetail
        list={list}
        categoryTitle={categoryTitle}
        listCount={listCount}
        isFetching={isFetching}
        handleUpdateListWorkouts={handleUpdateListWorkouts}
        filtersList={filtersList}
        handleFilterSelectChange={handleFilterSelectChange}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
};

export default connect(
  (state) => ({
    isLoaded: state.categoryDetail.isLoaded,
    isFetching: state.categoryDetail.isFetching,
    list: state.categoryDetail.list,
    listCount: state.categoryDetail.listCount,
    listStart: state.categoryDetail.listStart,
    filterParams: state.categoryDetail.filterParams,
    filtersString: state.categoryDetail.filtersString,
    categoryName: state.categoryDetail.categoryName,
  }),
  (dispatch) => ({
    setFilters: (filterParams, filtersString) =>
      dispatch(setFilters(filterParams, filtersString)),
    getFirstList: (category) => {
      return contentAPI
        .getWorkoutPlans(0, 8, `&category=${category}`)
        .then((res) => {
          dispatch(setList([...res.data.data]));
          dispatch(
            srtListInfo(res.data.count, 0 + res.data.data.length, category)
          );
          dispatch(setIsLoaded());
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    updateList: (start, limit, filters) => {
      dispatch(setIsFetching(true));
      return contentAPI
        .getWorkoutPlans(start, limit, filters)
        .then((res) => {
          dispatch(updateList([...res.data.data]));
          dispatch(srtListInfo(res.data.count, start + res.data.data.length));
          dispatch(setIsFetching(false));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    reloadList: (filters) => {
      return contentAPI
        .getWorkoutPlans(0, 8, filters)
        .then((res) => {
          dispatch(setList([...res.data.data]));
          dispatch(srtListInfo(res.data.count, 0 + res.data.data.length));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
  })
)(withRouter(CategoryDetailPage));
