import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import themeConfig from "../configs/theme";
import { contentAPI } from "../api";
import {
  setIsLoaded,
  setHistoryWorkouts,
  setHistoryInfo,
  setIsFetching,
  updateHistoryWorkouts,
  setFilterData,
  toggleDeleteElement,
  clearElementsToDelete,
  deleteWorkouts,
} from "../actions/history";

import { clearAuth } from "../actions/auth";

import PageLoader from "../components/common/PageLoader";
import { LoaderOver } from "../common/components";

import History from "../components/pages/History";

import dayjs from "dayjs";

let weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

const calculateListOfMonth = (startDate, finishDate, createDate) => {
  const resultArray = [];

  const startMonth = dayjs(startDate).format("M");
  const startYear = dayjs(startDate).format("YYYY");
  const finish = dayjs(finishDate).format("YYYY-M");

  let dateCounter = startYear + "-" + startMonth;

  while (dayjs(dateCounter).format() <= dayjs(finish).format()) {
    let title = dayjs(dateCounter).format("MMMM YYYY");
    let value = dayjs(dateCounter).format("MM_YYYY");

    if (dayjs(dateCounter).format() === dayjs(finish).format()) {
      title = "This Month";
      value = "this_month";
    } else if (
      dayjs(dateCounter).format() ===
      dayjs(dayjs(finish).subtract(1, "month").format())
        .startOf("month")
        .format()
    ) {
      title = "Last Month";
      value = "last_month";
    }

    resultArray.push({
      title: title,
      value: value,
      startDate: dayjs(dateCounter).startOf("month").format(),
      finishDate:
        dayjs(dateCounter).format() === dayjs(finish).format()
          ? dayjs().format()
          : dayjs(dateCounter).endOf("month").format(),
    });
    let tempYear = Number(dayjs(dateCounter).format("YYYY"));
    let tempMonth = Number(dayjs(dateCounter).format("M")) + 1;

    if (tempMonth > 12) {
      tempMonth = 1;
      tempYear += 1;
    }

    dateCounter = dayjs(tempYear + "-" + tempMonth).format("YYYY-M");
  }

  const currentWeekDay = dayjs().weekday();

  if (
    dayjs(dayjs().weekday(0).format()).endOf("day").format() >
    dayjs(dayjs(createDate).format()).startOf("day").format()
  ) {
    if (
      dayjs(dayjs().weekday(-6).format()).endOf("day").format() >
      dayjs(dayjs(createDate).format()).startOf("day").format()
    ) {
      resultArray.push({
        title: "Last Week",
        value: "last_week",
        startDate: dayjs(dayjs().weekday(-6).format()).startOf("day").format(),
        finishDate: dayjs(dayjs().weekday(0).format()).endOf("day").format(),
      });
    } else {
      resultArray.push({
        title: "Last Week",
        value: "last_week",
        startDate: dayjs(dayjs(createDate).format()).startOf("day").format(),
        finishDate: dayjs(dayjs().weekday(0).format()).endOf("day").format(),
      });
    }
  }
  resultArray.push({
    title: "This Week",
    value: "this_week",
    startDate: dayjs(dayjs().weekday(1).format()).startOf("day").format(),
    finishDate: dayjs().format(),
  });
  resultArray.push({
    title: "Last 7 Days",
    value: "last_7_days",
    startDate: dayjs(
      dayjs()
        .weekday(1 - (7 - currentWeekDay))
        .format()
    )
      .startOf("day")
      .format(),
    finishDate: dayjs().format(),
  });

  return resultArray;
};

const HistoryPage = ({
  isLoaded,
  isFetching,
  getHistory,
  workouts,
  count,
  start,
  updateHistory,
  profileCreatedDate,
  filterHistory,
  calories,
  duration,
  setFilterData,
  filterData,
  toggleDeleteElement,
  clearElementsToDelete,
  deleteWorkouts,
  isDeleting,
}) => {
  const [periodName, setPeriodName] = useState("Total");

  const handleFilterSelectChange = (value) => {
    const idx = listOfMonth.findIndex((item) => item.value === value);
    const idxLastMonth = listOfMonth.findIndex(
      (item) => item.value === "last_month"
    );
    const startDate = listOfMonth[idx].startDate;
    const finishDate = listOfMonth[idx].finishDate;

    setFilterData({
      startDate,
      finishDate,
      value: listOfMonth[idx].value,
    });

    filterHistory(0, startDate, finishDate).then((res) => {
      let result = listOfMonth[idx].title;
      if (idxLastMonth > idxLastMonth - 1 && idx > idxLastMonth) {
        result = dayjs(listOfMonth[idx].startDate).format("MMMM");
      }
      setPeriodName(result);
    });
  };

  const handleUpdateHistory = () => {
    updateHistory(start, filterData.startDate, filterData.finishDate);
  };

  useEffect(() => {
    if (count === null) {
      getHistory();
    }
  }, [getHistory, count]);

  if (!isLoaded) {
    return <PageLoader />;
  }

  const startDate = dayjs(profileCreatedDate).startOf("month").format();
  const finishDate = dayjs().startOf("month").format();
  const listOfMonth = calculateListOfMonth(
    startDate,
    finishDate,
    profileCreatedDate
  ).reverse();

  listOfMonth.unshift({
    title: "All",
    value: "all",
  });
  const filtersList = [
    {
      name: "date",
      label: "Period",
      options: [...listOfMonth],
      defaultOption: filterData.value,
    },
  ];

  return (
    <>
      <Helmet>
        <title>History - {themeConfig.title}</title>
      </Helmet>
      <History
        filtersList={filtersList}
        handleFilterSelectChange={handleFilterSelectChange}
        workouts={workouts}
        count={count}
        handleUpdateHistory={handleUpdateHistory}
        isFetching={isFetching}
        periodName={periodName}
        calories={calories}
        duration={duration}
        toggleDeleteElement={toggleDeleteElement}
        clearElementsToDelete={clearElementsToDelete}
        deleteWorkouts={deleteWorkouts}
        isDeleting={isDeleting}
      />
      <LoaderOver isLoading={isDeleting} />
    </>
  );
};

export default connect(
  (state) => ({
    isLoaded: state.history.isLoaded,
    isFetching: state.history.isFetching,
    workouts: state.history.workouts,
    count: state.history.count,
    start: state.history.start,
    calories: state.history.calories,
    duration: state.history.duration,
    filterData: state.history.filterData,
    profileCreatedDate: state.profile.profileCreatedDate,
    isDeleting: state.history.isDeleting,
  }),
  (dispatch) => ({
    deleteWorkouts: () => dispatch(deleteWorkouts()),
    toggleDeleteElement: (id) => dispatch(toggleDeleteElement(id)),
    clearElementsToDelete: () => dispatch(clearElementsToDelete()),
    setFilterData: (filterData) => dispatch(setFilterData(filterData)),
    getHistory: () => {
      return contentAPI
        .getFinishedWorkoutPlan(0, 7)
        .then((res) => {
          dispatch(
            setHistoryInfo(
              res.data.count,
              7,
              res.data.totalCalories,
              res.data.totalDuration
            )
          );
          dispatch(setHistoryWorkouts(res.data.data));
          dispatch(setIsLoaded());
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    updateHistory: (start, startDate, finishDate) => {
      const dataFilterFrom = startDate ? startDate : null;
      const dataFilterTo = finishDate ? finishDate : null;

      dispatch(setIsFetching(true));
      return contentAPI
        .getFinishedWorkoutPlan(start, 7, dataFilterFrom, dataFilterTo)
        .then((res) => {
          dispatch(
            setHistoryInfo(
              res.data.count,
              start + 7,
              res.data.totalCalories,
              res.data.totalDuration
            )
          );
          dispatch(updateHistoryWorkouts(res.data.data));
          dispatch(setIsFetching(false));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    filterHistory: (start, startDate, finishDate) => {
      const dataFilterFrom = startDate ? startDate : null;
      const dataFilterTo = finishDate ? finishDate : null;
      return contentAPI
        .getFinishedWorkoutPlan(start, 7, dataFilterFrom, dataFilterTo)
        .then((res) => {
          dispatch(
            setHistoryInfo(
              res.data.count,
              start + 7,
              res.data.totalCalories,
              res.data.totalDuration
            )
          );
          dispatch(setHistoryWorkouts(res.data.data));
          return res;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
  })
)(HistoryPage);
