import React from 'react';
import clsx from 'clsx';

import IconSprite from '../IconSprite';

import './SearchFail.sass';

const SearchFail = ({
  message,
  className,
  type,
  onCleanFilter
}) => {
  return (
    <div className={clsx("search-fail", {
      [className]: className
    })}>
      <div className="search-fail__inner">
        <div className="search-fail__icon">
          {type === "white" ? (
            <IconSprite className="search-fail-icon" name="search-fail-white" />
          ) : (
              <IconSprite className="search-fail-icon" name="search-fail-black" />
            )}
        </div>
        <div className="search-fail__message">{message}</div>
        {/* {onCleanFilter && (
          <button className="search-fail__clean" onClick={onCleanFilter}>clean filter</button>
        )} */}
      </div>
    </div>
  )
};

export default SearchFail;
