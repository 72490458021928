import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import dayjs from "dayjs";
import clsx from "clsx";
import Guard from "../Guard";
import MainNavLink from "../MainNavLink";
import Logo from "../Logo";
import themeConfig from "../../../configs/theme";
import "./MobNav.sass";

const MobNav = ({ confirmed, pagePath, navigationConfig, company }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    setIsOpen(true);
  };

  const closeMenu = () => {
    document.documentElement.style.overflow = "";
    document.body.style.overflow = "";
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={clsx("nav-burger", {
          open: isOpen,
        })}
        onClick={openMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <CSSTransition in={isOpen} timeout={300} classNames="alert" unmountOnExit>
        <div className="mob-nav-wrap">
          <div className="container">
            <div className="mob-nav">
              <div className="mob-nav-head">
                <Logo company={company} />
                <div className="nav-burger" onClick={closeMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>

              {navigationConfig && navigationConfig.length ? (
                <div className="mob-nav-menu">
                  {navigationConfig.map((navigation) => {
                    if (
                      navigation.guard &&
                      navigation.confirmed !== undefined
                    ) {
                      return (
                        <Guard
                          key={navigation.label}
                          condition={navigation.confirmed}
                        >
                          <MainNavLink
                            onClose={closeMenu}
                            key={navigation.label}
                            {...navigation}
                          />
                        </Guard>
                      );
                    }
                    return (
                      <MainNavLink
                        onClose={closeMenu}
                        key={navigation.label}
                        {...navigation}
                      />
                    );
                  })}
                </div>
              ) : null}
              <div className="mob-nav-footer">
                {/* <nav className="nav-terms">
                  <ul className="nav-terms__list">
                    <li className="nav-terms__item">
                      <a className="nav-terms__link" href="!#">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-terms__item">
                      <a className="nav-terms__link" href="!#">
                        Terms and Conditions
                      </a>
                    </li>
                  </ul>
                </nav> */}
                <div className="copyright">
                  © {dayjs().format("YYYY")} {themeConfig.copyright} All rights
                  reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default MobNav;
