import React from "react";
import { Field } from "formik";

import Input from "../Input";
import Icons from "../Icons";

import classes from "./FormControl.module.sass";

export const FormControlDouble = ({ first, second, icon }) => {
  return (
    <div className={classes.control}>
      <div className={classes.iconWrap}>
        <Icons name={`f-${icon}`} className={classes.icon} />
      </div>
      <div className={`${classes.double} ${classes.input}`}>
        <Field {...first} className={classes.half} component={Input} />
        <Field {...second} className={classes.half} component={Input} />
      </div>
    </div>
  );
};

const FormControl = ({ icon, ...props }) => {
  return (
    <div className={classes.control}>
      <div className={classes.iconWrap}>
        <Icons name={`f-${icon}`} className={classes.icon} />
      </div>
      <Field className={classes.input} component={Input} {...props} />
    </div>
  );
};

export default FormControl;
