import React from "react";

import { Backdrop } from "../../../../../common/ui";
import LoaderFullBlock from "../../../../../common/components/LoaderFullBlock";

import classes from "./Modal.module.sass";

const Modal = ({ children, open, step, stepLength, fetching, onClear }) => {
  return (
    <Backdrop isOpen={open} onClear={onClear}>
      <div className={classes.wrap}>
        <div className={classes.inner}>
          <div className={classes.modal}>
            <LoaderFullBlock isLoading={fetching} />
            <div
              style={{ width: (step * 100) / stepLength + "%" }}
              className={classes.progress}
            ></div>
            <div className={classes.modalWrap}>{children}</div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default Modal;
