import React from "react";

import Form from "./Form";

import "./signUp.sass";

const SignUp = ({ handleSubmitForm, isFetching }) => {
  return (
    <div className="form-wrap login-form">
      <div className="login-form__title sign-up-form-title">
        Welcome to Fitculator!
      </div>
      <div className="login-form__subtitle">
        Please create your login password
      </div>
      <Form onSubmit={handleSubmitForm} isFetching={isFetching} />
    </div>
  );
};

export default SignUp;
