import React from "react";
import { Formik, Form } from "formik";

import ButtonsGroup from "../ButtonsGroup";

import classes from "./Wrapper.module.sass";

const Wrapper = ({ schema, initialValues, children, onPrev, onNext }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(v) => onNext(v)}
      validationSchema={schema}
      validateOnChange
      validateOnBlur
      validateOnMount
    >
      {({ isValid, ...props }) => {
        return (
          <Form className={classes.wrapper}>
            <div className={classes.body}>{children}</div>
            <ButtonsGroup onPrev={onPrev} disabled={!isValid} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Wrapper;
