import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Axios from "axios";
import themeConfig from "../configs/theme";
import { connect } from "react-redux";

import { contentAPI } from "../api";
import {
  setIsLoaded,
  setWorkouts,
  setError,
  getFavorites,
  updateFavorites,
  setFavorites,
} from "../actions/workouts";

import { setPreviewWorkouts } from "../actions/history";

import { clearAuth } from "../actions/auth";

import PageLoader from "../components/common/PageLoader";
import Workouts from "../components/pages/Workouts";
import ErrorPage from "../components/pages/Error";
import { withRouter } from "react-router-dom";

const WorkoutsPage = ({
  isLoaded,
  historyCalories,
  historyList,
  workouts,
  error,
  getPageData,
  match,
  lifestyle,
  favoritesIsLoaded,
  getFavorites,
  favorites,
  favoritesCount,
  favoritesIsFetching,
  updateFavorites,
}) => {
  const section = match.path;

  useEffect(() => {
    if (!isLoaded) {
      getPageData();
    }
  }, [getPageData, isLoaded]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (!isLoaded) {
    return <PageLoader />;
  }

  return (
    <>
      <Helmet>
        <title>
          {section === "/workouts" ? "Workouts" : "Lifestyle"} - videos by{" "}
          {themeConfig.title}
        </title>
      </Helmet>
      <Workouts
        historyCalories={historyCalories}
        historyList={historyList}
        lastWorkout={historyList[0]}
        workouts={section === "/workouts" ? workouts : lifestyle}
        section={section}
        favoritesIsLoaded={favoritesIsLoaded}
        getFavorites={getFavorites}
        favorites={favorites}
        favoritesCount={favoritesCount}
        favoritesIsFetching={favoritesIsFetching}
        updateFavorites={updateFavorites}
      />
    </>
  );
};

export default connect(
  ({ workouts, history }) => ({
    isLoaded: workouts.isLoaded,
    historyCalories: history.previewWorkoutsCalories,
    historyList: history.previewWorkoutsList,
    workouts: workouts.workouts,
    error: workouts.error,
    lifestyle: workouts.lifestyle,
    favoritesIsLoaded: workouts.favoritesIsLoaded,
    favorites: workouts.favorites,
    favoritesCount: workouts.favoritesCount,
    favoritesIsFetching: workouts.favoritesIsFetching,
  }),
  (dispatch) => ({
    getFavorites: () => dispatch(getFavorites()),
    updateFavorites: () => dispatch(updateFavorites()),
    getPageData: () => {
      return Axios.all([
        contentAPI.getFinishedWorkoutPlan(0, 0),
        contentAPI.getActualWorkouts(),
        contentAPI.getDistinctFinishedWorkoutPlan(),
        contentAPI.getWorkoutsFavorites(0, 8),
      ])
        .then(
          Axios.spread((history, workouts, historyList, favorites) => {
            if (history) {
              dispatch(
                setPreviewWorkouts(
                  historyList.data,
                  history.data.count,
                  history.data.totalCalories
                )
              );
            }
            dispatch(setWorkouts(workouts.data.categories));
            dispatch(setIsLoaded());
            dispatch(setFavorites(favorites.data.count, favorites.data.data));
          })
        )
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
          if (error.response) {
            dispatch(setError(error.response.status));
          } else {
            dispatch(setError("error"));
          }
        });
    },
  })
)(withRouter(WorkoutsPage));
