import React from "react";
import clsx from "clsx";

import classes from "./Input.module.sass";

const Input = ({ helper, className, field, form, ...props }) => {
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  return (
    <div
      className={clsx(classes.wrap, {
        [className]: className,
      })}
    >
      <input
        className={clsx(classes.input, {
          [classes["error"]]: error && touched,
        })}
        {...field}
        {...props}
      />
      {helper && <span className={classes.helper}>{helper}</span>}
      {error && touched && <span className={classes.errorHelper}>{error}</span>}
    </div>
  );
};

export default Input;
