import React, { useState } from 'react';
import clsx from 'clsx';

import IconSprite from '../IconSprite';

import './ImageCreator.sass';

const checkPath = (path) => {
  if (!path) return null;
  const checkHttp = path.indexOf("http");
  const checkDefault = path.indexOf("//");

  if (checkHttp === 0 || checkDefault === 0) {
    return path;
  };

  return `${process.env.REACT_APP_API_DOMAIN}${path}`;
};

const ImageCreator = ({
  className,
  path,
  title,
  icon = "workouts"
}) => {
  const [isError, setError] = useState(false);
  const resultPath = checkPath(path);
  return (
    <div className={clsx({
      [className]: className
    })}>
      {(path && !isError) ? (
        <img
          className="image-creator-pic"
          src={resultPath}
          alt={title}
          onError={() => {
            setError(true);
          }}
        />
      ) : (
          <div className="image-creator-error">
            <IconSprite className={`icon-${icon}`} name={icon} />
          </div>
        )}
    </div>
  );
};

export default ImageCreator;
