import React from 'react';

import SectionDesc from '../../../../common/SectionDesc';
import ImageCreator from '../../../../common/ImageCreator';

import './lastWorkout.sass';
import { NavLink } from 'react-router-dom';

const LastWorkout = ({
  className,
  workout,
  calories
}) => {

  const classes = `last-workout ${className ? className : ''}`;

  return (
    <div className={classes}>
      {workout ? (
        <div className="last-workout-group">
          <NavLink to={`/workouts/${workout.id}`} className="last-workout-preview">
            <ImageCreator
              path={workout.preview}
              className="last-workout-preview__pic"
              alt={workout.title}
              icon="workouts"
            />
            <div className="last-workout-preview__body">
              <div className="last-workout-preview__title">Last workout</div>
              <div className="last-workout-preview__name">{workout.title}</div>
            </div>
          </NavLink>
          <NavLink to="/history" className="last-workout-calories">
            <div className="last-workout-calories__title"><span>Total </span>Calories burned</div>
            <div className="last-workout-calories__value">{calories}</div>
          </NavLink>
        </div>
      ) : (<SectionDesc className="last-workout__section-desc">
        Select workout plan or recovery workout and start your daily workout goals
      </SectionDesc>
        )}
    </div>
  );
};

export default LastWorkout;
