import React from "react";

import IconSprite from "../../common/IconSprite";
import Meal from "./components/Meal";
import MealsList from "./components/MealsList";
import "./MealPlan.sass";

const MealPlan = ({
  handleModalClose,
  meal,
  handleModalRecipeReplaceListOpen,
  isFetchingList,
  openList,
  filtersList,
  handleFilterSelectChange,
  mealName,
  handleSearchMealNameChange,
  mealsList,
  mealsListCount,
  isFetching,
  handleLoadMore,
  openPreview,
  handleOpenPreview,
  handleClosePreview,
  mealPreview,
  isLoadedMealPreview,
  openConfirmModal,
  handleReplaceFromList,
  statuses,
  withChange,
}) => {
  return (
    <div className="full-modal-wrap">
      <div className="full-modal-bg" onClick={handleModalClose}></div>
      <div className="full-modal">
        <button className="full-modal__close" onClick={handleModalClose}>
          <IconSprite className="icon-close" name="close" />
        </button>
        <div className="container">
          <div className="full-modal-inner">
            {openList !== "static" && (
              <Meal
                meal={meal}
                handleModalClose={handleModalClose}
                handleModalRecipeReplaceListOpen={
                  handleModalRecipeReplaceListOpen
                }
                openList={openList}
                nameButton="Replace Recipe"
                statuses={statuses}
                withChange={withChange}
              />
            )}
            {openList && (
              <MealsList
                type={openList}
                isFetchingList={isFetchingList}
                filtersList={filtersList}
                handleFilterSelectChange={handleFilterSelectChange}
                mealName={mealName}
                handleSearchMealNameChange={handleSearchMealNameChange}
                mealsList={mealsList}
                mealsListCount={mealsListCount}
                isFetching={isFetching}
                handleLoadMore={handleLoadMore}
                handleOpenPreview={handleOpenPreview}
                handleReplaceFromList={handleReplaceFromList}
                statuses={statuses}
                withChange={withChange}
              />
            )}
            {openPreview && (
              <Meal
                fixed
                meal={mealPreview}
                isFetching={!isLoadedMealPreview}
                handleModalClose={handleClosePreview}
                handleModalRecipeReplaceListOpen={openConfirmModal}
                openList={openList}
                nameButton="Select Recipe"
                statuses={statuses}
                withChange={withChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MealPlan;
