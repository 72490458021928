import React from "react";
import clsx from "clsx";

import IconSprite from "../IconSprite";

import "./filterSelect.sass";

const FilterSelect = ({
  label,
  defaultValue,
  options,
  name,
  handleChange = null,
  color,
}) => {
  const classes = clsx("select-wrap", {
    ["select-wrap--" + color]: color,
  });

  return (
    <div className={classes}>
      <label className="select-label">{label}</label>
      <div className="select">
        <select
          defaultValue={defaultValue}
          onChange={(event) => {
            handleChange(event.target.value, name);
          }}
        >
          {options.map((option, index) => {
            if (typeof option === "string") {
              return (
                <option key={index} value={option}>
                  {option}
                </option>
              );
            } else {
              return (
                <option key={index} value={option.value}>
                  {option.title}
                </option>
              );
            }
          })}
        </select>
        <IconSprite className="angle-bottom" name="angle-bottom" />
      </div>
    </div>
  );
};

export default FilterSelect;
