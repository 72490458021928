import React from "react";
import { Field } from "formik";
import * as Yup from "yup";

import Radio from "../Radio";
import Wrapper from "../Wrapper";

import classes from "./Food.module.sass";

const ValidatorSchema = Yup.object().shape({
  protein: Yup.string().required(),
  fish: Yup.string().required(),
  dairy: Yup.string().required(),
});

const Food = ({ config, answers, diet, onNext, onPrev }) => {
  const handleNext = (values) => {
    onNext({ food: values });
  };

  const questionProteinHidden = config[0].hideWithDiet === diet;
  const answerProteinNegative = config[0].answers[1].value;

  return (
    <Wrapper
      initialValues={{
        protein: String(
          questionProteinHidden ? answerProteinNegative : answers?.protein || ""
        ),
        fish: String(answers?.fish || ""),
        dairy: String(answers?.dairy || ""),
      }}
      schema={ValidatorSchema}
      onPrev={onPrev}
      onNext={handleNext}
    >
      <div className={classes.wrap}>
        {config.map(({ id, question, name, answers, hideWithDiet }) => {
          if (hideWithDiet === diet) {
            return null;
          }
          return (
            <div key={id} className={classes.item}>
              <div className={classes.question}>{question}</div>
              <div className={classes.answers}>
                {answers.map(({ id, value, title }) => (
                  <div key={id} className={classes.answer}>
                    <Field
                      type="radio"
                      className={classes.input}
                      name={name}
                      value={value}
                      title={title}
                      component={Radio}
                    />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default Food;
