import React from 'react';

import FilterSelectList from '../../common/FilterSelectList';
import FilterTabs from '../../common/FilterTabs';

import RecipesList from './components/RecipesList';
import RecipesFavoritesList from './components/RecipesFavoritesList';

import './Recipes.sass';

const Recipes = ({
  filtersList,
  handleFilterSelectChange,
  list,
  handleLike,
  recipesCurrentStart,
  recipesCount,
  recipesIsFetching,
  handleRecipesLoadMore,
  filterTabListActive,
  filterTabs,
  filterTabActive,
  handleFilterTabClick,
  favoriteRecipesList,
  handleDeleteLike,
  favoritesCurrentStart,
  favoritesCount,
  handleFavoritesLoadMore
}) => {

  return (
    <div className="content-page recipes-page">
      <div className="container">
        <div className="recipes">
          <div className="recipes-head">
            <FilterTabs
              filterTabs={filterTabs}
              filterActive={filterTabActive}
              handleClick={handleFilterTabClick}
              size="large"
              className="recipes-head__tabs"
            />
            <FilterSelectList
              className="recipes-head__selects"
              list={filtersList}
              handleChange={handleFilterSelectChange}
            />
          </div>
          {filterTabListActive === 'recipes' ?
            <RecipesList
              list={list}
              handleLike={handleLike}
              recipesCurrentStart={recipesCurrentStart}
              recipesCount={recipesCount}
              recipesIsFetching={recipesIsFetching}
              handleRecipesLoadMore={handleRecipesLoadMore}
              handleDeleteLike={handleDeleteLike}
            /> : <RecipesFavoritesList
              list={favoriteRecipesList}
              handleDeleteLike={handleDeleteLike}
              favoritesCurrentStart={favoritesCurrentStart}
              favoritesCount={favoritesCount}
              handleFavoritesLoadMore={handleFavoritesLoadMore}
              recipesIsFetching={recipesIsFetching}
            />}
        </div>
      </div>
    </div>
  );
};

export default Recipes;