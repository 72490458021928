import {
  ADD_IS_LOADED,
  ADD_WORKOUTS,
  ADD_CATEGORY_WORKOUTS,
  ADD_ACTIVE_CATEGORY,
  ADD_RECOMMENDED_WORKOUTS,
  ADD_QUOTE
} from '../constants/homeTypes';

const initialState = {
  isLoaded: false,
  workouts: [],
  categoryWorkouts: [],
  categories: [
    { title: 'All Categories', value: '&category_ncontains=weight_loss', name: 'all' },
    { title: 'Self Defence', value: '&category=self_defence', name: 'self_defence' },
    { title: 'Boxing', value: '&category=boxing', name: 'boxing' },
    { title: 'Running', value: '&category=running', name: 'running' },
    { title: 'Hip Hop', value: '&category=hip_hop', name: 'hip_hop' },
    { title: 'Meditations', value: '&category=meditations', name: 'meditations' },
    { title: 'Yoga', value: '&category=yoga', name: 'yoga' },
    { title: 'Barre', value: '&category=barre', name: 'barre' },
    { title: 'Boxercise', value: '&category=boxercise', name: 'boxercise' },
    { title: 'Dance Fitness', value: '&category=dance_fitness', name: 'dance_fitness' },
    { title: 'Pilates', value: '&category=pilates', name: 'pilates' },
    { title: 'Stretching', value: '&category=stretching', name: 'stretching' },
  ],
  categoryActive: 'all',
  recommendedWorkouts: [],
  quote: null,
};

const home = (state = initialState, action) => {

  switch (action.type) {
    case ADD_QUOTE: {
      return {
        ...state,
        quote: action.quote
      }
    }
    case ADD_IS_LOADED: {
      return {
        ...state,
        isLoaded: true
      }
    }
    case ADD_WORKOUTS: {
      return {
        ...state,
        workouts: [...action.workouts]
      }
    }
    case ADD_RECOMMENDED_WORKOUTS: {
      return {
        ...state,
        recommendedWorkouts: [...action.workouts]
      }
    }
    case ADD_CATEGORY_WORKOUTS: {
      return {
        ...state,
        categoryWorkouts: [...action.workouts]
      }
    }
    case ADD_ACTIVE_CATEGORY: {
      return {
        ...state,
        categoryActive: action.category
      }
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState
      }
    }
    default:
      return state;
  }
}

export default home;
