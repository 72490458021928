import React from "react";
import clsx from "clsx";

import sprite from "./sprite.svg";

const Icons = ({ name, className }) => {
  return (
    <svg
      className={clsx({
        [className]: className,
      })}
    >
      <use xlinkHref={`${sprite}#${name}`}></use>
    </svg>
  );
};

export default Icons;
