import React from "react";
import clsx from "clsx";

import "./title.sass";

const Title = ({ children, className, size, ...props }) => {
  return (
    <div
      className={clsx({
        "title-medium": size === "medium",
        title: size !== "medium",
        [className]: className,
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export default Title;
