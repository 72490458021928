export const createValidator = (yup) => {
  return (min, max) => {
    const errorMessage = `Please enter a value between ${min} and ${max}`;
    return yup
      .number()
      .required("Field is required")
      .positive()
      .integer()
      .min(min, errorMessage)
      .max(max, errorMessage);
  };
};
