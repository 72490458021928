import {
  SET_FETCHING_STATUS,
  SET_AUTH_DATA,
  CLEAR_AUTH_DATA,
  ADD_SUBSCRIBE_STATUS,
  ADD_APP_FIELDS,
  ADD_LAST_VISIT,
  SET_REFERRAL_MODAL,
} from "../constants/authTypes";

const initialState = {
  token: null,
  userId: null,
  userName: null,
  isAuth: false,
  isFetching: false,
  statuses: {},
  company: null,
  subscribes: [],
  isSignUp: false,
  source: "backend",
  lastVisit: null,
  passedLongTime: false,
  daysSince: 0,
  isPollPassed: true,
  referralModalOpen: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_REFERRAL_MODAL: {
      return {
        ...state,
        referralModalOpen: action.payload,
      };
    }
    case ADD_LAST_VISIT: {
      return {
        ...state,
        lastVisit: action.lastVisit,
        passedLongTime: action.passedLongTime,
        daysSince: action.daysSince,
      };
    }
    case ADD_APP_FIELDS: {
      return {
        ...state,
        source: action.source ? action.source : state.source,
      };
    }
    case SET_FETCHING_STATUS: {
      return { ...state, isFetching: action.isFetching };
    }
    case SET_AUTH_DATA: {
      return {
        ...state,
        token: action.token,
        userId: action.userId,
        userName: action.userName,
        isAuth: action.isAuth,
        isFetching: action.isFetching,
        isSignUp: action.isSignUp,
      };
    }
    case ADD_SUBSCRIBE_STATUS: {
      return {
        ...state,
        statuses: { ...action.statuses },
        company: action.company,
        subscribes: [...action.subscribes],
        isPollPassed: action.isPollPassed,
      };
    }
    case CLEAR_AUTH_DATA: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default auth;
