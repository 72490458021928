import React from "react";

import IconSprite from "../../../../../../common/IconSprite";

import "./Product.sass";

const Product = ({
  purchaseDescription,
  purchaseName,
  price,
  oldPrice,
  type,
  onChange,
  isChecked,
}) => {
  return (
    <label className="plan-cart-product">
      <input type="checkbox" onChange={onChange} checked={isChecked} />
      <div className="plan-cart-product__body">
        <div className="plan-cart-product__icon">
          <IconSprite className="cart-product-icon" name="checkbox" />
        </div>
        <img
          className="plan-cart-product__pic"
          src={require(`../../../../../../../assets/images/content/meal-plan/cart/${type}.jpg`)}
          alt="name of cart product"
        />
        <div className="plan-cart-product__info">
          <div className="plan-cart-product__desc">
            <div className="plan-cart-product__title">{purchaseName}</div>
            <div className="plan-cart-product__text">{purchaseDescription}</div>
          </div>
          <div className="plan-cart-product__prices">
            <span>${oldPrice}</span>${price}
          </div>
        </div>
      </div>
    </label>
  );
};

export default Product;
