import {
  ADD_MODAL_IS_OPEN,
  ADD_IS_LOADING,
  ADD_IS_DONE,
  CLEAR_DATA,
  ADD_ERROR,
} from "../constants/subscriptions";

const initialState = {
  isOpen: false,
  isLoading: false,
  isDone: false,
  isError: false,
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DATA: {
      return {
        ...initialState,
      };
    }
    case ADD_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    }
    case ADD_IS_DONE: {
      return {
        ...state,
        isDone: true,
      };
    }
    case ADD_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case ADD_MODAL_IS_OPEN: {
      return {
        ...state,
        isOpen: action.isOpen,
      };
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default subscriptions;
