import React from "react";

import IconsSprite from "../../../../common/IconSprite";

import "./Head.sass";

const Head = ({ isShow }) => {
  if (!isShow) {
    return null;
  }
  return (
    <div className="subs-modal-head">
      <IconsSprite className="icon-lock-effect" name="lock-effect" />
    </div>
  );
};

export default Head;
