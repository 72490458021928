import React from 'react';

import './circleProgress.sass'

const CircleProgress = ({
  goal,
  finished
}) => {

  const RADIUS = 54;
  const CIRCUMFERENCE = (2 * Math.PI * RADIUS);

  let progress = (finished * 100) / goal;
  if (progress > 95 && progress < 100) {
    progress = 95;
  } else if (progress > 100) {
    progress = 100;
  }

  const offset = CIRCUMFERENCE * (1 - (progress / 100));

  return (
    <div className="circle-progress">
      <svg className="circle-progress__icon" width="60" height="60" viewBox="0 0 120 120">
        <circle className="circle-progress__meter" cx="60" cy="60" r={RADIUS} strokeWidth="12" />
        <circle
          className="circle-progress__value"
          cx="60" cy="60"
          r={RADIUS} strokeWidth="12"
          strokeDasharray={CIRCUMFERENCE}
          strokeDashoffset={offset}
        />
      </svg>
      <div className="circle-progress__nums">{finished}</div>
    </div>
  );
};

export default CircleProgress;