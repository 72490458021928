export const SET_TOGGLE_FETCHING = 'MEALS:SET_TOGGLE_FETCHING';
export const SET_MEALS_LIST = 'MEALS:SET_MEALS_LIST';
export const REPLACE_MEALS_LIST = 'MEALS:REPLACE_MEALS_LIST';
export const SET_MEALS_INFO = 'MEALS:SET_MEALS_INFO';
export const CLEAR_MEALS_LIST = 'MEALS:CLEAR_MEALS_LIST';
export const SET_MEAL_NAME = 'MEALS:SET_MEAL_NAME';
export const SET_MEAL_LEVEL = 'MEALS:SET_MEAL_LEVEL';
export const SET_MEAL_TYPE = 'MEALS:SET_MEAL_TYPE';
export const SET_MEAL_TIME = 'MEALS:SET_MEAL_TIME';
export const SET_FIRST_LOADED_MEAL = 'MEALS:SET_FIRST_LOADED_MEAL';
export const CLEAR_MEAL_FILTERS = 'MEALS:CLEAR_FILTERS';
export const SET_PREVIEW_LOADING = 'MEALS:SET_PREVIEW_LOADING';
export const SET_MEAL_PREVIEW = 'MEALS:SET_MEAL_PREVIEW';
export const CLEAR_MEAL_PREVIEW = 'MEALS:CLEAR_MEAL_PREVIEW';