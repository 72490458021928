import {
  SET_TOGGLE_FETCHING,
  SET_WORKOUT_PLAN_DATA,
  TOGGLE_EXERCISE,
  SET_IS_LOADED,
  CLEAR_WORKOUT_PLAN,
  ADD_ERROR,
  ADD_SIMILAR_PLANS,
  ADD_SIMILAR_PLANS_LOADING
} from '../constants/workoutPlanTypes';

export const setSimilarWorkouts = (workouts) => {
  return {
    type: ADD_SIMILAR_PLANS,
    workouts
  }
};

export const setSimilarWorkoutsLoading = () => {
  return {
    type: ADD_SIMILAR_PLANS_LOADING
  };
};

export const setToggleFetching = (isFetching) => {
  return {
    type: SET_TOGGLE_FETCHING,
    isFetching
  };
};

export const setIsLoaded = () => {
  return {
    type: SET_IS_LOADED
  };
};

export const clearWorkoutPlan = () => {
  return {
    type: CLEAR_WORKOUT_PLAN
  };
};

export const setWorkoutPlanData = (data) => {
  return {
    type: SET_WORKOUT_PLAN_DATA,
    data
  };
};

export const toggleExercise = (setId, exerciseId) => {
  return {
    type: TOGGLE_EXERCISE,
    setId,
    exerciseId
  };
};

export const addError = (error) => {
  return {
    type: ADD_ERROR,
    error
  };
};

