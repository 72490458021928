import React from "react";
import { connect } from "react-redux";
import themeConfig from "../configs/theme";

import MicroCommitments from "../components/modals/MicroCommitments";

const MicroCommitmentsContainer = ({ isSignUp, isPollPassed, ...props }) => {
  const isOpen = isPollPassed && isSignUp;

  return (
    <>
      {themeConfig.type === "fitculator" && (
        <MicroCommitments isOpen={isOpen} {...props} />
      )}
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  isSignUp: auth.isSignUp,
  isPollPassed: auth.isPollPassed,
});

export default connect(mapStateToProps)(MicroCommitmentsContainer);
