import React, { useState } from 'react';
import clsx from 'clsx';

import IconSprite from '../IconSprite';

import './UserAvatar.sass';

const UserAvatar = ({
  avatarPath,
  firstLetter,
  className,
  withEdit,
  handleEdit
}) => {
  const [tempAvatar, setTempAvatar] = useState(null);
  const onChangeAvatar = (event) => {
    const file = event.target.files[0];
    if (handleEdit) {
      handleEdit(file);
    }

    setTempAvatar(URL.createObjectURL(file))
  }

  const avatar = (
    <img src={tempAvatar || avatarPath} alt="" className="user-avatar__pic" />
  );

  const avatarEmpty = (
    <div className="user-avatar__empty">{firstLetter}</div>
  );

  return (
    <div className={clsx("user-avatar", {
      [className]: className
    })}>
      {(avatarPath || tempAvatar) ? avatar : avatarEmpty}
      {!withEdit || (
        <label className="circle-edit-btn user-avatar__edit">
          <IconSprite className="icon-pencil" name="pencil" />
          <input type="file" hidden accept="image/*" onChange={onChangeAvatar} />
        </label>
      )}
    </div>
  );
};

export default UserAvatar;
