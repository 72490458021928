import React, { useState, useEffect } from 'react';

import IconSprite from '../../../../common/IconSprite';

import "./Finish.sass";

const Finish = ({
  workoutPlanName,
  audioUpdatePlay
}) => {

  const [isPlayAudio, setIsPlayAudio] = useState(false);

  useEffect(() => {
    if (!isPlayAudio) {
      audioUpdatePlay("You checked this one off the list. Good job!");
      setIsPlayAudio(true);
    }
  }, [audioUpdatePlay, isPlayAudio]);

  return (
    <div className="workout-play-finish">
      <div className="workout-play-finish__head">
        <div className="workout-play-finish__name">{workoutPlanName}</div>
        <div className="workout-play-finish__title">You did it!</div>
        <div className="workout-play-finish__subtitle">You go to the goal</div>
        <IconSprite className="icon-finish" name="timer-finish" />
      </div>
    </div>
  );
};

export default Finish;