import axios from "axios";
import { authAPI, contentAPI } from "../api";
import amplitude from "amplitude-js";

import {
  SET_BASE_USER_DATA,
  SET_IS_APP_LOADED,
  SET_IS_LOADED,
  SET_ALL_USER_DATA,
  CLEAR_USER_DATA,
  SET_GOAL_WEIGHT,
  SET_IS_FETCHING,
  SET_TEMP_AVATAR,
  SET_AVATAR,
  ADD_ACTUAL_WEIGHT,
  ADD_RESULTS_DATA,
  ADD_CANCEL_RESULT,
  ADD_MC_LOADED,
  UPDATE_USER_DATA,
  SET_HAS_INVITED_STATUS,
} from "../constants/profileTypes";

export const setHasInvited = (payload) => ({
  type: SET_HAS_INVITED_STATUS,
  payload: payload,
});

export const updateUserData = (payload) => ({
  type: UPDATE_USER_DATA,
  payload,
});

export const sendMicroCommitments = (data) => {
  return (dispatch) => {
    dispatch(updateUserData(data));
    return authAPI.updateProfile(data);
  };
};

export const setMicroCommitmentsLoad = (diets, goals) => ({
  type: ADD_MC_LOADED,
  payload: {
    diets,
    goals,
  },
});

export const microCommitmentsLoad = () => {
  return (dispatch, getState) => {
    return axios
      .all([contentAPI.getDietTypes(), contentAPI.getWorkoutsGoal()])
      .then(
        axios.spread((dietTypes, workoutGoals) => {
          const diets = dietTypes.data
            .map(({ name, id, type }) => {
              if (type === "intermittent-fasting") {
                return null;
              }
              return {
                id,
                title: name,
              };
            })
            .filter((diet) => diet);
          const goals = workoutGoals.data.map(({ id, title }) => ({
            id,
            title,
          }));
          dispatch(setMicroCommitmentsLoad(diets, goals));
        })
      );
  };
};

const getSubscribes = (subscribes) => {
  const result = {};

  subscribes.forEach((item) => {
    if (item?.product?.type === "workouts") {
      result.workouts = item.status;
    } else if (item?.product?.type === "recipes") {
      result.recipes = item.status;
    } else if (item?.product?.type === "workouts_recipes") {
      result.workouts = item.status;
      result.recipes = item.status;
    }
  });
  return result;
};

const getWorkoutsSerial = (subscribes) => {
  const defaultSerial = "none";

  for (let i = 0; i < subscribes.length; i++) {
    if (
      subscribes[i]?.product?.type === "workouts" ||
      subscribes[i]?.product?.type === "workouts_recipes"
    ) {
      const serial = subscribes[i]?.serial_num;
      return typeof serial === "number" && serial >= 0 ? serial : defaultSerial;
    }
  }
  return defaultSerial;
};

const sendCancelEventToAmplitude = (type, subscribes) => {
  const subs = getSubscribes(subscribes);
  const keys = Object.keys(subs);
  if (!keys.length) {
    return false;
  }

  if (type === "all") {
    keys.map((item) => (subs[item] = "canceled"));
  } else {
    subs[type] = "canceled";
  }
  const eventData = {
    subscriptions: keys.join(", "),
    subscriptions_status: keys.map((key) => subs[key]).join(", "),
  };
  amplitude.getInstance().logEvent("cancel_subscription", eventData);
};

export const sendOffer = (offer) => {
  return (dispatch, getState) => {
    const { cancelResult } = getState().profile;
    const { subscribes, source } = getState().auth;
    const serial = getWorkoutsSerial(subscribes);

    const data = { reason: cancelResult.reason, offer };
    if (Object.keys(cancelResult.subscribes).length === 2) {
      data.type = "all";
    } else {
      data.type = Object.keys(cancelResult.subscribes)[0];
    }
    data.id = Object.keys(cancelResult.subscribes).map((item) => {
      return cancelResult.subscribes[item].id;
    });

    amplitude
      .getInstance()
      .logEvent("cancel_offer", { ...data, source, serial });

    if (offer === "none") {
      sendCancelEventToAmplitude(data.type, subscribes);
    }

    return authAPI.cancel(data).then((res) => {
      amplitude.getInstance().logEvent("user_properties", { offer });
      return res;
    });
  };
};

export const updateCancelResult = (fieldType, payload) => {
  return {
    type: ADD_CANCEL_RESULT,
    fieldType,
    payload,
  };
};

export const setResultsData = (age, activity, gender) => {
  return {
    type: ADD_RESULTS_DATA,
    age,
    activity,
    gender,
  };
};

export const setIsAppLoaded = () => {
  return {
    type: SET_IS_APP_LOADED,
  };
};

export const setIsFetching = (isFetching) => {
  return {
    type: SET_IS_FETCHING,
    isFetching,
  };
};

export const setIsLoaded = () => {
  return {
    type: SET_IS_LOADED,
  };
};

export const clearUserData = () => {
  return {
    type: CLEAR_USER_DATA,
  };
};

export const setTempAvatar = (tempAvatar) => {
  return {
    type: SET_TEMP_AVATAR,
    tempAvatar,
  };
};

export const setAvatar = (avatar) => {
  return {
    type: SET_AVATAR,
    avatar,
  };
};

export const setGoalWeight = (goalWeight) => {
  return {
    type: SET_GOAL_WEIGHT,
    goalWeight,
  };
};

export const setAllUserData = (
  userId,
  userName,
  confirmed,
  height,
  currentWeight,
  goalWeight,
  measurement,
  language,
  email,
  timezone,
  profileCreatedDate,
  mealPlanTitle,
  subscribeCancelSent
) => {
  let trueHeight = height && height.indexOf("undefined") !== -1 ? null : height;
  return {
    type: SET_ALL_USER_DATA,
    userId,
    userName,
    confirmed,
    height: trueHeight,
    currentWeight,
    goalWeight,
    measurement,
    language,
    email,
    timezone,
    profileCreatedDate,
    mealPlanTitle,
    subscribeCancelSent,
  };
};

export const setBaseUserData = (
  userId,
  userName,
  confirmed,
  profileCreatedDate,
  currentWeight,
  measurement,
  email,
  actualWeight,
  goalWeight,
  height,
  age,
  countryCode
) => {
  let trueHeight = height && height.indexOf("undefined") !== -1 ? null : height;
  let defaultUnits =
    countryCode === "US" || countryCode === "CA" ? "imperial" : "metric";

  return {
    type: SET_BASE_USER_DATA,
    userId,
    userName,
    confirmed,
    profileCreatedDate,
    currentWeight,
    measurement,
    email,
    actualWeight,
    goalWeight,
    height: trueHeight,
    age,
    defaultUnits,
  };
};

export const setActualWeight = (actualWeight) => {
  return {
    type: ADD_ACTUAL_WEIGHT,
    actualWeight,
  };
};
