import {
  SET_TOGGLE_FETCHING,
  SET_MEALS_LIST,
  SET_MEALS_INFO,
  CLEAR_MEALS_LIST,
  SET_MEAL_NAME,
  SET_MEAL_LEVEL,
  REPLACE_MEALS_LIST,
  SET_MEAL_TYPE,
  SET_MEAL_TIME,
  CLEAR_MEAL_FILTERS,
  SET_FIRST_LOADED_MEAL,
  SET_PREVIEW_LOADING,
  SET_MEAL_PREVIEW,
  CLEAR_MEAL_PREVIEW,
} from '../constants/mealsTypes';

const initialState = {
  list: [],
  listCount: null,
  currentStart: null,
  isFetching: false,
  mealName: '',
  mealLevel: null,
  mealType: null,
  mealTime: null,
  firstLoaded: false,
  previewIsLoading: false,
  mealPreview: null
};

const meals = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOGGLE_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching
      }
    }
    case SET_MEALS_LIST: {
      return {
        ...state,
        list: [...state.list, ...action.list]
      }
    }
    case REPLACE_MEALS_LIST: {
      return {
        ...state,
        list: [...action.list]
      }
    }
    case SET_MEALS_INFO: {
      return {
        ...state,
        ...action.data
      }
    }
    case SET_MEAL_NAME: {
      return {
        ...state,
        mealName: action.mealName
      }
    }
    case SET_MEAL_LEVEL: {
      return {
        ...state,
        mealLevel: action.mealLevel
      }
    }
    case SET_MEAL_TYPE: {
      return {
        ...state,
        mealType: action.mealType
      }
    }
    case SET_MEAL_TIME: {
      return {
        ...state,
        mealTime: action.mealTime
      }
    }
    case CLEAR_MEALS_LIST: {
      return {
        ...state,
        list: []
      }
    }
    case SET_FIRST_LOADED_MEAL: {
      return {
        ...state,
        firstLoaded: true
      }
    }
    case CLEAR_MEAL_FILTERS: {
      return {
        ...state,
        mealName: '',
        mealLevel: null,
        mealType: null,
        mealTime: null,
        firstLoaded: false,
      }
    }
    case SET_PREVIEW_LOADING: {
      return {
        ...state,
        previewIsLoading: action.previewIsLoading
      }
    }
    case SET_MEAL_PREVIEW: {
      return {
        ...state,
        mealPreview: action.meal
      }
    }
    case CLEAR_MEAL_PREVIEW: {
      return {
        ...state,
        mealPreview: null
      }
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState
      }
    }
    default:
      return state;
  }
};


export default meals;
