import React, { useState } from "react";
import clsx from "clsx";

import Title from "../Title";
import Button from "../Button";
import Metric from "./Metric";
import Imperial from "./Imperial";

import classes from "./Measurements.module.sass";

const tabs = [
  {
    id: 0,
    name: "metric",
    title: "Metric",
  },
  {
    id: 1,
    name: "imperial",
    title: "Imperial",
  },
];

const Measurements = ({ units, onPrev, onSubmit, answers }) => {
  const [activeTab, setActiveTab] = useState(units);

  const handleClickTab = (tab) => setActiveTab(tab);

  const handleSubmit = (values) => {
    onSubmit({ measurements: { type: activeTab, ...values } });
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.head}>
        <Title className={classes.title}>Measurements</Title>
        <Button
          className={classes.backBtn}
          transparent
          type="button"
          onClick={onPrev}
        >
          Back
        </Button>
      </div>
      <div className={classes.body}>
        <div className={classes.tabs}>
          {tabs.map(({ id, name, title }) => (
            <div
              key={id}
              className={clsx(classes.tab, {
                [classes.activeTab]: activeTab === name,
              })}
              onClick={() => handleClickTab(name)}
            >
              {title}
            </div>
          ))}
        </div>
        {activeTab === "metric" && (
          <Metric answers={answers} onPrev={onPrev} onSubmit={handleSubmit} />
        )}
        {activeTab === "imperial" && (
          <Imperial answers={answers} onPrev={onPrev} onSubmit={handleSubmit} />
        )}
      </div>
    </div>
  );
};

export default Measurements;
