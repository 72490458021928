import React from 'react';

import MealInner from '../../common/MealInner';

import './meal.sass';

const Meal = ({
  meal
}) => {
  return (
    <div className="content-page meal-page">
      <div className="container">
        <div className="meal-page-body">
          <MealInner {...meal} />
        </div>
      </div>
    </div>
  )
};

export default Meal;

