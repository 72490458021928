import Cancel from "./cancel.svg";
import Reason from "./reason.svg";
import Promo from "./promo.svg";
import Thanks from "./thanks.svg";
import Sorry from "./sorry.svg";

export default {
  cancel: Cancel,
  reason: Reason,
  promo: Promo,
  thanks: Thanks,
  sorry: Sorry,
};
