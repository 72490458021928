import dayjs from 'dayjs';

import {
  ADD_IS_LOADED,
  ADD_LOG_WEIGHT_LIST,
  ADD_IS_SENDING,
  UPDATE_LOG_WEIGHT_LIST,
  ADD_CURRENT_WEEK_IS_LOGGED
} from '../constants/logWeightTypes';

const initialState = {
  isLoaded: false,
  isSending: false,
  list: [],
  currentWeekIsLogged: false
};

const logWeight = (state = initialState, action) => {
  switch (action.type) {
    case ADD_IS_LOADED: {
      return {
        ...state,
        isLoaded: true
      };
    }
    case ADD_IS_SENDING: {
      return {
        ...state,
        isSending: action.isSending
      };
    }
    case ADD_CURRENT_WEEK_IS_LOGGED: {
      return {
        ...state,
        currentWeekIsLogged: true
      };
    }
    case ADD_LOG_WEIGHT_LIST: {
      return {
        ...state,
        list: [...action.list]
      };
    }
    case UPDATE_LOG_WEIGHT_LIST: {
      const newItem = action.logItem;
      const oldList = [...state.list];
      const idx = oldList.findIndex(item =>
        dayjs(newItem.dateNative).startOf('day').format() === dayjs(item.dateNative).startOf('day').format());
      let result = [];

      if (idx === -1) {
        result = [newItem, ...oldList];
      } else {
        result = [newItem, ...oldList.slice(idx + 1)];
      }

      return {
        ...state,
        list: [...result]
      };
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState
      }
    }
    default:
      return state;
  }
};


export default logWeight;
