import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import WeekPicker from "../../common/WeekPicker";
import Title from "../../common/Title";
import DailyList from "./components/DailyList";
import Dropdown from "./components/Dropdown";
import PlanCartBody from "./components/PlanCartBody";
import PlanCartHead from "./components/PlanCartHead";
import PlanCartFooter from "./components/PlanCartFooter";
import IconSprite from "../../common/IconSprite";
import { purchaseConfig } from "../../../configs/mealPlans";

import { ModalRight } from "../../../common/components";

import "./MealPlan.sass";

const MealPlan = ({
  mealPlanDateCreated,
  daysCount,
  activeMeal,
  meals,
  setActiveMeal,
  setSelectedMealsDay,
  handleModalRecipeOpen,
  handleOpenModalList,
  handleMealDone,
  handleHelperClose,
  statuses,
  handleMealUnDo,
  userEmail,
  listPlans,
  activePlan,
  handleMealPlanChange,
  withChange,
  isOpenPurchaseModal,
  handlePurchaseModalClose,
  handlePurchaseModalOpen,
  notPurchasedMealPlans,
  handleSendPurchase,
  handleReplaceMealPlan,
  isPurchasing,
  isPurchasedDone,
  typeToPurchase,
  listPlansReadyToDefault,
  handleReplaceMealPlanFromModal,
  clearPurchaseModalData,
  isPurchasedError,
  passedLongTime,
}) => {
  const [isCurrentDay, setIsCurrentDay] = useState(true);
  const [productsForPurchase, setProductsForPurchase] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalOldPrice, setTotalOldPrice] = useState(0);
  const [dietToReplace, setDietToReplace] = useState(null);

  useEffect(() => {
    if (typeToPurchase && purchaseConfig[typeToPurchase]) {
      setProductsForPurchase([
        typeToPurchase[0].toUpperCase() + typeToPurchase.slice(1),
      ]);
      setTotalPrice(purchaseConfig[typeToPurchase].price);
      setTotalOldPrice(purchaseConfig[typeToPurchase].oldPrice);
    }
  }, [typeToPurchase, setProductsForPurchase, setTotalPrice, setTotalOldPrice]);

  const handleChangeProductsForPurchase = (product, price, oldPrice) => {
    let newProductsArr = [...productsForPurchase];
    const idx = productsForPurchase.findIndex((item) => item === product);
    if (idx === -1) {
      newProductsArr.push(product);
      setTotalPrice(totalPrice + price);
      setTotalOldPrice(totalOldPrice + oldPrice);
    } else {
      newProductsArr = [
        ...productsForPurchase.slice(0, idx),
        ...productsForPurchase.slice(idx + 1),
      ];
      setTotalPrice(totalPrice - price);
      setTotalOldPrice(totalOldPrice - oldPrice);
    }
    setProductsForPurchase([...newProductsArr]);
  };

  let resultDate = mealPlanDateCreated;
  const date1 = dayjs().startOf("day");
  const date2 = dayjs(mealPlanDateCreated);
  const diff = dayjs(date1).diff(date2, "day");
  if (diff < 0) {
    resultDate = date1.format();
  }

  return (
    <>
      <div className="meal-plan-page content-page">
        <div className="container">
          <div className="meal-plan">
            <div className="meal-plan__head">
              <Title>Your meals</Title>
              <div className="meal-plan__nav">
                <Dropdown
                  className="meal-plan__dropdown"
                  list={listPlans}
                  activePlan={activePlan}
                  onChoose={handleMealPlanChange}
                  onUpdate={handlePurchaseModalOpen}
                  notPurchasedMealPlans={notPurchasedMealPlans}
                  handleReplaceMealPlan={handleReplaceMealPlan}
                  passedLongTime={passedLongTime}
                />
                {activePlan.isPurchased ? (
                  <WeekPicker
                    recipesCount={daysCount}
                    dateStartInput={resultDate}
                    activeMeal={activeMeal}
                    setActiveMeal={setActiveMeal}
                    setSelectedMealsDay={setSelectedMealsDay}
                    setIsCurrentDay={setIsCurrentDay}
                    activeId={activePlan.id}
                  />
                ) : (
                  <div className="meal-plan-info-wrap">
                    <div className="meal-plan-info">
                      <IconSprite
                        className="meal-plan-info__icon"
                        name="visible-outline"
                      />
                      <div className="meal-plan-info__body">
                        <div className="meal-plan-info__title">
                          {purchaseConfig[activePlan.type].name}
                        </div>
                        <div className="meal-plan-info__desc">
                          {purchaseConfig[activePlan.type].description}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="meal-plan__recipes">
              <DailyList
                meals={meals}
                activeMeal={activeMeal}
                handleModalRecipeOpen={handleModalRecipeOpen}
                handleOpenModalList={handleOpenModalList}
                handleMealDone={handleMealDone}
                handleMealUnDo={handleMealUnDo}
                userEmail={userEmail}
                handleHelperClose={handleHelperClose}
                isCurrentDay={isCurrentDay}
                statuses={statuses}
                withChange={withChange}
                isPurchased={activePlan.isPurchased}
                isDefault={activePlan.isDefault}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalRight
        isOpen={isOpenPurchaseModal}
        onClose={handlePurchaseModalClose}
        isLoading={isPurchasing}
        onClearData={clearPurchaseModalData}
        head={<PlanCartHead isShow={!isPurchasedDone && !isPurchasedError} />}
        footer={
          <PlanCartFooter
            totalPrice={totalPrice}
            totalOldPrice={totalOldPrice}
            handleSendPurchase={handleSendPurchase}
            productsForPurchase={productsForPurchase}
            isPurchasedDone={isPurchasedDone}
            isPurchasedError={isPurchasedError}
            handlePurchaseModalClose={handlePurchaseModalClose}
            onFinish={() => {
              if (dietToReplace) {
                handleReplaceMealPlanFromModal(dietToReplace);
              }
            }}
          />
        }
      >
        <PlanCartBody
          listCreator={notPurchasedMealPlans}
          onChooseProduct={handleChangeProductsForPurchase}
          isPurchasedDone={isPurchasedDone}
          typeToPurchase={typeToPurchase}
          productsForPurchase={productsForPurchase}
          listPlansReadyToDefault={listPlansReadyToDefault}
          setDietToReplace={setDietToReplace}
          isPurchasedError={isPurchasedError}
        />
      </ModalRight>
    </>
  );
};

export default MealPlan;
