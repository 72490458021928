import React, { useEffect } from "react";
import clsx from "clsx";
import { CSSTransition } from "react-transition-group";

import classes from "./Backdrop.module.sass";
import "./Animations.sass";

const Backdrop = ({ isOpen, theme, children, onClear }) => {
  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    if (isOpen) {
      document.body.style.overflow = "hidden";
      if (scrollbarWidth) {
        document.body.style.paddingRight = scrollbarWidth + "px";
      }
    }
  }, [isOpen]);

  return (
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames="backdrop-transition"
      unmountOnExit
      onExited={() => {
        document.body.style.overflow = "";
        document.body.style.paddingRight = "";
        if (onClear) {
          onClear();
        }
      }}
    >
      <div
        className={clsx(classes.wrap, {
          [classes[theme]]: theme,
        })}
      >
        {children}
      </div>
    </CSSTransition>
  );
};

export default Backdrop;
