export const SET_IS_LOADED = "MEALPLAN:SET_IS_LOADED";
export const SET_DAILY_MEALS = "MEALPLAN:SET_DAILY_MEALS";
export const SET_PLAN_INFO = "MEALPLAN:SET_PLAN_INFO";
export const CLEAR_MEAL_PLAN = "MEALPLAN:CLEAR";

export const SET_TOGGLE_FETCHING = "MEALPLAN:SET_TOGGLE_FETCHING";

export const SET_MEAL = "MEALPLAN:SET_MEAL";
export const SET_MEALS_LIST = "MEALPLAN:SET_MEALS_LIST";
export const RELOAD_MEALS_LIST = "MEALPLAN:RELOAD_MEALS_LIST";
export const SET_IS_FETCHING_LIST = "MEALPLAN:SET_IS_FETCHING_LIST";
export const SET_SELECTED_TYPE = "MEALPLAN:SET_SELECTED_TYPE";
export const CLEAR_MEALS_LIST = "MEALPLAN:CLEAR_MEALS_LIST";
export const SET_MEAL_PREVIEW = "MEALPLAN:SET_MEAL_PREVIEW";
export const CLEAR_MEAL_PREVIEW = "MEALPLAN:CLEAR_MEAL_PREVIEW";
export const SET_IS_REPLACING = "MEALPLAN:SET_IS_REPLACING";
export const SET_NEW_MEAL_ID = "MEALPLAN:SET_NEW_MEAL_ID";

export const SET_NEW_MEAL_TYPE = "MEALPLAN:SET_NEW_MEAL_TYPE";
export const SET_SELECTED_MEALS_DAY = "MEALPLAN:SET_SELECTED_MEALS_DAY";

export const ADD_CURRENT_MEAL_PLAN = "MEALPLAN:ADD_CURRENT_MEAL_PLAN";
export const ADD_ERROR = "MEALPLAN:ADD_ERROR";

export const ADD_LIST_PLANS = "MEALPLAN:ADD_LIST_PLANS";
export const UPDATE_ACTIVE_PLAN = "MEALPLAN:UPDATE_ACTIVE_PLAN";
export const UPDATE_LIST_PLANS = "MEALPLAN:UPDATE_LIST_PLANS";

export const ADD_IS_PURCHASING = "MEALPLAN:ADD_IS_PURCHASING";
export const CLEAR_PURCHASE_MODAL = "MEALPLAN:CLEAR_PURCHASE_MODAL";

export const ADD_IS_REPLACING_MEAL_PLAN = "MEALPLAN:ADD_IS_REPLACING_MEAL_PLAN";
export const ADD_REPLACE_MEAL_PLAN = "MEALPLAN:ADD_REPLACE_MEAL_PLAN";
export const ADD_PURCHASE_ERROR = "MEALPLAN:ADD_PURCHASE_ERROR";

export const ADD_MEALS_FROM_MEAL_PLAN = "MEALPLAN:ADD_MEALS_FROM_MEAL_PLAN";
