export default (subscribes) => {
  if (!subscribes) return false;

  for (let i = 0; i < subscribes.length; i++) {
    const subscribe = subscribes[i];
    if (subscribe.product.code !== 503 && subscribe.status === "active") {
      return true;
    }
  }
  return false;
};
