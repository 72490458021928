import React from "react";
import clsx from "clsx";

import Logo from "../Logo";
import MainNavLink from "../MainNavLink";
import Guard from "../Guard";
import BackButton from "../BackButton";
import MobNav from "../MobNav";

import "./Header.sass";

const Header = ({
  navigationConfig,
  withBackButton,
  company,
  purpleDesign,
  onBack,
  logoDisabled,
}) => {
  return (
    <header
      className={clsx("header", {
        "purple-header": purpleDesign,
      })}
    >
      <div className="container-fluid">
        <div className="header-inner">
          {withBackButton ? (
            <BackButton handleBack={onBack} />
          ) : (
            <Logo
              disabled={logoDisabled}
              whiteVariant={purpleDesign}
              company={company}
            />
          )}

          {navigationConfig && navigationConfig.length ? (
            <>
              <div className="main-nav">
                {navigationConfig.map((navigation) => {
                  if (navigation.guard && navigation.confirmed !== undefined) {
                    return (
                      <Guard
                        key={navigation.label}
                        condition={navigation.confirmed}
                      >
                        <MainNavLink key={navigation.label} {...navigation} />
                      </Guard>
                    );
                  }
                  return <MainNavLink key={navigation.label} {...navigation} />;
                })}
              </div>
              <MobNav navigationConfig={navigationConfig} company={company} />
            </>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default Header;
