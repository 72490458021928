import React from "react";

import Pic404 from "./icons/404.svg";
import PicServerError from "./icons/server-error.svg";
import "./Error.sass";

const Error = ({ error }) => {
  const notFoundBlock = (
    <div className="error-block">
      <img src={Pic404} className="error-block__icon" alt="" />
      <div className="error-block__body">
        <div className="error-block__title">Page not found</div>
        <div className="error-block__description">Try to go back to home</div>
      </div>
    </div>
  );

  const serverErrorBlock = (
    <div className="error-block">
      <img src={PicServerError} className="error-block__icon" alt="" />
      <div className="error-block__body">
        <div className="error-block__title">server error</div>
        <div className="error-block__description">Try to go back to home</div>
      </div>
    </div>
  );

  const notFoundErrors = [404, 400];
  const isNotFound = notFoundErrors.indexOf(+error) !== -1;

  return (
    <div className="error-wrap">
      {isNotFound ? notFoundBlock : serverErrorBlock}
    </div>
  );
};

export default Error;
