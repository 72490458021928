export const purchaseConfig = {
  flexitarian: {
    name: "Flexitarian Meal Plan",
    purchaseName: "Flexitarian Plan",
    description:
      "A vegetarian diet, of grains, fruits, vegetables and nuts; with the flexibility of meat in moderation.",
    purchaseDescription:
      "Enjoy South-European coast cuisine at home with this 60-day meal plan",
    price: 8.99,
    oldPrice: 39.98,
  },
  mediterranean: {
    name: "Mediterranean Meal Plan",
    purchaseName: "Mediterranean Plan",
    description:
      "Plant-based diet inspired by the South-European coast cuisine: whole grains, fruits, vegetables, beans, herbs, seafood and olive oil.",
    purchaseDescription:
      "Enjoy South-European coast cuisine at home with this 60-day meal plan",
    price: 8.99,
    oldPrice: 39.98,
  },
};

const createMeal = (type, imageName, id, time, level, serving, title) => {
  return {
    image: [
      {
        url:
          process.env.REACT_APP_DOMAIN +
          require(`../assets/images/content/meal-plan/previews/${type}/${imageName}`),
      },
    ],
    id: type + "-" + id,
    cooking_time: time,
    level,
    meal_serving: serving,
    title,
  };
};

export const previewMealPlan = {
  flexitarian: {
    daily_meals: [
      {
        breakfast: createMeal(
          "flexitarian",
          "1.jpeg",
          "1",
          "20",
          "easy",
          2,
          "SWEET CASHEW CREAM"
        ),
        morning_snack: createMeal(
          "flexitarian",
          "2.jpeg",
          "2",
          "20",
          "easy",
          2,
          "SWEET CASHEW CREAM"
        ),
        lunch: createMeal(
          "flexitarian",
          "3.jpeg",
          "3",
          "20",
          "easy",
          2,
          "SWEET CASHEW CREAM"
        ),
        afternoon_snack: createMeal(
          "flexitarian",
          "4.jpeg",
          "4",
          "20",
          "easy",
          2,
          "SWEET CASHEW CREAM"
        ),
        dinner: createMeal(
          "flexitarian",
          "5.jpeg",
          "5",
          "20",
          "easy",
          2,
          "SWEET CASHEW CREAM"
        ),
        evening_snack: createMeal(
          "flexitarian",
          "6.jpeg",
          "6",
          "20",
          "easy",
          2,
          "SWEET CASHEW CREAM"
        ),
      },
    ],
  },
  mediterranean: {
    daily_meals: [
      {
        breakfast: createMeal(
          "mediterranean",
          "1.jpeg",
          "1",
          "5",
          "easy",
          3,
          "STRAWBERRY SMOOTHIE"
        ),
        morning_snack: createMeal(
          "mediterranean",
          "2.jpeg",
          "2",
          "5",
          "easy",
          3,
          "TOMATO, MOZZARELLA AND BALSAMIC VINEGAR"
        ),
        lunch: createMeal(
          "mediterranean",
          "3.jpeg",
          "3",
          "30",
          "easy",
          3,
          "STUFFED SWEET POTATO"
        ),
        afternoon_snack: createMeal(
          "mediterranean",
          "4.jpeg",
          "4",
          "120",
          "easy",
          3,
          "HOMEMADE HUMMUS"
        ),
        dinner: createMeal(
          "mediterranean",
          "5.jpeg",
          "5",
          "20",
          "easy",
          2,
          "SWEET CASHEW CREAM"
        ),
        evening_snack: createMeal(
          "mediterranean",
          "6.jpeg",
          "6",
          "20",
          "easy",
          3,
          "QUINOA BOWL"
        ),
      },
    ],
  },
};
