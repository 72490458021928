import React, { useEffect } from "react";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { contentAPI } from "../api";
import themeConfig from "../configs/theme";
import {
  setToggleFetching,
  setMeal,
  clearMeal,
  addError,
} from "../actions/meal";

import { clearAuth } from "../actions/auth";

import PageLoader from "../components/common/PageLoader";
import Meal from "../components/pages/Meal";
import ErrorPage from "../components/pages/Error";

const MealPage = ({ match, getMeal, clearMeal, isFetching, meal, error }) => {
  useEffect(() => {
    getMeal(match.params.id).then((res) => {
      const categories = res.data.meal_types.map((type) => {
        return type.name;
      });
      amplitude.getInstance().logEvent("recipe", {
        title: res.data.title,
        cooking_time: res.data.cooking_time,
        categories: categories.join(", "),
      });
    });
    return () => {
      clearMeal();
    };
  }, [getMeal, match.params.id, clearMeal]);

  if (error) {
    return (
      <>
        <Helmet>
          <title>Error - {themeConfig.title}</title>
        </Helmet>
        <ErrorPage error={error} />
      </>
    );
  }

  if (isFetching || !meal) {
    return <PageLoader />;
  }

  return (
    <>
      <Helmet>
        <title>
          {meal.title} - Recipes by {themeConfig.title}
        </title>
      </Helmet>
      <Meal meal={meal} />
    </>
  );
};

export default connect(
  (state) => ({
    isFetching: state.meal.isFetching,
    meal: state.meal.meal,
    error: state.meal.error,
  }),
  (dispatch) => ({
    getMeal: (id) => {
      dispatch(setToggleFetching(true));
      return contentAPI
        .getMeal(id)
        .then((res) => {
          dispatch(setMeal(res.data));
          dispatch(setToggleFetching(false));
          return res;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
          dispatch(addError(error.response.status));
          dispatch(setToggleFetching(false));
        });
    },
    clearMeal: () => dispatch(clearMeal()),
  })
)(withRouter(MealPage));
