import React from 'react';

import './Input.sass';

const Input = ({
  label,
  value
}) => {
  return (
    <div className="input-wrap">
      <div className="input-label">{label}</div>
      <input className="input" type="text" value={value} disabled />
    </div>
  );
};

export default Input;