import React, { useState, useEffect, useRef } from "react";

import Title from "../../common/Title";
import LogCounter from "../../common/LogCounter";
import IconSprite from "../../common/IconSprite";
import Loader from "../../common/Loader";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import themeConfig from "./themeConfig";

import "./LogWeight.sass";

const options = {
  title: {
    text: " ",
  },
  series: [
    {
      data: [],
      color: "#ffffff",
      lineWidth: 1,
      marker: {
        radius: 3.5,
        fillColor: themeConfig.markerColor,
      },
      dataLabels: {
        enabled: true,
        color: "#ffffff",
        shadow: false,
        style: {
          fontWeight: "bold",
          fontFamily: "DIN Condensed",
          fontSize: "18px",
        },
      },
      enableMouseTracking: false,
    },
  ],
  xAxis: {
    minRange: 1,
    categories: [],
    gridLineWidth: 0,
    gridLineColor: "#000000",
    lineColor: themeConfig.xAxisLineColor,
    labels: {
      style: {
        color: themeConfig.labelColor,
        fontSize: "12px",
        fontFamily: "HelveticaNeue",
      },
    },
  },
  yAxis: {
    title: {
      text: "KG",
      align: "high",
      rotation: 0,
      offset: 0,
      y: -25,
      x: -15,
      style: {
        color: themeConfig.labelColor,
        fontSize: "12px",
        fontFamily: "HelveticaNeue",
      },
    },
    labels: {
      style: {
        color: themeConfig.labelColor,
        fontSize: "12px",
        fontFamily: "HelveticaNeue",
      },
    },
    gridLineWidth: 0,
    minorGridLineWidth: 0,
    gridLineColor: "#000000",
    lineColor: "#000000",
    lineWidth: 0,
  },
  legend: {
    enabled: false,
  },
  chart: {
    type: "spline",
    spacingTop: 50,
    height: 310,
    backgroundColor: themeConfig.backgroundColor,
    events: {
      load: function () {
        const xAxis = this.xAxis[0];
        let diff = xAxis.dataMax - 4;
        if (diff >= 0) {
          xAxis.setExtremes(diff, xAxis.dataMax);
        } else {
          xAxis.setExtremes(0, xAxis.dataMax);
        }
      },
    },
  },
};

const LogWeight = ({
  actualWeight,
  goalWeight,
  measurement,
  list,
  isSending,
  handleSendLogWeight,
  isLogged,
}) => {
  const chartRef = useRef(null);
  const isImperial = measurement === "imperial";
  let units = isImperial ? "LB" : "KG";

  const labels = [];
  const datas = [];

  list.forEach((item) => {
    labels.push(item.dateMin);
    datas.push(item.weightValue);
  });

  options.yAxis.title.text = units;
  options.xAxis.categories = [...labels.reverse()];
  options.series[0].data = [...datas.reverse()];

  const [localActualWeight, setLocalActualWeight] = useState(null);
  const [optionState, setOptionState] = useState(null);

  useEffect(() => {
    if (localActualWeight !== actualWeight) {
      setOptionState({ ...options });
      setLocalActualWeight(actualWeight);
    }
  }, [
    localActualWeight,
    actualWeight,
    setLocalActualWeight,
    chartRef,
    optionState,
  ]);

  useEffect(() => {
    if (chartRef.current) {
      const xAxis = chartRef.current.chart.xAxis[0];
      const dataMax = xAxis.dataMax;
      const currentMin = xAxis.min;
      const currentMax = xAxis.max;

      if (dataMax > xAxis.userMax) {
        const diff = dataMax - currentMax;
        let newMin = currentMin + diff;
        let newMax = currentMax + diff;
        xAxis.setExtremes(newMin, newMax);
      }
    }
  }, [list, chartRef]);

  const handleZoomIn = () => {
    const xAxis = chartRef.current.chart.xAxis[0];
    const currentMin = xAxis.min;
    const currentMax = xAxis.max;
    let newMin = currentMin + 1;
    let newMax = currentMax - 1;

    if (newMin >= currentMax - 4) {
      newMin = currentMax - 4;
      newMax = currentMax;
    }

    if (newMin < 0) {
      newMin = 0;
    }

    xAxis.setExtremes(newMin, newMax);
  };

  const handleZoomOut = () => {
    const xAxis = chartRef.current.chart.xAxis[0];
    const currentMin = xAxis.min;
    const currentMax = xAxis.max;
    const dataMax = xAxis.dataMax;

    let newMin = currentMin - 1;
    let newMax = currentMax + 1;

    if (newMax > dataMax) {
      newMin = currentMin - 2;
      newMax = currentMax;
    }

    if (newMin < 0) {
      newMin = 0;
      newMax = currentMax + 2;
    }

    if (newMax > dataMax) {
      newMax = currentMax;
    }

    xAxis.setExtremes(newMin, newMax);
  };

  const handleScrollLeft = () => {
    const xAxis = chartRef.current.chart.xAxis[0];
    const currentMin = xAxis.min;
    const currentMax = xAxis.max;
    let newMin = currentMin - 1;
    let newMax = currentMax - 1;

    if (newMin < 0) {
      newMin = 0;
      newMax = currentMax;
    }

    xAxis.setExtremes(newMin, newMax);
  };

  const handleScrollRight = () => {
    const xAxis = chartRef.current.chart.xAxis[0];
    const currentMin = xAxis.min;
    const currentMax = xAxis.max;
    const dataMax = xAxis.dataMax;
    let newMin = currentMin + 1;
    let newMax = currentMax + 1;

    if (newMax > dataMax) {
      newMin = currentMin;
      newMax = currentMax;
    }

    xAxis.setExtremes(newMin, newMax);
  };

  return (
    <div className="content-page">
      <div className="container">
        <div className="content-page-inner log-weight">
          <div className="content-page-left log-weight-head">
            <Title className="log-weight__title">Log your weight</Title>
            <div className="title-add log-weight__title-add">
              Take weight measurements every week and track the progress you get
              from training and nutrition
            </div>
            <LogCounter
              value={actualWeight}
              measurement={measurement || "metric"}
              isSending={isSending}
              onSubmit={handleSendLogWeight}
              isLogged={isLogged}
            />
          </div>
          <div className="content-page-right">
            <div className="log-weight-chart">
              <div className="log-weight-chart__head">
                <Title size="medium" className="log-weight-chart__title">
                  <span>Your Weight history</span>
                  <span>Goal Weight</span>
                </Title>
                {goalWeight && (
                  <div className="log-weight-chart__target">
                    <IconSprite className="target-icon" name="target" />
                    <span>{goalWeight}</span>
                  </div>
                )}
              </div>
              <div className="log-weight-chart__body">
                {list.length > 1 ? (
                  <div className="log-weight-chart__main">
                    <div className="log-weight-chart__nav scrolling">
                      <button
                        className="log-weight-chart__btn scroll"
                        onClick={handleScrollLeft}
                      >
                        <IconSprite className="angle-icon" name="angle-left" />
                      </button>
                      <button
                        className="log-weight-chart__btn scroll"
                        onClick={handleScrollRight}
                      >
                        <IconSprite className="angle-icon" name="angle-right" />
                      </button>
                    </div>
                    <div className="log-weight-chart__nav">
                      <button
                        className="log-weight-chart__btn minus"
                        onClick={handleZoomOut}
                      ></button>
                      <button
                        className="log-weight-chart__btn"
                        onClick={handleZoomIn}
                      ></button>
                    </div>
                    {optionState ? (
                      <HighchartsReact
                        ref={chartRef}
                        highcharts={Highcharts}
                        options={optionState}
                        allowChartUpdate={true}
                      />
                    ) : (
                      <div className="log-weight-chart__loader">
                        <Loader />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="log-weight-chart__empty">
                    <div className="log-weight-chart__empty-inner">
                      <IconSprite
                        className="log-weight-empty-icon"
                        name="log-weight-empty"
                      />
                      <div className="log-weight-chart__empty-desc">
                        No weight measurements
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {Boolean(list.length) && (
              <ul className="log-weight-list">
                {list.map(({ id, dateDetail, weightValue }) => {
                  return (
                    <li className="log-weight-item" key={id}>
                      <div className="log-weight-item__date">{dateDetail}</div>
                      <div className="log-weight-item__value">
                        {weightValue} {isImperial ? "lb" : "kg"}
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogWeight;
