import React, { useState, useRef, useEffect } from "react";
import amplitude from "amplitude-js";
import clsx from "clsx";
import Vimeo from "@u-wave/react-vimeo";
import Loader from "../../common/Loader";
import IconSprite from "../../common/IconSprite";
import CategoryTag from "../../common/CategoryTag";
import Param from "../../common/Param";
import "./WorkoutPlanSingle.sass";
import { NavLink } from "react-router-dom";

const WorkoutPlanSingle = ({
  singleVideoData,
  handleSendWorkoutPlanToResults,
  handleSendGAProgress,
  handleSendGAPause,
  similarWorkoutsIsLoading,
  similarWorkouts,
}) => {
  const [singleVideoIsReady, setSingleVideoIsReady] = useState(false);
  const [videoIsPlay, setVideoIsPlay] = useState(false);
  const vimeoRef = useRef(null);

  useEffect(() => {
    if (singleVideoIsReady) {
      const data = {
        title: singleVideoData.title,
        category: singleVideoData.category,
        level: singleVideoData.level,
      };
      if (singleVideoData.author) {
        data.author = `${singleVideoData.author.firstName} ${singleVideoData.author.lastName}`;
      }
      amplitude.getInstance().logEvent("single_video", data);
    }
  }, [singleVideoIsReady, singleVideoData]);

  const playVideo = () => {
    vimeoRef.current.player.play();
    setVideoIsPlay(true);
  };

  return (
    <div className="workout-plan-page content-page single-video-page">
      <div className="single-video-wrap single-video-widget-wrap">
        <div className="single-video">
          <div
            className={clsx("single-video-file", {
              loading: !singleVideoIsReady,
            })}
          >
            {videoIsPlay || (
              <>
                {singleVideoData.preview && (
                  <img
                    className="single-video-preview"
                    src={singleVideoData.preview}
                    alt={singleVideoData.title}
                  />
                )}
                <div
                  className="single-video-play-btn"
                  onClick={() => {
                    playVideo();
                  }}
                >
                  <IconSprite className="play" name="play" />
                </div>
              </>
            )}
            <Vimeo
              video={singleVideoData.video}
              color="000000"
              loop={true}
              onReady={() => {
                setSingleVideoIsReady(true);
              }}
              ref={vimeoRef}
              onTimeUpdate={(event) => {
                if (event.seconds > 10) {
                  handleSendWorkoutPlanToResults();
                }
                handleSendGAProgress(event.percent);
              }}
              onPause={(event) => {
                handleSendGAPause();
              }}
            />
          </div>
          {singleVideoIsReady || (
            <div className="single-video-loader">
              <Loader size={32} />
            </div>
          )}
        </div>
        <div className="single-video-body">
          <div className="container">
            <div className="single-video-body__main">
              <div className="single-video-title">{singleVideoData.title}</div>
              <div className="single-video-plan-info">
                {singleVideoData.author && (
                  <NavLink
                    to={`/author/${singleVideoData.author.id}`}
                    className="workout-author-name"
                  >
                    Created by{" "}
                    {`${singleVideoData.author.firstName} ${singleVideoData.author.lastName}`}
                  </NavLink>
                )}
                <CategoryTag
                  category={singleVideoData.category}
                  color={singleVideoData.level}
                />
              </div>
            </div>
            <div className="single-video-duration">
              <Param
                icon={
                  <IconSprite className="icon-time-small" name="time-small" />
                }
              >
                {singleVideoData.duration} min
              </Param>
            </div>
            <div className="single-video-description">
              <div className="single-video-description__body">
                {singleVideoData.description}
              </div>

              {/* <button className="single-video-description__btn" onClick={handleReadMoreDescription}>
                Read More
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="similar-videos single-video-widget-wrap">
        <div className="container">
          <div className="similar-video-head">
            <div className="similar-video-title">Next videos</div>
          </div>
          <div className="single-video-list">
            {!similarWorkoutsIsLoading ? (
              <div className="similar-video-loader">
                <Loader size={32} />
              </div>
            ) : (
              <>
                {similarWorkouts.map((workout) => {
                  return (
                    <NavLink
                      to={`/workouts/${workout.id}`}
                      className="similar-video"
                      key={workout.id}
                    >
                      <img
                        src={workout.preview}
                        alt=""
                        className="similar-video__pic"
                      />
                      <div className="similar-video__inner">
                        <div className="similar-video__name">
                          {workout.title}
                        </div>
                        <Param
                          icon={
                            <IconSprite
                              className="icon-time-small"
                              name="time-small"
                            />
                          }
                        >
                          {workout.duration} min
                        </Param>
                      </div>
                    </NavLink>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkoutPlanSingle;
