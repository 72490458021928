import React, { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import IconSprite from "../../../components/common/IconSprite";
import LoaderFullBlock from "../LoaderFullBlock";
import "./ModalRight.sass";

const ModalRight = ({
  children,
  head,
  footer,
  isOpen,
  onClose,
  isLoading,
  onClearData,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const handleClose = ({ target }) => {
    if (target.classList.contains("modal-right-wrap")) {
      onClose();
    }
  };

  return (
    <CSSTransition
      in={isOpen}
      timeout={400}
      classNames="modal-right-transition"
      unmountOnExit
      onExited={() => {
        if (onClearData) {
          onClearData();
        }
      }}
    >
      <div className="modal-right-wrap" onClick={handleClose}>
        <div className="modal-right">
          <LoaderFullBlock isLoading={isLoading} />
          <div className="modal-right-close" onClick={onClose}>
            <IconSprite className="modal-right-close-icon" name="close" />
          </div>
          {head && (
            <div className="modal-right-head">
              <div className="modal-right-container">{head}</div>
            </div>
          )}
          <div className="modal-right-body">
            <div className="modal-right-container">{children}</div>
          </div>
          {footer && (
            <div className="modal-right-footer">
              <div className="modal-right-container">{footer}</div>
            </div>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default ModalRight;
