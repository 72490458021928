import React from "react";

import FilterSelect from "../FilterSelect";

import "./filterSelectList.sass";

const FilterSelectList = ({ color, className, list, handleChange }) => {
  const classes = `filter-select-list ${className ? className : ""}`;

  return (
    <div className={classes}>
      {list.map(({ label, options, name, defaultOption }) => {
        return (
          <FilterSelect
            key={name}
            label={label}
            options={options}
            name={name}
            defaultValue={defaultOption}
            handleChange={(value) => handleChange(value, name, label)}
            color={color}
          />
        );
      })}
    </div>
  );
};

export default FilterSelectList;
