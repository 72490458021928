import React from 'react';
import Loader from '../Loader';

import './button.sass'

const Button = ({
  type,
  fullWith,
  spin,
  spinColor,
  disabled,
  className,
  value,
  children,
  icon,
  size,
  ...props
}) => {

  const buttonType = type ? "btn-" + type : "btn-primary";
  const buttonWith = fullWith ? '100%' : '';
  const buttonClass = className ? `${buttonType} ${className}` : buttonType;
  const sizeClass = size === 'large' ? `${buttonClass} btn-large` : buttonClass;
  const outputClass = icon ? `${sizeClass} with-icon` : sizeClass;

  const buttonInner = icon ? (
    <>{children}{icon}</>
  ) : children;

  return (
    <button
      style={{ width: buttonWith }}
      className={outputClass}
      disabled={disabled}
      {...props}
    >
      {
        spin
          ? <Loader color={spinColor ? spinColor : "#fff"} size={24} />
          : <span>{buttonInner}</span>
      }
    </button>
  );
};

export default Button;
