import React from "react";

import Button from "../../common/Button";

import { Field, reduxForm } from "redux-form";
import {
  required,
  checkCorrectEmail,
} from "../../../common/helpers/validators";
import InputAuth from "../../common/InputAuth";
import IconSprite from "../../common/IconSprite";

const Form = ({ handleSubmit, isFetching }) => {
  return (
    <form onSubmit={handleSubmit} className="form-wrap">
      <Field
        size="large"
        type="text"
        placeholder="Your email"
        name={"email"}
        icon={<IconSprite className="mail-symbol" name="mail-symbol" />}
        validate={[required, checkCorrectEmail]}
        component={InputAuth}
        normalize={(value) => value.replace(/\s/g, "")}
      />
      <Field
        size="large"
        type="password"
        icon={<IconSprite className="lock" name="lock" />}
        placeholder="Password"
        name={"password"}
        validate={[required]}
        component={InputAuth}
        normalize={(value) => value.replace(/\s/g, "")}
      />
      <Button
        type="primary"
        className="auth-btn"
        disabled={isFetching}
        spin={isFetching}
      >
        Login
      </Button>
    </form>
  );
};

export default reduxForm({
  form: "login",
})(Form);
