import React from 'react';
import { NavLink } from 'react-router-dom';

import './ViewAllLink.sass';

const ViewAllLink = ({
  to
}) => {
  return (
    <NavLink to={to} className="view-all-link">
      <span className="view-all-link__name">View all</span>
      <span className="view-all-link__arrow">→</span>
    </NavLink>
  );
};

export default ViewAllLink;
