import React from "react";

import classes from "./Radio.module.sass";

const Radio = ({ name, children, onChange }) => {
  return (
    <label className={classes.wrap}>
      <input
        className={classes.input}
        onChange={onChange}
        type="radio"
        name={name}
      />
      <div className={classes.content}>
        <div className={classes.check}>
          <span></span>
        </div>
        <div className={classes.value}>{children}</div>
      </div>
    </label>
  );
};

export default Radio;
