import React from "react";
import { connect } from "react-redux";
import { setIsOpen } from "../../actions/subscriptions";
import MealCard from "../../components/common/MealCard";

const MealCardContainer = ({ statuses, openSubscribeModal, ...props }) => {
  const isBlocked = statuses.workouts && statuses.recipes === "empty";

  const handleBuySubscribe = () => {
    if (!isBlocked) {
      return false;
    }
    openSubscribeModal();
  };

  return (
    <>
      <MealCard
        isBlocked={isBlocked}
        handleBuySubscribe={handleBuySubscribe}
        {...props}
      />
    </>
  );
};

export default connect(
  ({ auth }) => ({
    statuses: auth.statuses,
  }),
  (dispatch) => ({
    openSubscribeModal: () => dispatch(setIsOpen(true)),
  })
)(MealCardContainer);
