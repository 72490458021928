import React, { useState, useEffect } from "react";

import StartScreen from "./components/StartScreen";
import WorkoutPlayCarousel from "./components/WorkoutPlayCarousel";
import ConfirmModal from "../../modals/ConfirmModal";

import "./WorkoutPlanPlay.sass";

const WorkoutPlanPlay = ({
  isPlayWorkout,
  handleChangeModuleStatus,
  workoutPlanName,
  workoutPlanAuthor,
  workoutPlanCategory,
  workoutPlanLevel,
  workoutPlayModel,
  setActiveExercise,
  activeExerciseIndex,
  activeExerciseCount,
  nextExerciseName,
  toggleWorkoutPause,
  isWorkoutPause,
  isWorkoutFinishModal,
  handleOpenStopWorkoutModal,
  handleCloseStopWorkoutModal,
  handleFinishWorkoutPlay,
  isWorkoutFinish,
  setIsWorkoutFinish,
  workoutSetsCount,
}) => {
  const [audio, setAudio] = useState(new Audio());
  const [audioSignal] = useState(new Audio());
  const [isAudioMute, setIsAudioMute] = useState(false);

  const audioToggleMute = () => {
    setIsAudioMute(!isAudioMute);
    audio.muted = !audio.muted;
    audioSignal.muted = !audioSignal.muted;
  };

  const audioCountLoad = () => {
    audio.src = `${
      process.env.REACT_APP_API_DOMAIN
    }/tts/synthesize?text=${encodeURI('<countdown duration="7"><beep>')}`;
    audio.autoplay = false;
    audio.load();
  };

  const audioCountUpdate = (text) => {
    audio.src = `${
      process.env.REACT_APP_API_DOMAIN
    }/tts/synthesize?text=${encodeURI('<countdown duration="7"><beep>')}`;
    audio.autoplay = false;
    audio.load();
  };

  const audioCountSkip = () => {
    audio.pause();
    audioSignal.play();
  };

  const audioUpdatePlay = (text) => {
    audio.src = `${
      process.env.REACT_APP_API_DOMAIN
    }/tts/synthesize?text=${encodeURI(text)}`;
    audio.play();
  };

  const audioPlay = () => {
    audio.play();
  };

  const audioSignalPlay = () => {
    audioSignal.play();
  };

  useEffect(() => {
    audioSignal.src = `${
      process.env.REACT_APP_API_DOMAIN
    }/tts/synthesize?text=${encodeURI("<beep>")}`;
    audioSignal.autoplay = false;
    audioSignal.load();
  }, [audioSignal]);

  useEffect(() => {
    return () => {
      audio.pause();
      setAudio(null);
    };
  }, [audio]);

  return (
    <>
      {!isPlayWorkout && (
        <StartScreen
          handleChangeModuleStatus={handleChangeModuleStatus}
          audioLoad={audioCountLoad}
          audioPlay={audioPlay}
          audioUpdate={audioCountUpdate}
          audioCountSkip={audioCountSkip}
          workoutPlanName={workoutPlanName}
          workoutPlanAuthor={workoutPlanAuthor}
          workoutPlanCategory={workoutPlanCategory}
          workoutPlanLevel={workoutPlanLevel}
        />
      )}
      {isPlayWorkout && (
        <WorkoutPlayCarousel
          workoutPlanName={workoutPlanName}
          workoutPlayModel={workoutPlayModel}
          setActiveExercise={setActiveExercise}
          activeExerciseIndex={activeExerciseIndex}
          activeExerciseCount={activeExerciseCount}
          nextExerciseName={nextExerciseName}
          toggleWorkoutPause={toggleWorkoutPause}
          isWorkoutPause={isWorkoutPause}
          handleOpenStopWorkoutModal={handleOpenStopWorkoutModal}
          isWorkoutFinishModal={isWorkoutFinishModal}
          handleFinishWorkoutPlay={handleFinishWorkoutPlay}
          isWorkoutFinish={isWorkoutFinish}
          setIsWorkoutFinish={setIsWorkoutFinish}
          audioUpdatePlay={audioUpdatePlay}
          isAudioMute={isAudioMute}
          audioToggleMute={audioToggleMute}
          workoutSetsCount={workoutSetsCount}
          audioSignalPlay={audioSignalPlay}
        />
      )}
      {isWorkoutFinishModal && (
        <ConfirmModal
          title="Finish your workout"
          description="Ready to finish this workout?"
          buttonMainName="Finish"
          buttonSecondName="Continue"
          handleSecondClick={handleCloseStopWorkoutModal}
          handleClose={handleCloseStopWorkoutModal}
          handleMainClick={handleFinishWorkoutPlay}
        />
      )}
    </>
  );
};

export default WorkoutPlanPlay;
