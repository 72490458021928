import React from 'react';

import clsx from 'clsx';
import IconSprite from '../IconSprite';
import './Helper.sass';

const Helper = ({
  message,
  onClose,
  className
}) => {

  return (
    <div className={clsx("helper", {
      [className]: className
    })}>
      {/* <IconSprite className="helper-icon" name="helper" /> */}
      <div className="helper-icon">
        <IconSprite className="angle-small-left-icon" name="angle-small-left" />
        <IconSprite className="handle-icon" name="handle" />
      </div>
      <div className="helper__message">{message}</div>

      <button
        className="helper__delete"
        onClick={onClose}
      >
        <IconSprite className="close-icon" name="close" />
      </button>
    </div>
  )
};

export default Helper;