import React from "react";
import { Field } from "formik";
import * as Yup from "yup";

import Radio from "../Radio";
import Wrapper from "../Wrapper";

import classes from "./Gender.module.sass";

const config = [
  { id: 0, value: "male", title: "Male" },
  { id: 1, value: "female", title: "Female" },
];

const ValidatorSchema = Yup.object().shape({
  gender: Yup.string().required(),
});

const Gender = ({ answer, onNext, onPrev }) => {
  return (
    <Wrapper
      initialValues={{ gender: String(answer || "") }}
      schema={ValidatorSchema}
      onPrev={onPrev}
      onNext={onNext}
    >
      <div
        role="group"
        aria-labelledby="my-radio-group"
        className={classes.wrap}
      >
        {config.map(({ id, value, title }) => (
          <div className={classes.item} key={id}>
            <Field
              type="radio"
              className={classes.input}
              name="gender"
              value={value}
              title={title}
              big
              component={Radio}
              checked={answer}
            />
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default Gender;
