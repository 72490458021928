import React, { useState, useEffect, useCallback } from 'react';

const calculateBreakpoint = (pageWidth, breakpoint) => {
  if (pageWidth < breakpoint) {
    return 'mobile';
  }
  return 'desktop';
};

const BlockGroup = ({
  className,
  children,
  breakpoint
}) => {

  const [breakpointName, setBreakpointName] = useState(calculateBreakpoint(window.innerWidth, breakpoint));

  const calculateBreakpointWrapper = useCallback((event) => {
    setBreakpointName(calculateBreakpoint(event.target.innerWidth, breakpoint));
  }, [setBreakpointName, breakpoint]);


  useEffect(() => {

    window.addEventListener('resize', calculateBreakpointWrapper);
    return () => window.removeEventListener('resize', calculateBreakpointWrapper);
  }, [calculateBreakpointWrapper]);

  if (breakpointName === 'mobile') {
    return (
      <>
        {children}
      </>
    )
  }
  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default BlockGroup;
