import React, { useState, useEffect } from "react";

import HeadIcon from "../HeadIcon";
import Title from "../Title";
import Checkbox from "../Checkbox";
import Button from "../Button";

import { MealPlan, Workouts } from "../../../../../../../common/ui/icons";

import classes from "./ModalCancel.module.sass";

const ModalCancel = ({ config, onClose, onSend }) => {
  const [disabledButton, setDisabledButton] = useState(true);
  const [subscribes, setSubscribes] = useState(() => {
    return {};
  });

  const handleChange = (id, type) => {
    setSubscribes((state) => {
      const newSubscribe = { id, type };
      const result = { ...state };

      if (result[type]) {
        delete result[type];
      } else {
        result[type] = { ...newSubscribe };
      }

      return { ...result };
    });
  };

  const handleSend = () => {
    onSend(subscribes);
  };

  useEffect(() => {
    const subsLength = Object.keys(subscribes).length;
    if (subsLength) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [subscribes]);

  return (
    <div className={classes.wrap}>
      <div className={classes.body}>
        <HeadIcon name="cancel" />
        <Title>Cancel my membership</Title>
        <Title type="desc">Please confirm your cancellation</Title>
        {config.workouts && config.workouts?.status === "active" && (
          <Checkbox
            icon={<Workouts className={classes.icon} />}
            name="cancel_subs"
            title="Workouts"
            onChange={() =>
              handleChange(config.workouts?.id, config.workouts?.value)
            }
          />
        )}
        {config.recipes && config.recipes?.status === "active" && (
          <Checkbox
            icon={<MealPlan className={classes.icon} />}
            name="cancel_subs"
            title="Recipes"
            onChange={() =>
              handleChange(config.recipes?.id, config.recipes?.value)
            }
          />
        )}
        {config.workouts_recipes &&
          config.workouts_recipes?.status === "active" && (
            <Checkbox
              icon={<MealPlan className={classes.icon} />}
              name="cancel_subs"
              title={config.workouts_recipes.label}
              onChange={() =>
                handleChange(
                  config.workouts_recipes?.id,
                  config.workouts_recipes?.value
                )
              }
            />
          )}
      </div>
      <div className={classes.footer}>
        <Button
          className={classes.btn}
          disabled={disabledButton}
          onClick={handleSend}
        >
          continue canceling
        </Button>
        <Button
          type="transparent"
          className={classes.emptyBtn}
          onClick={onClose}
        >
          KEEP MY MEMBERSHIP
        </Button>
      </div>
    </div>
  );
};

export default ModalCancel;
