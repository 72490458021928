import React, { useState } from "react";
import amplitude from "amplitude-js";
import { Swiper, SwiperSlide } from "swiper/react";
import clsx from "clsx";
import WorkoutPlayHeader from "../WorkoutPlayHeader";
import WorkoutPlaySlide from "../WorkoutPlaySlide";
import WorkoutPlayRest from "../WorkoutPlayRest";
import Finish from "../Finish";
import PauseVideo from "../PauseVideo";
import IconSprite from "../../../../common/IconSprite";

import "./WorkoutPlayCarousel.sass";

const sendGA = (event) => {
  if (window.gtag) {
    window.gtag("event", event);
  }
  amplitude.getInstance().logEvent(event);
};

const WorkoutPlayCarousel = ({
  workoutPlanName,
  workoutPlayModel,
  toggleWorkoutPause,
  isWorkoutPause,
  handleOpenStopWorkoutModal,
  isWorkoutFinishModal,
  isWorkoutFinish,
  setIsWorkoutFinish,
  audioUpdatePlay,
  isAudioMute,
  audioToggleMute,
  workoutSetsCount,
  audioSignalPlay,
}) => {
  const [swiper, setSwiper] = useState(null);
  const [isRest, setIsRest] = useState(false);
  const [buttonNextRef, setButtonNextRef] = useState(false);
  const [isWorkoutEnd, setIsWorkoutEnd] = useState(false);

  const [exerciseInfo, setExerciseInfo] = useState({
    index: workoutPlayModel[0].index + 1,
    name: workoutPlayModel[0].name,
    nextExerciseName: workoutPlayModel[0].nextExerciseName,
    pauseVideo: workoutPlayModel[0].video2,
    pauseVideoPreview: workoutPlayModel[0].video2Preview,
    setNumber: workoutPlayModel[0].setNumber,
    exerciseCount: workoutPlayModel[0].exerciseCount,
  });

  const handleSlideNext = () => {
    if (isWorkoutPause) return false;
    swiper.slideNext();
    if (swiper.isEnd) {
      setIsWorkoutEnd(true);
    }
    if (isWorkoutEnd) {
      setIsWorkoutFinish(true);
    }
  };

  const handleSlidePrev = () => {
    if (isWorkoutPause) return false;
    swiper.slidePrev();
    setIsWorkoutEnd(false);
  };

  const handleSlideChange = (swiper) => {
    const idx = swiper.activeIndex;

    if (workoutPlayModel[idx].type === "exercise") {
      setExerciseInfo({
        index: workoutPlayModel[idx].index + 1,
        name: workoutPlayModel[idx].name,
        nextExerciseName: workoutPlayModel[idx].nextExerciseName,
        pauseVideo: workoutPlayModel[idx].video2,
        pauseVideoPreview: workoutPlayModel[idx].video2Preview,
        setNumber: workoutPlayModel[idx].setNumber,
        exerciseCount: workoutPlayModel[idx].exerciseCount,
      });
      setIsRest(false);
    } else {
      setExerciseInfo({
        index: workoutPlayModel[idx - 1].index + 1,
        name: workoutPlayModel[idx - 1].name,
        nextExerciseName: workoutPlayModel[idx - 1].nextExerciseName,
        pauseVideo: null,
        pauseVideoPreview: null,
        setNumber: workoutPlayModel[idx - 1].setNumber,
        exerciseCount: workoutPlayModel[idx - 1].exerciseCount,
      });
      setIsRest(true);
    }
  };

  const handleFullScreen = () => {
    let doc = window.document;
    let docEl = doc.documentElement;

    let requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    let cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  };

  return (
    <div
      className={clsx("workout-play-carousel-wrapper", {
        "is-finish": isWorkoutFinish,
      })}
    >
      <WorkoutPlayHeader
        workoutPlanName={workoutPlanName}
        activeExerciseIndex={exerciseInfo.index}
        activeExerciseCount={exerciseInfo.exerciseCount}
        nextExerciseName={exerciseInfo.nextExerciseName}
        currentExerciseName={exerciseInfo.name}
        workoutSetsCount={workoutSetsCount}
        currentSetIndex={exerciseInfo.setNumber}
        handleSlideNext={handleSlideNext}
        handleSlidePrev={handleSlidePrev}
        toggleWorkoutPause={toggleWorkoutPause}
        isWorkoutPause={isWorkoutPause}
        handleOpenStopWorkoutModal={handleOpenStopWorkoutModal}
        isWorkoutFinishModal={isWorkoutFinishModal}
        isRest={isRest}
        isWorkoutFinish={isWorkoutFinish}
        setButtonNextRef={setButtonNextRef}
        handleFullScreen={handleFullScreen}
      />
      <div className="workout-play-carousel-inner">
        {!isWorkoutFinish ? (
          <div className="workout-play-carousel">
            <Swiper
              onSwiper={(swiper) => setSwiper(swiper)}
              spaceBetween={50}
              slidesPerView={1}
              autoHeight={true}
              breakpoints={{
                320: {
                  spaceBetween: 0,
                },
                890: {
                  spaceBetween: 50,
                },
              }}
              allowTouchMove={false}
              onSlideChange={handleSlideChange}
            >
              {swiper &&
                buttonNextRef &&
                workoutPlayModel.map(({ id, type, ...item }, slideIndex) => {
                  if (type === "rest") {
                    return (
                      <SwiperSlide key={id}>
                        {({ isActive }) => {
                          return (
                            <WorkoutPlayRest
                              key={id}
                              isActive={isActive || slideIndex === 0}
                              handleSlideNext={handleSlideNext}
                              slideIndex={slideIndex}
                              isWorkoutPause={isWorkoutPause}
                              {...item}
                              audioUpdatePlay={audioUpdatePlay}
                            />
                          );
                        }}
                      </SwiperSlide>
                    );
                  } else {
                    return (
                      <SwiperSlide key={id}>
                        {({ isActive }) => {
                          return (
                            <WorkoutPlaySlide
                              isActive={isActive}
                              slideIndex={slideIndex}
                              {...item}
                              handleSlideNext={() => buttonNextRef.click()}
                              toggleWorkoutPause={toggleWorkoutPause}
                              isWorkoutPause={isWorkoutPause}
                              audioUpdatePlay={audioUpdatePlay}
                              isAudioMute={isAudioMute}
                              audioToggleMute={audioToggleMute}
                              audioSignalPlay={audioSignalPlay}
                              workoutPlanName={workoutPlanName}
                              handleFullScreen={handleFullScreen}
                            />
                          );
                        }}
                      </SwiperSlide>
                    );
                  }
                })}
            </Swiper>
          </div>
        ) : (
          <Finish
            workoutPlanName={workoutPlanName}
            audioUpdatePlay={audioUpdatePlay}
          />
        )}
        {isWorkoutPause && !isWorkoutFinishModal && (
          <div className="workout-play-stop">
            <div
              className="workout-play-stop-bg"
              onClick={toggleWorkoutPause}
            ></div>
            <div className="workout-play-title" onClick={toggleWorkoutPause}>
              Workout paused
            </div>
            <div className="workout-play-subtitle" onClick={toggleWorkoutPause}>
              Tap on screen or press Resume to continue
            </div>
            {exerciseInfo.pauseVideo && (
              <PauseVideo
                video={exerciseInfo.pauseVideo}
                preview={exerciseInfo.pauseVideoPreview}
              />
            )}
          </div>
        )}
      </div>
      <div className="workout-info-mobile-wrap">
        <div className="container">
          <div className="workout-info-mobile">
            {!isRest && (
              <div className="workout-info-mobile__head">
                <div className="workout-info-mobile__info">
                  <div className="workout-info-mobile__title">Current</div>
                  <div className="workout-info-mobile__value big">
                    {exerciseInfo.name}
                  </div>
                </div>
                <div className="workout-info-mobile__info">
                  <div className="workout-info-mobile__title">Next</div>
                  <div className="workout-info-mobile__value">
                    {exerciseInfo.nextExerciseName}
                  </div>
                </div>
              </div>
            )}
            <div className="workout-info-mobile__fixed">
              <div className="workout-info-mobile__add">
                <div className="container">
                  <div className="workout-info-mobile__info">
                    <div className="workout-info-mobile__title">Workout</div>
                    <div className="workout-info-mobile__value">
                      {workoutPlanName}
                    </div>
                  </div>
                  <div className="workout-info-mobile__info right">
                    <div className="workout-info-mobile__title">Set</div>
                    <div className="workout-info-mobile__value">
                      {exerciseInfo.setNumber}/{workoutSetsCount}
                    </div>
                  </div>
                  <div className="workout-info-mobile__info right">
                    <div className="workout-info-mobile__title">Exercise</div>
                    <div className="workout-info-mobile__value">
                      {exerciseInfo.index}/{exerciseInfo.exerciseCount}
                    </div>
                  </div>
                </div>
              </div>
              <div className="workout-play-nav">
                {isWorkoutPause && !isWorkoutFinishModal ? (
                  <button
                    className="workout-play-btn"
                    onClick={toggleWorkoutPause}
                  >
                    <IconSprite className="icon-play" name="play" />
                    <span>Resume</span>
                  </button>
                ) : (
                  <button
                    className="workout-play-btn"
                    onClick={handleOpenStopWorkoutModal}
                  >
                    <IconSprite className="icon-stop" name="stop" />
                    <span>Finish</span>
                  </button>
                )}
                <button
                  className="workout-play-btn workout-play-btn-prev"
                  onClick={() => {
                    handleSlidePrev();
                    sendGA("prev exercise");
                  }}
                >
                  <IconSprite className="icon-angle-left" name="angle-left" />
                  <span>Prev</span>
                </button>
                <button
                  className="workout-play-btn workout-play-btn-next"
                  onClick={() => {
                    handleSlideNext();
                    sendGA("next exercise");
                  }}
                  ref={setButtonNextRef}
                >
                  <span>Next</span>
                  <IconSprite className="icon-angle-right" name="angle-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkoutPlayCarousel;
