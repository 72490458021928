import React from "react";
import clsx from "clsx";

import "./PlanCartFooter.sass";

const PlanCartFooter = ({
  totalPrice,
  totalOldPrice,
  handleSendPurchase,
  productsForPurchase,
  isPurchasedDone,
  handlePurchaseModalClose,
  onFinish,
  isPurchasedError,
}) => {
  return (
    <div className="plan-cart-footer">
      {!isPurchasedDone && !isPurchasedError && (
        <div className="plan-cart-footer__cart">
          <div
            className={clsx("plan-cart-total", {
              "is-empty": !totalPrice,
            })}
          >
            <div className="plan-cart-total__title">Total</div>
            <div className="plan-cart-total__prices">
              <span
                className={clsx({
                  "is-empty": !totalOldPrice,
                })}
              >
                ${totalOldPrice.toFixed(2)}
              </span>{" "}
              ${totalPrice.toFixed(2)}
            </div>
          </div>
          <button
            className={clsx("plan-cart-purchase", {
              "is-empty": !totalOldPrice,
            })}
            onClick={() => {
              if (!totalPrice) {
                return false;
              }
              handleSendPurchase(productsForPurchase);
            }}
          >
            <span className="is-active">
              Buy now for ${totalPrice.toFixed(2)}
            </span>
            <span className="is-disabled">Please choose a Diet</span>
          </button>
        </div>
      )}

      {isPurchasedDone && (
        <div className="plan-cart-footer__success">
          <button className="plan-cart-purchase" onClick={onFinish}>
            Start new diet
          </button>
          <button
            className="plan-cart-purchase-empty"
            onClick={handlePurchaseModalClose}
          >
            No, I’ll do this later
          </button>
        </div>
      )}

      {isPurchasedError && (
        <div className="plan-cart-footer__error">
          <button
            className="plan-cart-purchase"
            onClick={handlePurchaseModalClose}
          >
            Close Modal
          </button>
        </div>
      )}
    </div>
  );
};

export default PlanCartFooter;
