import React from "react";
import clsx from "clsx";

import PageLoader from "../../../../common/PageLoader";
import MealInner from "../../../../common/MealInner";
import IconSprite from "../../../../common/IconSprite";
import Button from "../../../../common/Button";
import LoaderWrap from "../../../../common/LoaderWrap";
import Guard from "../../../../common/Guard";

import "./Meal.sass";

const Meal = ({
  meal,
  handleModalClose,
  handleModalRecipeReplaceListOpen,
  openList,
  nameButton,
  fixed,
  isFetching,
  statuses,
  withChange,
}) => {
  return (
    <div
      className={clsx("meal-preview", {
        "fixed-list": openList === "fixed",
        fixed: fixed,
      })}
    >
      {isFetching ? (
        <PageLoader />
      ) : (
        <>
          <div className="meal-preview__head">
            <div className="meal-preview__back" onClick={handleModalClose}>
              <IconSprite className="arrow-left-icon" name="arrow-left" />
              <span>back</span>
            </div>
            {withChange && (
              <Guard condition={statuses.recipes}>
                <LoaderWrap spin={false} colorLoader="#ffffff">
                  <Button
                    disabled={false}
                    onClick={handleModalRecipeReplaceListOpen}
                  >
                    {nameButton}
                  </Button>
                </LoaderWrap>
              </Guard>
            )}
          </div>
          <div className="meal-preview__body">
            <MealInner colorTheme="meal--dark" {...meal} />
          </div>
        </>
      )}
    </div>
  );
};

export default Meal;
