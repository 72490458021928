import React, { useState } from "react";
import clsx from "clsx";

import Title from "../../common/Title";
import ViewAllLink from "../../common/ViewAllLink";
import WorkoutsList from "./components/WorkoutsList";
import LastWorkout from "./components/LastWorkout";
import ListCategory from "./components/ListCategory";
import Favorites from "./components/Favorites";

import "./Workouts.sass";

const Workouts = ({
  historyCalories,
  historyList,
  lastWorkout,
  workouts,
  section,
  favoritesIsLoaded,
  getFavorites,
  favorites,
  favoritesCount,
  favoritesIsFetching,
  updateFavorites,
}) => {
  const [tab, setTab] = useState(0);

  return (
    <div className="workouts-page content-page">
      <div className="container">
        <div className="workouts-page-head">
          <div className="workouts-page-tabs">
            <Title
              className={clsx("workouts-page-head__title", {
                active: tab === 0,
              })}
              onClick={() => setTab(0)}
            >
              {section === "/workouts" ? "Workouts" : "Lifestyle"}
            </Title>
            <Title
              className={clsx("workouts-page-head__title", {
                active: tab === 1,
              })}
              onClick={() => setTab(1)}
            >
              Favorites
              <span>{favoritesCount}</span>
            </Title>
          </div>
          {section === "/workouts" && (
            <LastWorkout
              calories={historyCalories}
              workout={lastWorkout}
              className="workouts-page-head__last-workout"
            />
          )}
        </div>
      </div>
      {tab === 0 ? (
        <>
          {historyList.length > 0 && (
            <div className="workouts-page-history">
              <div className="container">
                <div className="workouts-page-section">
                  <Title size="medium">Recent Activities</Title>
                  <ViewAllLink to={`/history`} />
                </div>
              </div>
              <WorkoutsList list={historyList} carouselId="history" />
            </div>
          )}
          <div className="workouts-page-categories">
            {workouts.map((workout) => {
              if (!workout.list.length) {
                return null;
              }
              return <ListCategory key={workout.name} {...workout} />;
            })}
          </div>
        </>
      ) : (
        <Favorites
          favoritesIsLoaded={favoritesIsLoaded}
          getFavorites={getFavorites}
          list={favorites}
          listCount={favoritesCount}
          isFetching={favoritesIsFetching}
          onUpdate={updateFavorites}
        />
      )}
    </div>
  );
};

export default Workouts;
