import React from "react";
import amplitude from "amplitude-js";

import clsx from "clsx";
import { NavLink } from "react-router-dom";

import BackButton from "../../../../common/BackButton";
import IconSprite from "../../../../common/IconSprite";

import "./WorkoutPlayHeader.sass";

const sendGA = (event) => {
  if (window.gtag) {
    window.gtag("event", event);
  }
  amplitude.getInstance().logEvent(event);
};

const WorkoutPlayHeader = ({
  activeExerciseIndex,
  activeExerciseCount,
  nextExerciseName,
  workoutSetsCount,
  currentExerciseName,
  currentSetIndex,
  workoutPlanName,
  handleSlidePrev,
  handleSlideNext,
  toggleWorkoutPause,
  isWorkoutPause,
  handleOpenStopWorkoutModal,
  isWorkoutFinishModal,
  isRest,
  isWorkoutFinish,
  setButtonNextRef,
  handleFullScreen,
}) => {
  return (
    <>
      <div
        className={clsx("workout-play-header", {
          "is-finish": isWorkoutFinish,
        })}
      >
        <div className="container-fluid">
          <div className="workout-play-header__inner">
            <BackButton handleBack={handleOpenStopWorkoutModal} />
            <div className="workout-play-header__title">{workoutPlanName}</div>
            <div className="workout-play-info">
              <div className="workout-play-info__item">
                <div className="workout-play-info__title">Set</div>
                <div className="workout-play-info__value">
                  {currentSetIndex}/{workoutSetsCount}
                </div>
              </div>
              <div className="workout-play-info__item">
                <div className="workout-play-info__title">Exercise </div>
                <div className="workout-play-info__value">
                  {activeExerciseIndex}/{activeExerciseCount}
                </div>
              </div>
              <div
                className={clsx("workout-play-info__item", {
                  "is-hide": isRest,
                })}
              >
                <div className="workout-play-info__title">Current </div>
                <div className="workout-play-info__value">
                  {currentExerciseName}
                </div>
              </div>
              <div className="workout-play-info__item">
                <div className="workout-play-info__title">Next</div>
                <div className="workout-play-info__value">
                  {nextExerciseName}
                </div>
              </div>
            </div>
            {!isWorkoutFinish ? (
              <div className="workout-play-nav">
                {isWorkoutPause && !isWorkoutFinishModal ? (
                  <button
                    className="workout-play-btn"
                    onClick={toggleWorkoutPause}
                  >
                    <IconSprite className="icon-play" name="play" />
                    <span>Resume</span>
                  </button>
                ) : (
                  <button
                    className="workout-play-btn"
                    onClick={handleOpenStopWorkoutModal}
                  >
                    <IconSprite className="icon-stop" name="stop" />
                    <span>Finish</span>
                  </button>
                )}
                <button
                  className="workout-play-btn workout-play-btn-prev"
                  onClick={() => {
                    handleSlidePrev();
                    sendGA("prev exercise");
                  }}
                >
                  <IconSprite className="icon-angle-left" name="angle-left" />
                  <span>Prev</span>
                </button>
                <button
                  className="workout-play-btn workout-play-btn-next"
                  onClick={() => {
                    handleSlideNext();
                    sendGA("next exercise");
                  }}
                  ref={setButtonNextRef}
                >
                  <span>Next</span>
                  <IconSprite className="icon-angle-right" name="angle-right" />
                </button>
              </div>
            ) : (
              <NavLink
                to="/workouts"
                className="workout-play-finish-btn"
                onClick={() => {
                  let doc = window.document;
                  let cancelFullScreen =
                    doc.exitFullscreen ||
                    doc.mozCancelFullScreen ||
                    doc.webkitExitFullscreen ||
                    doc.msExitFullscreen;
                  if (
                    !(
                      !doc.fullscreenElement &&
                      !doc.mozFullScreenElement &&
                      !doc.webkitFullscreenElement &&
                      !doc.msFullscreenElement
                    )
                  ) {
                    cancelFullScreen.call(doc);
                  }
                }}
              >
                finish
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkoutPlayHeader;
