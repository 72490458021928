export const navigation = (statuses, logout) => ([
  {
    label: 'Home',
    linkTo: '/',
    icon: 'home-icon',
    guard: true,
    confirmed: !(!statuses.workouts && !statuses.mealPlan)
  }, {
    label: 'Workouts',
    linkTo: '/workouts',
    icon: 'workouts',
    guard: true,
    confirmed: statuses.workouts
  }, {
    label: 'LifeStyle',
    linkTo: '/lifestyle',
    icon: 'lifestyle',
    guard: true,
    confirmed: statuses.workouts
  }, {
    label: 'Meal plan',
    linkTo: '/meals',
    icon: 'meal-plan',
    guard: true,
    confirmed: statuses.mealPlan
  }, {
    label: 'Recipes',
    linkTo: '/recipes',
    icon: 'recipes',
    guard: true,
    confirmed: statuses.recipes
  }, {
    label: 'Profile',
    linkTo: '/profile',
    icon: 'profile'
  }, {
    label: 'Logout',
    handleClick: () => {
      logout();
    },
    icon: 'logout'
  },
]);