import React from "react";
import clsx from "clsx";

import { Backdrop } from "../../ui";
import { Close } from "../../ui/icons";
import LoaderFullBlock from "../LoaderFullBlock";
import classes from "./Modal.module.sass";

const Modal = ({
  open,
  onClose,
  children,
  className,
  isLoading,
  mobileSmall,
  onClear,
}) => {
  const handleClose = ({ target }) => {
    if (
      target.classList.contains(classes.wrap) ||
      target.classList.contains(classes.inner)
    ) {
      onClose();
    }
  };

  return (
    <Backdrop isOpen={open} onClear={onClear}>
      <div
        className={clsx(classes.wrap, {
          [classes.mobileSmall]: mobileSmall,
        })}
        onClick={handleClose}
      >
        <div className={classes.inner}>
          <div
            className={clsx(classes.modal, {
              [className]: className,
            })}
          >
            <LoaderFullBlock isLoading={isLoading} />
            <button className={classes.close} onClick={onClose}>
              <Close />
            </button>
            {children}
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default Modal;
