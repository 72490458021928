import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormControl, { FormControlDouble } from "../FormControl";
import Button from "../Button";

import { createValidator } from "./utils";
import classes from "./Measurements.module.sass";

const validator = createValidator(Yup);

const ValidatorSchema = Yup.object().shape({
  age: validator(18, 99),
  ft: validator(4, 8),
  inch: validator(0, 11),
  weight: validator(90, 400),
  target: validator(90, 400),
});

const Imperial = ({ onSubmit, onPrev }) => {
  return (
    <Formik
      initialValues={{
        age: "",
        ft: "",
        inch: "",
        weight: "",
        target: "",
      }}
      validationSchema={ValidatorSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validateOnChange
    >
      <Form className={classes.form}>
        <div className={classes.fields}>
          <FormControl
            id="age"
            name="age"
            placeholder="Age"
            icon="age"
            type="number"
            autoComplete="off"
          />
          <FormControlDouble
            first={{
              id: "ft",
              name: "ft",
              placeholder: "ft",
              autoComplete: "off",
              type: "number",
            }}
            second={{
              id: "inch",
              name: "inch",
              placeholder: "inch",
              autoComplete: "off",
              type: "number",
            }}
            icon="height"
          />
          <FormControl
            id="weight"
            name="weight"
            placeholder="Weight (lb)"
            icon="weight"
            type="number"
            autoComplete="off"
          />
          <FormControl
            id="target"
            name="target"
            placeholder="Target weight (lb)"
            icon="target"
            type="number"
            autoComplete="off"
          />
        </div>
        <div className={classes.formFooter}>
          <Button
            className={`${classes.backBtnForm} ${classes.formBtn}`}
            transparent
            type="button"
            onClick={onPrev}
          >
            Back
          </Button>
          <Button className={classes.formBtn} type="submit">
            Continue
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default Imperial;
