import React, { useRef, useEffect, useState } from 'react';

import IconSprite from '../../../../common/IconSprite';
import Timer from '../Timer';
import './WorkoutPlayRest.sass';

const WorkoutPlayRest = ({
  isActive,
  handleSlideNext,
  isWorkoutPause,
  isPause,
  duration,
  phrase,
  audioUpdatePlay
}) => {
  const timer = useRef(null);

  const [isPlayAudio, setIsPlayAudio] = useState(false);

  useEffect(() => {
    if (isActive && !isPlayAudio) {
      audioUpdatePlay(phrase);
      setIsPlayAudio(true);
    }

    if (!isActive) {
      setIsPlayAudio(false);
    }
  }, [isActive, phrase, audioUpdatePlay, isPlayAudio, setIsPlayAudio]);

  useEffect(() => {
    if (isActive) {
      timer.current.onStart();
    } else {
      timer.current.onReset();
      timer.current.onStop();
      timer.current.onSetTime(duration);
    }

    if (isPause && isActive) {
      timer.current.onPause();
    }
  }, [timer, isActive, isWorkoutPause, isPause, duration]);

  return (
    <div className="workout-play-slide-rest">
      <div className="workout-play-rest">
        <div className="workout-play-rest-title">
          <IconSprite className="icon-rest-icon" name="rest-icon" />
          <span>Time to rest</span>
        </div>
        <Timer
          onTimerEnd={() => {
            handleSlideNext();
          }}
          time={duration}
          ref={timer}
          startImmediately={false}
        />
      </div>
    </div>
  )
};

export default WorkoutPlayRest;
