import React from 'react';

const Guard = ({
  children,
  condition
}) => {

  if (condition) {
    return (
      <>
        {children}
      </>
    );
  };
  return null
};

export default Guard;
