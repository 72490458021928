import React from "react";

import { connect } from "react-redux";

import MealPlan from "../components/modals/MealPlan";
// import FixedLoader from "../components/common/FixedLoader";

const MealPlanModal = ({
  handleModalClose,
  meal,
  handleModalRecipeReplaceListOpen,
  isFetchingList,
  openList,
  setFilterObj,
  filterObj,
  selectedType,
  mealsList,
  mealsListCount,
  handleLoadMore,
  isFetching,
  setMealName,
  mealName,
  selectChange,
  handleSearch,
  openPreview,
  handleOpenPreview,
  handleClosePreview,
  mealPreview,
  isLoadedMealPreview,
  openConfirmModal,
  handleReplaceFromList,
  statuses,
  withChange,
}) => {
  const handleSearchMealNameChange = (mealName) => {
    setMealName(mealName);
    handleSearch(mealName);
  };

  const handleFilterSelectChange = (value, name) => {
    const result = { ...filterObj };
    result[name] = value;
    setFilterObj({ ...result });
    selectChange(result);
  };

  const filtersList = [
    {
      name: "meal_types",
      label: "Type",
      options: [
        { value: "all", title: "All" },
        { value: "1", title: "Breakfast" },
        { value: "2", title: "Morning snack" },
        { value: "3", title: "Lunch" },
        { value: "4", title: "Afternoon snack" },
        { value: "5", title: "Dinner" },
        { value: "6", title: "Evening snack" },
      ],
      defaultOption: selectedType,
    },
    {
      name: "cooking_time_lte",
      label: "Time",
      options: [
        { value: "all", title: "All" },
        { value: "15", title: "15 min or less" },
        { value: "30", title: "30 min or less" },
        { value: "45", title: "45 min or less" },
        { value: "60", title: "60 min or less" },
        { value: "75", title: "75 min or less" },
        { value: "90", title: "90 min or less" },
      ],
      defaultOption: "all",
    },
  ];

  return (
    <>
      <MealPlan
        handleModalClose={handleModalClose}
        meal={meal}
        handleModalRecipeReplaceListOpen={handleModalRecipeReplaceListOpen}
        isFetchingList={isFetchingList}
        openList={openList}
        filtersList={filtersList}
        handleFilterSelectChange={handleFilterSelectChange}
        mealName={mealName}
        handleSearchMealNameChange={handleSearchMealNameChange}
        mealsList={mealsList}
        mealsListCount={mealsListCount}
        handleLoadMore={handleLoadMore}
        isFetching={isFetching}
        openPreview={openPreview}
        handleOpenPreview={handleOpenPreview}
        handleClosePreview={handleClosePreview}
        mealPreview={mealPreview}
        isLoadedMealPreview={isLoadedMealPreview}
        openConfirmModal={openConfirmModal}
        handleReplaceFromList={handleReplaceFromList}
        statuses={statuses}
        withChange={withChange}
      />
    </>
  );
};

export default connect(
  ({ mealPlan, auth }) => ({
    meal: mealPlan.meal,
    isFetchingList: mealPlan.isFetchingList,
    selectedType: mealPlan.selectedType,
    mealsList: mealPlan.mealsList,
    mealsListCount: mealPlan.mealsListCount,
    isFetching: mealPlan.isFetching,
    mealPreview: mealPlan.mealPreview,
    withChange: mealPlan.withChange,
    isLoadedMealPreview: mealPlan.isLoadedMealPreview,
    statuses: auth.statuses,
  }),
  null
)(MealPlanModal);
