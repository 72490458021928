import React from 'react';

import Button from '../../common/Button';
import IconSprite from '../../common/IconSprite';

import './RateWorkoutModal.sass';

const RateWorkoutModal = ({
  handleSendWorkoutPlan
}) => {
  return (
    <div className="rate-workout-modal-wrap">
      <div className="rate-workout-modal-bg"></div>
      <div className="rate-workout-modal">
        <div className="rate-workout-modal-inner">
          <div className="rate-workout-modal-inner__title">Please, rate the workout</div>
          <div className="rate-workout-modal-inner__desc">How are you feeling about today's workout?</div>
          <div className="rate-workout-modal-inner__footer">
            <Button
              size="large"
              icon={<IconSprite className="rate-easy-icon" name="rate-easy" />}
              onClick={() => handleSendWorkoutPlan('easy')}
            >
              Too Easy
            </Button>
            <Button
              size="large"
              icon={<IconSprite className="rate-easy-icon" name="rate-middle" />}
              onClick={() => handleSendWorkoutPlan('normal')}
            >
              Tough, in a good way
            </Button>
            <Button
              size="large"
              icon={<IconSprite className="rate-easy-icon" name="rate-hard" />}
              onClick={() => handleSendWorkoutPlan('hard')}
            >
              Way Too Hard
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateWorkoutModal;
