import React from 'react';
import clsx from 'clsx';

import IconSprite from '../../../../common/IconSprite';

import "./NotPurchasedIcon.sass";

const NotPurchasedIcon = ({
  className
}) => {
  return (
    <div className={clsx("not-purchased-icon", {
      [className]: className
    })}>
      <IconSprite className="icon" name="cart" />
    </div>
  );
};

export default NotPurchasedIcon;