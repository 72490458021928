export default (categories) => {
  const result = categories.map(category => {
    if (category.name !== 'weight_loss') {
      const title = category.name.split('_').join(' ');
      return {
        title: title.charAt(0).toUpperCase() + title.slice(1),
        name: category.name,
        value: '&category=' + category.name
      }
    }
    return null;
  }).filter(item => item);

  return result;
};