import React from 'react';

import { useHistory } from 'react-router-dom';
import IconSprite from '../IconSprite';

import './BackButton.sass';

const BackButton = ({
  handleBack
}) => {
  const history = useHistory();

  if (!handleBack) {
    handleBack = () => {
      if (history.action === 'PUSH') {
        history.goBack();
      } else {
        history.push('/');
      }
    }
  }

  return (
    <div className="back-button" onClick={handleBack}>
      <IconSprite className="icon-arrow-left" name="arrow-left" />
    </div>
  )
};

export default BackButton;