import React, { useEffect, useState } from "react";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import themeConfig from "../configs/theme";
import Axios from "axios";

import { contentAPI } from "../api";
import {
  setIsLoaded,
  setRecipesList,
  setRecipesListInfo,
  uploadRecipesList,
  setRecipesListIsFetching,
  setLike,
  setFavoritesList,
  removeLike,
  setFavoritesListInfo,
  uploadFavoritesList,
  setFilters,
} from "../actions/recipes";

import { clearAuth } from "../actions/auth";

import PageLoader from "../components/common/PageLoader";
import Recipes from "../components/pages/Recipes";

const filtersOptionsConfig = {
  type: [
    { value: "all", title: "All" },
    { value: "1", title: "Breakfast" },
    { value: "3", title: "Lunch" },
    { value: "5", title: "Dinner" },
    { value: "2&meal_types=4&meal_types=6", title: "Snacks" },
  ],
  time: [
    { value: "all", title: "All" },
    { value: "15", title: "15 min or less" },
    { value: "30", title: "30 min or less" },
    { value: "45", title: "45 min or less" },
    { value: "60", title: "60 min or less" },
    { value: "75", title: "75 min or less" },
    { value: "90", title: "90 min or less" },
  ],
  preferences: [
    { title: "None", value: "all" },
    { title: "No Preferences", value: "3" },
    { title: "Vegan", value: "1" },
    // { title: 'No Fish, No Dairy', value: "4" },
    { title: "Pescatarian", value: "5" },
    // { title: 'No Dairy', value: "6" },
    { title: "Vegetarian", value: "2" },
    { title: "Lacto-Pescatarian", value: "7" },
    // { title: 'No Fish', value: "8" },
    { title: "Lacto-Vegetarian", value: "9" },
  ],
};

const sendFilterToAmplitude = (value, label) => {
  const options = filtersOptionsConfig[label.toLowerCase()];
  const idx = options.findIndex((item) => item.value === value);
  const data = {
    label: label,
    value: options[idx].title,
  };
  amplitude.getInstance().logEvent("recipes_filters", data);
};

const RecipesPage = ({
  isLoaded,
  getRecipesList,
  recipesList,
  recipesCount,
  recipesCurrentStart,
  recipesIsFetching,
  uploadRecipesList,
  reloadRecipesList,
  sendFavoriteRecipe,
  favoriteRecipesList,
  deleteFavoriteRecipe,
  favoritesCurrentStart,
  favoritesCount,
  updateFavoritesList,
  favoritesLimit,
  setFilters,
  filterParams,
  filtersString,
}) => {
  const handleFilterSelectChange = (value, name, label) => {
    sendFilterToAmplitude(value, label);
    const resultObject = { ...filterParams };
    resultObject[name] = value;

    let resultFilterString = "";
    const keys = Object.keys(resultObject);

    if (keys.length) {
      keys.forEach((key) => {
        if (resultObject[key] !== "all" && resultObject[key]) {
          resultFilterString += `&${key}=${resultObject[key]}`;
        }
      });
    }
    reloadRecipesList(0, resultFilterString);
    setFilters({ ...resultObject }, resultFilterString);
  };

  const handleFilterTabClick = (value, name) => {
    setFilterTabListActive(name);
  };

  const handleLike = (id) => {
    sendFavoriteRecipe(id);
  };

  const handleDeleteLike = (id) => {
    deleteFavoriteRecipe(id).then((res) => {
      if (
        favoriteRecipesList.length - 1 < 5 &&
        favoritesCount - 1 > 0 &&
        favoritesCount - 1 > favoriteRecipesList.length - 1
      ) {
        updateFavoritesList(4, "", 4);
      }
    });
  };

  const handleRecipesLoadMore = () => {
    uploadRecipesList(recipesCurrentStart, filtersString);
  };

  const handleFavoritesLoadMore = () => {
    updateFavoritesList(favoritesCurrentStart, "", favoritesLimit);
  };

  const [filterTabListActive, setFilterTabListActive] = useState("recipes");

  useEffect(() => {
    if (!isLoaded) {
      getRecipesList();
    }
  }, [getRecipesList, isLoaded]);

  if (!isLoaded) {
    return <PageLoader />;
  }

  const filterTabs = [
    { title: "Recipes", name: "recipes", value: "recipes" },
    {
      title: "Favorites",
      name: "favorites",
      value: "favorites",
      children: <span className="filter-tab-counter">{favoritesCount}</span>,
    },
  ];
  const filterTabActive = filterTabs[0].name;

  const filtersList = [
    {
      name: "meal_types",
      label: "Type",
      options: [...filtersOptionsConfig.type],
      defaultOption: filterParams.meal_types,
    },
    {
      name: "cooking_time_lte",
      label: "Time",
      options: [...filtersOptionsConfig.time],
      defaultOption: filterParams.cooking_time_lte,
    },
    {
      name: "diet_preferences",
      label: "Preferences",
      options: [...filtersOptionsConfig.preferences],
      defaultOption: filterParams.diet_preferences,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Recipes by {themeConfig.title}</title>
      </Helmet>
      <Recipes
        filtersList={filtersList}
        handleFilterSelectChange={handleFilterSelectChange}
        list={recipesList}
        handleLike={handleLike}
        recipesCount={recipesCount}
        recipesCurrentStart={recipesCurrentStart}
        recipesIsFetching={recipesIsFetching}
        handleRecipesLoadMore={handleRecipesLoadMore}
        filterTabs={filterTabs}
        filterTabActive={filterTabActive}
        handleFilterTabClick={handleFilterTabClick}
        filterTabListActive={filterTabListActive}
        favoriteRecipesList={favoriteRecipesList}
        handleDeleteLike={handleDeleteLike}
        favoritesCurrentStart={favoritesCurrentStart}
        favoritesCount={favoritesCount}
        handleFavoritesLoadMore={handleFavoritesLoadMore}
      />
    </>
  );
};

export default connect(
  (state) => ({
    isLoaded: state.recipes.isLoaded,
    recipesList: state.recipes.recipesList,
    recipesCount: state.recipes.recipesCount,
    recipesCurrentStart: state.recipes.recipesCurrentStart,
    recipesIsFetching: state.recipes.recipesIsFetching,
    favoriteRecipesList: state.recipes.favoriteRecipesList,
    favoritesCount: state.recipes.favoritesCount,
    favoritesCurrentStart: state.recipes.favoritesCurrentStart,
    favoritesLimit: state.recipes.favoritesLimit,
    filterParams: state.recipes.filterParams,
    filtersString: state.recipes.filtersString,
  }),
  (dispatch) => ({
    setFilters: (filterParams, filtersString) =>
      dispatch(setFilters(filterParams, filtersString)),
    getRecipesList: (filters = "") => {
      return Axios.all([
        contentAPI.getMeals(0, filters, 8),
        contentAPI.getFavoriteRecipes(0, filters, 8),
      ])
        .then(
          Axios.spread((recipes, favorites) => {
            dispatch(setRecipesList(recipes.data.data.recipes));
            dispatch(setRecipesListInfo(recipes.data.count, 8));
            dispatch(setFavoritesList(favorites.data.data));
            dispatch(setFavoritesListInfo(favorites.data.count, 8));
            dispatch(setIsLoaded());
          })
        )
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    uploadRecipesList: (start, filters = "") => {
      dispatch(setRecipesListIsFetching(true));
      return contentAPI
        .getMeals(start, filters, 8)
        .then((res) => {
          dispatch(uploadRecipesList(res.data.data.recipes));
          dispatch(setRecipesListInfo(res.data.count, start + 8));
          dispatch(setRecipesListIsFetching(false));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    updateFavoritesList: (start, filters = "", limit = 8) => {
      dispatch(setRecipesListIsFetching(true));
      return contentAPI
        .getFavoriteRecipes(start, filters, limit)
        .then((res) => {
          dispatch(uploadFavoritesList(res.data.data));
          dispatch(setFavoritesListInfo(res.data.count, start + limit));
          dispatch(setRecipesListIsFetching(false));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    reloadRecipesList: (start, filters = "") => {
      return contentAPI
        .getMeals(start, filters, 8)
        .then((res) => {
          dispatch(setRecipesList(res.data.data.recipes));
          dispatch(setRecipesListInfo(res.data.count, start + 8));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },

    sendFavoriteRecipe: (id) => {
      return contentAPI
        .sendFavorite(id)
        .then((res) => {
          dispatch(setLike(id, res.data));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    deleteFavoriteRecipe: (id) => {
      return contentAPI
        .deleteFavorite(id)
        .then((res) => {
          dispatch(removeLike(res.data.recipe.id, id));
          return res;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
  })
)(RecipesPage);
