import {
  SET_TOGGLE_FETCHING,
  SET_MEAL,
  SET_CLEAR,
  ADD_ERROR
} from '../constants/mealTypes';

export const setToggleFetching = (isFetching) => {
  return {
    type: SET_TOGGLE_FETCHING,
    isFetching
  };
};

export const setMeal = (data) => {
  return {
    type: SET_MEAL,
    data
  };
};

export const clearMeal = () => {
  return {
    type: SET_CLEAR,
  };
};

export const addError = (error) => {
  return {
    type: ADD_ERROR,
    error
  };
};