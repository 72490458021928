const getThemeNameFromDomainUrl = (themeName, dev) => {
  const currentHostName = dev ? `client.${dev}.com` : window.location.hostname;
  const parts = currentHostName.split(".");
  const hasThemeName = parts.findIndex((part) => part === themeName);
  return hasThemeName !== -1 ? parts[hasThemeName] : false;
};

export const getBankSiteName = (dev) => {
  const currentHostName = dev ? dev + ".com" : window.location.hostname;
  const hostIndex = currentHostName.indexOf("site");
  if (hostIndex === -1) {
    return "";
  }

  const parts = currentHostName.slice(hostIndex).split(".");
  const result = parts[1];

  return result[0].toUpperCase() + result.slice(1);
};

export const getConfig = (themeName, config, domains, dev) => {
  let name = themeName;
  for (let i = 0; i < domains.length; i++) {
    const nameFromUrl = getThemeNameFromDomainUrl(domains[i], dev);
    if (nameFromUrl) {
      name = nameFromUrl;
      break;
    }
  }

  if (getBankSiteName(dev)) {
    name = "banksites";
  }

  return config[name];
};
