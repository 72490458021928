import {
  SET_IS_LOADED,
  SET_CATEGORIES_LIST
} from '../constants/categoriesTypes';

export const setIsLoaded = () => {
  return {
    type: SET_IS_LOADED
  };
};

export const setCategoriesList = (list) => {
  return {
    type: SET_CATEGORIES_LIST,
    list
  };
};
