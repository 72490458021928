import React from "react";

import classes from "./Checkbox.module.sass";

const Checkbox = ({ name, icon, title, ...props }) => {
  return (
    <label className={classes.wrap}>
      <input className={classes.input} type="checkbox" name={name} {...props} />
      <div className={classes.content}>
        {icon && <div className={classes.icon}>{icon}</div>}
        <div className={classes.body}>
          <div className={classes.info}>
            <div className={classes.title}>{title}</div>
          </div>
          <div className={classes.check}>
            <span></span>
          </div>
        </div>
      </div>
    </label>
  );
};

export default Checkbox;
