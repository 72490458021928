import LogoHadew from "./hadew.svg";
import LogoFitculator from "./fitculator.png";
import LogoFitculatorWhite from "./fitculator-white.png";
import LogoMealplan60fit from "./mealplan60fit.png";
import LogoBanksite from "./banksite.png";
import LogoDelifitplan from "./delifitplan.png";
import LogoFunappetit from "./funappetit.png";

export default {
  hadew: LogoHadew,
  fitculator: LogoFitculator,
  fitculatorWhite: LogoFitculatorWhite,
  mealplan60fit: LogoMealplan60fit,
  banksite: LogoBanksite,
  delifitplan: LogoDelifitplan,
  funappetit: LogoFunappetit,
};
