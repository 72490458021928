import { contentAPI } from "../api";
import {
  SET_IS_LOADED,
  SET_HISTORY_WORKOUTS,
  SET_HISTORY_INFO,
  SET_IS_FETCHING,
  UPDATE_HISTORY_WORKOUTS,
  SET_HISTORY_PREVIEW_LIST,
  UPDATE_HISTORY_PREVIEW_LIST,
  SET_FILTER_DATA,
  ADD_CURRENT_MONTH_COUNT,
  TOGGLE_ELEMENT_TO_DELETE,
  CLEAR_ELEMENT_TO_DELETE,
  ADD_IS_DELETING,
  DELETE_WORKOUTS,
} from "../constants/historyTypes";

export const deleteWorkouts = () => {
  return (dispatch, getState) => {
    const { workoutsToDelete, start, count, workouts } = getState().history;
    if (workoutsToDelete.length) {
      dispatch(setIsDeleting(true));
      const deleteString = workoutsToDelete
        .map((item, index) => {
          if (index === 0) {
            return "?id_in=" + item;
          }
          return "&id_in=" + item;
        })
        .join("");
      return contentAPI.deleteWorkoutsHistory(deleteString).then((res) => {
        return contentAPI.getFinishedWorkoutPlan(0, 0).then((res) => {
          dispatch(
            setHistoryInfo(
              res.data.count,
              7,
              res.data.totalCalories,
              res.data.totalDuration
            )
          );

          const deleteLength = workoutsToDelete.length;
          const allLength = workouts.length - deleteLength;
          const newStart = start - deleteLength;
          const newCount = count - deleteLength;

          dispatch(setDeleteWorkouts(newCount, newStart));
          dispatch(setIsDeleting(false));
          if (newCount > allLength && allLength < 7) {
            dispatch(setIsFetching(true));
            return contentAPI
              .getFinishedWorkoutPlan(allLength, 7 - allLength)
              .then((res) => {
                dispatch(
                  setHistoryInfo(
                    res.data.count,
                    7,
                    res.data.totalCalories,
                    res.data.totalDuration
                  )
                );
                dispatch(updateHistoryWorkouts(res.data.data));
                dispatch(setIsFetching(false));
              });
          }
        });
      });
    }
  };
};

export const setDeleteWorkouts = (count, start) => ({
  type: DELETE_WORKOUTS,
  payload: {
    count,
    start,
  },
});

export const setIsDeleting = (payload) => ({
  type: ADD_IS_DELETING,
  payload,
});

export const clearElementsToDelete = () => ({
  type: CLEAR_ELEMENT_TO_DELETE,
});

export const toggleDeleteElement = (id) => ({
  type: TOGGLE_ELEMENT_TO_DELETE,
  id,
});

export const setPreviewWorkouts = (history, count, calories) => {
  return {
    type: SET_HISTORY_PREVIEW_LIST,
    list: history,
    count,
    calories,
  };
};

export const setFilterData = (filterData) => {
  return {
    type: SET_FILTER_DATA,
    filterData,
  };
};

export const setCurrentHistoryCount = (count) => {
  return {
    type: ADD_CURRENT_MONTH_COUNT,
    count,
  };
};

export const updatePreviewWorkouts = (workoutPlan) => {
  return {
    type: UPDATE_HISTORY_PREVIEW_LIST,
    workoutPlan,
  };
};

export const setIsLoaded = () => {
  return {
    type: SET_IS_LOADED,
  };
};

export const setIsFetching = (isFetching) => {
  return {
    type: SET_IS_FETCHING,
    isFetching,
  };
};

export const setHistoryWorkouts = (workouts) => {
  return {
    type: SET_HISTORY_WORKOUTS,
    workouts,
  };
};

export const updateHistoryWorkouts = (workouts) => {
  return {
    type: UPDATE_HISTORY_WORKOUTS,
    workouts,
  };
};

export const setHistoryInfo = (count, start, calories, duration) => {
  return {
    type: SET_HISTORY_INFO,
    count,
    start,
    calories,
    duration,
  };
};
