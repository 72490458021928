import React from "react";

import Loader from "../Loader";

import IconsSprite from "../../../../common/IconSprite";

import "./Body.sass";

const Body = ({ isDone, isError }) => {
  return (
    <div className="subs-modal-body">
      {isError && (
        <div className="subs-modal-body-error">
          <div className="subs-modal-body-error__inner">
            <div className="subs-modal-body-ico">
              <span></span>
              <span></span>
            </div>
            <div className="subs-modal-body__desc">Error</div>
            {/* <div className="subs-modal-body__desc">
              Lorem ipsum dolor sit amet.
            </div> */}
          </div>
        </div>
      )}
      {isDone && (
        <>
          <Loader className="subs-modal-body-icon" />
          <div className="subs-modal-body__title">
            You're in! Congratulations!
          </div>
          <div className="subs-modal-body__desc">
            Good luck on your journey ahead
          </div>
        </>
      )}
      {!isDone && !isError && (
        <>
          <div className="subs-modal-body__title">
            7 day FREE Access to all our exclusive content
          </div>
          {/* <div className="subs-modal-body__desc">
            Take your fitness goals to the next level. The first 7 days are free
          </div> */}
          <ul className="subs-modal-body__list">
            <li className="subs-modal-body__item">
              <span className="subs-modal-body__icon">
                <IconsSprite className="icon-check" name="check-outlined" />
              </span>
              <span className="subs-modal-body__text">
                Dancing, Yoga, 10 minute workouts and MUCH MUCH more
              </span>
            </li>
            <li className="subs-modal-body__item">
              <span className="subs-modal-body__icon">
                <IconsSprite className="icon-check" name="check-outlined" />
              </span>
              <span className="subs-modal-body__text">
                1300+ Deserts, Smoothies and Delicious snacks for your diet
              </span>
            </li>
            <li className="subs-modal-body__item">
              <span className="subs-modal-body__icon">
                <IconsSprite className="icon-check" name="check-outlined" />
              </span>
              <span className="subs-modal-body__text">
                It's obviously free for 7 days
              </span>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default Body;
