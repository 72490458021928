import {
  SET_IS_LOADED,
  SET_RECIPES_LIST,
  SET_RECIPES_LIST_INFO,
  UPLOAD_RECIPES_LIST,
  SET_RECIPES_LIST_IS_FETCHING,
  ADD_LIKE,
  ADD_FAVORITES_RECIPES,
  REMOVE_LIKE,
  SET_FAVORITES_LIST_INFO,
  UPDATE_FAVORITES_LIST_INFO,
  ADD_FILTERS_PARAMS
} from '../constants/recipesTypes';

const initialState = {
  isLoaded: false,
  recipesList: [],
  recipesCount: null,
  recipesCurrentStart: null,
  recipesIsFetching: false,
  favoriteRecipesList: [],
  favoritesCount: 0,
  favoritesCurrentStart: null,
  favoritesLimit: 8,
  filterParams: {
    cooking_time_lte: "all",
    diet_preferences: "all",
    meal_types: "all",
  },
  filtersString: '',
};

const recipes = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADED: {
      return {
        ...state,
        isLoaded: true
      }
    }
    case SET_RECIPES_LIST_IS_FETCHING: {
      return {
        ...state,
        recipesIsFetching: action.recipesIsFetching
      }
    }
    case ADD_FILTERS_PARAMS: {
      return {
        ...state,
        filterParams: { ...action.filterParams },
        filtersString: action.filtersString
      }
    }
    case SET_RECIPES_LIST: {
      return {
        ...state,
        recipesList: [...action.list]
      }
    }
    case UPLOAD_RECIPES_LIST: {
      return {
        ...state,
        recipesList: [...state.recipesList, ...action.list]
      }
    }
    case SET_RECIPES_LIST_INFO: {
      return {
        ...state,
        recipesCount: action.count,
        recipesCurrentStart: action.currentStart
      }
    }
    case ADD_LIKE: {
      const newArr = [...state.recipesList];
      const recipeIdx = state.recipesList.findIndex(recipe => recipe.id === action.id);
      newArr[recipeIdx].isFavourite = true;
      newArr[recipeIdx].favoriteId = action.newRecipe.id;

      let newCurrentStart = ++state.favoritesCurrentStart;
      let newFavoritesLimit = 8 - ((state.favoriteRecipesList.length + 1) % 8) || 8;

      if (newFavoritesLimit < 4) {
        newFavoritesLimit += 4;
      }

      return {
        ...state,
        recipesList: [...newArr],
        favoriteRecipesList: [action.newRecipe, ...state.favoriteRecipesList],
        favoritesCount: state.favoritesCount + 1,
        favoritesCurrentStart: newCurrentStart,
        favoritesLimit: newFavoritesLimit
      }
    }
    case REMOVE_LIKE: {
      const newArr = [...state.recipesList];
      const recipeIdx = state.recipesList.findIndex(recipe => recipe.id === action.id);
      const favoriteIdx = state.favoriteRecipesList.findIndex(recipe => recipe.id === action.recipeId);

      if (recipeIdx !== -1) {
        newArr[recipeIdx].isFavourite = false;
      }

      let favoriteRecipesList = [];

      if (favoriteIdx !== -1) {
        favoriteRecipesList = [
          ...state.favoriteRecipesList.slice(0, favoriteIdx),
          ...state.favoriteRecipesList.slice(favoriteIdx + 1)
        ];
      }

      let newCurrentStart = --state.favoritesCurrentStart;
      let newFavoritesLimit = 8 - ((state.favoriteRecipesList.length - 1) % 8) || 8;

      if (newFavoritesLimit < 4) {
        newFavoritesLimit += 4;
      }

      return {
        ...state,
        recipesList: [...newArr],
        favoriteRecipesList: [...favoriteRecipesList],
        favoritesCount: state.favoritesCount - 1,
        favoritesCurrentStart: newCurrentStart,
        favoritesLimit: newFavoritesLimit
      }
    }
    case ADD_FAVORITES_RECIPES: {
      return {
        ...state,
        favoriteRecipesList: [...action.list]
      }
    }
    case UPDATE_FAVORITES_LIST_INFO: {
      return {
        ...state,
        favoriteRecipesList: [...state.favoriteRecipesList, ...action.list]
      }
    }
    case SET_FAVORITES_LIST_INFO: {
      return {
        ...state,
        favoritesCount: action.count,
        favoritesCurrentStart: action.currentStart,
      }
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState
      }
    }
    default:
      return state;
  }
};


export default recipes;
