import React, { useState, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { Modal, Step, Diets, Gender, Food, Measurements } from "./components";
import config from "./config";

import "./Animation.sass";

const Survey = ({ defaultUnits, isOpen, loading, onSubmit }) => {
  const stepsCount = 4;
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState({});
  const [diet, setDiet] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setOpen(true);
        document.body.style.overflow = "hidden";
      });
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const handleChooseDiet = (product) => {
    setDiet(product);
    setStep(step + 1);
  };

  const handleNextStep = (data) => {
    setStep(step + 1);
    setAnswers({ ...answers, ...data });
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleSubmitMeasurements = (data) => {
    setAnswers({ ...answers, ...data });

    let result = {};
    const keys = Object.keys(answers);

    keys.forEach((key) => {
      if (key !== "food") {
        result[key] = answers[key].split(",");
      }
    });

    const keysFood = Object.keys(answers.food);
    keysFood.forEach((key) => {
      const answer = answers.food[key];
      const question = config.find((item) => item.name === key);

      const include = [];
      const exclude = [];

      question.answers.forEach((item) => {
        if (answer === item.value) {
          include.push(item.value);
        } else {
          exclude.push(item.value);
        }
      });

      result[`${key}(include)`] = include;
      result[`${key}(exclude)`] = exclude;
    });

    result.activity = 1.38;

    result = { ...result, activity: 1.38, ...data };

    onSubmit({ product: diet, additionalData: result }).then((res) => {
      setOpen(false);
    });
  };

  return (
    <Modal open={open} step={step} stepLength={stepsCount} fetching={loading}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={step}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
          timeout={200}
        >
          <>
            {step === 1 && (
              <Step title="Select a meal plan that best fits your lifestyle">
                <Diets answer={diet} onNext={handleChooseDiet} />
              </Step>
            )}
            {step === 2 && (
              <Step title="Select your gender" fullHeight>
                <Gender
                  answer={answers["gender"]}
                  onNext={handleNextStep}
                  onPrev={handlePrevStep}
                />
              </Step>
            )}
            {step === 3 && (
              <Step title="Select your food preferences" fullHeight>
                <Food
                  config={config}
                  answers={answers.food}
                  diet={diet}
                  onNext={handleNextStep}
                  onPrev={handlePrevStep}
                />
              </Step>
            )}
            {step === 4 && (
              <Step fullHeight>
                <Measurements
                  onPrev={handlePrevStep}
                  onSubmit={handleSubmitMeasurements}
                  answers={answers.measurements}
                  units={defaultUnits}
                />
              </Step>
            )}
          </>
        </CSSTransition>
      </SwitchTransition>
    </Modal>
  );
};

export default Survey;
