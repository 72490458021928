import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import { CSSTransition } from "react-transition-group";
import themeConfig from "../../../../../configs/theme";
import IconSprite from "../../../../common/IconSprite";

import NotPurchasedIcon from "../NotPurchasedIcon";
import { purchaseConfig } from "../../../../../configs/mealPlans";
import "./Dropdown.sass";

const Dropdown = ({
  className,
  list,
  activePlan,
  onChoose,
  onUpdate,
  notPurchasedMealPlans,
  handleReplaceMealPlan,
  passedLongTime,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseDropdown = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdown);

    return () => {
      document.removeEventListener("click", handleCloseDropdown);
    };
  }, [handleCloseDropdown]);

  if ((list && list.length < 2) || themeConfig.type !== "fitculator") {
    return null;
  }

  return (
    <div
      className={clsx("plans-dropdown", {
        [className]: className,
        open: isOpen,
      })}
    >
      {Boolean(notPurchasedMealPlans.length) && (
        <NotPurchasedIcon className="plans-dropdown__with-cart" />
      )}
      {passedLongTime && <div className="plans-dropdown-dot"></div>}
      <div className="plans-dropdown__active" onClick={handleToggleDropdown}>
        <div className="plans-dropdown__inner">
          <div className="plans-dropdown__name">{activePlan.name}</div>
          <div className="plans-dropdown__status">{activePlan.status}</div>
        </div>
        <IconSprite className="plans-dropdown__angle" name="angle-bottom" />
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="plans-dropdown-transition"
        unmountOnExit
      >
        <div className="plans-dropdown__list" onClick={handleToggleDropdown}>
          {list.length &&
            list.map(({ id, name, status, isPurchased }) => {
              if (id === activePlan.id) {
                return null;
              }
              if (isPurchased) {
                return (
                  <div
                    className="plans-dropdown__item"
                    key={id}
                    onClick={() => onChoose(id, isPurchased)}
                  >
                    <div className="plans-dropdown__inner">
                      <div className="plans-dropdown__name">{name}</div>
                      <div className="plans-dropdown__status">{status}</div>
                    </div>
                  </div>
                );
              }
              return (
                <div
                  className="plans-dropdown__item plans-dropdown__item-not-purchased"
                  key={id}
                  onClick={() => onChoose(id, isPurchased)}
                >
                  <NotPurchasedIcon className="plans-dropdown__item-not-purchased-icon" />
                  <div className="plans-dropdown__inner">
                    <div className="plans-dropdown__name">{name}</div>
                    <div className="plans-dropdown__status strong">
                      {status}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </CSSTransition>
      {activePlan.isPurchased && !activePlan.isDefault && (
        <button
          className="plans-dropdown-btn"
          onClick={() => handleReplaceMealPlan(activePlan.type, activePlan.id)}
        >
          Set as default
        </button>
      )}
      {!activePlan.isPurchased && (
        <button
          className="plans-dropdown-btn no-purchased"
          onClick={() => onUpdate(activePlan.type)}
        >
          GET this meal plan for ${purchaseConfig[activePlan.type].price}
        </button>
      )}
    </div>
  );
};

export default Dropdown;
