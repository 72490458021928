import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import amplitude from "amplitude-js";
import { useHistory } from "react-router-dom";
import themeConfig from "../../../configs/theme";

import classes from "./Greeting.module.sass";
import "./Animation.sass";

const Greeting = ({ open }) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const goToLogWeight = () => {
    history.push("/workouts");
    amplitude.getInstance().logEvent("greeting_modal_open_workout");
    handleCloseModal();
  };

  const goToMeals = () => {
    history.push("/meals");
    amplitude.getInstance().logEvent("greeting_modal_open_meal_plan");
    handleCloseModal();
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        amplitude
          .getInstance()
          .logEvent("open_greeting_modal", { type: "welcome" });
        setIsOpen(true);
        document.body.style.overflow = "hidden";
      });
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);

  return (
    <CSSTransition
      in={isOpen}
      timeout={400}
      classNames="greeting"
      unmountOnExit
      onExited={() => {
        document.body.style.overflow = "";
      }}
    >
      <div className={classes.wrap}>
        <div className={classes.inner}>
          <div className={`${classes.modal} greeting-modal`}>
            <div className={classes.body}>
              <div className={classes.content}>
                <div className={classes.title}>
                  Welcome to {themeConfig.title}
                </div>
                <div className={classes.subtitle}>
                  You just joined a community with 20k+ active members.
                </div>
                <ul className={classes.list}>
                  <li className={classes.item}>
                    Easy to follow 60-day meal plan
                  </li>
                  <li className={classes.item}>Amazing and fun workouts</li>
                  <li className={classes.item}>
                    3 other meal plan options (Flexitarian, Mediterranean,
                    Vegetarian) if you feel like trying something new
                  </li>
                  <li className={classes.item}>
                    Weight tracker. See how much weight you lost with your 60
                    day meal plan. On average {themeConfig.title} clients can
                    lose up to 40+ pounds
                  </li>
                </ul>
                <div className={classes.desc}>
                  Let's get started!
                  <span role="img" aria-label="power">
                    💪
                  </span>
                </div>
              </div>
              <div className={classes.footer}>
                <button className={classes.btn} onClick={goToLogWeight}>
                  Start your workout
                </button>
                <button className={classes.btn} onClick={goToMeals}>
                  open your
                  <br />
                  meal plan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Greeting;
