import React from "react";

import ModalRight from "../../../common/components/ModalRight";

import Head from "./components/Head";
import Body from "./components/Body";
import Footer from "./components/Footer";

import "./Subscriptions.sass";

const Subscriptions = ({
  handleCloseModal,
  onPurchaseSubscriptions,
  isDone,
  isError,
  ...props
}) => {
  return (
    <ModalRight
      {...props}
      onClose={handleCloseModal}
      head={<Head isShow={!isDone && !isError} />}
      footer={
        <Footer
          onPurchaseSubscriptions={onPurchaseSubscriptions}
          isDone={isDone}
          isError={isError}
          handleCloseModal={handleCloseModal}
        />
      }
    >
      <Body isDone={isDone} isError={isError} />
    </ModalRight>
  );
};

export default Subscriptions;
