import {
  SET_TOGGLE_FETCHING,
  SET_MEAL,
  SET_CLEAR,
  ADD_ERROR,
} from "../constants/mealTypes";

const initialState = {
  meal: null,
  isFetching: false,
  error: null,
};

const meal = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOGGLE_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case ADD_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case SET_MEAL: {
      return {
        ...state,
        meal: action.data,
      };
    }
    case SET_CLEAR: {
      return {
        ...state,
        meal: null,
        error: null,
      };
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default meal;
