import React from 'react';
import clsx from 'clsx';
import Button from '../../common/Button';

import './ConfirmModal.sass';

const ConfirmModal = ({
  title = '',
  description = '',
  handleMainClick,
  buttonMainName,
  handleSecondClick,
  buttonSecondName,
  handleClose,
  children,
  className,
  mainButtonDisabled
}) => {
  return (
    <div className={clsx("small-modal-wrap", {
      [className]: className
    })}>
      <div className="small-modal-bg" onClick={handleClose}></div>
      <div className="small-modal">
        <div className="small-modal-inner">
          <div className="small-modal-inner__title">{title}</div>
          <div className="small-modal-inner__desc">{description}</div>
          {children}
          <div className="small-modal-inner__footer">
            <Button size="large" disabled={mainButtonDisabled} onClick={handleMainClick}>{buttonMainName}</Button>
            <Button type="secondary" size="large" onClick={handleSecondClick}>{buttonSecondName}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
