import React from "react";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import themeConfig from "../configs/theme";
import { Redirect, withRouter } from "react-router-dom";
import { SubmissionError } from "redux-form";

import { authAPI } from "../api";
import axios from "../configs/axios";
import {
  checkSubscribeStatuses,
  amplitudeTransformData,
} from "../common/helpers";
import {
  setFetchingLoader,
  setAuthData,
  setStatuses,
  setAppFields,
  setLastVisit,
} from "../actions/auth";

import { setBaseUserData } from "../actions/profile";

import SignUp from "../components/pages/SignUp";

const getLanguage = () => {
  return window.navigator.userLanguage || window.navigator.language;
};

const SignUpPage = ({ isFetching, sendPassword, location }) => {
  const urlCodeParam = new URLSearchParams(location.search).get("code");
  const urlSourceParam = new URLSearchParams(location.search).get("source");

  const handleSubmitForm = (values) => {
    return sendPassword(
      urlCodeParam,
      values.password,
      values.repeat_password,
      urlSourceParam
    ).then((res) => {
      if (res) {
        if (res.response && res.response.data) {
          const errorDesc = res.response.data.message;
          if (errorDesc === "Forbidden") {
            throw new SubmissionError({
              password: "Password has already been created",
            });
          } else {
            throw new SubmissionError({
              password: res.response.data.message,
            });
          }
        } else if (res.innerError) {
          throw new SubmissionError({
            password: res.message,
          });
        } else {
          throw new SubmissionError({
            password: "Ops... Unknown error",
          });
        }
      }
    });
  };

  if (!urlCodeParam) {
    return <Redirect to="/signin" />;
  }

  return (
    <>
      <Helmet>
        <title>Sign Up - {themeConfig.title}</title>
      </Helmet>
      <SignUp handleSubmitForm={handleSubmitForm} isFetching={isFetching} />
    </>
  );
};

export default connect(
  (state) => ({
    isFetching: state.auth.isFetching,
  }),
  (dispatch) => ({
    sendPassword: (code, password, repeatPassword, urlSourceParam) => {
      dispatch(setFetchingLoader(true));
      return authAPI
        .createPassword(
          code,
          password,
          repeatPassword,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          getLanguage()
        )
        .then((res) => {
          let userIsActive = checkSubscribeStatuses(res.data.subscribes);
          if (!userIsActive) {
            if (!userIsActive) {
              throw Object.assign(new Error(), {
                innerError: true,
                message: "Account is not active",
              });
            }
          }
          dispatch(setStatuses(res.data.subscribes));
          localStorage.setItem("token", res.data.jwt);
          localStorage.setItem("userId", res.data.user.id);
          localStorage.setItem("userName", res.data.user.first_name);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.data.jwt}`;
          dispatch(
            setBaseUserData(
              res.data.user.id,
              res.data.user.first_name,
              true,
              res.data.user.created_at,
              res.data.user.current_weight,
              res.data.user.units,
              res.data.user.email,
              res.data.user.actual_weight || res.data.user.current_weight,
              res.data.user.goal_weight,
              res.data.user.height,
              res.data.user.age,
              res.data.user.country_code
            )
          );
          dispatch(setLastVisit(res.data.user.last_visited));

          amplitude.getInstance().setUserId(res.data.user.email);
          amplitude.getInstance().setGroup("Client Name", themeConfig.title);
          amplitude
            .getInstance()
            .logEvent(
              "user_properties",
              amplitudeTransformData(res.data.user, res.data.subscribes)
            );
          dispatch(
            setAuthData(
              res.data.jwt,
              true,
              res.data.user.id,
              res.data.user.username,
              true
            )
          );
          if (urlSourceParam) {
            dispatch(setAppFields(urlSourceParam));
          }

          return res;
        })
        .catch((err) => {
          dispatch(setFetchingLoader(false));
          console.log(err);
          return err;
        });
    },
  })
)(withRouter(SignUpPage));
