import React from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import themeConfig from "../../../configs/theme";
import "./Logo.sass";

import logos from "./icons";

const Logo = ({ company, className, whiteVariant, disabled }) => {
  if (themeConfig.isBankSite) {
    return (
      <NavLink to="/" className="logo bank-site-logo">
        <div className="bank-site-logo__inner">
          <img
            className="bank-site-logo__img"
            src={logos["banksite"]}
            alt={themeConfig.title}
          />
          <div className="bank-site-logo__body">
            <div className="bank-site-logo__desc">{themeConfig.title}</div>
            <div className="bank-site-logo__desc">Meals & Fitness</div>
          </div>
        </div>
      </NavLink>
    );
  }

  const handleClick = (event) => {
    if (disabled) {
      event.preventDefault();
    }
  };

  return (
    <NavLink
      to="/"
      className={clsx("logo", {
        [themeConfig.name]: themeConfig.name,
        [className]: className,
      })}
      onClick={handleClick}
    >
      <div className="logo-inner">
        <img
          src={logos[themeConfig.name + (whiteVariant ? "White" : "")]}
          alt={themeConfig.title}
        />
        {!company || <div className="logo-company">{company}</div>}
      </div>
    </NavLink>
  );
};

export default Logo;
