import React from "react";

import HeadIcon from "../HeadIcon";
import Title from "../Title";
import Button from "../Button";

import classes from "./ModalPromo.module.sass";

const ModalPromo = ({ onCancel, onSend }) => {
  return (
    <div className={classes.wrap}>
      <div className={classes.body}>
        <HeadIcon name="promo" />
        <Title size="large">WAIT, BEFORE YOU GO!</Title>
        <Title type="desc" className={classes.desc}>
          <p>We have an amazing offer for you today</p>
          <p>
            Keep unlimited access to your incredible meal plan as well as other
            meal plans and great recipes along with workout videos for{" "}
            <strong>only $10.99 per month</strong>.
          </p>
          <p>Don't miss out on this deal of a lifetime!</p>
          <p>
            Click <strong>'Accept'</strong> below to continue your access
          </p>
        </Title>
      </div>
      <div className={classes.footer}>
        <Button className={classes.btn} onClick={onSend}>
          Accept
        </Button>
        <Button
          type="transparent"
          className={classes.emptyBtn}
          onClick={onCancel}
        >
          finish canceling
        </Button>
      </div>
    </div>
  );
};

export default ModalPromo;
