import produce from "immer";

import {
  SET_BASE_USER_DATA,
  SET_IS_APP_LOADED,
  SET_IS_LOADED,
  SET_ALL_USER_DATA,
  CLEAR_USER_DATA,
  SET_GOAL_WEIGHT,
  SET_IS_FETCHING,
  SET_TEMP_AVATAR,
  SET_AVATAR,
  ADD_ACTUAL_WEIGHT,
  ADD_RESULTS_DATA,
  ADD_CANCEL_RESULT,
  ADD_MC_LOADED,
  UPDATE_USER_DATA,
  SET_HAS_INVITED_STATUS,
} from "../constants/profileTypes";

const initialState = {
  isAppLoaded: false,
  isFetching: false,
  isLoaded: false,
  userId: null,
  userName: null,
  confirmed: null,
  height: null,
  currentWeight: null,
  goalWeight: null,
  measurement: null,
  language: null,
  email: null,
  timezone: null,
  tempAvatar: null,
  avatar: null,
  profileCreatedDate: null,
  mealPlanTitle: null,
  actualWeight: null,
  subscribeCancelSent: false,
  age: null,
  activity: null,
  gender: null,
  cancelResult: {},
  microCommitmentFetching: false,
  microCommitmentLoaded: false,
  configGoals: [],
  configProducts: [],
  defaultUnits: "metric",
  hasInvited: null,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case SET_HAS_INVITED_STATUS: {
      return {
        ...state,
        hasInvited: action.payload,
      };
    }
    case UPDATE_USER_DATA: {
      return {
        ...state,
        currentWeight: action.payload.current_weight,
        actualWeight: action.payload.current_weight,
        goalWeight: action.payload.goal_weight,
        height: action.payload.height,
        age: action.payload.age,
      };
    }
    case ADD_MC_LOADED: {
      return {
        ...state,
        microCommitmentLoaded: true,
        configProducts: action.payload.diets,
        configGoals: action.payload.goals,
      };
    }
    case ADD_CANCEL_RESULT: {
      const newCancelResult = produce(state.cancelResult, (draft) => {
        draft[action.fieldType] = action.payload;
      });
      return {
        ...state,
        cancelResult: newCancelResult,
      };
    }
    case ADD_RESULTS_DATA: {
      return {
        ...state,
        age: action.age,
        activity: action.activity,
        gender: action.gender,
      };
    }
    case SET_IS_APP_LOADED: {
      return {
        ...state,
        isAppLoaded: true,
      };
    }
    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case SET_IS_LOADED: {
      return {
        ...state,
        isLoaded: true,
      };
    }

    case SET_GOAL_WEIGHT: {
      return {
        ...state,
        goalWeight: action.goalWeight,
      };
    }
    case SET_AVATAR: {
      return {
        ...state,
        avatar: action.avatar,
      };
    }
    case SET_TEMP_AVATAR: {
      return {
        ...state,
        tempAvatar: action.tempAvatar,
      };
    }
    case SET_ALL_USER_DATA: {
      return {
        ...state,
        userId: action.userId,
        userName: action.userName,
        confirmed: action.confirmed,
        height: action.height,
        currentWeight: action.currentWeight,
        goalWeight: action.goalWeight,
        measurement: action.measurement,
        language: action.language,
        email: action.email,
        timezone: action.timezone,
        profileCreatedDate: action.profileCreatedDate,
        mealPlanTitle: action.mealPlanTitle,
        subscribeCancelSent: action.subscribeCancelSent,
      };
    }
    case SET_BASE_USER_DATA: {
      return {
        ...state,
        userId: action.userId,
        userName: action.userName,
        confirmed: action.confirmed,
        profileCreatedDate: action.profileCreatedDate,
        currentWeight: action.currentWeight,
        measurement: action.measurement,
        email: action.email,
        actualWeight: action.actualWeight,
        goalWeight: action.goalWeight,
        height: action.height,
        age: action.age,
        defaultUnits: action.defaultUnits,
      };
    }
    case ADD_ACTUAL_WEIGHT: {
      return {
        ...state,
        actualWeight: action.actualWeight,
      };
    }
    case CLEAR_USER_DATA: {
      return {
        ...state,
        height: null,
        language: null,
        timezone: null,
        isLoaded: false,
      };
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default profile;
