import React from 'react';

import './loaderIcon.sass';

const LoaderIcon = ({
  size,
  color
}) => {
  const loaderSize = size ? size : 24;
  const loaderColor = color ? color : '#7DF3B3';

  return (
    <div className="loader-icon">
      <div style={{ borderColor: loaderColor, width: loaderSize, height: loaderSize }}></div>
    </div>
  )
};

export default LoaderIcon;
