import React from "react";

import HeadIcon from "../HeadIcon";
import Title from "../Title";
import Button from "../Button";

import classes from "./ModalThanks.module.sass";

const ModalThanks = ({ onCancel }) => {
  return (
    <div className={classes.wrap}>
      <div className={classes.body}>
        <HeadIcon name="thanks" />
        <Title size="large">AMAZING!</Title>
        <Title type="desc">
          So happy you are staying with us!
          <br />
          This promo is as delicious as our recipes
        </Title>
      </div>
      <div className={classes.footer}>
        <Button className={classes.btn} onClick={onCancel}>
          Let’s Go
        </Button>
      </div>
    </div>
  );
};

export default ModalThanks;
