import React, { useState, useEffect } from "react";
import amplitude from "amplitude-js";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import themeConfig from "../configs/theme";
import { Redirect, useHistory } from "react-router-dom";

import { contentAPI } from "../api";
import { updatePreviewWorkouts } from "../actions/history";

import { clearAuth } from "../actions/auth";

import RateWorkoutModal from "../components/modals/RateWorkoutModal";
import WorkoutPlanPlay from "../components/pages/WorkoutPlanPlay";

const calculateSetCount = (workouts) => {
  let count = 0;

  workouts.forEach((workout) => {
    count = count + (workout.repeatCount || 1);
  });
  return count;
};

const transformWorkoutPlanData = (plan) => {
  if (!plan) return false;
  const { workouts } = plan;
  const phrasesArr = [
    "Coming up - ",
    "Time for ",
    "Get Ready for ",
    "Here we go with ",
    "Next drill up is ",
    "Up Next - ",
  ];
  let phrasesIndex = 0;
  let setNumber = 1;

  const result = workouts.map((workout, idx) => {
    const repeat = parseInt(workout.repeatCount) || 1;

    const arrLength = workout.workout_exercises.length;
    const resultArr = [];
    let j = 0;

    for (let i = 0; i < arrLength * repeat; i++) {
      const item = workout.workout_exercises[j];

      const resultItem = {
        type: "exercise",
        setId: workout.id,
        id: `${workout.id}-${i}`,
        name: item.exercise.title,
        video1: item.exercise.video1,
        video2: item.exercise.video2,
        video2Preview: item.exercise.video2Preview,
        index: j,
        setIndex: idx,
        setNumber: setNumber,
        exerciseCount: arrLength,
        preview: item.exercise.video1Preview,
        duration: parseInt(item.duration) || 15,
      };

      if (workout.workout_exercises[j + 1]) {
        resultItem.nextExerciseName =
          workout.workout_exercises[j + 1].exercise.title;
      } else if (i + 1 < arrLength * repeat) {
        resultItem.nextExerciseName =
          workout.workout_exercises[0].exercise.title;
      } else if (idx + 1 < workouts.length) {
        resultItem.nextExerciseName =
          workouts[idx + 1].workout_exercises[0].exercise.title;
      } else {
        resultItem.nextExerciseName = "finish";
      }

      if (j === 0) {
        resultItem.phrase = "Starting of with " + item.exercise.title;
      } else if (j === arrLength - 1) {
        resultItem.phrase = "Finishing with " + item.exercise.title;
      } else {
        resultItem.phrase = phrasesArr[phrasesIndex] + item.exercise.title;
        if (phrasesIndex < phrasesArr.length - 1) {
          phrasesIndex++;
        } else {
          phrasesIndex = 0;
        }
      }

      resultArr.push(resultItem);

      if (j < arrLength - 1) j++;
      else {
        if (i < arrLength * repeat - 1) {
          resultArr.push({
            type: "rest",
            id: "rest" + i + workout.id,
            duration: parseInt(workout.rest) || 20,
            phrase: `Recover for ${parseInt(workout.rest) || 20} seconds`,
          });
        }
        j = 0;
        setNumber++;
      }
    }

    if (idx < workouts.length - 1) {
      resultArr.push({
        type: "rest",
        id: "rest" + workout.id,
        duration: parseInt(plan.rest) || 30,
        phrase: `Recover for ${parseInt(plan.rest) || 30} seconds`,
      });
    }

    return resultArr;
  });

  return [].concat(...result);
};

const sendGA = (event) => {
  if (window.gtag) {
    window.gtag("event", event);
  }
  amplitude.getInstance().logEvent(event);
};

const handleRotateTrack = ({ currentTarget }) => {
  if (currentTarget.innerHeight > currentTarget.innerWidth) {
    sendGA("rotate screen");
  }
};

const WorkoutPlanPlayPage = ({ workoutPlan, sendWorkoutFinished }) => {
  const history = useHistory();

  const [workoutPlayModel, setWorkoutPlayModel] = useState(null);

  const [isPlayWorkout, setIsPlayWorkout] = useState(false);
  const [isWorkoutFinishModal, setIsWorkoutFinishModal] = useState(false);
  const [isWorkoutFinish, setIsWorkoutFinish] = useState(false);
  const [rateModalIsOpen, setRateModalIsOpen] = useState(true);
  const [isWorkoutPause, setIsWorkoutPause] = useState(null);
  const [workoutSetsCount, setWorkoutSetsCount] = useState(null);

  useEffect(() => {
    window.addEventListener("orientationchange", handleRotateTrack);

    return () => {
      window.removeEventListener("orientationchange", handleRotateTrack);
    };
  }, []);

  useEffect(() => {
    if (!workoutPlayModel) {
      setWorkoutPlayModel(transformWorkoutPlanData(workoutPlan));
    }
    if (workoutPlan && !workoutPlayModel) {
      setWorkoutSetsCount(calculateSetCount(workoutPlan.workouts));
    }
  }, [workoutPlan, workoutPlayModel]);

  const handleFinishWorkoutPlay = () => {
    document.body.style.overflow = "";
    history.push("/workouts/" + workoutPlan.id, {
      from: "timer",
    });
    sendGA("finish workouts");
  };

  const toggleWorkoutPause = () => {
    if (!isWorkoutPause) {
      sendGA("pause workout timer");
    }
    setIsWorkoutPause(!isWorkoutPause);
  };

  const handleChangeModuleStatus = (status) => {
    setIsPlayWorkout(status);
  };

  const handleOpenStopWorkoutModal = () => {
    if (!isWorkoutPause) {
      toggleWorkoutPause();
    }
    setIsWorkoutFinishModal(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseStopWorkoutModal = () => {
    toggleWorkoutPause();
    setIsWorkoutFinishModal(false);
    document.body.style.overflow = "";
  };

  const handleSendWorkoutPlan = (effect = "easy") => {
    sendWorkoutFinished(workoutPlan.id, effect);
    setRateModalIsOpen(false);
  };

  if (!workoutPlan) {
    return <Redirect to="/workouts" />;
  }

  const isiPhone =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  return (
    <>
      <Helmet>
        <title>
          Timer: {workoutPlan.title} - Workouts by {themeConfig.title}
        </title>
        <html
          className={clsx("workout-play-horizontal", {
            "is-ios": isiPhone,
          })}
        />
        <body
          className={clsx("workout-play-horizontal", {
            "is-ios": isiPhone,
          })}
        />
      </Helmet>
      <WorkoutPlanPlay
        isPlayWorkout={isPlayWorkout}
        handleChangeModuleStatus={handleChangeModuleStatus}
        workoutPlanName={workoutPlan.title}
        workoutPlanAuthor={workoutPlan.author}
        workoutPlanCategory={workoutPlan.category}
        workoutPlanLevel={workoutPlan.level}
        workoutPlayModel={workoutPlayModel}
        toggleWorkoutPause={toggleWorkoutPause}
        isWorkoutPause={isWorkoutPause}
        isWorkoutFinishModal={isWorkoutFinishModal}
        handleOpenStopWorkoutModal={handleOpenStopWorkoutModal}
        handleCloseStopWorkoutModal={handleCloseStopWorkoutModal}
        handleFinishWorkoutPlay={handleFinishWorkoutPlay}
        isWorkoutFinish={isWorkoutFinish}
        setIsWorkoutFinish={setIsWorkoutFinish}
        workoutSetsCount={workoutSetsCount}
      />
      {isWorkoutFinish && rateModalIsOpen && (
        <RateWorkoutModal handleSendWorkoutPlan={handleSendWorkoutPlan} />
      )}
    </>
  );
};

export default connect(
  ({ workoutPlan }) => ({
    workoutPlan: workoutPlan.workoutPlanData,
  }),
  (dispatch) => ({
    sendWorkoutFinished: (id, effect = "easy") => {
      return contentAPI
        .sendFinishedWorkoutPlan(id, effect)
        .then((res) => {
          dispatch(updatePreviewWorkouts(res.data.workout_plan));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
  })
)(WorkoutPlanPlayPage);
