import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import workouts from "./workouts";
import auth from "./auth";
import workoutPlan from "./workoutPlan";
import meals from "./meals";
import meal from "./meal";
import mealPlan from "./mealPlan";
import home from "./home";
import profile from "./profile";
import recipes from "./recipes";
import categories from "./categories";
import categoryDetail from "./categoryDetail";
import history from "./history";
import logWeight from "./logWeight";
import author from "./author";
import subscriptions from "./subscriptions";

export default combineReducers({
  workouts,
  auth,
  workoutPlan,
  meals,
  meal,
  mealPlan,
  home,
  profile,
  recipes,
  categories,
  categoryDetail,
  history,
  logWeight,
  author,
  subscriptions,
  form: formReducer,
});
