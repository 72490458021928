import React, { useEffect, useState } from "react";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { authAPI } from "../api";
import themeConfig from "../configs/theme";
import {
  setIsLoaded,
  setAllUserData,
  clearUserData,
  setGoalWeight,
  setIsFetching,
  setTempAvatar,
  setAvatar,
  setResultsData,
  updateCancelResult,
  sendOffer,
  setActualWeight,
  setHasInvited,
} from "../actions/profile";

import { setReferralModal, clearAuth } from "../actions/auth";

import PageLoader from "../components/common/PageLoader";

import Profile from "../components/pages/Profile";

const sendGA = (event) => {
  if (window.gtag) {
    window.gtag("event", event);
  }
};

const getSubscribes = (subscribes) => {
  const result = [];

  subscribes.forEach((item) => {
    if (item?.product?.type === "workouts") {
      result.workouts = item.status;
    } else if (item?.product?.type === "recipes") {
      result.recipes = item.status;
    } else if (item?.product?.type === "workouts_recipes") {
      result.workouts = item.status;
      result.recipes = item.status;
    }
  });
  return result;
};

const sendCancelEventToAmplitude = (type, subscribes) => {
  const subs = getSubscribes(subscribes);
  const keys = Object.keys(subs);
  if (!keys.length) {
    return false;
  }

  if (type === "all") {
    keys.map((item) => (subs[item] = "canceled"));
  } else {
    subs[type] = "canceled";
  }
  const eventData = {
    subscriptions: keys.join(", "),
    subscriptions_status: keys.map((key) => subs[key]).join(", "),
  };

  amplitude.getInstance().logEvent("cancel_subscription", eventData);
};

const ProfilePage = ({
  isLoaded,
  userName,
  height,
  goalWeight,
  measurement,
  language,
  email,
  timezone,
  getProfile,
  clearUserData,
  setGoalWeight,
  isFetching,
  updateProfile,
  tempAvatar,
  setTempAvatar,
  avatar,
  mealPlanTitle,
  subscribes,
  sendCancelOne,
  subscribeCancelSent,
  actualWeight,
  age,
  activity,
  gender,
  updateCancelResult,
  sendOffer,
  hasInvited,
  openReferralModal,
  ...props
}) => {
  const [isModalCancelDisabled, setIsModalCancelDisabled] = useState(true);
  const [isEdit, setIsEdit] = useState(true);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const [fieldIsFocus, setFieldIsFocus] = useState(false);
  const [goalWeightError, setGoalWeightError] = useState(null);
  const [idCancelingSubscription, setIdCancelingSubscription] = useState(null);
  const [valueCancelingSubscription, setValueCancelingSubscription] =
    useState(null);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isCancelSend, setIsCancelSend] = useState(false);
  const points = {
    from: 40,
    to: 180,
  };

  if (measurement === "imperial") {
    points.from = 90;
    points.to = 400;
  }

  const handleGoalWeightChange = (event) => {
    const value = event.target.value;
    const valueIsNumber = /^\d*\.?\d*$/.test(value);
    if (!valueIsNumber) {
      return false;
    }
    if (value < points.from || value > points.to) {
      setGoalWeightError(
        `You can enter a value from ${points.from} to ${points.to}`
      );
    } else {
      setGoalWeightError(null);
    }

    setGoalWeight(+value);
    setIsEdit(false);
  };

  const handleGoalWeightFocus = (event) => {
    setFieldIsFocus(true);
  };

  const handleGoalWeightBlur = (event) => {
    setFieldIsFocus(false);
    let value = +event.target.value;

    if (value < points.from || value > points.to) {
      setGoalWeightError(
        `You can enter a value from ${points.from} to ${points.to}`
      );
    } else {
      setGoalWeightError(null);
    }

    setGoalWeight(value);
  };

  const handleEdit = (avatar) => {
    setIsEdit(false);
    setTempAvatar(avatar);
  };

  const handleSubmit = () => {
    if (goalWeightError) return false;

    updateProfile(goalWeight, tempAvatar).then((res) => {
      setIsEdit(true);
    });
  };

  const handleCloseCancelModal = () => {
    setCancelModalIsOpen(false);
    setIsModalCancelDisabled(true);
    document.body.style.overflow = "";
  };

  const handleOpenCancelModal = () => {
    setCancelModalIsOpen(true);
    sendGA("click on cancel subscription from Profile");
    document.body.style.overflow = "hidden";
  };

  const handleChangeSubscriptionCancelRadio = (value, id) => {
    setIsModalCancelDisabled(false);
    if (value === "all") {
      sendGA("cancel: select cancel all");
    } else {
      sendGA("cancel: select " + value);
    }
    setIdCancelingSubscription(id);
    setValueCancelingSubscription(value);
  };

  const handleSubmitCancelSubscription = () => {
    if (!idCancelingSubscription) return false;
    const cancelArr = String(idCancelingSubscription).split(",");

    sendCancelOne(valueCancelingSubscription, cancelArr).then((res) => {
      setIsCancelLoading(false);
      setCancelModalIsOpen(false);
      setIsModalCancelDisabled(true);
      setIsCancelSend(true);
      document.body.style.overflow = "";
    });
    sendCancelEventToAmplitude(valueCancelingSubscription, subscribes);
    sendGA("cancel: click on Yes, Cancel");
    setIsCancelLoading(true);
  };

  const handleSendOffer = (offer) => {
    return sendOffer(offer).then((res) => {
      setIsCancelSend(true);
      return res;
    });
  };

  useEffect(() => {
    getProfile();

    return () => {
      clearUserData();
    };
  }, [getProfile, clearUserData]);

  if (!isLoaded) {
    return <PageLoader />;
  }

  const allSubscribeValue = [];
  const cancelSubscribeOptions = subscribes
    .map((item) => {
      if (item.status === "active" && item?.product?.type !== "meal_plan") {
        const result = {};
        if (item?.product?.type === "workouts") {
          result.label = "Workouts";
          result.value = "workouts";
          result.id = item.id;
          result.checked = true;
          result.serial = item.serial_num;
          result.status = item.status;
          allSubscribeValue.push(item.id);
        } else if (item?.product?.type === "recipes") {
          result.label = "Recipes";
          result.value = "recipes";
          result.id = item.id;
          result.serial = item.serial_num;
          result.status = item.status;
          allSubscribeValue.push(item.id);
        } else if (item?.product?.type === "workouts_recipes") {
          result.label = "Workouts & Recipes";
          result.value = "workouts_recipes";
          result.id = item.id;
          result.checked = true;
          result.serial = item.serial_num;
          result.status = item.status;
          allSubscribeValue.push(item.id);
        } else {
          return null;
        }
        return result;
      }
      return null;
    })
    .filter((item) => item);

  cancelSubscribeOptions.push({
    label: "Cancel ALL",
    value: "all",
    id: allSubscribeValue.join(","),
  });

  let cancelSubscribeRadiosConfig = {
    name: "cancel_subscribe",
    options: [...cancelSubscribeOptions],
    defaultOption: cancelSubscribeOptions[0].value,
  };

  if (cancelSubscribeOptions.length < 2) {
    cancelSubscribeRadiosConfig = null;
  }

  return (
    <>
      <Helmet>
        <title>Your Profile - {themeConfig.title}</title>
      </Helmet>
      <Profile
        userName={userName}
        height={height}
        currentWeight={actualWeight}
        goalWeight={goalWeight}
        measurement={measurement}
        language={language}
        email={email}
        timezone={timezone}
        handleGoalWeightChange={handleGoalWeightChange}
        handleGoalWeightBlur={handleGoalWeightBlur}
        isEdit={isEdit}
        handleSubmit={handleSubmit}
        isFetching={isFetching}
        avatar={avatar}
        handleEdit={handleEdit}
        fieldIsFocus={fieldIsFocus}
        handleGoalWeightFocus={handleGoalWeightFocus}
        goalWeightError={goalWeightError}
        cancelSubscribeRadiosConfig={cancelSubscribeRadiosConfig}
        handleCloseCancelModal={handleCloseCancelModal}
        handleOpenCancelModal={handleOpenCancelModal}
        cancelModalIsOpen={cancelModalIsOpen}
        mealPlanTitle={mealPlanTitle}
        handleChangeSubscriptionCancelRadio={
          handleChangeSubscriptionCancelRadio
        }
        handleSubmitCancelSubscription={handleSubmitCancelSubscription}
        isCancelLoading={isCancelLoading}
        isCancelSend={isCancelSend}
        subscribeCancelSent={subscribeCancelSent}
        isModalCancelDisabled={isModalCancelDisabled}
        age={age}
        activity={activity}
        gender={gender}
        updateCancelResult={updateCancelResult}
        sendOffer={handleSendOffer}
        hasInvited={hasInvited}
        openReferralModal={openReferralModal}
      />
    </>
  );
};

export default connect(
  (state) => ({
    isLoaded: state.profile.isLoaded,
    userName: state.profile.userName,
    height: state.profile.height,
    currentWeight: state.profile.currentWeight,
    goalWeight: state.profile.goalWeight,
    measurement: state.profile.measurement,
    language: state.profile.language,
    email: state.profile.email,
    timezone: state.profile.timezone,
    isFetching: state.profile.isFetching,
    tempAvatar: state.profile.tempAvatar,
    avatar: state.profile.avatar,
    mealPlanTitle: state.profile.mealPlanTitle,
    subscribes: state.auth.subscribes,
    subscribeCancelSent: state.profile.subscribeCancelSent,
    actualWeight: state.profile.actualWeight,
    age: state.profile.age,
    activity: state.profile.activity,
    gender: state.profile.gender,
    hasInvited: state.profile.hasInvited,
  }),
  (dispatch) => ({
    openReferralModal: () => dispatch(setReferralModal(true)),
    sendOffer: (offer) => dispatch(sendOffer(offer)),
    updateCancelResult: (type, payload) =>
      dispatch(updateCancelResult(type, payload)),
    clearUserData: () => dispatch(clearUserData()),
    setGoalWeight: (goalWeight) => dispatch(setGoalWeight(goalWeight)),
    setTempAvatar: (tempAvatar) => dispatch(setTempAvatar(tempAvatar)),
    sendCancelOne: (type, id) => {
      return authAPI
        .cancelSubscribe(type, id)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    getProfile: () => {
      return authAPI
        .getUser()
        .then((res) => {
          dispatch(
            setAvatar(
              res.data.avatar
                ? `${process.env.REACT_APP_API_DOMAIN}${res.data.avatar.url}`
                : res.data.avatar
            )
          );
          dispatch(
            setAllUserData(
              res.data.id,
              res.data.first_name,
              res.data.confirmed,
              res.data.height,
              res.data.current_weight,
              res.data.goal_weight,
              res.data.units,
              res.data.language,
              res.data.email,
              res.data.timezone,
              res.data.created_at,
              res.data.meal_plan_title,
              res.data.subscribeCancelSent
            )
          );

          const subscribeWorkoutsRecipes = res.data.subscribes.find(
            (subscribe) => subscribe?.product?.type === "workouts_recipes"
          );
          if (subscribeWorkoutsRecipes) {
            dispatch(
              setHasInvited(
                subscribeWorkoutsRecipes?.serial_num === 1 &&
                  !res.data.has_invited
                  ? "no"
                  : "yes"
              )
            );
          }

          dispatch(
            setActualWeight(res.data.actual_weight || res.data.current_weight)
          );

          dispatch(
            setResultsData(res.data.age, res.data.activity, res.data.gender)
          );
          dispatch(setIsLoaded());
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    updateProfile: (goalWeight, tempAvatar) => {
      dispatch(setIsFetching(true));
      return authAPI
        .updateUser(goalWeight, tempAvatar)
        .then((res) => {
          dispatch(
            setAvatar(
              res.data.avatar
                ? `${process.env.REACT_APP_API_DOMAIN}${res.data.avatar.url}`
                : res.data.avatar
            )
          );
          dispatch(setIsFetching(false));
          return res;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
  })
)(ProfilePage);
