import React from "react";

import Title from "../Title";

import icons from "./icons";
import classes from "./HeadIcon.module.sass";

const HeadIcon = ({ children, type }) => {
  return (
    <div className={classes.wrap}>
      {type && (
        <div className={classes.icon}>
          <img src={icons[type]} alt="" />
        </div>
      )}
      <Title type="desc">{children}</Title>
    </div>
  );
};

export default HeadIcon;
