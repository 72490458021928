import axios from "../configs/axios";
import themeConfig from "../configs/theme";

const authAPI = {
  login(identifier, password) {
    return axios.post("login/local", {
      identifier,
      password,
    });
  },
  createPassword(code, password, repeatPassword, timezone, language) {
    return axios.post("me/confirm", {
      code,
      password,
      repeatPassword,
      timezone,
      language,
    });
  },
  createPasswordTemp(code, email, password, timezone, language) {
    return axios.post("me/confirm", {
      code,
      email,
      password,
      timezone,
      language,
    });
  },
  forgotPassword(email) {
    const data = {
      email,
    };

    if (themeConfig.type !== "hadew") {
      data.type = themeConfig.name;
    }

    return axios.post("password/forgot", data);
  },
  resetPassword(email, code, password, repeatPassword) {
    return axios.post("password/reset", {
      email,
      code,
      password,
      repeatPassword,
    });
  },
  getUser() {
    return axios.get("users/me");
  },
  updateUser(goalWeight, tempAvatar) {
    const formData = new FormData();

    if (goalWeight) {
      formData.append(
        "data",
        JSON.stringify({
          goal_weight: goalWeight,
        })
      );
    }

    if (tempAvatar) {
      formData.append("files.avatar", tempAvatar);
    }

    return axios.patch("/me", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateProfile(data) {
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        ...data,
      })
    );

    return axios.patch("/me", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  cancelSubscribe(type, id) {
    return axios.patch("subscribes/me/cancel", {
      type,
      id,
    });
  },
  cancel(data) {
    return axios.patch("subscribes/me/cancel", {
      ...data,
    });
  },
};

export default authAPI;
