import {
  SET_TOGGLE_FETCHING,
  SET_MEALS_LIST,
  SET_MEALS_INFO,
  CLEAR_MEALS_LIST,
  SET_MEAL_NAME,
  SET_MEAL_LEVEL,
  REPLACE_MEALS_LIST,
  SET_MEAL_TYPE,
  SET_MEAL_TIME,
  CLEAR_MEAL_FILTERS,
  SET_FIRST_LOADED_MEAL,
  SET_PREVIEW_LOADING,
  SET_MEAL_PREVIEW,
  CLEAR_MEAL_PREVIEW,
} from '../constants/mealsTypes';

export const setToggleFetching = (isFetching) => {
  return {
    type: SET_TOGGLE_FETCHING,
    isFetching
  };
};

export const setList = (list) => {
  return {
    type: SET_MEALS_LIST,
    list
  };
};

export const replaceList = (list) => {
  return {
    type: REPLACE_MEALS_LIST,
    list
  };
};

export const setInfo = (data) => {
  return {
    type: SET_MEALS_INFO,
    data
  };
};

export const clearList = () => {
  return {
    type: CLEAR_MEALS_LIST
  };
};

export const clearFilters = () => {
  return {
    type: CLEAR_MEAL_FILTERS
  };
};

export const setMealName = (mealName) => {
  return {
    type: SET_MEAL_NAME,
    mealName
  };
};

export const setMealLevel = (mealLevel) => {
  return {
    type: SET_MEAL_LEVEL,
    mealLevel
  };
};

export const setMealType = (mealType) => {
  return {
    type: SET_MEAL_TYPE,
    mealType
  };
};

export const setMealTime = (mealTime) => {
  return {
    type: SET_MEAL_TIME,
    mealTime
  };
};

export const setFirstLoaded = () => {
  return {
    type: SET_FIRST_LOADED_MEAL
  };
};

export const setPreviewLoading = (previewIsLoading) => {
  return {
    type: SET_PREVIEW_LOADING,
    previewIsLoading
  };
};

export const setMealPreview = (meal) => {
  return {
    type: SET_MEAL_PREVIEW,
    meal
  };
};

export const clearMealPreview = () => {
  return {
    type: CLEAR_MEAL_PREVIEW,
  };
};
