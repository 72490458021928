import React from 'react';

const BMIResult = ({
  status,
  value
}) => {
  return (
    <div className="finish-card-bmi">
      <div className="finish-card-bmi__body">
        <div className="finish-card-value">{value} ({status})</div>
      </div>
    </div>
  );
};

export default BMIResult;