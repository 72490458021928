import React from "react";
import clsx from "clsx";

import classes from "./Title.module.sass";

const Title = ({ children, type, size, className }) => {
  return (
    <div
      className={clsx(classes.title, {
        [classes[type]]: type,
        [classes[size]]: size,
        [className]: className,
      })}
    >
      {children}
    </div>
  );
};

export default Title;
