import React from "react";
import clsx from "clsx";
import { Switch, Route, withRouter } from "react-router-dom";
import themeConfig from "../../configs/theme";

import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";

import Login from "../../containers/LoginPage";
import SignUp from "../../containers/SignUpPage";
import SignUpTemp from "../../containers/SignUpPageTemp";
import LoginForgotPage from "../../containers/LoginForgotPage";
import LoginResetPage from "../../containers/LoginResetPage";

const Auth = ({ location, history }) => {
  const path = history.location.pathname;

  const pagePath = location.pathname;
  const navigationConfig = [];
  let purpleDesign = false;

  if (pagePath === "/signup" || pagePath === "/signin/forgot") {
    navigationConfig.push({
      label: "Sign In",
      linkTo: "/signin",
      icon: "profile",
    });
  }

  if (pagePath === "/signup" && themeConfig.name === "fitculator") {
    purpleDesign = true;
  }

  if (window.gtag) {
    window.gtag("event", "page_view", {
      page_path: path,
    });
  }

  return (
    <div
      className={clsx("app-wrap auth-page", {
        purple: purpleDesign,
      })}
    >
      <Header navigationConfig={navigationConfig} purpleDesign={purpleDesign} />
      <div className="content auth-wrap">
        <div className="auth-layout-wrap">
          <div className="container">
            <Switch>
              <Route exact path="/signin" component={Login} />
              <Route exact path="/signin/forgot" component={LoginForgotPage} />
              <Route exact path="/signin/reset" component={LoginResetPage} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/signup-temp" component={SignUpTemp} />
            </Switch>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(Auth);
