import React, { useState, useEffect } from "react";
import amplitude from "amplitude-js";
import { connect } from "react-redux";
import {
  microCommitmentsLoad,
  sendMicroCommitments,
} from "../../../actions/profile";

import { Modal, ModalHead, ModalBody } from "./components";
import { Head } from "./components/Heads/Heads";
import {
  StepWeight,
  StepHeight,
  StepCheckboxes,
  StepAge,
} from "./components/Steps/Steps";
import Greeting from "../Greeting";

const MicroCommitments = ({
  user,
  goalsList,
  microCommitmentFetching,
  microCommitmentLoaded,
  microCommitmentsLoad,
  sendMicroCommitments,
  configProducts,
  isOpen,
}) => {
  const stepsCount = 6;
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [greetingOpen, setGreetingOpen] = useState(false);
  const [resultData, setResultData] = useState({});

  const handleNextStep = (data, name, event) => {
    const newData = { ...resultData };
    newData[name] = data;
    setResultData(newData);

    if (event) {
      amplitude.getInstance().logEvent(event.name, event.data);
    }

    if (step + 1 <= stepsCount) {
      setStep(step + 1);
    } else {
      setOpen(false);
      sendMicroCommitments(newData);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        amplitude
          .getInstance()
          .logEvent("open_greeting_modal", { type: "micro_commitments" });
        setOpen(true);
        document.body.style.overflow = "hidden";
      });
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  useEffect(() => {
    if (!microCommitmentLoaded) {
      microCommitmentsLoad();
    }
  }, [microCommitmentLoaded, microCommitmentsLoad]);

  return (
    <>
      <Modal
        fetching={microCommitmentFetching || !microCommitmentLoaded}
        loaded
        open={open}
        step={step}
        stepLength={stepsCount}
        onClear={() => setGreetingOpen(true)}
      >
        <ModalHead>
          <Head>Let’s set your goals</Head>
        </ModalHead>
        <ModalBody>
          {step === 1 && (
            <StepWeight
              measurement={user.measurement}
              weight={user.currentWeight}
              onSubmit={handleNextStep}
              name="current_weight"
              eventName="weight_greeting_modal"
              title={
                user.currentWeight ? (
                  <>
                    Did we get your <strong>current weight</strong> right?
                  </>
                ) : (
                  <>
                    What's your <strong>current weight</strong>?
                  </>
                )
              }
            />
          )}
          {step === 2 && (
            <StepWeight
              measurement={user.measurement}
              weight={user.goalWeight}
              onSubmit={handleNextStep}
              name="goal_weight"
              eventName="goalWeight_greeting_modal"
              title={
                user.goalWeight ? (
                  <>
                    Is this your <strong>goal weight</strong> that you want to
                    achieve?
                  </>
                ) : (
                  <>
                    What's your <strong>goal weight</strong>?
                  </>
                )
              }
            />
          )}
          {step === 3 && (
            <StepHeight
              measurement={user.measurement}
              height={user.height}
              onSubmit={handleNextStep}
            />
          )}
          {step === 4 && <StepAge age={user.age} onSubmit={handleNextStep} />}
          {step === 5 && (
            <StepCheckboxes
              onSubmit={handleNextStep}
              config={goalsList}
              name="workout_goals"
              eventName="goals_greeting_modal"
              title={
                <>
                  Select your <strong>fitness goals?</strong>
                </>
              }
            />
          )}
          {step === 6 && (
            <StepCheckboxes
              onSubmit={handleNextStep}
              config={configProducts}
              name="diet_types"
              eventName="diets_greeting_modal"
              title={
                <>
                  What are your <strong>diet preferences?</strong>
                </>
              }
            />
          )}
        </ModalBody>
      </Modal>
      <Greeting open={greetingOpen} />
    </>
  );
};

const mapStateToProps = ({ profile }) => {
  const user = {
    currentWeight: profile.currentWeight,
    goalWeight: profile.goalWeight,
    height: profile.height,
    measurement: profile.measurement,
    age: profile.age,
  };
  return {
    user,
    goalsList: profile.configGoals,
    microCommitmentFetching: profile.microCommitmentFetching,
    microCommitmentLoaded: profile.microCommitmentLoaded,
    configProducts: profile.configProducts,
  };
};

const mapDispatchToProps = { microCommitmentsLoad, sendMicroCommitments };

export default connect(mapStateToProps, mapDispatchToProps)(MicroCommitments);
