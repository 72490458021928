import {
  ADD_MODAL_IS_OPEN,
  ADD_IS_LOADING,
  ADD_IS_DONE,
  CLEAR_DATA,
  ADD_ERROR,
} from "../constants/subscriptions";

export const setError = () => {
  return {
    type: ADD_ERROR,
  };
};

export const clearModalData = () => {
  return {
    type: CLEAR_DATA,
  };
};

export const setIsDone = () => {
  return {
    type: ADD_IS_DONE,
  };
};

export const setIsOpen = (isOpen) => {
  return {
    type: ADD_MODAL_IS_OPEN,
    isOpen,
  };
};

export const setIsLoading = (isLoading) => {
  return {
    type: ADD_IS_LOADING,
    isLoading,
  };
};
