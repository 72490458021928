import React, { useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { contentAPI } from "../api";
import { clearAuth } from "../actions/auth";
import { setMealsIdFromMealPlan } from "../actions/mealPlan";

import PageLoader from "../components/common/PageLoader";
import MealPage from "./MealPage";

const MealPageWrapper = ({ mealsFromMealPlan, getMealPlan, match }) => {
  useEffect(() => {
    if (!mealsFromMealPlan.length) {
      getMealPlan();
    }
  }, [mealsFromMealPlan, getMealPlan]);

  if (!mealsFromMealPlan.length) {
    return <PageLoader />;
  }

  const currentRecipeIsMealPlan =
    mealsFromMealPlan.indexOf(parseInt(match.params.id)) !== -1;

  if (!currentRecipeIsMealPlan) {
    return <Redirect to="/" />;
  }

  return <MealPage />;
};

const mapStateToProps = ({ mealPlan }) => ({
  mealsFromMealPlan: mealPlan.mealsFromMealPlan,
});

const mapDispatchToProps = (dispatch) => ({
  getMealPlan: () => {
    return contentAPI
      .getMealPlan()
      .then((res) => {
        dispatch(setMealsIdFromMealPlan(res.data.data.daily_meals));
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          clearAuth(dispatch);
        }
      });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MealPageWrapper));
