import React from "react";

import Button from "../../../../common/Button";
import SearchFail from "../../../../common/SearchFail";
import LoaderWrap from "../../../../common/LoaderWrap";
import MealCard from "../../../../../common/containers/MealCardContainer";

import "./RecipesList.sass";

const RecipesList = ({
  list,
  handleLike,
  recipesCurrentStart,
  recipesCount,
  recipesIsFetching,
  handleRecipesLoadMore,
  handleDeleteLike,
}) => {
  return (
    <>
      {list.length ? (
        <>
          <div className="recipes-body">
            <div className="meals-list meals-list--small">
              {list.map(
                ({
                  id,
                  image,
                  meal_types,
                  title,
                  level,
                  cooking_time,
                  meal_serving,
                  isFavourite,
                  favoriteId,
                  ...props
                }) => {
                  return (
                    <MealCard
                      key={id}
                      linkTo={`/meals/${id}`}
                      size="small"
                      image={image.length ? image[0].url : null}
                      tagName={meal_types.length ? meal_types[0].name : null}
                      title={title}
                      level={level}
                      cooking_time={cooking_time}
                      serving={meal_serving ? meal_serving.type : null}
                      id={id}
                      handleLike={() => {
                        if (isFavourite) {
                          handleDeleteLike(favoriteId);
                        } else {
                          handleLike(id, isFavourite);
                        }
                      }}
                      isFavourite={isFavourite}
                    />
                  );
                }
              )}
            </div>
          </div>
          {recipesCurrentStart < recipesCount ? (
            <div className="recipes-footer">
              <LoaderWrap spin={recipesIsFetching}>
                <Button
                  onClick={handleRecipesLoadMore}
                  disabled={recipesIsFetching}
                >
                  View more
                </Button>
              </LoaderWrap>
            </div>
          ) : null}
        </>
      ) : (
        <SearchFail
          message="No recipes with such parameters were found"
          className="recipes-list-not-found"
        />
      )}
    </>
  );
};

export default RecipesList;
