import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import themeConfig from "../configs/theme";
import { withRouter } from "react-router-dom";
import { contentAPI } from "../api";
import {
  setWorkoutPlanData,
  toggleExercise,
  setIsLoaded,
  clearWorkoutPlan,
  addError,
  setSimilarWorkouts,
  setSimilarWorkoutsLoading,
} from "../actions/workoutPlan";

import { updatePreviewWorkouts } from "../actions/history";

import { clearAuth } from "../actions/auth";

import PageLoader from "../components/common/PageLoader";
import WorkoutPlan from "../components/pages/WorkoutPlan";
import WorkoutPlanSingle from "../components/pages/WorkoutPlanSingle";
import ErrorPage from "../components/pages/Error";

const sendGA = (event) => {
  if (window.gtag) {
    window.gtag("event", event);
  }
};

const handleSendGAPause = () => {
  sendGA("Pause video");
};

const WorkoutPlanPage = ({
  workoutPlanData,
  getWorkoutPlan,
  match,
  toggleExercise,
  isLoaded,
  clearWorkoutPlan,
  error,
  sendWorkoutFinished,
  getSimilarWorkoutsByTag,
  similarWorkoutsIsLoading,
  similarWorkouts,
  getSimilarWorkoutPlansByAuthor,
}) => {
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [videoProgress, setVideoProgress] = useState(25);

  const handleToggleExercise = (setId, exerciseId) => {
    toggleExercise(setId, exerciseId);
  };

  useEffect(() => {
    getWorkoutPlan(match.params.id);

    return () => {
      clearWorkoutPlan();
    };
  }, [getWorkoutPlan, match.params.id, clearWorkoutPlan]);

  const handleSendWorkoutPlanToResults = () => {
    setVideoIsPlaying(true);
    if (!videoIsPlaying) {
      sendGA("Play video");
      sendWorkoutFinished(match.params.id);
    }
  };

  const handleSendGAProgress = (progress) => {
    if (!videoProgress) return false;
    progress = parseFloat(progress) * 100;
    if (progress >= 100 && progress >= videoProgress) {
      sendGA("Completions 100%");
      setVideoProgress(false);
    } else if (progress >= 99 && progress >= videoProgress) {
      sendGA("Completions 99%");
      setVideoProgress(100);
    } else if (progress >= 98 && progress >= videoProgress) {
      sendGA("Completions 98%");
      setVideoProgress(99);
    } else if (progress >= 97 && progress >= videoProgress) {
      sendGA("Completions 97%");
      setVideoProgress(98);
    } else if (progress >= 75 && progress >= videoProgress) {
      sendGA("Completions 75%");
      setVideoProgress(97);
    } else if (progress >= 50 && progress >= videoProgress) {
      sendGA("Completions 50%");
      setVideoProgress(75);
    } else if (progress >= 25 && progress >= videoProgress) {
      sendGA("Completions 25%");
      setVideoProgress(50);
    }
  };

  useEffect(() => {
    if (workoutPlanData && workoutPlanData.tag_workout_plans.length) {
      getSimilarWorkoutsByTag(
        workoutPlanData.tag_workout_plans[0].tag.id,
        workoutPlanData.id
      );
    } else if (workoutPlanData && workoutPlanData.id) {
      getSimilarWorkoutPlansByAuthor(workoutPlanData.id);
    }
  }, [
    workoutPlanData,
    getSimilarWorkoutsByTag,
    getSimilarWorkoutPlansByAuthor,
  ]);

  if (error) {
    return (
      <>
        <Helmet>
          <title>Error - {themeConfig.title}</title>
        </Helmet>
        <ErrorPage error={error} />
      </>
    );
  }

  if (!isLoaded) {
    return <PageLoader />;
  }

  let isLifestyle = workoutPlanData.category !== "weight_loss";
  let singleVideo = false;
  let singleVideoData = null;

  if (
    workoutPlanData.workouts.length === 1 &&
    workoutPlanData.workouts[0].workout_exercises.length === 1 &&
    !workoutPlanData.workouts[0].workout_exercises[0].exercise.video1
  ) {
    singleVideo = true;
    isLifestyle = true;

    singleVideoData = {
      video: workoutPlanData.workouts[0].workout_exercises[0].exercise.video2,
      title: workoutPlanData.title,
      preview:
        workoutPlanData.workouts[0].workout_exercises[0].exercise.video2Preview,
      duration: workoutPlanData.duration,
      level: workoutPlanData.level,
      category: workoutPlanData.category,
      author: workoutPlanData.author,
      description: workoutPlanData.description,
    };
  }

  if (singleVideo) {
    return (
      <>
        <Helmet>
          <title>
            {singleVideoData.title} - Workouts by {themeConfig.title}
          </title>
        </Helmet>
        <WorkoutPlanSingle
          singleVideoData={singleVideoData}
          handleSendWorkoutPlanToResults={handleSendWorkoutPlanToResults}
          handleSendGAProgress={handleSendGAProgress}
          handleSendGAPause={handleSendGAPause}
          similarWorkoutsIsLoading={similarWorkoutsIsLoading}
          similarWorkouts={similarWorkouts}
        />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {workoutPlanData.title} - Workouts by {themeConfig.title}
        </title>
      </Helmet>
      <WorkoutPlan
        {...workoutPlanData}
        handleToggleExercise={handleToggleExercise}
        workoutPlanId={match.params.id}
        isLifestyle={isLifestyle}
        singleVideo={singleVideo}
        singleVideoData={singleVideoData}
        handleSendWorkoutPlanToResults={handleSendWorkoutPlanToResults}
        handleSendGAProgress={handleSendGAProgress}
        handleSendGAPause={handleSendGAPause}
      />
    </>
  );
};

export default connect(
  (state) => ({
    workoutPlanData: state.workoutPlan.workoutPlanData,
    isFetching: state.workoutPlan.isFetching,
    isLoaded: state.workoutPlan.isLoaded,
    error: state.workoutPlan.error,
    similarWorkoutsIsLoading: state.workoutPlan.similarWorkoutsIsLoading,
    similarWorkouts: state.workoutPlan.similarWorkouts,
  }),
  (dispatch) => ({
    toggleExercise: (setId, exerciseId) =>
      dispatch(toggleExercise(setId, exerciseId)),
    clearWorkoutPlan: () => dispatch(clearWorkoutPlan()),
    getWorkoutPlan: (id) => {
      return contentAPI
        .getWorkoutPlan(id)
        .then((res) => {
          const { workouts, ...workoutPlanData } = res.data;
          let resultWorkouts = [];

          if (workouts.length) {
            resultWorkouts = workouts.map((workout) => {
              const { workout_exercises, ...workoutInfo } = workout;
              const exercisesResult = workout_exercises.map((item) => {
                item.isOpen = false;
                return item;
              });
              workoutInfo.workout_exercises = exercisesResult;
              return workoutInfo;
            });
          }
          const resultWorkoutPlanData = { ...workoutPlanData };
          resultWorkoutPlanData.workouts = resultWorkouts;

          if (res.status === 200) {
            dispatch(setWorkoutPlanData(resultWorkoutPlanData));
            dispatch(setIsLoaded());
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
          dispatch(addError(error.response.status));
          dispatch(setIsLoaded());
        });
    },
    sendWorkoutFinished: (id) => {
      return contentAPI
        .sendFinishedWorkoutPlan(id)
        .then((res) => {
          dispatch(updatePreviewWorkouts(res.data.workout_plan));
          return res;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    getSimilarWorkoutsByTag: (id, workoutPlanId) => {
      return contentAPI.getSimilarWorkoutPlansByTag(id).then((res) => {
        const idx = res.data.findIndex((item) => workoutPlanId === item.id);
        const result = [...res.data.slice(idx + 1), ...res.data.slice(0, idx)];
        dispatch(setSimilarWorkouts(result));
        dispatch(setSimilarWorkoutsLoading());
      });
    },
    getSimilarWorkoutPlansByAuthor: (id) => {
      return contentAPI.getSimilarWorkoutPlansByAuthor(id).then((res) => {
        dispatch(setSimilarWorkouts(res.data));
        dispatch(setSimilarWorkoutsLoading());
      });
    },
  })
)(withRouter(WorkoutPlanPage));
