import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import themeConfig from "../configs/theme";
import { connect } from "react-redux";
import { contentAPI } from "../api";
import Author from "../components/pages/Author";
import {
  setIsLoaded,
  setAuthorData,
  addError,
  clearAuthor,
} from "../actions/author";
import { clearAuth } from "../actions/auth";
import { withRouter } from "react-router-dom";
import PageLoader from "../components/common/PageLoader";
import ErrorPage from "../components/pages/Error";

const AuthorPage = ({
  isLoaded,
  getAuthor,
  match,
  error,
  clearAuthor,
  ...props
}) => {
  useEffect(() => {
    getAuthor(match.params.id);

    return () => {
      clearAuthor();
    };
  }, [getAuthor, match, clearAuthor]);

  if (error) {
    return (
      <>
        <Helmet>
          <title>Error - {themeConfig.title}</title>
        </Helmet>
        <ErrorPage error={error} />
      </>
    );
  }

  if (!isLoaded) {
    return <PageLoader />;
  }

  return (
    <>
      <Helmet>
        <title>
          {props.name} - Coach on {themeConfig.title}
        </title>
      </Helmet>
      <Author {...props} />
    </>
  );
};

export default connect(
  ({ author }) => ({
    isLoaded: author.isLoaded,
    name: author.name,
    avatar: author.avatar,
    description: author.description,
    socials: author.socials,
    error: author.error,
  }),
  (dispatch) => ({
    clearAuthor: () => dispatch(clearAuthor()),
    getAuthor: (id) => {
      return contentAPI
        .getAuthor(id)
        .then((res) => {
          dispatch(setAuthorData(res.data));
          dispatch(setIsLoaded());
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
          dispatch(addError(error.response.status));
          dispatch(setIsLoaded());
        });
    },
  })
)(withRouter(AuthorPage));
