import React from "react";

import HeadIcon from "../HeadIcon";
import Title from "../Title";
import Button from "../Button";

import classes from "./ModalSorry.module.sass";

const ModalSorry = ({ onCancel }) => {
  return (
    <div className={classes.wrap}>
      <div className={classes.body}>
        <HeadIcon name="sorry" />
        <Title size="large">This really SUCKS!</Title>
        <Title type="desc" className={classes.desc}>
          Keep on living a healthy life even if its not with us.
        </Title>
      </div>
      <div className={classes.footer}>
        <Button className={classes.btn} onClick={onCancel}>
          close
        </Button>
      </div>
    </div>
  );
};

export default ModalSorry;
