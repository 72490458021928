import React from 'react';

import IconSprite from '../../../../common/IconSprite';

import BarrePic from '../../../../../assets/images/content/categories/barre.jpg';
import DanceFitnessPic from '../../../../../assets/images/content/categories/dance_fitness.jpg';
import HipHopPic from '../../../../../assets/images/content/categories/hip_hop.jpg';
import MeditationsPic from '../../../../../assets/images/content/categories/meditations.jpg';
import PilatesPic from '../../../../../assets/images/content/categories/pilates.jpg';
import RunningPic from '../../../../../assets/images/content/categories/running.jpg';
import SelfDefencePic from '../../../../../assets/images/content/categories/self_defence.jpg';
import StretchingPic from '../../../../../assets/images/content/categories/stretching.jpg';
import YogaPic from '../../../../../assets/images/content/categories/yoga.jpg';
import BoxercisePic from '../../../../../assets/images/content/categories/boxercise.jpg';
import BoxingPic from '../../../../../assets/images/content/categories/boxing.jpg';

import './CategoryPreview.sass';
import { NavLink } from 'react-router-dom';

const CategoryPreview = ({
  title,
  name
}) => {

  const pics = {
    barre: BarrePic,
    dance_fitness: DanceFitnessPic,
    hip_hop: HipHopPic,
    meditations: MeditationsPic,
    pilates: PilatesPic,
    running: RunningPic,
    self_defence: SelfDefencePic,
    stretching: StretchingPic,
    yoga: YogaPic,
    boxercise: BoxercisePic,
    boxing: BoxingPic
  };

  return (
    <NavLink to={`/categories/${name}`} className="category-preview">
      <img src={pics[name]} alt="" className="category-preview__pic" />
      <div className="category-preview__body">
        <div className="category-preview__icon">
          <IconSprite className={`icon-${name}`} name={`category-${name}`} />
        </div>
        <div className="category-preview__name">{title}</div>
      </div>
    </NavLink>
  );
};

export default CategoryPreview;
