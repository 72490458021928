import axios from "../configs/axios";

const contentAPI = {
  updateSubscriptions() {
    return axios.post("subscribes/content", {
      shop_id: 5011,
    });
  },
  getWorkoutPlans(page = 0, limit = 6, filters = "") {
    return axios.get(
      `workout-plans?_start=${page}&_limit=${limit}${filters}&_sort=created_at:desc`
    );
  },
  getWorkoutsFavorites(start = 0, limit = 6, filters = "") {
    return axios.get(
      `favorite-workouts?_start=${start}&_limit=${limit}&_sort=created_at:desc`
    );
  },
  getWorkoutsFavoritesCount() {
    return axios.get(`favorite-workouts/count`);
  },
  getSimilarWorkoutPlansByTag(id) {
    return axios.get(`tags/${id}/similar?&_sort=order:asc`);
  },
  getSimilarWorkoutPlansByAuthor(id) {
    return axios.get(`workout-plans/${id}/similar?_limit=10`);
  },
  getRecommendedWorkoutPlans() {
    return axios.get(`/workout-plans/recommended`);
  },
  getRandomQuote() {
    return axios.get(`/quotes/random`);
  },
  getWorkoutPlan(id) {
    return axios.get(`workout-plans/${id}`);
  },
  getAuthor(id) {
    return axios.get(`authors/${id}`);
  },
  sendFinishedWorkoutPlan(workout_plan, effect) {
    const data = {
      workout_plan,
    };
    if (effect) {
      data.effect = effect;
    }

    return axios.post(`workout-plan-results`, data);
  },
  getFinishedWorkoutPlan(start = 0, limit = 3, dataFilterFrom, dataFilterTo) {
    let query = `workout-plan-results/me?_start=${start}&_limit=${limit}&_sort=created_at:desc`;
    if (dataFilterFrom) {
      query += `&created_at_gte=${dataFilterFrom}`;
    }

    if (dataFilterTo) {
      query += `&created_at_lte=${dataFilterTo}`;
    }

    return axios.get(query);
  },
  getDistinctFinishedWorkoutPlan() {
    return axios.get("/workout-plan-results/me/distinct");
  },
  getExercise(id) {
    return axios.get(`exercises/${id}`);
  },
  getMeals(start, filters = "", limit = 6) {
    return axios.get(
      `recipes?_start=${start}&_limit=${limit}&_sort=created_at:desc${filters}`
    );
  },
  getMeal(id) {
    return axios.get(`recipes/${id}`);
  },
  getActualWorkouts() {
    return axios.get(`/workout-plans/actual`);
  },
  replaceMealPlan(mealPlanId) {
    return axios.post("api/v1/meal-plans/me", {
      mealPlanId,
    });
  },
  purchaseMealPlan(diets) {
    return axios.post("subscribes/me", {
      dietTypes: [...diets],
    });
  },
  getMealPlan() {
    return axios.get("meal-plans/me");
  },
  getListMealPlans() {
    return axios.get("meal-plans?_sort=created_at:desc");
  },
  replaceAllMeal(from, to) {
    return axios.put(`/api/v1/meal-plans/me`, {
      from: from,
      to: to,
    });
  },
  replaceOneMeal(day, to, serving) {
    return axios.put(`/api/v1/meal-plans/me`, {
      day: day,
      to: to,
      serving: serving,
    });
  },
  sendFavorite(id) {
    return axios.post("/favorite-recipes", {
      recipe: id,
    });
  },
  deleteFavorite(id) {
    return axios.delete(`/favorite-recipes/${id}`);
  },
  getFavoriteRecipes(start, filters = "", limit = 6) {
    return axios.get(
      `favorite-recipes?_start=${start}&_limit=${limit}&_sort=created_at:desc${filters}`
    );
  },
  sendFavoriteWorkout(id) {
    return axios.post("/favorite-workouts", {
      workout: id,
    });
  },
  deleteFavoriteWorkout(id) {
    return axios.delete(`/favorite-workouts/?workout=${id}`);
  },
  getWeightLogs(isLastElement) {
    return axios.get(
      `/weight-logs?_sort=created_at:desc${isLastElement ? "&_limit=1" : ""}`
    );
  },
  sendWeightLogs(weight) {
    return axios.post("/me/weight", {
      actual_weight: weight,
    });
  },
  getDietTypes() {
    return axios.get("/diet-types");
  },
  getWorkoutsGoal() {
    return axios.get("/workout-goals?_sort=created_at:desc");
  },
  deleteWorkoutsHistory(workouts) {
    return axios.delete(`/v2/workout-plan-results${workouts}`);
  },
  sendSurvey(data) {
    return axios.post("/subscribes", data);
  },
  sendReferral(data, subscribeId) {
    return axios.patch(`/subscribes/${subscribeId}/invite`, {
      ...data,
    });
  },
};

export default contentAPI;
