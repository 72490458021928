import React, { useEffect } from "react";

import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";

import Workouts from "../../containers/WorkoutsPage";
import WorkoutPlan from "../../containers/WorkoutPlanPage";
import MealPlan from "../../containers/MealPlanPage";
import Meal from "../../containers/MealPage";
import Home from "../../containers/HomePage";
import Categories from "../../containers/CategoriesPage";
import CategoryDetailPage from "../../containers/CategoryDetailPage";
import Profile from "../../containers/ProfilePage";
import Recipes from "../../containers/RecipesPage";
import History from "../../containers/HistoryPage";
import WorkoutPlanPlay from "../../containers/WorkoutPlanPlayPage";
import Author from "../../containers/AuthorPage";
import ErrorPage from "../../containers/ErrorPage";
import LogWeightPage from "../../containers/LogWeightPage";
import SubscriptionsModal from "../../containers/SubscriptionsModal";
import MealPageWrapper from "../../containers/MealPageWrapper";
import MicroCommitments from "../../containers/MicroCommitmentsContainer";
import Survey from "../../containers/SurveyPage";
import ReferralModal from "../../containers/ReferralContainer";
import { navigation } from "./config";

const GuardRoute = ({ status, changeTo, component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) => {
        return status ? <Component {...props} /> : <Redirect to={changeTo} />;
      }}
    />
  );
};

const MainLayout = ({
  confirmed,
  history,
  logout,
  statuses,
  company,
  passedLongTime,
  isPollPassed,
}) => {
  useEffect(() => {
    if (!isPollPassed) {
      history.push("/profile");
    } else if (passedLongTime) {
      history.push("/meals");
    }
  }, [passedLongTime, history, isPollPassed]);

  let appWrapClasses = "";
  let headerOff = false;
  let withBackButton = true;

  const path = history.location.pathname;

  if (path === "/") {
    appWrapClasses = "index-page";
    withBackButton = false;
  } else if (
    path === "/meals" ||
    path === "/recipes" ||
    path === "/recipes/favorites"
  ) {
    appWrapClasses = "meals-page";
    withBackButton = false;
  } else if (
    (path.split("/").length === 3 && path.includes("/meals")) ||
    path === "/log-weight"
  ) {
    appWrapClasses = "meals-page";
  } else if (path === "/profile") {
    appWrapClasses = "profile-page";
    withBackButton = false;
  } else if (path === "/workouts") {
    appWrapClasses = "workouts-page-wrapper";
    withBackButton = false;
  } else if (path === "/lifestyle") {
    appWrapClasses = "workouts-page-wrapper";
    withBackButton = false;
  } else if (path === "/workout-play") {
    appWrapClasses = "workout-play-page";
  } else if (path.split("/").length === 3 && path.includes("/workouts")) {
    appWrapClasses = "workouts-plan-page";
  } else if (path === "/categories" || path.includes("/categories")) {
    appWrapClasses = "categories-page";
  } else if (path === "/history") {
    appWrapClasses = "history-page";
  } else if (path.includes("/author")) {
    appWrapClasses = "author-page";
  } else if (path.includes("/survey")) {
    appWrapClasses = "survey-page";
    headerOff = true;
  }

  if (window.gtag) {
    window.gtag("event", "page_view", {
      page_path: path,
    });
  }

  const navigationConfig = navigation(statuses, logout);

  return (
    <>
      <div className={`app-wrap ${appWrapClasses}`}>
        {headerOff || (
          <Header
            navigationConfig={navigationConfig}
            pagePath={history.location.pathname}
            confirmed={confirmed}
            withBackButton={withBackButton}
            company={company}
          />
        )}
        <div className="content">
          <Switch>
            <GuardRoute
              status={!(!statuses.workouts && !statuses.mealPlan)}
              changeTo="/recipes"
              exact
              path="/"
              component={Home}
            />
            <GuardRoute
              status={statuses.workouts}
              changeTo="/meals"
              exact
              path="/categories"
              component={Categories}
            />
            <GuardRoute
              status={statuses.workouts}
              changeTo="/meals"
              exact
              path="/categories/:categoryName"
              component={CategoryDetailPage}
            />

            <GuardRoute
              status={statuses.workouts}
              changeTo="/meals"
              exact
              path="/workouts"
              component={Workouts}
            />
            <GuardRoute
              status={statuses.workouts}
              changeTo="/meals"
              exact
              path="/lifestyle"
              component={Workouts}
            />
            <GuardRoute
              status={statuses.workouts}
              changeTo="/meals"
              exact
              path="/workouts/:id"
              component={WorkoutPlan}
            />
            <GuardRoute
              status={statuses.recipes}
              changeTo={
                !statuses.mealPlan && !statuses.mealPlan ? "/profile" : "/meals"
              }
              exact
              path="/recipes"
              component={Recipes}
            />
            <GuardRoute
              status={statuses.mealPlan}
              changeTo="/"
              exact
              path="/meals"
              component={MealPlan}
            />
            <GuardRoute
              status={statuses.mealPlan}
              changeTo="/"
              exact
              path="/meal/:id"
              component={MealPageWrapper}
            />
            <GuardRoute
              status={statuses.recipes}
              changeTo={
                !statuses.mealPlan && !statuses.mealPlan ? "/profile" : "/meals"
              }
              exact
              path="/meals/:id"
              component={Meal}
            />
            <GuardRoute
              status={statuses.workouts}
              changeTo="/meals"
              exact
              path="/workout-play"
              component={WorkoutPlanPlay}
            />
            <GuardRoute
              status={statuses.workouts}
              changeTo="/meals"
              exact
              path="/history"
              component={History}
            />
            <GuardRoute
              status={statuses.workouts}
              changeTo="/meals"
              exact
              path="/author/:id"
              component={Author}
            />

            <Route exact path="/profile" component={Profile} />
            <Route exact path="/log-weight" component={LogWeightPage} />
            <Route
              component={() => {
                return <ErrorPage error="404" />;
              }}
            />
          </Switch>
        </div>
        <Footer />
      </div>
      <Survey />
      <SubscriptionsModal />
      <MicroCommitments />
      <ReferralModal />
    </>
  );
};

export default withRouter(MainLayout);
