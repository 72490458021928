import React, { useState } from "react";
import amplitude from "amplitude-js";
import clsx from "clsx";
import themeConfig from "../../../../../configs/theme";

import Logo from "../../../../common/Logo";
import { Modal } from "../../../../../common/components";
import {
  ModalCancel,
  ModalCancelReason,
  ModalPromoTrial,
  ModalPromo,
  ModalThanks,
  ModalSorry,
} from "./components";

import classes from "./CancelSubscription.module.sass";

const setIsTrial = (subscribes) => {
  let isTrial = true;
  subscribes.forEach((sub) => {
    if (sub.serial !== undefined && parseInt(sub.serial) !== 1) {
      isTrial = false;
    }
  });
  return isTrial;
};

const getSubscribes = (subscribes) => {
  const result = {};
  subscribes.forEach((sub) => {
    if (
      sub.value === "workouts" ||
      sub.value === "recipes" ||
      sub.value === "workouts_recipes"
    ) {
      result[sub.value] = { ...sub };
    }
  });
  return result;
};

const CancelSubscription = ({
  subscribes,
  updateCancelResult,
  sendOffer,
  isCancelSend,
  subscribeCancelSent,
}) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [fetching, setFetching] = useState(false);

  const isTrial = setIsTrial(subscribes.options);
  const subscribesConfig = getSubscribes(subscribes.options);

  const handleClearData = () => {
    setStep(0);
    setFetching(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveSubscribes = (subscribes) => {
    updateCancelResult("subscribes", subscribes);

    let type = "all";
    if (Object.keys(subscribes).length < 2) {
      type = subscribes[Object.keys(subscribes)[0]].type;
    }
    amplitude.getInstance().logEvent("type_cancel_offer", { type });
    setStep(1);
  };

  const handleSaveReason = (reason) => {
    updateCancelResult("reason", reason);
    amplitude.getInstance().logEvent("reason_cancel_offer", { reason });
    setStep(2);
  };

  const handleSendCancel = () => {
    setFetching(true);
    amplitude.getInstance().logEvent("offer_cancel_offer", { offer: "none" });
    sendOffer("none").then((res) => {
      setFetching(false);
      setStep(3);
      amplitude.getInstance().logEvent("sucks_cancel_offer");
    });
  };

  const handleSendOffer = () => {
    setFetching(true);
    const offer = isTrial ? "in_trial" : "after_trial";
    amplitude.getInstance().logEvent("offer_cancel_offer", { offer });
    sendOffer(offer).then((res) => {
      setFetching(false);
      setStep(4);
      amplitude.getInstance().logEvent("great_cancel_offer");
    });
  };

  const themeClassName = themeConfig.name;

  return (
    <>
      <div className={classes.cancel}>
        <div className={classes.wrap}>
          <Logo className={classes.logo} />
          <div className={clsx(classes.body, classes[themeClassName])}>
            <div className={classes.item}>
              <div className={classes.value}>1.1M</div>
              <div className={classes.title}>Calories Burned</div>
            </div>
            <div className={classes.item}>
              <div className={classes.value}>230k+</div>
              <div className={classes.title}>Active Members</div>
            </div>
            <div className={classes.item}>
              <div className={classes.value}>1300+</div>
              <div className={classes.title}>Delicious Recipes</div>
            </div>
            <div className={classes.item}>
              <div className={classes.value}>340</div>
              <div className={classes.title}>Fun Workouts</div>
            </div>
          </div>
        </div>
        {!isCancelSend && !subscribeCancelSent ? (
          <button className={classes.btn} onClick={() => setOpen(true)}>
            Cancel membership
          </button>
        ) : (
          <div className={classes.helper}>
            You will receive an email confirming your cancellation. Please allow
            48 hours for this email to be delivered.
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        isLoading={fetching}
        onClear={handleClearData}
      >
        {step === 0 && (
          <ModalCancel
            onClose={handleClose}
            config={subscribesConfig}
            onSend={handleSaveSubscribes}
          />
        )}
        {step === 1 && (
          <ModalCancelReason onClose={handleClose} onSend={handleSaveReason} />
        )}
        {step === 2 && isTrial && (
          <ModalPromoTrial
            onCancel={handleSendCancel}
            onSend={handleSendOffer}
          />
        )}
        {step === 2 && !isTrial && (
          <ModalPromo onCancel={handleSendCancel} onSend={handleSendOffer} />
        )}
        {step === 3 && <ModalSorry onCancel={handleClose} />}
        {step === 4 && <ModalThanks onCancel={handleClose} />}
      </Modal>
    </>
  );
};

export default CancelSubscription;
