import React from "react";
import dayjs from "dayjs";
import themeConfig from "../../../configs/theme";
import "./Footer.sass";

// const openTerms = (event) => {
//   event.preventDefault();
// const page = event.target.getAttribute("href");
// window.open(page, '', 'scrollbars=yes,width=700,height=700');
// return false;
// };

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="footer-inner">
          <div className="copyright">
            © {dayjs().format("YYYY")} {themeConfig.copyright}. All rights
            reserved.
          </div>
          {/* <nav className="nav-terms">
            <ul className="nav-terms__list">
              <li className="nav-terms__item">
                <a
                  className="nav-terms__link"
                  href="/docs/privacy-policy"
                  onClick={openTerms}
                >
                  Privacy Policy
                </a>
              </li>
              <li className="nav-terms__item">
                <a
                  className="nav-terms__link"
                  href="/docs/terms"
                  onClick={openTerms}
                >
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
