import React from "react";
import clsx from "clsx";

import "./Icons.sass";

const Close = ({ className }) => {
  return (
    <svg
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("icon", {
        [className]: className,
      })}
    >
      <path d="M14.9916 13.501L26.6911 1.8015C27.103 1.38959 27.103 0.721762 26.6911 0.309907C26.2792 -0.101948 25.6114 -0.102 25.1995 0.309907L13.5 12.0094L1.80052 0.309907C1.38861 -0.102 0.720785 -0.102 0.308931 0.309907C-0.102924 0.721815 -0.102977 1.38964 0.308931 1.8015L12.0084 13.501L0.308931 25.2005C-0.102977 25.6124 -0.102977 26.2802 0.308931 26.6921C0.514858 26.898 0.784805 27.0009 1.05475 27.0009C1.3247 27.0009 1.59459 26.898 1.80057 26.6921L13.5 14.9926L25.1995 26.6921C25.4054 26.898 25.6753 27.0009 25.9453 27.0009C26.2152 27.0009 26.4851 26.898 26.6911 26.6921C27.103 26.2802 27.103 25.6123 26.6911 25.2005L14.9916 13.501Z" />
    </svg>
  );
};

export default Close;
