import React from "react";

import ViewAllLink from "../../../../common/ViewAllLink";
import Title from "../../../../common/Title";
import WorkoutsList from "../WorkoutsList";

import "./ListCategory.sass";

const ListCategory = ({ name, title, list }) => {
  return (
    <div className="category-list">
      <div className="container">
        <div className="workouts-page-section">
          <Title size="medium">{title}</Title>
          <ViewAllLink to={`/categories/${name}`} />
        </div>
      </div>
      <WorkoutsList
        list={list}
        carouselId={name}
        paginationStyle="white"
        size="small"
        className="category-list-carousel"
        withLike
      />
    </div>
  );
};

export default ListCategory;
