import React from 'react';
import { NavLink } from 'react-router-dom';
import Form from './Form';

import './login.sass';

const Login = ({
  isFetching,
  handleSubmitForm,
}) => {
  return (
    <div className="form-wrap login-form">
      <div className="login-form__title">Sign In</div>
      <div className="login-form__subtitle">to get started, just log in</div>
      <Form
        onSubmit={handleSubmitForm}
        isFetching={isFetching}
      />
      <NavLink className="login-form__link" to="/signin/forgot">Forgot password</NavLink>
    </div>
  );
};

export default Login;
