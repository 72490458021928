import React from 'react';
import Helmet from 'react-helmet'

import "./Docs.sass";

const Terms = () => {
  return (
    <>
      <Helmet>
        <body className="docs-page" />
        <title>ONLINE PRIVACY POLICY</title>
      </Helmet>
      <h1>Terms and Conditions</h1>
      <p><u>You Accept These Terms.</u></p>
      <p>These terms and conditions ( "Terms and Conditions") govern and determine this web site and / or its applications provided by Company. / hereinafter referred to as the SITE.</p>
      <p>If you do not agree with any of these Terms, including the General Conditions, Data Protection Policy, Cookies Policy or Privacy Policy incorporated here, please do not use this Site. By using this Site you will be deemed to have irrevocably agreed to these Terms. Some areas of this Site may be subject to additional terms and conditions, which you should read carefully before making any use of those areas. Such additional terms will not change or replace these Terms regarding use of this Site, unless otherwise expressly stated.</p>
      <p>Updates to Terms.<br />
          Please note that these Terms may be revised and reissued, prospectively, by posting updated terms on this Site. You consent and agree to receive notices of updates to these Terms through our posting of updated Terms on the Site. You should visit this page regularly to review the current terms. Your continued use of the Site will be deemed as irrevocable acceptance of any revisions.</p>
      <p>Legal Capacity.<br />
            This is a general audience Site for ADULTS. We will assume (and by using this Site you warrant that) you have legal capacity to enter into the agreement set out in these Terms (i.e., that you are of sufficient age and mental capacity and are otherwise entitled to be legally bound in contract).</p>
      <p>IMPORTANT SALES TERMS</p>
      <p>BY PROCEEDING WITH YOUR PURCHASE AND ORDERING YOUR PERSONALIZED 60-DAY MEAL AND EXERCISE PLAN YOU ARE ALSO ENROLLING INTO OUR CONTINUOUS SERVICE PROGRAM AND SUBSCRIBING TO THE MEMBERSHIPS/SUBSCRIPTIONS DESCRIBED HEREIN: Our Continuous Service program is designed to avoid interruption in service by guaranteeing that subscribers do not miss any content. Continuous Service Program: I understand that unless I tell you otherwise, I will receive uninterrupted service and access; my subscription(s) will be automatically renewed at the end of each subscription term, at the rate(s) then in effect. I authorize you to fulfill my subscription(s) and charge the credit/debit card if provided, or send me a bill if not. I won't be bothered with any renewal notices. I may opt out of the automatic renewal at any time by contacting customer service who’s contact information is as follows: Toll Free USA: +1-866-856-4807 / UK: +44-80-0041-8628 / AU: +61-18-0095-8362. I understand and acknowledge that in addition to receiving access to my digital “Fitculator” for which I agree to pay the initial fees of $1.99, I am additionally consenting to be billed $28.99 after seven (7) days of the purchase and then again every 30 days thereafter on the credit card provided to continue having access, unless I cancel the service prior to the subsequent billing. I am additionally signing up and consenting to be billed for the following membership programs: 30 day free membership trial to us.madisonfitnesslife.com. At the end of the free trial, I consent to be billed $28.99 every 30 days on the credit card provided to continue having access, unless I cancel the service prior to the next billing. I also consent to sign up for the 75 day free membership trial to us.saltpluspepper.com.mwap.mobi At the end of the free trial, I consent to be billed $10.99 every 30 days on the credit card provided to continue having access, unless I cancel the service prior to the next billing.</p>
      <p>The Information You Give Us Must Be Correct.<br />
              To access this Site or some of the resources it has to offer, you may be asked to provide registration details. It is a condition of use of this Site, you do so as yourself and that all the details you provide be truthful, correct, current and complete. If we believe the details are not correct, current, or complete, we have the right to refuse you access to the Site, or any of its resources and to terminate or suspend your account.</p>
      <p>Online Privacy Notice.<br />
            Your privacy is important to us. To better protect your privacy, we provide a notice explaining our online information practices and the choices you can make about the way your information is collected and used on this Site.</p>
      <p>Disclaimer of Warranties.<br />
              YOUR USE OF THIS SITE IS AT YOUR OWN RISK. THE SITE AND ALL THE MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICES AND OTHER CONTENT IN THE SITE ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THE SITE, Company., AND ANY SUBSIDIARIES OR AFFILIATED COMPANIES DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE Company. GROUP DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THIS SITE WILL BE AVAILABLE, UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVERS THAT MAKE THE SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE Company. GROUP DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIAL, INFORMATION, SOFTWARE, FACILITIES, SERVICES, OR OTHER CONTENT ON THE SITE OR ANY WEBSITES LINKED TO THE SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. THE Company. GROUP MAKES NO WARRANTIES THAT YOUR USE OF THE MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICES, OR OTHER CONTENT IN THE SITE OR ANY WEBSITE WILL NOT INFRINGE THE RIGHTS OF OTHERS AND THE Company. GROUP ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICES, OR OTHER CONTENT OF THE SITE OR ANY OTHER WEBSITE. IF APPLICABLE LAW DOES NOT ALLOW THE EXCLUSION OF SOME OR ALL OF THE ABOVE IMPLIED WARRANTIES TO APPLY TO YOU, THE ABOVE EXCLUSIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.<br />
                IF YOU ARE ACCESSING A UNITED KINGDOM VERSION OF THE SITE, NOTHING IN THIS AGREEMENT SHALL EXCLUDE OR LIMIT LIABILITY FOR DEATH OR PERSONAL INJURY RESULTING FROM THE NEGLIGENCE OF EITHER PARTY OR THEIR SERVANTS, AGENTS, OR EMPLOYEES.</p>
      <p>Limitation of Liability.<br />
                  THE COMPANY GROUP DISCLAIMS ALL LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, AND DOES NOT ACCEPT ANY LIABILITY FOR ANY LOSS OR DAMAGE (DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY, OR OTHERWISE) RESULTING FROM ANY USE OF, OR INABILITY TO USE, THE SITE OR ANY OTHER WEBSITE, OR THE MATERIAL, INFORMATION, SOFTWARE, FACILITIES, SERVICES, OR OTHER CONTENT ON THE SITE OR ANY OTHER WEBSITE, REGARDLESS OF THE BASIS UPON WHICH LIABILITY IS CLAIMED AND EVEN IF ANY MEMBER OF THE Company. GROUP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. WITHOUT LIMITATION, YOU (AND NOT ANY MEMBER OF THE Company. GROUP) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION IN THE EVENT OF ANY SUCH LOSS OR DAMAGE ARISING THEREIN. IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.</p>
      <h2>Disputes</h2>
      <p>If any controversy, allegation, or claim (including any non-contractual claim) arises out of or relates to the Site or Terms of Service, then you and we agree to send a written notice to the other providing a reasonable description of the Section 8(b) Dispute, along with a proposed resolution of it. Our notice to you will be sent to you based on the most recent contact information that you provide us. But if no such information exists or if such information is not current, then we have no obligation under this Section 8(b). Your notice to us must be sent to us at Contact Information: Company, 1156 Autumn Ridge Dr, Lexington, KY 40509 USA: +1-866-856-4807 / UK: +44-80-0041-8628 / AU: +61-18-0095-8362 (toll-free) / 1-502-251-1332 (International Callers). For a period of ninety (90) days from the date of receipt of notice from the other party, Company and you will engage in a dialogue in order to attempt to resolve the Section 8(b) Dispute. Falling resolution, the parties will submit the dispute to arbitration and the modalities will be agreed upon by the parties.</p>
      <p>Trademarks and Copyrights.<br />
                    This Site and materials incorporated by Company on this Site ("Material") are protected by copyrights, patents, trade secrets or other proprietary rights ("Copyrights"). Some of the characters, logos, or other images incorporated by Company. on this Site are also protected as registered or unregistered trademarks, trade names, and/or service marks owned by Company or others ("Trademarks"). Company respects the intellectual property rights of others and asks users of this Site to do the same.</p>
      <p>Your Use of Material.<br />
                      Your right to make use of this Site and any Material or other content appearing on it is subject to your compliance with these Terms. Modification or use of the Material or any other content on this Site for any purpose not permitted by these Terms may be a violation of the Copyrights and/or Trademarks protected by law and these Terms and is prohibited.<br />
            You may access and display Material and all other content displayed on this Site for non-commercial, personal, entertainment use on a single device (e.g., computer, tablet or mobile) only. The Material and all other content on this Site may not otherwise be copied, reproduced, republished, uploaded, posted, transmitted, distributed, or used in any way unless specifically authorized by Company Any authorization to copy Material granted by Company. in any part of this Site for any reason is restricted to making a single copy for non-commercial, personal, entertainment use on a single device only, and is subject to your keeping intact all copyright and other proprietary notices. Using any Material on any other website or networked computer environment is prohibited. Also, decompiling, reverse engineering, disassembling, or otherwise reducing the code used in any software on this Site into a readable form in order to examine the construction of such software and/or to copy or create other products based (in whole or in part) on such software, is prohibited.</p>
      <p>Seller; Risk of Loss.<br />
              Company may be the seller of various physical or digital goods and services on the Site. All items purchased from the Site are made pursuant to a the present agreement. This means that for physical products the risk of loss and title for such items pass to you upon delivery to the carrier.</p>
      <p>Release.<br />
                If you have a dispute, you release us (and our parent companies, affiliates and subsidiaries, and their respective officers, directors, employees, and agents) from claims, demands, liabilities, costs, or expenses and damages (actual and consequential of every kind and nature, known and unknown, arising out of or in any way connected with such disputes). In entering into this release, you expressly waive any protections (whether statutory or otherwise) to the extent permitted by applicable law that would otherwise limit the coverage of this release to include only those claims which you may know or suspect to exist in your favor at the time of agreeing to this release.</p>
      <p>Security.<br />
                  Any usernames and passwords used for this Site are for individual use only. You shall be responsible for the security of your username and password (if any). Company shall be entitled to monitor your username and password and, at its discretion, require you to change it. If you use a username and password that Company considers insecure, Company will be entitled to require this to be changed and/or terminate your account.</p>
      <p>Reservation of Rights.<br />
                    Company reserves the right to modify or discontinue, temporarily or permanently, all or any part of this Site and/or any software, facilities, and services on this Site, with or without notice and/or to establish general guidelines and limitations on their use.</p>
      <p>Local Regulations.<br />
            Company makes no representation that Materials or other content on the Site are appropriate or available for use outside the United States, its territories, possessions, and protectorates. If you choose to access the Site from other locations, you do so on your own initiative and at your own risk.<br />
              You are responsible for complying with local laws, if and to the extent local laws are applicable. You specifically agree to comply with all applicable laws concerning the transmission of technical data exported from the United States or the country you reside in.</p>
      <p>Third-Party Sites.<br />
                This Site may link you to other sites on the Internet. These sites may contain information or material that some people may find inappropriate or offensive. These other sites are not under the control of Company, and you acknowledge that (whether or not such sites are affiliated in any way with Company) Company is not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such sites. The inclusion of such a link on this Site does not imply endorsement of any site by Company or any association with its operators.<br />
                  Company cannot ensure that you will be satisfied with any products or services that you purchase from any third-party site that links to or from Company since the third-party sites are owned and operated by independent retailers. Company does not endorse any of the merchandise, nor has Company. taken any steps to confirm the accuracy or reliability of any of the information contained on such third-party sites. Company does not make any representations or warranties as to the security of any information (including, without limitation, credit card and other personal information) you might be requested to give any third-party, and you irrevocably waive any claim against us with respect to such sites. We strongly encourage you to make whatever investigation you feel necessary or appropriate before proceeding with any online transaction with any of these third-parties.</p>
      <p>By using our products and services you acknowledge and agree to allow that your personal information be transferred and/or passed to specially selected third parties, such as, but not limited to Insideketo.com and ketoeverything.com.</p>
      <p>Proprietary Online Services.<br />
                    Any area of this Site that is accessed through any proprietary online service is subject to the rules, policies and guidelines of such proprietary online service.</p>
      <p>Choice of Law.<br />
                      These Terms shall be governed by, construed and enforced in accordance with the laws of the State of Kentucky.</p>
      <p>How to Contact Us.<br />
                        This Site is controlled and operated by Company</p>
      <p>Contact Information: Company, 1156 Autumn Ridge Dr, Lexington, KY 40509 USA: +1-866-856-4807 / UK: +44-80-0041-8628 / AU: +61-18-0095-8362 (toll-free) / 1-502-251-1332 (International Callers)</p>
      <br />
      <hr size="2" noshade color="#000000" />
      <br />
      <p>2019</p>
    </>
  );
};

export default Terms;
