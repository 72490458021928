import React from "react";
import amplitude from "amplitude-js";

import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { useLocation } from "react-router-dom";

import themeConfig from "../configs/theme";
import {
  setFetchingLoader,
  setAuthData,
  setStatuses,
  setAppFields,
  setLastVisit,
} from "../actions/auth";

import { setBaseUserData, setAvatar } from "../actions/profile";

import { authAPI } from "../api";
import axios from "../configs/axios";

import {
  checkSubscribeStatuses,
  amplitudeTransformData,
} from "../common/helpers";

import Login from "../components/pages/Login";

const LoginPage = ({ isFetching, login }) => {
  const location = useLocation();
  const urlSourceParam = new URLSearchParams(location.search).get("source");

  const handleSubmitForm = ({ email, password }) => {
    return login(email, password, urlSourceParam);
  };

  return (
    <>
      <Helmet>
        <title>Sign In - {themeConfig.title}</title>
      </Helmet>
      <Login handleSubmitForm={handleSubmitForm} isFetching={isFetching} />
    </>
  );
};

export default connect(
  (state) => {
    return {
      isFetching: state.auth.isFetching,
    };
  },
  (dispatch) => {
    return {
      login: (email, password, urlSourceParam) => {
        dispatch(setFetchingLoader(true));
        return authAPI
          .login(email, password)
          .then((res) => {
            let userIsActive = checkSubscribeStatuses(res.data.subscribes);
            if (!userIsActive) {
              throw Object.assign(new Error(), {
                innerError: true,
                message: "Account is not active",
              });
            }
            dispatch(setStatuses(res.data.subscribes));
            localStorage.setItem("token", res.data.jwt);
            localStorage.setItem("userId", res.data.user.id);
            localStorage.setItem("userName", res.data.user.first_name);
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.jwt}`;
            dispatch(
              setAvatar(
                res.data.user.avatar
                  ? `${process.env.REACT_APP_API_DOMAIN}${res.data.user.avatar.url}`
                  : res.data.user.avatar
              )
            );
            dispatch(
              setBaseUserData(
                res.data.user.id,
                res.data.user.first_name,
                res.data.user.confirmed,
                res.data.user.created_at,
                res.data.user.current_weight,
                res.data.user.units,
                res.data.user.email,
                res.data.user.actual_weight || res.data.user.current_weight,
                res.data.user.goal_weight,
                res.data.user.height,
                res.data.user.age,
                res.data.user.country_code
              )
            );
            dispatch(setLastVisit(res.data.user.last_visited));

            amplitude.getInstance().setUserId(res.data.user.email);
            amplitude.getInstance().setGroup("Client Name", themeConfig.title);
            amplitude
              .getInstance()
              .logEvent(
                "user_properties",
                amplitudeTransformData(res.data.user, res.data.subscribes)
              );
            dispatch(
              setAuthData(
                res.data.jwt,
                true,
                res.data.user.id,
                res.data.user.username
              )
            );
            if (urlSourceParam) {
              dispatch(setAppFields(urlSourceParam));
            }

            return res;
          })
          .catch((err) => {
            dispatch(setFetchingLoader(false));
            if (err) {
              if (
                err.response &&
                err.response.data.data &&
                err.response.data.data.length
              ) {
                const errorDesc = err.response.data.data[0].messages[0];
                const errorId = err.response.data.data[0].messages[0].id;

                throw new SubmissionError({
                  email:
                    errorId === "Auth.form.email.invalid"
                      ? errorDesc.message
                      : null,
                  password:
                    errorId === "Auth.form.password.invalid"
                      ? errorDesc.message
                      : null,
                });
              } else if (err.innerError) {
                throw new SubmissionError({
                  email: err.message,
                });
              } else {
                throw new SubmissionError({
                  email: "Ops... Unknown error",
                });
              }
            }
          });
      },
    };
  }
)(LoginPage);
