import React from 'react';

import './paramList.sass';

const ParamList = ({
  children,
  className
}) => {

  const mainClass = 'param-list';
  const classes = className ? `${mainClass} ${className}` : mainClass;

  return (
    <div className={classes}>
      {children}
    </div>
  )
};

export default ParamList;
