import dayjs from "dayjs";
import { previewMealPlan } from "../configs/mealPlans";

import {
  SET_TOGGLE_FETCHING,
  SET_DAILY_MEALS,
  SET_PLAN_INFO,
  CLEAR_MEAL_PLAN,
  SET_NEW_MEAL_TYPE,
  SET_IS_REPLACING,
  SET_SELECTED_MEALS_DAY,
  SET_IS_LOADED,
  SET_MEAL,
  SET_MEALS_LIST,
  SET_IS_FETCHING_LIST,
  SET_SELECTED_TYPE,
  RELOAD_MEALS_LIST,
  CLEAR_MEALS_LIST,
  SET_MEAL_PREVIEW,
  CLEAR_MEAL_PREVIEW,
  SET_NEW_MEAL_ID,
  ADD_ERROR,
  ADD_CURRENT_MEAL_PLAN,
  ADD_LIST_PLANS,
  UPDATE_ACTIVE_PLAN,
  ADD_IS_PURCHASING,
  UPDATE_LIST_PLANS,
  ADD_IS_REPLACING_MEAL_PLAN,
  ADD_REPLACE_MEAL_PLAN,
  CLEAR_PURCHASE_MODAL,
  ADD_PURCHASE_ERROR,
  ADD_MEALS_FROM_MEAL_PLAN,
} from "../constants/mealPlanTypes";

export const setMealsIdFromMealPlan = (mealPlan) => {
  const result = [];

  mealPlan.forEach((item) => {
    const keys = Object.keys(item).slice(2);
    keys.forEach((key) => {
      result.push(item[key].id);
    });
  });

  return {
    type: ADD_MEALS_FROM_MEAL_PLAN,
    mealsFromMealPlan: [...result],
  };
};

export const setPurchaseError = () => {
  return {
    type: ADD_PURCHASE_ERROR,
  };
};

export const clearPurchaseModalData = () => {
  return {
    type: CLEAR_PURCHASE_MODAL,
  };
};

export const setReplaceMealPlan = (mealPlanType, activeMeal) => {
  return {
    type: ADD_REPLACE_MEAL_PLAN,
    mealPlanType,
    activeMeal,
  };
};

export const setIsReplacingMealPlan = (isReplacingMealPlan) => {
  return {
    type: ADD_IS_REPLACING_MEAL_PLAN,
    isReplacingMealPlan,
  };
};

export const setUpdateListPlans = (list) => {
  const result = list.map((diet) => {
    return {
      id: diet.id,
      datePurchased: diet.activated_at
        ? dayjs(diet.activated_at).format()
        : dayjs().format(),
      status: !diet.isDefault ? "Not default" : "Default",
      isPurchased: true,
      days: [...diet.data.daily_meals],
      daysCount: diet.data.daily_meals.length,
    };
  });
  return {
    type: UPDATE_LIST_PLANS,
    diets: [...result],
  };
};

export const setIsPurchasing = (isPurchasing) => {
  return {
    type: ADD_IS_PURCHASING,
    isPurchasing,
  };
};

export const setListPlans = (list) => {
  let activePlan = null;
  const notPurchased = [];
  const resultList = list.map((diet) => {
    let dietTypeName = diet.diet_type.name;
    const result = {
      id: diet.id,
      name: dietTypeName + " Meal Plan",
      datePurchased: diet.activated_at
        ? dayjs(diet.activated_at).format()
        : dayjs().format(),
      status: !diet.isDefault ? "Not default" : "Default",
      isPurchased: true,
      isDefault: diet.isDefault,
      type: diet.diet_type.name.toLowerCase(),
    };

    if (diet.isDefault) {
      activePlan = { ...result };
    }

    if (diet.status === "inactive" || diet.status === "canceled") {
      result.status = "Not Purchased";
      result.isPurchased = false;
      result.days = [...previewMealPlan[result.type].daily_meals];
      result.daysCount = previewMealPlan[result.type].daily_meals.length;
      notPurchased.push(result.type);
    } else {
      result.days = [...diet.data.daily_meals];
      result.daysCount = diet.data.daily_meals.length;
    }
    return result;
  });

  return {
    type: ADD_LIST_PLANS,
    list: [...resultList],
    plan: { ...activePlan },
    notPurchased: [...notPurchased],
  };
};

export const updateActiveMealPlan = (id) => {
  return {
    type: UPDATE_ACTIVE_PLAN,
    id,
  };
};

export const setToggleFetching = (isFetching) => {
  return {
    type: SET_TOGGLE_FETCHING,
    isFetching,
  };
};

export const setIsLoaded = () => {
  return {
    type: SET_IS_LOADED,
  };
};

export const setIsReplacing = (isReplacing) => {
  return {
    type: SET_IS_REPLACING,
    isReplacing,
  };
};

export const setDailyMeals = (dailyMeals) => {
  return {
    type: SET_DAILY_MEALS,
    dailyMeals,
  };
};

export const setMealPlanInfo = (count, date) => {
  return {
    type: SET_PLAN_INFO,
    count,
    date,
  };
};

export const clearMealPlan = () => {
  return {
    type: CLEAR_MEAL_PLAN,
  };
};

export const setSelectedMealType = (id, name) => {
  return {
    type: SET_NEW_MEAL_TYPE,
    id,
    name,
  };
};

export const setSelectedMealsDay = (day) => {
  return {
    type: SET_SELECTED_MEALS_DAY,
    day,
  };
};

export const setMeal = (meal) => {
  return {
    type: SET_MEAL,
    meal,
  };
};

export const setNewMealId = (newMealId) => {
  return {
    type: SET_NEW_MEAL_ID,
    newMealId,
  };
};

export const setMealsList = (mealsList, mealsListStart, mealsListCount) => {
  return {
    type: SET_MEALS_LIST,
    mealsList,
    mealsListStart,
    mealsListCount,
  };
};

export const reloadMealsList = (mealsList, mealsListStart, mealsListCount) => {
  return {
    type: RELOAD_MEALS_LIST,
    mealsList,
    mealsListStart,
    mealsListCount,
  };
};

export const setIsFetchingList = (isFetchingList) => {
  return {
    type: SET_IS_FETCHING_LIST,
    isFetchingList,
  };
};

export const setSelectedType = (selectedType, typeName, mealId, activeDay) => {
  return {
    type: SET_SELECTED_TYPE,
    selectedType,
    typeName,
    mealId,
    activeDay,
  };
};

export const clearMealsList = () => {
  return {
    type: CLEAR_MEALS_LIST,
  };
};

export const setMealPreview = (mealPreview) => {
  return {
    type: SET_MEAL_PREVIEW,
    mealPreview,
  };
};

export const clearMealPreview = () => {
  return {
    type: CLEAR_MEAL_PREVIEW,
  };
};

export const setError = (error) => {
  return {
    type: ADD_ERROR,
    error,
  };
};

export const setCurrentMealPlan = (mealPlan) => {
  return {
    type: ADD_CURRENT_MEAL_PLAN,
    mealPlan,
  };
};
