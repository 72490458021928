import React from "react";

import Title from "../../common/Title";
import WorkoutCard from "../../../common/containers/WorkoutCardContainer";
import LoaderWrap from "../../common/LoaderWrap";
import Button from "../../common/Button";
import FilterSelectList from "../../common/FilterSelectList";
import SearchFail from "../../common/SearchFail";

import "./CategoryDetail.sass";

const CategoryDetail = ({
  list,
  listCount,
  categoryTitle,
  isFetching,
  handleUpdateListWorkouts,
  handleFilterSelectChange,
  filtersList,
  handleClearFilter,
}) => {
  return (
    <div className="content-page">
      <div className="container">
        <div className="content-page-head">
          <Title>{categoryTitle}</Title>
          <FilterSelectList
            className="category-detail-filters"
            list={filtersList}
            handleChange={handleFilterSelectChange}
          />
        </div>
        {list.length ? (
          <>
            <div className="workout-plans-list">
              {list.map(
                ({
                  id,
                  title,
                  level,
                  calories,
                  duration,
                  category,
                  preview,
                  isFavourite,
                }) => {
                  return (
                    <WorkoutCard
                      key={id}
                      id={id}
                      linkTo={`/workouts/${id}`}
                      title={title}
                      level={level}
                      calories={calories}
                      duration={duration}
                      category={category}
                      preview={preview}
                      size="small"
                      withLike
                      isFavourite={isFavourite}
                    />
                  );
                }
              )}
            </div>
            {listCount > list.length ? (
              <div className="workout-plans-more">
                <LoaderWrap spin={isFetching}>
                  <Button
                    disabled={isFetching}
                    onClick={handleUpdateListWorkouts}
                  >
                    View more
                  </Button>
                </LoaderWrap>
              </div>
            ) : null}
          </>
        ) : (
          <SearchFail
            message="No workouts with such parameters were found"
            className="categories-not-found"
            onCleanFilter={handleClearFilter}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryDetail;
