import React from "react";

import Title from "../Title";
import ParamList from "../ParamList";
import Param from "../Param";
import IconSprite from "../IconSprite";
import BlockGroup from "../BlockGroup";

import "./mealInner.sass";

const convertToHours = (minutes) => {
  minutes = parseInt(minutes);

  if (minutes === 0) return ":)";
  if (minutes <= 60) {
    return `${minutes} min`;
  }
  const diff = minutes % 60;
  const resultMinutes = diff > 0 ? `${diff}m` : "";
  return `${(minutes - diff) / 60}h ${resultMinutes}`;
};

const convertStrings = (string) => {
  if (!string) return null;

  const baseArr = string.split("# ").filter((item) => item);

  return baseArr.map((item) => {
    return item
      .split("\n")
      .filter((item) => item)
      .join("<br>");
  });
};

const MealInner = ({
  title,
  image,
  meal_serving,
  cooking_time,
  ingredients,
  prep,
  colorTheme,
  portions,
  cooking,
}) => {
  const mealPic = image.length
    ? `${process.env.REACT_APP_API_DOMAIN}` + image[0].url
    : null;
  const preparationsList = convertStrings(prep);
  const cookingList = convertStrings(cooking);
  const classes = `meal content-page-inner ${colorTheme ? colorTheme : ""}`;
  const ingredientsList = ingredients
    ? ingredients.split("# ").filter((item) => item)
    : null;
  const portionsList = portions
    ? portions.split("#").filter((item) => item)
    : [];
  const serving =
    meal_serving &&
    (typeof meal_serving === "number" ? meal_serving : meal_serving.type);

  return (
    <div className={classes}>
      <BlockGroup className="content-page-left" breakpoint={720}>
        <div className="meal__head">
          <Title>{title}</Title>
          <ParamList>
            <Param
              icon={
                <IconSprite className="icon-time-small" name="time-small" />
              }
            >
              {convertToHours(cooking_time)}
            </Param>
            {!meal_serving || (
              <Param icon={<IconSprite className="icon-serve" name="serve" />}>
                {serving} {serving > 1 ? "servings" : "serving"}
              </Param>
            )}
          </ParamList>
        </div>
        {/* <div className="title-add"></div> */}
        {ingredientsList && (
          <div className="meal__ingredients">
            <div className="title-medium">Ingredients</div>
            <ul className="ingredients">
              {ingredientsList.map((ingredient, index) => {
                return (
                  <li className="ingredient" key={index}>
                    <div className="ingredient__name">{ingredient}</div>
                    <div className="ingredient__value">
                      {portionsList[index]}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </BlockGroup>
      <BlockGroup className="content-page-right" breakpoint={720}>
        {mealPic && (
          <div className="meal__pic">
            <img src={mealPic} alt={title} />
          </div>
        )}
        {cookingList ? (
          <div className="meal__preparations">
            <div className="title-medium">Cooking</div>
            <ul className="preparations">
              {cookingList.map((preparation, index) => {
                return (
                  <li className="preparation" key={index}>
                    <div className="preparation__num">{index + 1}</div>
                    <div
                      className="preparation__value"
                      dangerouslySetInnerHTML={{ __html: preparation }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        {preparationsList ? (
          <div className="meal__preparations">
            <div className="title-medium">How to Cook</div>
            <ul className="preparations">
              {preparationsList.map((preparation, index) => {
                return (
                  <li className="preparation" key={index}>
                    <div className="preparation__num">{index + 1}</div>
                    <div
                      className="preparation__value"
                      dangerouslySetInnerHTML={{ __html: preparation }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </BlockGroup>
    </div>
  );
};

export default MealInner;
