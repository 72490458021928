import React, { useState, useEffect } from "react";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import { contentAPI } from "../api";
import themeConfig from "../configs/theme";
import { connect } from "react-redux";

import {
  setIsLoaded,
  setDailyMeals,
  setMealPlanInfo,
  clearMealPlan,
  setSelectedMealType,
  setSelectedMealsDay,
  setMeal,
  setMealsList,
  setIsFetchingList,
  setSelectedType,
  setToggleFetching,
  reloadMealsList,
  clearMealsList,
  setMealPreview,
  clearMealPreview,
  setIsReplacing,
  setNewMealId,
  setError,
  setListPlans,
  updateActiveMealPlan,
  setIsPurchasing,
  setUpdateListPlans,
  setIsReplacingMealPlan,
  setReplaceMealPlan,
  clearPurchaseModalData,
  setPurchaseError,
  setMealsIdFromMealPlan,
} from "../actions/mealPlan";

import { setMealType } from "../actions/meals";

import { clearAuth } from "../actions/auth";

import ConfirmModal from "../components/modals/ConfirmModal";
import MealPlan from "../components/pages/MealPlan";
import MealPlanModal from "./MealPlanModal";
import PageLoader from "../components/common/PageLoader";
import FixedLoader from "../components/common/FixedLoader";

import ErrorPage from "../components/pages/Error";

import { LoaderOver } from "../common/components";

const calculateFilterString = (filterObj) => {
  const keys = Object.keys(filterObj);
  let result = "";
  keys.map((query) => {
    if (filterObj[query] && filterObj[query] !== "all") {
      result += `&${query}=${filterObj[query]}`;
    }
    return query;
  });

  return result;
};

const MealPlanPage = ({
  isLoaded,
  dailyMeals,
  daysCount,
  mealPlanDateCreated,
  getMealPlan,
  clearMealPlan,
  setSelectedMealsDay,
  setMeal,
  getMealsList,
  setSelectedType,
  selectedType,
  updateMealsList,
  mealsListStart,
  reloadMealsList,
  clearMealsList,
  getMealPreview,
  clearMealPreview,
  isReplacing,
  replaceAllMeals,
  mealId,
  setNewMealId,
  newMealId,
  replaceOneMeal,
  activeDay,
  typeName,
  error,
  userEmail,
  statuses,
  listPlans,
  activePlan,
  updateMealPlans,
  withChange,
  notPurchasedMealPlans,
  replaceMealPlan,
  purchaseMealPlan,
  isPurchasing,
  isPurchasedDone,
  isReplacingMealPlan,
  listPlansReadyToDefault,
  clearPurchaseModalData,
  isPurchasedError,
  profileCreatedDate,
  passedLongTime,
  daysSince,
}) => {
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [modalRecipeIsOpen, setModalRecipeIsOpen] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [activeMeal, setActiveMeal] = useState(null);
  const [mealName, setMealName] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [filterObj, setFilterObj] = useState({
    meal_types: selectedType,
  });
  const [isOpenPurchaseModal, setIsOpenPurchaseModal] = useState(false);
  const [typeToPurchase, setTypeToPurchase] = useState(null);

  useEffect(() => {
    getMealPlan(profileCreatedDate);
    return () => {
      clearMealPlan();
    };
  }, [getMealPlan, clearMealPlan, profileCreatedDate]);

  useEffect(() => {
    if (passedLongTime) {
      amplitude.getInstance().logEvent("meal_plan_open_force", {
        Days_since: daysSince,
      });
    }
  }, [passedLongTime, daysSince]);

  const handleSearch = (mealName) => {
    let filterString = calculateFilterString(filterObj);
    if (mealName) {
      filterString += "&title_contains=" + encodeURI(mealName);
    }
    reloadMealsList(filterString);
  };

  const selectChange = (filterObj) => {
    let filterString = calculateFilterString(filterObj);
    if (mealName) {
      filterString += "&title_contains=" + encodeURI(mealName);
    }
    reloadMealsList(filterString);
  };

  const handleModalRecipeReplaceListOpen = () => {
    setOpenList("fixed");
    const filterObj = { meal_types: selectedType };
    getMealsList(calculateFilterString(filterObj));
    setFilterObj({ ...filterObj });
  };

  const handleModalRecipeOpen = (meal, typeId, typeName, mealId, activeDay) => {
    if (!activePlan.isPurchased) {
      return false;
    }
    setMeal({ ...meal });
    document.body.style.overflow = "hidden";
    setModalRecipeIsOpen(true);
    setSelectedType(typeId, typeName, mealId, activeDay);
  };

  const handleReplaceFromList = (newMealId) => {
    setNewMealId(newMealId);
    openConfirmModal();
  };

  const handleModalRecipeClose = () => {
    setModalRecipeIsOpen(false);
    setOpenList(false);
    setOpenPreview(false);
    clearMealsList();
    document.body.style.overflow = "";
  };

  const handleLoadMore = () => {
    let filterString = calculateFilterString(filterObj);
    if (mealName) {
      filterString += "&title_contains=" + encodeURI(mealName);
    }
    updateMealsList(mealsListStart, filterString);
  };

  const handleOpenPreview = (id) => {
    setOpenPreview(true);
    setNewMealId(id);
    getMealPreview(id);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    clearMealPreview();
  };

  const openConfirmModal = () => {
    setConfirmModalIsOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalIsOpen(false);
  };

  const handleReplaceAllMeals = () => {
    closeConfirmModal(false);
    replaceAllMeals(mealId, newMealId).then((res) => {
      handleModalRecipeClose();
    });
    amplitude.getInstance().logEvent("meal_plan_replace_meal");
  };

  const handleReplaceOneMeal = () => {
    closeConfirmModal(false);
    replaceOneMeal(activeDay, newMealId, typeName).then((res) => {
      handleModalRecipeClose();
    });
    amplitude.getInstance().logEvent("meal_plan_replace_meal");
  };

  const handleOpenModalList = (typeId, activeDay, typeName, mealId) => {
    setOpenList("static");
    setModalRecipeIsOpen(true);
    document.body.style.overflow = "hidden";
    const filterObj = { meal_types: typeId };
    setSelectedType(typeId, typeName, mealId, activeDay);
    getMealsList(calculateFilterString(filterObj));
    setFilterObj({ ...filterObj });
  };

  const handleMealDone = (type) => {
    const mealPlanModel = JSON.parse(localStorage.getItem("mealPlan"));
    let result = {
      [userEmail]: {
        list: {},
      },
    };

    if (mealPlanModel) {
      if (mealPlanModel[userEmail]) {
        result[userEmail] = { ...mealPlanModel[userEmail] };
      }
      result = { ...mealPlanModel, ...result };
    }

    if (
      mealPlanModel &&
      mealPlanModel[userEmail] &&
      mealPlanModel[userEmail].date ===
        dayjs().startOf("day").format("YYYY-MM-DD")
    ) {
      result[userEmail].list[type] = type;
    } else {
      result[userEmail].date = dayjs().startOf("day").format("YYYY-MM-DD");
      result[userEmail].list = {
        [type]: type,
      };
    }
    localStorage.setItem("mealPlan", JSON.stringify(result));
    amplitude.getInstance().logEvent("meal_plan_meal_log");
  };

  const handleMealUnDo = (type) => {
    const mealPlanModel = JSON.parse(localStorage.getItem("mealPlan"));
    let result = {
      [userEmail]: {
        list: {},
      },
    };

    if (mealPlanModel) {
      if (mealPlanModel[userEmail]) {
        result[userEmail] = { ...mealPlanModel[userEmail] };
      }
      result = { ...mealPlanModel, ...result };
    }

    if (
      mealPlanModel &&
      mealPlanModel[userEmail] &&
      mealPlanModel[userEmail].date ===
        dayjs().startOf("day").format("YYYY-MM-DD")
    ) {
      if (result[userEmail].list[type]) {
        delete result[userEmail].list[type];
      }
    }

    localStorage.setItem("mealPlan", JSON.stringify(result));
    amplitude.getInstance().logEvent("meal_plan_meal_undo");
  };

  const handleHelperClose = () => {
    const mealPlanModel = JSON.parse(localStorage.getItem("mealPlan"));
    let result = {
      [userEmail]: {
        withHelper: false,
      },
    };

    if (mealPlanModel) {
      if (mealPlanModel[userEmail]) {
        result[userEmail] = { ...mealPlanModel[userEmail], withHelper: false };
      }
      result = { ...mealPlanModel, ...result };
    }

    localStorage.setItem("mealPlan", JSON.stringify(result));
  };

  const handleMealPlanChange = (id, isPreview) => {
    if (!isPreview) {
      setActiveMeal(1);
    } else {
      setActiveMeal(null);
    }
    updateMealPlans(id);
  };

  const handlePurchaseModalOpen = (type) => {
    setIsOpenPurchaseModal(true);
    setTypeToPurchase(type);
  };

  const handlePurchaseModalClose = () => {
    setIsOpenPurchaseModal(false);
    setTypeToPurchase(null);
  };

  const handleSendPurchase = (products) => {
    if (!products.length) {
      return false;
    }
    purchaseMealPlan(products);
  };

  const handleReplaceMealPlan = (type, id) => {
    replaceMealPlan(type[0].toUpperCase() + type.slice(1), activeMeal - 1, id);
  };

  const handleReplaceMealPlanFromModal = (type) => {
    handleReplaceMealPlan(type);
    handlePurchaseModalClose();
  };

  if (error) {
    return <ErrorPage error="server_error" />;
  }

  if (!isLoaded) {
    return <PageLoader />;
  }
  return (
    <>
      <Helmet>
        <title>Your Meal Plan by {themeConfig.title}</title>
      </Helmet>
      <MealPlan
        daysCount={daysCount}
        mealPlanDateCreated={mealPlanDateCreated}
        meals={dailyMeals[activeMeal - 1]}
        activeMeal={activeMeal}
        setActiveMeal={setActiveMeal}
        handleModalRecipeOpen={handleModalRecipeOpen}
        setSelectedMealsDay={setSelectedMealsDay}
        handleOpenModalList={handleOpenModalList}
        handleMealDone={handleMealDone}
        handleMealUnDo={handleMealUnDo}
        userEmail={userEmail}
        handleHelperClose={handleHelperClose}
        statuses={statuses}
        listPlans={listPlans}
        activePlan={activePlan}
        handleMealPlanChange={handleMealPlanChange}
        withChange={withChange}
        isOpenPurchaseModal={isOpenPurchaseModal}
        handlePurchaseModalOpen={handlePurchaseModalOpen}
        handlePurchaseModalClose={handlePurchaseModalClose}
        notPurchasedMealPlans={notPurchasedMealPlans}
        handleSendPurchase={handleSendPurchase}
        handleReplaceMealPlan={handleReplaceMealPlan}
        isPurchasing={isPurchasing}
        isPurchasedDone={isPurchasedDone}
        typeToPurchase={typeToPurchase}
        listPlansReadyToDefault={listPlansReadyToDefault}
        handleReplaceMealPlanFromModal={handleReplaceMealPlanFromModal}
        clearPurchaseModalData={clearPurchaseModalData}
        isPurchasedError={isPurchasedError}
        passedLongTime={passedLongTime}
      />

      {!modalRecipeIsOpen || (
        <MealPlanModal
          handleModalClose={handleModalRecipeClose}
          handleModalRecipeReplaceListOpen={handleModalRecipeReplaceListOpen}
          openList={openList}
          setFilterObj={setFilterObj}
          filterObj={filterObj}
          handleLoadMore={handleLoadMore}
          mealName={mealName}
          setMealName={setMealName}
          selectChange={selectChange}
          handleSearch={handleSearch}
          handleOpenPreview={handleOpenPreview}
          openPreview={openPreview}
          handleClosePreview={handleClosePreview}
          openConfirmModal={openConfirmModal}
          handleReplaceFromList={handleReplaceFromList}
        />
      )}

      {isReplacing && <FixedLoader />}

      {confirmModalIsOpen && (
        <ConfirmModal
          title="Replace Recipe"
          description="Replace this recipe once or for all?"
          buttonMainName="Select Recipe"
          buttonSecondName="For all"
          handleClose={closeConfirmModal}
          handleSecondClick={handleReplaceAllMeals}
          handleMainClick={handleReplaceOneMeal}
        />
      )}
      <LoaderOver isLoading={isReplacingMealPlan} />
    </>
  );
};

export default connect(
  (state) => ({
    isLoaded: state.mealPlan.isLoaded,
    dailyMeals: state.mealPlan.dailyMeals,
    daysCount: state.mealPlan.daysCount,
    mealPlanDateCreated: state.mealPlan.mealPlanDateCreated,
    selectedType: state.mealPlan.selectedType,
    mealsListStart: state.mealPlan.mealsListStart,
    isReplacing: state.mealPlan.isReplacing,
    mealId: state.mealPlan.mealId,
    newMealId: state.mealPlan.newMealId,
    activeDay: state.mealPlan.activeDay,
    typeName: state.mealPlan.typeName,
    error: state.mealPlan.error,
    userEmail: state.profile.email,
    statuses: state.auth.statuses,
    listPlans: state.mealPlan.listPlans,
    activePlan: state.mealPlan.activePlan,
    withChange: state.mealPlan.withChange,
    notPurchasedMealPlans: state.mealPlan.notPurchased,
    isPurchasing: state.mealPlan.isPurchasing,
    isPurchasedDone: state.mealPlan.isPurchasedDone,
    isPurchasedError: state.mealPlan.isPurchasedError,
    isReplacingMealPlan: state.mealPlan.isReplacingMealPlan,
    listPlansReadyToDefault: state.mealPlan.listPlansReadyToDefault,
    profileCreatedDate: state.profile.profileCreatedDate,
    passedLongTime: state.auth.passedLongTime,
    daysSince: state.auth.daysSince,
  }),
  (dispatch) => ({
    setMealType: (id) => dispatch(setMealType(id)),
    setSelectedMealType: (id, name) => dispatch(setSelectedMealType(id, name)),
    setSelectedMealsDay: (day) => dispatch(setSelectedMealsDay(day)),

    setSelectedType: (mealType, typeName, mealId, activeDay) =>
      dispatch(setSelectedType(mealType, typeName, mealId, activeDay)),
    setMeal: (meal) => dispatch(setMeal(meal)),
    getMealPlan: (profileCreatedDate) => {
      return contentAPI
        .getListMealPlans()
        .then((list) => {
          if (list.data.length === 0 || !list.data) {
            return contentAPI.getMealPlan().then((res) => {
              const mealPlanDateActivated =
                res.data.activated_at || profileCreatedDate;

              dispatch(
                setMealPlanInfo(
                  res.data.data.daily_meals.length,
                  dayjs(mealPlanDateActivated).format()
                )
              );

              dispatch(setMealsIdFromMealPlan(res.data.data.daily_meals));
              dispatch(setDailyMeals([...res.data.data.daily_meals]));
              dispatch(
                setListPlans([
                  {
                    ...res.data,
                    isDefault: true,
                    activated_at: mealPlanDateActivated,
                  },
                ])
              );
              dispatch(setIsLoaded());
            });
          }
          const defaultMealPlanIdx = list.data.findIndex(
            (mealPlan) => mealPlan.isDefault
          );
          const mealPlanDateActivated =
            list.data[defaultMealPlanIdx].activated_at || profileCreatedDate;

          dispatch(
            setMealPlanInfo(
              list.data[defaultMealPlanIdx].data.daily_meals.length,
              dayjs(mealPlanDateActivated).format()
            )
          );
          dispatch(
            setDailyMeals([...list.data[defaultMealPlanIdx].data.daily_meals])
          );
          dispatch(setListPlans([...list.data]));
          dispatch(setIsLoaded());
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
          console.log(error);
          dispatch(setError(error.response.status));
          dispatch(setIsLoaded());
        });
    },
    replaceMealPlan: (type, activeMeal, id) => {
      dispatch(setIsReplacingMealPlan(true));
      return contentAPI.replaceMealPlan(id).then((res) => {
        dispatch(setReplaceMealPlan(type.toLowerCase(), activeMeal));
        dispatch(setIsReplacingMealPlan(false));
      });
    },
    clearPurchaseModalData: () => dispatch(clearPurchaseModalData()),
    purchaseMealPlan: (diets) => {
      dispatch(setIsPurchasing(true));
      return contentAPI
        .purchaseMealPlan(diets)
        .then((res) => {
          dispatch(setUpdateListPlans(res.data));
          dispatch(setIsPurchasing(false));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
          dispatch(setPurchaseError());
          dispatch(setIsPurchasing(false));
        });
    },
    updateMealPlans: (id) => dispatch(updateActiveMealPlan(id)),
    clearMealPlan: () => dispatch(clearMealPlan()),
    clearMealsList: () => dispatch(clearMealsList()),
    clearMealPreview: () => dispatch(clearMealPreview()),
    setNewMealId: (id) => dispatch(setNewMealId(id)),
    getMealsList: (filter = "") => {
      dispatch(setIsFetchingList(true));
      return contentAPI
        .getMeals(0, filter, 6)
        .then((res) => {
          dispatch(setMealsList(res.data.data.recipes, 6, res.data.count));
          dispatch(setIsFetchingList(false));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    updateMealsList: (start, filter = "") => {
      dispatch(setToggleFetching(true));
      return contentAPI
        .getMeals(start, filter, 6)
        .then((res) => {
          dispatch(
            setMealsList(res.data.data.recipes, start + 6, res.data.count)
          );
          dispatch(setToggleFetching(false));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    reloadMealsList: (filter = "") => {
      return contentAPI
        .getMeals(0, filter, 6)
        .then((res) => {
          dispatch(reloadMealsList(res.data.data.recipes, 6, res.data.count));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    getMealPreview: (id) => {
      return contentAPI
        .getMeal(id)
        .then((res) => {
          dispatch(setMealPreview(res.data));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    replaceAllMeals: (from, to) => {
      dispatch(setIsReplacing(true));
      return contentAPI
        .replaceAllMeal(from, to)
        .then((res) => {
          dispatch(setDailyMeals(res.data.data.daily_meals));
          dispatch(setMealsIdFromMealPlan(res.data.data.daily_meals));
          dispatch(setIsReplacing(false));
          return res;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    replaceOneMeal: (day, to, serving) => {
      dispatch(setIsReplacing(true));
      return contentAPI
        .replaceOneMeal(day, to, serving)
        .then((res) => {
          dispatch(setDailyMeals(res.data.data.daily_meals));
          dispatch(setMealsIdFromMealPlan(res.data.data.daily_meals));
          dispatch(setIsReplacing(false));
          return res;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
  })
)(MealPlanPage);
