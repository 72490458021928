import {
  ADD_IS_LOADED,
  ADD_AUTHOR,
  ADD_ERROR,
  CLEAR_AUTHOR_DATA
} from '../constants/authorTypes';

const initialState = {
  isLoaded: false,
  name: null,
  avatar: null,
  description: null,
  socials: [],
  error: null
};

const author = (state = initialState, action) => {
  switch (action.type) {
    case ADD_IS_LOADED: {
      return {
        ...state,
        isLoaded: true
      }
    }
    case ADD_ERROR: {
      return {
        ...state,
        error: action.error
      }
    }
    case ADD_AUTHOR: {
      return {
        ...state,
        name: action.name,
        avatar: action.avatar,
        description: action.description,
        socials: [...action.socials],
      }
    }
    case CLEAR_AUTHOR_DATA: {
      return {
        ...initialState,
      }
    }
    case "DEFAULT_STATE": {
      return {
        ...initialState
      }
    }
    default:
      return state;
  }
};


export default author;
