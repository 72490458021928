import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import themeConfig from "../configs/theme";
import { contentAPI } from "../api";
import amplitude from "amplitude-js";

import {
  setIsLoaded,
  setLogWeightList,
  setIsSending,
  updateLogWeightList,
  setCurrentWeekIsLogged,
} from "../actions/logWeight";

import { setActualWeight } from "../actions/profile";

import { clearAuth } from "../actions/auth";

import PageLoader from "../components/common/PageLoader";
import LogWeight from "../components/pages/LogWeight";

const LogWeightPage = ({
  getWeightLogs,
  sendWeightLogs,
  actualWeight,
  goalWeight,
  measurement,
  isLoaded,
  list,
  isSending,
}) => {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      getWeightLogs();
    }
  }, [getWeightLogs, isLoaded]);

  const handleSendLogWeight = (value) => {
    sendWeightLogs(value).then(() => {
      setIsLogged(true);
    });
  };

  if (!isLoaded) {
    return <PageLoader />;
  }

  return (
    <>
      <Helmet>
        <title>Log Your Weight - {themeConfig.title}</title>
      </Helmet>
      <LogWeight
        actualWeight={actualWeight}
        goalWeight={goalWeight}
        measurement={measurement}
        list={list}
        isSending={isSending}
        handleSendLogWeight={handleSendLogWeight}
        isLogged={isLogged}
      />
    </>
  );
};

export default connect(
  ({ profile, logWeight }) => ({
    actualWeight: profile.actualWeight,
    measurement: profile.measurement,
    goalWeight: profile.goalWeight,
    isLoaded: logWeight.isLoaded,
    list: logWeight.list,
    isSending: logWeight.isSending,
  }),
  (dispatch) => ({
    sendWeightLogs: (value) => {
      dispatch(setIsSending(true));
      return contentAPI
        .sendWeightLogs(value)
        .then((res) => {
          dispatch(setActualWeight(res.data.weightLog.weight));
          dispatch(updateLogWeightList(res.data.weightLog));
          dispatch(setCurrentWeekIsLogged());
          dispatch(setIsSending(false));
          amplitude
            .getInstance()
            .logEvent("user_properties", { current_weight: value });
          return res;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
    getWeightLogs: () => {
      return contentAPI
        .getWeightLogs()
        .then((res) => {
          dispatch(setLogWeightList(res.data));
          dispatch(setIsLoaded());
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            clearAuth(dispatch);
          }
        });
    },
  })
)(LogWeightPage);
