export const SET_IS_LOADED = 'RECIPES:SET_IS_LOADED';
export const SET_RECIPES_LIST = 'RECIPES:SET_RECIPES_LIST';
export const UPLOAD_RECIPES_LIST = 'RECIPES:UPLOAD_RECIPES_LIST';
export const SET_RECIPES_LIST_INFO = 'RECIPES:SET_RECIPES_LIST_INFO';
export const SET_RECIPES_LIST_IS_FETCHING = 'RECIPES:SET_RECIPES_LIST_IS_FETCHING';
export const ADD_LIKE = 'RECIPES:ADD_LIKE';
export const REMOVE_LIKE = 'RECIPES:REMOVE_LIKE';
export const ADD_FAVORITES_RECIPES = 'RECIPES:ADD_FAVORITES_RECIPES';
export const SET_FAVORITES_LIST_INFO = 'RECIPES:SET_FAVORITES_LIST_INFO';
export const UPDATE_FAVORITES_LIST_INFO = 'RECIPES:UPDATE_FAVORITES_LIST_INFO';
export const ADD_FILTERS_PARAMS = 'RECIPES:ADD_FILTERS_PARAMS';