import React, { useEffect } from "react";
import { purchaseConfig } from "../../../../../configs/mealPlans";

import Product from "./components/Product";
import Loader from "./components/Loader";
import Select from "../../../../common/Select";
import "./PlanCartBody.sass";

const PlanCartBody = ({
  listCreator,
  onChooseProduct,
  isPurchasedDone,
  productsForPurchase,
  listPlansReadyToDefault,
  setDietToReplace,
  isPurchasedError,
}) => {
  useEffect(() => {
    if (
      listPlansReadyToDefault.length &&
      listPlansReadyToDefault.length === 1
    ) {
      setDietToReplace(listPlansReadyToDefault[0]);
    }
  }, [listPlansReadyToDefault, setDietToReplace]);

  return (
    <div className="plan-cart-body">
      {!isPurchasedDone && !isPurchasedError && (
        <div className="plan-cart-body-main">
          <div className="plan-cart-title">Get New Meal Plans</div>
          <div className="plan-cart-body-list">
            {listCreator &&
              listCreator.length &&
              listCreator.map((type) => {
                const diet = purchaseConfig[type];
                return (
                  <div className="plan-cart-body-item" key={type}>
                    <Product
                      {...diet}
                      type={type}
                      isChecked={
                        productsForPurchase.indexOf(
                          type[0].toUpperCase() + type.slice(1)
                        ) !== -1
                      }
                      onChange={() =>
                        onChooseProduct(
                          type[0].toUpperCase() + type.slice(1),
                          diet.price,
                          diet.oldPrice
                        )
                      }
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}

      {isPurchasedDone && (
        <div className="plan-cart-body-success">
          <div className="plan-cart-body-success__inner">
            <Loader className="plan-cart-body-icon" />
            {listPlansReadyToDefault.length > 1 ? (
              <>
                <div className="plan-cart-body-success__title">
                  New diets were added
                </div>
                <div className="plan-cart-body-success__desc">
                  You can start a new diet now,
                  <br />
                  or do this later from your Meal Plan page
                </div>
                <div className="plan-cart-body-success__select">
                  <Select
                    label="label"
                    onChange={({ target }) => {
                      setDietToReplace(target.value);
                    }}
                    options={listPlansReadyToDefault.map((diet) => {
                      return {
                        title: diet[0].toUpperCase() + diet.slice(1) + " Diet",
                        value: diet,
                      };
                    })}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="plan-cart-body-success__title">
                  New diets were added
                </div>
                <div className="plan-cart-body-success__desc">
                  You can start a new diet now,
                  <br />
                  or do this later from your Meal Plan page
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {isPurchasedError && (
        <div className="plan-cart-body-error">
          <div className="plan-cart-body-error__inner">
            <div className="plan-cart-error-ico">
              <span></span>
              <span></span>
            </div>
            <div className="plan-cart-error-title">Error</div>
            <div className="plan-cart-desc">Lorem ipsum dolor sit amet.</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanCartBody;
