import React from "react";

import DietCard from "../DietCard";

import images from "./images";
import classes from "./Diets.module.sass";

const config = [
  {
    id: 0,
    product: 501,
    title: "Keto",
    name: "keto",
    desc: "Keto diet is a high-fat, low-carb diet with adequate-protein: meat, fish, eggs, healthy oils, and low carb veggies.",
  },
  {
    id: 1,
    product: 506,
    title: "Mediterranean",
    name: "mediterranean",
    desc: "Plant-based diet with good fats and seafood: fruits, vegetables, beans, nuts, seafood, and olive oil",
  },
  {
    id: 2,
    product: 507,
    title: "Flexitarian",
    name: "flexitarian",
    desc: "A vegetarian diet, of grains, fruits, vegetables and nuts; with the flexibility of meat in moderation",
  },
  {
    id: 3,
    product: 508,
    title: "Vegan",
    name: "vegan",
    desc: "Diet involves eating only plant-based food, excluding foods made from animals: meat, dairy, and eggs",
  },
];

const Diets = ({ answer, onNext }) => {
  const handleClick = (product) => {
    onNext(product);
  };

  return (
    <div className={classes.wrap}>
      {config.map(({ id, name, product, ...props }) => (
        <DietCard
          key={id}
          active={answer === product}
          image={images[name]}
          onClick={() => handleClick(product)}
          {...props}
        />
      ))}
    </div>
  );
};

export default Diets;
