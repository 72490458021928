import React from 'react';

import Title from '../../common/Title';

import CategoryPreview from './components/CategoryPreview';

import './categories.sass';

const Categories = ({
  categories
}) => {
  return (
    <div className="content-page">
      <div className="container">
        <div className="content-page-head">
          <Title className="categories-title">Categories</Title>
        </div>
        <div className="categories-list">
          {categories.map(category => {
            return (
              <CategoryPreview key={category.name} {...category} />
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default Categories;