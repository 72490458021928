import React from 'react';
import clsx from 'clsx';
import "./Loader.sass";

const Loader = ({
  className
}) => {
  return (
    <div className={clsx("success-checkmark", {
      [className]: className
    })}>
      <div className="check-icon">
        <span className="icon-line line-tip"></span>
        <span className="icon-line line-long"></span>
        <div className="icon-circle"></div>
        <div className="icon-fix"></div>
      </div>
    </div>
  );
};

export default Loader;