import {
  SET_IS_LOADED,
  SET_RECIPES_LIST,
  SET_RECIPES_LIST_INFO,
  UPLOAD_RECIPES_LIST,
  SET_RECIPES_LIST_IS_FETCHING,
  ADD_LIKE,
  REMOVE_LIKE,
  ADD_FAVORITES_RECIPES,
  SET_FAVORITES_LIST_INFO,
  UPDATE_FAVORITES_LIST_INFO,
  ADD_FILTERS_PARAMS
} from '../constants/recipesTypes';


export const setIsLoaded = () => {
  return {
    type: SET_IS_LOADED
  };
};

export const setFilters = (filterParams, filtersString) => {
  return {
    type: ADD_FILTERS_PARAMS,
    filterParams,
    filtersString
  };
};

export const setRecipesListIsFetching = (recipesIsFetching) => {
  return {
    type: SET_RECIPES_LIST_IS_FETCHING,
    recipesIsFetching
  };
};

export const setRecipesList = (list) => {
  return {
    type: SET_RECIPES_LIST,
    list
  };
};

export const uploadRecipesList = (list) => {
  return {
    type: UPLOAD_RECIPES_LIST,
    list
  };
};

export const setRecipesListInfo = (count, currentStart) => {
  return {
    type: SET_RECIPES_LIST_INFO,
    count,
    currentStart
  };
};

export const setLike = (id, newRecipe) => {
  return {
    type: ADD_LIKE,
    id,
    newRecipe
  };
};

export const removeLike = (id, recipeId) => {
  return {
    type: REMOVE_LIKE,
    id,
    recipeId
  };
};

export const setFavoritesList = (list) => {
  return {
    type: ADD_FAVORITES_RECIPES,
    list
  };
};

export const setFavoritesListInfo = (count, currentStart) => {
  return {
    type: SET_FAVORITES_LIST_INFO,
    count,
    currentStart
  };
};

export const uploadFavoritesList = (list) => {
  return {
    type: UPDATE_FAVORITES_LIST_INFO,
    list
  };
};