import {
  ADD_IS_LOADED,
  ADD_WORKOUTS,
  ADD_CATEGORY_WORKOUTS,
  ADD_ACTIVE_CATEGORY,
  ADD_RECOMMENDED_WORKOUTS,
  ADD_QUOTE
} from '../constants/homeTypes';

export const setIsLoaded = () => {
  return {
    type: ADD_IS_LOADED
  };
};

export const setWorkouts = (workouts) => {
  return {
    type: ADD_WORKOUTS,
    workouts
  };
};

export const setQuote = (quote, date) => {
  const localeQuote = {
    date,
    quote
  };
  localStorage.setItem("quote", JSON.stringify(localeQuote))


  return {
    type: ADD_QUOTE,
    quote
  };
};

export const setRecommendedWorkouts = (workouts) => {
  return {
    type: ADD_RECOMMENDED_WORKOUTS,
    workouts
  };
};

export const setCategoryWorkouts = (workouts) => {
  return {
    type: ADD_CATEGORY_WORKOUTS,
    workouts
  };
};

export const setActiveCategory = (category) => {
  return {
    type: ADD_ACTIVE_CATEGORY,
    category
  };
};
