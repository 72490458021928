import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Loader from '../../../components/common/Loader';

import "./LoaderFullBlock.sass";

const LoaderFullBlock = ({
  isLoading
}) => {
  return (
    <CSSTransition
      in={isLoading}
      timeout={550}
      classNames="loader-full-block-transition"
      unmountOnExit
    >
      <div className="loader-full-block">
        <Loader size={36} />
      </div>
    </CSSTransition>
  )
};

export default LoaderFullBlock;
