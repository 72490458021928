import React from 'react';

import clsx from 'clsx';

import './inputAuth.sass';

const InputAuth = ({ input, error, meta, icon, ...props }) => {
  const hasError = (meta.touched && meta.error) || error;

  return (
    <>
      <div className={clsx("input-auth-wrap", {
        "with-icon": icon
      })}>
        <input {...props} {...input} className={clsx("input-auth", {
          'error': hasError
        })} />
        {
          !icon || icon
        }
      </div>
      {
        !hasError || <span className="help-error">{error || meta.error}</span>
      }
    </>
  );
};


export default InputAuth;