import React from 'react';

import './tag.sass';

const Tag = ({
  children,
  className
}) => {

  const mainClass = 'tag';
  const classes = className ? `${mainClass} ${className}` : mainClass;

  return (
    <div className={classes}>{children}</div>
  );
};

export default Tag;

