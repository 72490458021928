import React from 'react';
import Loader from '../Loader';
import './loaderWrap.sass';

const LoaderWrap = ({
  children,
  spin,
  type,
  sizeLoader = 42,
  colorLoader = "#7DF3B3",
  height
}) => {

  if (type === 'static') {
    return (
      <div className="loader-wrap" style={{ height: height }}>
        <div className="loader-block">
          <Loader size={sizeLoader} color={colorLoader} />
        </div>
      </div>
    );
  }

  const innerWrapClasses = "inner-wrap " + (spin ? " spin" : "");

  return (
    <div className="loader-wrap">
      <div className={innerWrapClasses}>
        {children}
      </div>
      {!spin ||
        <div className="loader-block">
          <Loader size={32} color={colorLoader} />
        </div>
      }
    </div>
  )
};

export default LoaderWrap;
