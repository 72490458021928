import {
  SET_IS_LOADED,
  SET_IS_FETCHING,
  SET_LIST,
  CLEAR_DATA,
  SET_LIST_INFO,
  UPDATE_LIST,
  ADD_FILTERS_PARAMS,
  ADD_FAVORITE,
} from "../constants/categoryDetailTypes";

export const toggleFavorite = (id) => ({
  type: ADD_FAVORITE,
  payload: id,
});

export const setFilters = (filterParams, filtersString) => {
  return {
    type: ADD_FILTERS_PARAMS,
    filterParams,
    filtersString,
  };
};

export const setIsLoaded = () => {
  return {
    type: SET_IS_LOADED,
  };
};

export const setIsFetching = (isFetching) => {
  return {
    type: SET_IS_FETCHING,
    isFetching,
  };
};

export const setList = (list) => {
  return {
    type: SET_LIST,
    list,
  };
};

export const updateList = (list) => {
  return {
    type: UPDATE_LIST,
    list,
  };
};

export const srtListInfo = (listCount, listStart, categoryName) => {
  return {
    type: SET_LIST_INFO,
    listCount,
    listStart,
    categoryName,
  };
};

export const clearData = () => {
  return {
    type: CLEAR_DATA,
  };
};
